import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import React from 'react';
import { BackTitle } from 'api/file/interfaces/get-file-summary-report';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

type BackTitleInfoProps = {
  backTitles: BackTitle[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
      boxShadow: 'none',
    },
    tableHead: {
      padding: theme.spacing(1, 1, 0, 1),
      fontSize: '11pt',
      fontWeight: 800,
    },
    tableCell: {
      padding: theme.spacing(1, 1, 0, 1),
      fontSize: '10pt',
    },
  }),
);

const BackTitleInfo: React.FC<BackTitleInfoProps> = ({ backTitles }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>Document No.</TableCell>
            <TableCell className={classes.tableHead} align="left">
              Document Type
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
              Document Date
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
              Property Address
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {backTitles.map((row: BackTitle, index: number) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell} component="th" scope="row">
                {row.documentNumber}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.documentType}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.documentDate}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.propertyAddress}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BackTitleInfo;
