import React from 'react';
import { AppBar, AppBarProps, Container, createStyles, makeStyles, Theme } from '@material-ui/core';

export type L2NavProps = AppBarProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      flexDirection: 'row',
      height: 64,
      backgroundColor: theme.palette.background.paper,
    },
    centerBundleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export const L2Nav = ({ children, ...props }: L2NavProps): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar {...props} color="default" classes={{ root: classes.root }}>
      <Container maxWidth="lg" disableGutters className={classes.centerBundleContainer}>
        {children as ChildNode}
      </Container>
    </AppBar>
  );
};

export default L2Nav;
