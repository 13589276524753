/**
 * A set which filters unique objects, not just values, via deep compare
 * https://stackoverflow.com/questions/41404607/why-does-javascript-set-not-do-unique-objects
 * */
class DeepSet extends Set {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(o: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const i of this) if (this.deepCompare(o, i)) return this;
    super.add.call(this, o);
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private deepCompare(o: any, i: any) {
    return JSON.stringify(o) === JSON.stringify(i);
  }
}

export default DeepSet;
