import React from 'react';
import { propertyType } from 'api/file/interfaces/get-file';
import { withBaseFormGroup } from 'utilities/form/components/base-form';
import { createPropertySchema, propertySchema } from 'utilities/validation/schemas';
import PropertyGroup, { AddressTypeProperties } from './PropertyGroup';
import FileinfoProprty from './FileinfoProperty';

const PropertyGroupWithAgentNetBase = (props: AddressTypeProperties): JSX.Element => {
  const formWithBase = withBaseFormGroup<propertyType, AddressTypeProperties>(
    props.isCreateProperty ? PropertyGroup : FileinfoProprty,
    {
      ...props,
      schema: props.isCreateProperty ? createPropertySchema : propertySchema,
    },
  );
  return <>{formWithBase}</>;
};
export default PropertyGroupWithAgentNetBase;
