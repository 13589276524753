import { grey } from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    brand: {
      blue: string;
      blueDark: string;
      brightBlue: string;
      cyan: string;
      cyanDark: string;
      yellow: string;
      lightBlue: string;
      lightGrey: string;
      lightGrey50: string;
      lightGrey25: string;
      sky25: string;
      sky50: string;
    };
    actionSecondary: {
      active: string;
      hover: string;
      hoverOpacity: number;
      selected: string;
      disabled: string;
      disabledBackground: string;
      focus: string;
    };
  }
  interface PaletteOptions {
    brand?: Palette['brand'];
    actionSecondary?: Palette['actionSecondary'];
  }
}

const palette = createPalette({
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: {
    main: '#0075C3',
    dark: '#00497A',
    light: '#D0E3F5',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#65788F',
    dark: '#3D4D5F',
    light: '#D5DCE2',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#AC1209', // red/600
    dark: '#9A0C05', // red/800
    light: '#BE382F', // red/400
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FB8C00', // orange/600
    dark: '#EF6C00', // orange/800
    light: '#FFA726', // orange/400
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#039BE5', // lightBlue/600
    dark: '#0277BD', // lightBlue/800
    light: '#29B6F6 ', // lightBlue/400
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#43A047', // green/600
    dark: '#2E7D32', // green/800
    light: '#66BB6A', // green/400
    contrastText: '#FFFFFF',
  },
  text: {
    primary: grey[800],
    secondary: grey[600],
    disabled: grey[500],
  },
  divider: '#CFD8DC', // blueGray/100
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  action: {
    active: '#0075C3', // primary/main
    hover: grey[100],
    hoverOpacity: 0.12,
    selected: '#EBEBEB',
    disabled: '#989898',
    disabledBackground: grey[100],
    focus: grey[300],
  },
  actionSecondary: {
    active: '#65788F', // secondary/main
    hover: '#65788f0a',
    hoverOpacity: 0.04,
    selected: '#65788f14',
    disabled: '#65788f61',
    disabledBackground: '#65788f1f',
    focus: '#65788f1f',
  },
  brand: {
    blue: '#003D66',
    blueDark: '#141D3A',
    cyan: '#4EADE1',
    cyanDark: '#1688C5',
    yellow: '#EBBD5F',
    lightBlue: '#DCEFF9',
    lightGrey: '#F4F4F4',
    lightGrey50: '#F9F9F9',
    lightGrey25: '#FAFAFA',
    brightBlue: '#0075C3',
    sky25: '#F6FBFD',
    sky50: '#EDF7FC',
  },
});

export default palette;
