import appConfig from '../../app.config.json';

/* Use Async GET to Retrieve Google Address API */
export const getStreetViewUrl = async (location: string, size: string): Promise<string> => {
  location = encodeURIComponent(location);
  const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=${size}&location=${location}&key=${appConfig.google_maps_api_key}`;
  const metadataUrl = `https://maps.googleapis.com/maps/api/streetview/metadata?location=${location}&key=${appConfig.google_maps_api_key}`;

  const response = await fetch(metadataUrl, { method: 'GET' });
  const json = await response.json();

  if (json?.status !== 'OK') {
    return `https://maps.googleapis.com/maps/api/staticmap?size=${size}&markers=size:mid%7Ccolor:red%7C${location}&maptype=satellite&key=${appConfig.google_maps_api_key}`;
  }

  return streetViewUrl;
};
