import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { createStyles, makeStyles, TextField, TextFieldProps, Typography } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    helperText: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export type CharacterLimitTextareaProps = TextFieldProps & { characterLimit?: number; value?: string };

export const CharacterLimitTextarea = ({
  characterLimit = 255,
  value,
  error,
  onChange,
  ...props
}: CharacterLimitTextareaProps): JSX.Element => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState(value?.replace('/r', '') || '');

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
    } else {
      props?.onKeyPress?.(event);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setInputValue(event.target.value);
    onChange?.(event);
  };

  return (
    <TextField
      {...props}
      value={inputValue}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      error={!!error}
      inputProps={{ maxLength: characterLimit }}
      helperText={
        <span className={classes.helperText}>
          {error ? (
            <Typography data-testid="error" variant="caption">
              {error}
            </Typography>
          ) : (
            <Typography data-testid="char-limit" variant="caption">
              {characterLimit} Characters or less
            </Typography>
          )}
          <Typography data-testid="char-count" variant="caption">
            {inputValue?.length || 0}/{characterLimit}
          </Typography>
        </span>
      }
    />
  );
};

export default CharacterLimitTextarea;
