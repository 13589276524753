import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, FilterOptionsState } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import states from './states.json';

type stateType = {
  name: string;
  abbreviation: string;
};

export type StateSelectFieldProps = Partial<TextFieldProps> & {
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  shouldDisplayAbbreviation?: boolean;
  shouldDisableClearable?: boolean;
};

export function StateSelectField({
  value: valueProp = '',
  onBlur = () => undefined,
  onChange = () => undefined,
  id = '',
  shouldDisplayAbbreviation = true,
  shouldDisableClearable = false,
  disabled = false,
  ...props
}: StateSelectFieldProps): JSX.Element {
  const statesByAbbr: { [key: string]: stateType } = useMemo(
    () => states.reduce((prev, current) => ({ ...prev, [current.abbreviation]: current }), {}),
    [],
  );

  const [value, setValue] = useState<stateType | null>(null);

  useEffect(() => {
    if (value !== statesByAbbr[valueProp]) {
      setValue(statesByAbbr[valueProp] || null);
    }
  }, [valueProp]);

  const filterStates = (options: stateType[], searchValue: FilterOptionsState<stateType>) => {
    const input = searchValue.inputValue.toLowerCase();

    if (input == '') {
      return options;
    }

    // Filter down state results that matches the first letter of the input
    const filteredOptions = options.filter((state: stateType) => {
      const stateResult = state.abbreviation.toLowerCase();
      return stateResult.includes(input) && stateResult[0] == input[0];
    });

    // Sort alphabetically
    return filteredOptions.sort((stateA, stateB) => {
      return stateA.abbreviation.localeCompare(stateB.abbreviation);
    });
  };

  return (
    <Autocomplete
      autoComplete
      disableClearable={shouldDisableClearable}
      clearOnEscape
      autoSelect
      disabled={disabled}
      autoHighlight
      filterOptions={filterStates}
      onBlur={onBlur}
      options={states}
      getOptionLabel={(option: stateType) => (shouldDisplayAbbreviation ? option.abbreviation : option.name)}
      getOptionSelected={(option, state) => option.abbreviation === state.abbreviation}
      value={value}
      onChange={(_, value) => {
        onChange(value?.abbreviation || '');
        setValue(value);
      }}
      renderInput={(param) => (
        <TextField
          label="State"
          {...param}
          {...props}
          inputProps={{ ...param.inputProps, 'aria-label': 'state-field-input-select' }}
        />
      )}
      id={id}
      data-testid={id}
    />
  );
}
export default StateSelectField;
