import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CardIcon from './Icons/CardIcon';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import PaymentCard from 'ui-kit/components/card/PaymentCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '24px',
      marginTop: '32px',
    },
    cardtext: {
      textAlign: 'center',
      gap: '8px',
      display: 'flex',
      flexDirection: 'column',
    },
    responsiveCard: {
      transition: 'all 0.3s ease-in-out',
      maxWidth: '340px',
    },
  }),
);

const PaymentCards = ({ paymentMethodData, handleOpenDrawer, onEdit, onDelete }: any) => {
  const classes = useStyles();

  if (paymentMethodData?.length === 0)
    return (
      <Grid item sm={12}>
        <Grid className={classes.cardContainer}>
          <CardIcon />
          <div className={classes.cardtext}>
            <Typography variant="h3">No Payment Methods On File</Typography>
            <Typography variant="body2">Would you like to add one now?</Typography>
          </div>
          <AgentNetButton color="primary" size="medium" variant="contained" onClick={handleOpenDrawer}>
            Add New Payment Method
          </AgentNetButton>
        </Grid>
      </Grid>
    );

  return paymentMethodData.map((data: any) => {
    const {
      paymentType,
      accountAliasId,
      cardBrand,
      nameOnAccount,
      nickName,
      category,
      accountType,
      expirationDate,
      accountNumber,
    } = data;
    return (
      <Grid item key={accountAliasId} className={classes.responsiveCard} sm={6} md={4}>
        <PaymentCard
          key={accountAliasId}
          ePayName={nameOnAccount}
          ePayNickName={nickName as string}
          accountType={`${category ?? ''} ${accountType ?? ''}`.trim()}
          ePayType={paymentType === 'CC' ? (cardBrand as string) : paymentType}
          goodThru={expirationDate}
          cardEnding={accountNumber}
          accountAliasId={accountAliasId}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Grid>
    );
  });
};

export default PaymentCards;
