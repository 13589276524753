import React from 'react';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';

interface ServiceOrderActionProps {
  hasServiceOrders?: boolean;
  saveServiceOrder: () => void;
  cancelServiceOrder?: () => void;
  isSaveDisabled?: boolean;
}
const ServiceOrderActions: React.FC<ServiceOrderActionProps> = ({
  hasServiceOrders,
  saveServiceOrder,
  cancelServiceOrder,
  isSaveDisabled,
}: ServiceOrderActionProps) => {
  return (
    <BottomAppBar
      onCancel={() => {
        cancelServiceOrder && cancelServiceOrder();
      }}
      primaryActionLabel={hasServiceOrders ? 'Update Service Order' : 'Place Service Order'}
      primaryActionDataQa={'ServiceOrderPlaceOrder'}
      cancelDataQa={'ServiceOrderCancel'}
      onPrimaryClick={() => saveServiceOrder && saveServiceOrder()}
      disableCancel={false}
      showPrimary={true}
      disablePrimary={isSaveDisabled}
      accordionView
    />
  );
};
export default ServiceOrderActions;
