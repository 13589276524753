import React from 'react';
import NestedRouteLayout from '../NestedRouteLayout';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';

const ServiceContent = ({ routes }: never): JSX.Element => {
  return (
    <ContentContainer fullWidth>
      <NestedRouteLayout {...{ routes }} />
    </ContentContainer>
  );
};

export default ServiceContent;
