import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'required';

export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (maxLength?: number): string => `Max length is ${maxLength}`;

const max_characters = {
  message: 1000,
};

export const emailSchema = (): any => {
  return yup.object().shape({
    emails: yup
      .string()
      .required(requiredFieldMessage('Email'))
      .test('emails', 'Invalid email format', (value) => {
        const emails = (value ?? '').split(';');
        for (const email of emails) {
          if (!yup.string().email().isValidSync(email.trim())) {
            return false;
          }
        }
        return true;
      }),
    message: yup.string().max(max_characters.message, maxLengthFieldMessage(max_characters.message)),
  });
};
