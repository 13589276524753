import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function MonetizationIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <path
        fill="#48799C"
        d="M9.99996 1.66667C5.39996 1.66667 1.66663 5.4 1.66663 10C1.66663 14.6 5.39996 18.3333 9.99996 18.3333C14.6 18.3333 18.3333 14.6 18.3333 10C18.3333 5.4 14.6 1.66667 9.99996 1.66667ZM11.175 15.075V16.6667H8.94996V15.0583C7.52496 14.7583 6.31663 13.8417 6.22496 12.225H7.85829C7.94163 13.1 8.54163 13.7833 10.0666 13.7833C11.7 13.7833 12.0666 12.9667 12.0666 12.4583C12.0666 11.7667 11.7 11.1167 9.84163 10.675C7.77496 10.175 6.35829 9.325 6.35829 7.61667C6.35829 6.18333 7.51663 5.25 8.94996 4.94167V3.33333H11.175V4.95833C12.725 5.33333 13.5 6.50833 13.55 7.78333H11.9166C11.875 6.85833 11.3833 6.225 10.0666 6.225C8.81663 6.225 8.06663 6.79167 8.06663 7.59167C8.06663 8.29167 8.60829 8.75 10.2916 9.18333C11.975 9.61667 13.775 10.3417 13.775 12.4417C13.7666 13.9667 12.625 14.8 11.175 15.075Z"
      ></path>
    </SvgIcon>
  );
}

export default MonetizationIcon;
