import React from 'react';
import { CPLStatusType } from 'features/cpl/types';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';

interface CplActionProps {
  index?: number;
  status?: CPLStatusType;
  saveCPL: (status: 'Open' | 'Pending') => void;
  cancelCPL?: () => void;
  isSaveDisabled?: boolean;
  isStarsStatusActive?: boolean;
  noStarsStatusActive?: boolean;
  isNewCpl?: boolean;
  onViewMode?: () => void;
}
const CPLActions: React.FC<CplActionProps> = ({
  index,
  status = '',
  saveCPL,
  cancelCPL,
  isSaveDisabled,
  isStarsStatusActive,
  noStarsStatusActive,
  isNewCpl,
  onViewMode,
}: CplActionProps) => {
  return (
    <BottomAppBar
      onCancel={() => {
        isNewCpl ? cancelCPL && cancelCPL() : onViewMode && onViewMode();
      }}
      primaryActionLabel={status === 'Open' ? 'Update CPL' : 'Create CPLs'}
      onPrimaryClick={() => saveCPL('Open')}
      disableCancel={isSaveDisabled}
      cancelDataQa={index ? `CPL${index}Cancel` : 'CPLCancel'}
      primaryActionDataQa={
        status === 'Open' ? (index ? `CPL${index}Update` : 'CPLUpdate') : index ? `CPL${index}Create` : 'CPLCreate'
      }
      showPrimary={status !== 'Void' && !isStarsStatusActive && !noStarsStatusActive}
      disablePrimary={isSaveDisabled}
      accordionView
    />
  );
};
export default CPLActions;
