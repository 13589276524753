import React, { ReactElement } from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    articleTip: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#eff4f8',
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      lineHeight: theme.spacing(3) + 'px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    articleTipIcon: {
      stroke: '#1C94B9',
      strokeWidth: 0,
      fill: '#1C94B9',
      fontSize: theme.spacing(4),
      marginRight: theme.spacing(3),
      marginTop: -theme.spacing(0.5),
      marginBottom: -theme.spacing(0.5),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  }),
);

export function ArticleTip({ articleTip }: { articleTip: string }): ReactElement | null {
  const classes = useStyles();

  if (!articleTip) {
    return null;
  }

  return (
    <Grid item sm={12}>
      <div className={classes.articleTip}>
        <Typography component="div" variant="body1" color="primary">
          <svg
            className={classes.articleTipIcon}
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="LightbulbOutlinedIcon"
          >
            <path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z"></path>
          </svg>
        </Typography>
        <Typography component="div" variant="body1" color="textPrimary">
          {articleTip}
        </Typography>
      </div>
    </Grid>
  );
}
