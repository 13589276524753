export enum SelectPartyType {
  buyer = 'buyer',
  seller = 'seller',
  lender = 'lender',
}

export enum PartyGroupType {
  Buyer = 'Buyer/Borrower',
  Seller = 'Seller/Owner',
  Lender = 'Lender',
}

export enum PartyTypeSubGroup {
  Address = 'Address',
  PersonalIdentification = 'PersonalIdentification',
  SpousePersonalIdentification = 'SpousePersonalIdentification',
}
