import React, { useState } from 'react';

export type ContentfulErrorType = {
  contentfulErrorType: ContentfulErrorTypes | null;
  setContentfulErrorType: (errorType: ContentfulErrorTypes | null) => void;
};

export enum ContentfulErrorTypes {
  Error = 'error',
  NoContent = 'no-content',
}

export const ContentfulErrorContext = React.createContext<ContentfulErrorType>(null as never);

export const ContentfulErrorProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [contentfulErrorType, setContentfulErrorType] = useState<ContentfulErrorTypes | null>(null);

  return (
    <ContentfulErrorContext.Provider value={{ contentfulErrorType, setContentfulErrorType }}>
      {children}
    </ContentfulErrorContext.Provider>
  );
};
