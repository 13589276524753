import { createTheme } from '@material-ui/core/styles';
import palette from './palette/palette';
import overrides from './overrides';
import typography from './typography/typography';
import breakpoints from './breakpoints';

export const theme = createTheme({
  palette: {
    ...palette,
  },
  typography: {
    ...typography,
  },
  overrides: {
    ...overrides,
  },
  breakpoints: {
    ...breakpoints,
  },
});
