import React from 'react';

export function boldSearchPhrase(searchPhrase?: string, text?: string) {
  const result: React.ReactNode[] = [];

  if (!searchPhrase || !text) {
    return;
  }

  let textRemaining = text;
  let foundIndex = -1;
  while ((foundIndex = textRemaining.toLowerCase().indexOf(searchPhrase)) >= 0) {
    const before = textRemaining.slice(0, foundIndex);
    const after = textRemaining.slice(foundIndex + searchPhrase.length);
    result.push(before);
    result.push(<b>{textRemaining.slice(foundIndex, foundIndex + searchPhrase.length)}</b>);
    textRemaining = after;
  }

  if (!result.length) {
    return text;
  }

  result.push(textRemaining);
  return result;
}
