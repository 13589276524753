import React, { useState, ReactNode, FC, MouseEvent } from 'react';
import { Box, Typography, Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Ellipsis from '@material-ui/icons/MoreVertOutlined';

interface MenuItem {
  label: string;
  link: string;
}

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  divider?: boolean;
  menuItems?: MenuItem[];
}
const useStyles = makeStyles(() =>
  createStyles({
    menuItemBtn: {
      padding: '0 10px',
      '&:hover': {
        background: 'transparent',
      },
    },
  }),
);

const PageHeader: FC<PageHeaderProps> = ({
  title,
  subtitle,
  contentLeft,
  contentRight,
  divider = true,
  menuItems = [],
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box m={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Box display="flex">
            <Typography variant="h1" component="h1">
              {title}
            </Typography>
            <Box display="flex">
              {menuItems.length > 0 && (
                <>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={classes.menuItemBtn}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    color="secondary"
                  >
                    <Ellipsis fontSize="large" />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                  >
                    {menuItems.map((item, index) => (
                      <MenuItem key={index} onClick={() => window.open(item.link, '_blank')}>
                        <Typography variant="body1">{item.label}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
              {contentLeft && <Box mb={2}>{contentLeft}</Box>}
            </Box>
          </Box>
          {contentRight && <Box mr={2}>{contentRight}</Box>}
        </Box>
        {subtitle && (
          <Typography variant="body1" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Box>
      {divider && (
        <Box marginX={3}>
          <Divider />
        </Box>
      )}
    </>
  );
};

export default PageHeader;
