import { Property } from 'api/file/interfaces/get-file-order-insights';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme, Grid, Typography } from '@material-ui/core';
import React from 'react';

type PropertyInfoProps = {
  property: Property;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    fixedWidth: {
      width: '300px',
    },
    f8: {
      fontSize: '9pt',
      paddingLeft: theme.spacing(1),
    },
  }),
);

const PropertyInfo: React.FC<PropertyInfoProps> = ({ property }) => {
  const classes = useStyles();

  return (
    <div className={classes.fullWidth}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>NAIC Property Type:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.propertyType}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>County:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.address?.county}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Census Tract / Block:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.censusTractBlock}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>APN:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.apn}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Township-Range-Sect:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.townshipRangeSection}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Alternate APN:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.alternateAPN}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Legal Book / Page:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.legalBookPage}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Legal Lot:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.legalLot}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Map Reference:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.mapReference}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Legal Block:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.legalBlock}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Tract:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.tract}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Section:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.legalSection}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>District:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.legalDistrict}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Market Area:</strong>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{property.marketArea}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Subdivision:</strong>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.f8}>{property.subdivision}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>School District:</strong>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.f8}>{property.schoolDistrict}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Short Legal Description:</strong>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.f8}>{property.legalDescription}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>
            <strong>Long Legal Description:</strong>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.f8}>{property.longLegalDesc}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PropertyInfo;
