/* TODO: Refactor
 * Make state options/values pass-in only with all-states options the default (handle logic at parents where it belongs)
 * Get rid of all these state-related bools and logic
 * Run by Christine: Simplify validation by establishing at lower component levels and unplug validation props coming from withBaseForm component
 */
import React, { useContext, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { createStyles, FormControlLabel, Grid, GridProps, makeStyles, Typography } from '@material-ui/core';
import DomesticAddressField from '../file-create/DomesticAddress';
import { FileCreateContext, FileCreateContextInterface } from '../../../hooks/FileCreateContext';
import { FileDataContext, FileDataContextInterface } from '../../../hooks/FileDataContext';
import AgentNetDropdownSelector from '../../../ui-kit/inputs/AgentNetDropdownSelector';
import { AgentNetTextInput } from '../../../ui-kit/inputs/TextField';
import { propertyType } from '../../../api/file/interfaces/get-file';
import { propertyDomesticAddressSchema } from 'utilities/validation/schemas';
import { IAgentNetBaseFormGroupProps } from 'utilities/form/components/base-form';
import './Property.scss';
import '../file-information/form/FileInformationForm.scss';
import Switch from '@material-ui/core/Switch';
import { PropertyTypeOptions } from '../../constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formContainer: {
      [theme.breakpoints.up('sm')]: {
        marginTop: '0px !important',
      },
    },
    outsidePropertySwitch: {
      marginLeft: theme.spacing(2),
    },
    titleTypography: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  }),
);

export interface OutsideJurisdictionStates {
  name: string;
  abbreviation: string;
}

export interface AddressTypeProperties extends IAgentNetBaseFormGroupProps<propertyType> {
  ContainerComponentProps?: GridProps;
  addProperty?: () => void;
  isFileReady?: boolean;
  isFileInformation: boolean;
  isCreateProperty: boolean;
  listIndex?: number;
  isDrawerOpen?: boolean;
  showOutsideJurisdictionControl?: boolean;
  isCreateFileForm?: boolean;
  outsideJurisdictionStates?: OutsideJurisdictionStates[];
  isFirstPropertyStateOnly?: boolean;
  disableState?: boolean;
  currentIndex?: number;
  properties?: propertyType[];
  setProperties?: any;
  handleGenerateReport?: (fullAddress: string) => void;
  handlePropertyDelete?: (index: number, propertyId: string) => void;
  defaultProperty?: propertyType[];
  propertyRefs?: any;
  showTitleTypography?: boolean;
}

const PropertyGroup: React.FC<AddressTypeProperties> = ({
  onChange,
  value = {},
  ContainerComponentProps,
  isDrawerOpen = false,
  showOutsideJurisdictionControl = false,
  isCreateProperty,
  validationErrors,
  showAllValidation,
  setSubFormGroupValidity,
  outsideJurisdictionStates = [],
  isFirstPropertyStateOnly = false,
  isFileInformation = false,
  disableState = false,
  isFileReady = false,
  currentIndex = 0,
  defaultProperty,
  showTitleTypography = false,
}: AddressTypeProperties) => {
  const fileCtx: FileCreateContextInterface = useContext(FileCreateContext) ?? {};
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const fileCreateData = fileCtx?.fileCreateData || {};
  const { fileData } = fileDataCtx;
  const classes = useStyles();
  const { address1, address2, city, postalCode, propertyType, apn, county, kyTaxCodeResult } = value;
  const isOutsideJurisdiction = value.isOutsideJurisdiction || false;

  let state: string = value.state || '';

  if (showOutsideJurisdictionControl && !isOutsideJurisdiction) {
    state = fileCreateData?.stateCode || '';
  }

  useEffect(() => {
    if (!isOutsideJurisdiction) {
      state = fileCreateData?.stateCode || '';
    }
  }, [isOutsideJurisdiction]);

  let stateOptions: { name: string; abbreviation: string }[] = [];

  if (isOutsideJurisdiction && outsideJurisdictionStates.length) {
    stateOptions = outsideJurisdictionStates;
  } else if (isFirstPropertyStateOnly || (showOutsideJurisdictionControl && !isOutsideJurisdiction)) {
    stateOptions = state ? [{ name: state || '', abbreviation: state || '' }] : [];
  }

  const isKYVisible = useMemo(
    () => defaultProperty && defaultProperty[currentIndex] && defaultProperty[currentIndex].state === 'KY',
    [defaultProperty, currentIndex],
  );
  /**
   * Logic for disabling the Property-State
   * based on File-State and outside jurisdiction selection
   */
  const isStateDisabled = (): boolean => {
    if (disableState) {
      return true;
      /* disabled when file state is selected, and it's not outside-j */
    } else if (fileCreateData?.stateCode && !isOutsideJurisdiction) {
      return true;
    } else if (!fileCreateData?.stateCode) {
      /* disabled when file state is not selected, and it's not outside-j */
      return !isOutsideJurisdiction;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={clsx({ [classes.titleTypography]: showTitleTypography === true })}>
        {showTitleTypography && <Typography variant="h3">Property</Typography>}
        {showOutsideJurisdictionControl && (
          <FormControlLabel
            control={
              <Switch
                checked={isOutsideJurisdiction ?? false}
                onChange={() => {
                  onChange({
                    ...value,
                    isOutsideJurisdiction: !isOutsideJurisdiction,
                    state: !isOutsideJurisdiction ? '' : value.state ?? '',
                    county: !isOutsideJurisdiction ? '' : value.county ?? '',
                  });
                }}
                {...(isCreateProperty ? { 'data-qa': 'CreateFileOutsidePropertyJurisdiction' } : {})}
                name="Outside Property Jurisdiction"
                size="small"
              />
            }
            label="Outside Property Jurisdiction"
            value={isOutsideJurisdiction ?? false}
            className={classes.outsidePropertySwitch}
          />
        )}
      </div>
      <Grid container {...ContainerComponentProps} spacing={3} className={classes.formContainer}>
        <Grid item xs={6} sm={6}>
          <AgentNetDropdownSelector
            disabled={fileData?.fileStatus !== 'Open' && isFileInformation}
            name="propertyType"
            id="propertyType"
            label="NAIC Property Type"
            required
            defaultValue=""
            options={PropertyTypeOptions}
            value={propertyType}
            menuOption={(val: string) => onChange({ ...value, propertyType: val })}
            dropdowntype="outlined"
            errs={validationErrors}
            showValidation={showAllValidation}
            {...(isCreateProperty
              ? { 'data-qa': 'CreateFileNAICPropertyType' }
              : { 'data-qa': `FileInfoProperty${currentIndex + 1}NAICPropertyType` })}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <AgentNetTextInput
            disabled={fileData?.fileStatus !== 'Open' && isFileInformation}
            fullWidth
            variant="outlined"
            label={'APN / Parcel'}
            errs={validationErrors}
            showValidation={showAllValidation}
            name="apn"
            id="apn"
            value={apn ?? ''}
            onChange={(e) => {
              onChange({ ...value, apn: e.target.value });
            }}
            {...(isCreateProperty
              ? { 'data-qa': 'CreateFileAPNParcel' }
              : { 'data-qa': `FileInfoProperty${currentIndex + 1}APNParcel` })}
            tooltipQAPrefix={isCreateProperty ? 'CreateFileAPNParcel' : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <DomesticAddressField
            disabled={fileData?.fileStatus !== 'Open' && isFileInformation}
            showCounty={true}
            disableState={isStateDisabled()}
            schema={propertyDomesticAddressSchema}
            isOutsideJurisdiction={isOutsideJurisdiction}
            setIsValid={(isValid) => setSubFormGroupValidity && setSubFormGroupValidity('domestic-address', isValid)}
            isFileInformation={isFileInformation}
            displayRequiredAddressFields={true}
            value={{
              address1: address1,
              address2: address2 ?? '',
              city: city,
              postalCode: postalCode,
              state: state,
              county: county,
            }}
            stateOptions={stateOptions.length ? stateOptions : false}
            showAllValidation={showAllValidation}
            stateOrProvinceField={false}
            useGoogleAutocomplete={true}
            {...(isCreateProperty
              ? { qaAttributePrefix: 'CreateFileProperty' }
              : { qaAttributePrefix: `FileInfoProperty${currentIndex + 1}` })}
            onCountyUpdate={(e: any) => {
              onChange({ ...value, ...e });
            }}
            onChange={(e) => {
              const address2 = value?.address2 ?? '';
              if (isKYVisible && isFileReady && isFileInformation && address2 === e?.address2 && state === 'KY') {
                const updatedTaxCode = { ...kyTaxCodeResult, taxCode: '' };
                onChange({ ...value, ...e, kyTaxCodeResult: updatedTaxCode, taxCode: '' });
              } else {
                onChange({ ...value, ...e });
              }
            }}
          />
        </Grid>

        {!isCreateProperty && state === 'KY' && isKYVisible && isDrawerOpen === false && (
          <Grid item xs={12} sm={6} md={6} lg={3} key={'Kentucky Premium'} className="tax-code-container">
            <AgentNetTextInput
              disabled={fileData?.fileStatus !== 'Open' && isFileInformation}
              fullWidth
              variant="outlined"
              key={'Kentucky Premium Tax Code'}
              name="kyTaxCodeResult.taxCode"
              label={'Kentucky Premium Tax Code'}
              errs={validationErrors}
              showValidation={showAllValidation}
              value={(kyTaxCodeResult?.isSuccess && kyTaxCodeResult?.taxCode) || ''}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                const updatedTaxCode = { ...kyTaxCodeResult, taxCode: e.target.value };
                onChange({ ...value, kyTaxCodeResult: updatedTaxCode, taxCode: e.target.value });
              }}
              data-qa={`FileInfoProperty${currentIndex + 1}KYPremiumTaxCode`}
              icon="pdf"
              className="tax-code-container"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PropertyGroup;
