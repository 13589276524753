export const officeLevelAutoReportingStatus = [
  { name: 'All', value: 'All' },
  { name: 'On', value: 'On' },
  { name: 'Off', value: 'Off' },
];

export const isCplOnly = [
  { name: 'All', value: 'All' },
  { name: 'Yes', value: 'Yes' },
  { name: 'No', value: 'No' },
];

export const isFileByFile = [
  { name: 'All', value: 'All' },
  { name: 'Yes', value: 'Yes' },
  { name: 'No', value: 'No' },
];

export const dayAfterPolicyDate = [
  { name: 'All', value: 'All' },
  { name: '5', value: '5' },
  { name: '15', value: '15' },
  { name: '30', value: '30' },
  { name: '60', value: '60' },
];

export const frequency = [
  { name: 'All', value: 'All' },
  { name: 'Daily', value: 'Daily' },
  { name: 'Weekly', value: 'Weekly' },
  { name: 'Bi-Monthly (15th & 25th)', value: 'Bi-Monthly (15th & 25th)' },
  { name: 'Monthly (25th)', value: 'Monthly (25th)' },
];

export const daysAfterPolicyDate = [
  { name: '5', value: '5' },
  { name: '15', value: '15' },
  { name: '30', value: '30' },
  { name: '45', value: '45' },
  { name: '60', value: '60' },
];

export const reportDays = [
  { name: 'Monday', value: 'Monday' },
  { name: 'Tuesday', value: 'Tuesday' },
  { name: 'Wednesday', value: 'Wednesday' },
  { name: 'Thursday', value: 'Thursday' },
  { name: 'Friday', value: 'Friday' },
];

export const frequencyFirm = [
  { name: 'Daily', value: 'Daily' },
  { name: 'Weekly', value: 'Weekly' },
  { name: 'Bi-Monthly (15th & 25th)', value: 'Bi-Monthly (15th & 25th)' },
  { name: 'Monthly (25th)', value: 'Monthly (25th)' },
];
