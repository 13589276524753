import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, Grid, GridProps, IconButton, makeStyles, TextFieldProps, Theme } from '@material-ui/core';
import { AddCircle, RemoveCircleOutline } from '@material-ui/icons';

import cuid from 'cuid';
import PhoneFieldGroup, { PhoneFields } from './PhoneFieldGroup';
import StarAdornment from './StarAdornment';
import ConfirmationDialog from '../ConfirmationDialog';
import { PhoneFieldProps } from '../PhoneField';
import { ExtFieldProps } from './ExtensionField';

type CustomPhoneFieldGroupProps = {
  PhoneTypeSelectProps?: TextFieldProps;
  PhoneFieldProps?: PhoneFieldProps;
  ExtFieldProps?: ExtFieldProps;
};

export type MultiRowPhoneFieldGroupProps = {
  ContainerComponentProps?: GridProps;
  value?: PhoneFields[];
  onChange: (value: PhoneFields[]) => void;
  onBlur?: (value: PhoneFields[]) => void;
  InputProps?: CustomPhoneFieldGroupProps;
  errors?: (string | undefined)[];
  typeErrors?: (string | undefined)[];
  validationTrigger?: 'onChange' | 'onBlur';
  confirmModal?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    delete: {
      color: theme.palette.text.secondary,
    },
    add: {
      color: theme.palette.primary.main,
    },
  }),
);

const generateDefaultValue = (value?: PhoneFields) => {
  const id = cuid();

  return {
    id,
    type: '',
    phoneNumber: '',
    ext: '',
    isPrimary: false,
    ...value,
  } as PhoneFields;
};

export const MultiRowPhoneFieldGroup = (props: MultiRowPhoneFieldGroupProps): JSX.Element => {
  const {
    ContainerComponentProps,
    errors,
    typeErrors,
    onBlur,
    onChange,
    value,
    validationTrigger = 'onChange',
    confirmModal = true,
    InputProps,
  } = props;

  const classes = useStyles();
  const [state, setState] = useState([generateDefaultValue({ isPrimary: true })]);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (value) {
      const updatedValue = (value.length ? value : [generateDefaultValue({ isPrimary: true })]).map(
        generateDefaultValue,
      );
      setState(updatedValue);
    }
  }, [value]);

  const revalidate = (newState: PhoneFields[]) => {
    // NOTE: We need to cause validation to retrigger on the specified user event
    const validationHandler = (props as MultiRowPhoneFieldGroupProps)[validationTrigger];
    if (validationHandler) validationHandler(newState);
  };

  const updateState = (state: PhoneFields[]) => {
    setState(state);
    onChange(state);
  };

  const handleChange = (value: PhoneFields, index: number) => {
    const newState = [...state];
    newState[index] = { ...value };

    updateState(newState);
  };

  const handleBlur = () => {
    if (onBlur) onBlur(state);
  };

  const handleRowEvent = (state: PhoneFields[]) => {
    updateState(state);
    revalidate(state);
  };

  const handleToggle = (value: PhoneFields, index: number) => {
    const newState = [...state];

    if (!value.isPrimary && index != 0) {
      // Update previous primary to false before moving new primary to top row
      newState[0]['isPrimary'] = false;
      newState[index]['isPrimary'] = true;

      // Move new primary to top of the row
      newState.splice(index, 1);
      newState.unshift(value);

      handleRowEvent(newState);
    }
  };

  const deleteRow = (index: number) => {
    const newState = [...state];
    newState.splice(index, 1);

    handleRowEvent(newState);
  };

  const addRow = () => {
    const newRow: PhoneFields = generateDefaultValue();
    const newState = [...state];

    newState.splice(1, 0, newRow);

    handleRowEvent(newState);
  };

  const handleOpen = (index: number) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    deleteRow(currentIndex);
    handleClose();
  };

  return (
    <Grid container {...ContainerComponentProps}>
      {state.map((group, index) => {
        const error = errors && errors[index];
        const typeError = typeErrors && typeErrors[index];

        const adornment =
          state.length > 1 ? (
            <StarAdornment
              color="primary"
              edge="end"
              isFilled={group.isPrimary as boolean}
              onToggle={() => handleToggle(group, index)}
              data-testid="primary-phone-star"
            />
          ) : null;

        return (
          <Grid
            item
            container
            xs={12}
            key={group.id}
            role="row"
            id={group.id}
            data-testid="multi-row-phone-field-group"
          >
            <Grid item xs={11}>
              <PhoneFieldGroup
                PhoneTypeSelectProps={{
                  label: 'Phone Type',
                  ...InputProps?.PhoneTypeSelectProps,
                }}
                PhoneFieldProps={{
                  InputProps: {
                    endAdornment: adornment,
                  },
                  label: 'Phone Number',
                  placeholder: '###-###-####',
                  ...InputProps?.PhoneFieldProps,
                }}
                ExtFieldProps={{
                  label: 'Ext',
                  ...InputProps?.ExtFieldProps,
                }}
                onChange={(e) => handleChange(e, index)}
                onBlur={() => handleBlur()}
                value={group}
                error={error}
                typeError={typeError}
              />
            </Grid>
            <Grid item xs={1}>
              {index == 0 && (
                <IconButton
                  key={index}
                  onClick={addRow}
                  className={clsx(classes.add)}
                  aria-label="add-row-button"
                  data-testid="add-phone-button"
                >
                  <AddCircle />
                </IconButton>
              )}
              {state.length > 1 && index != 0 && (
                <IconButton
                  key={index}
                  onClick={() => (confirmModal ? handleOpen(index) : deleteRow(index))}
                  className={clsx(classes.delete)}
                  aria-label="delete-row-button"
                  data-testid="delete-phone-button"
                >
                  <RemoveCircleOutline />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })}
      <ConfirmationDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        dialogText="You're about to remove a phone number from this record. Do you wish to continue?"
      />
    </Grid>
  );
};

export default MultiRowPhoneFieldGroup;
