import React, { useEffect, useState } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import './AgentNetButton.scss';

export interface ICustomButtonProps extends ButtonProps {
  hide?: boolean;
  danger?: boolean;
  loading?: boolean;
  'data-qa'?: string;
}

const AgentNetButton: React.FC<ICustomButtonProps> = (propsValues: ICustomButtonProps): JSX.Element => {
  const [props, changeProps] = useState(propsValues);
  useEffect(() => {
    changeProps(propsValues);
  }, [propsValues]);

  return (
    <>
      {!props.hide && (
        <Button
          disableRipple
          disableElevation
          {...props}
          className={`MuiAgentNetButton ${props.danger ? 'Mui-Error' : ''} ${props.className ? props.className : ''}`}
        >
          {props.loading && <CircularProgress size={20} className="MuiAgentNetButton-Spinner" />}
          {props.children}
        </Button>
      )}
    </>
  );
};

export default AgentNetButton;
