import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { AgentNetTextInput, AgentTextInputProps } from '../TextField';

export type PhoneFieldProps = AgentTextInputProps & {
  MaskedProps?: {
    format?: string;
    mask?: string;
    prefix?: string;
  };
};

const defaultMaskedProps = {
  format: '###-###-####',
  disabled: false,
};

const NumberMaskedField = ({ MaskedProps, onChange, max, ...props }: any): JSX.Element => {
  const [internalValue, setInternalValue] = React.useState(props.value);

  React.useEffect(() => {
    setInternalValue(props.value);
  }, [props.value]);

  return (
    <NumberFormat
      {...MaskedProps}
      {...(props as NumberFormatProps)}
      customInput={AgentNetTextInput}
      isAllowed={({ value }) => (max && value ? value.length <= max : true)}
      value={internalValue}
      onValueChange={(values) => {
        setInternalValue(values.value);
        if (!onChange) {
          return;
        }
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
    />
  );
};

export const PhoneField = ({ MaskedProps, InputProps, ...props }: any): JSX.Element => {
  return (
    <NumberMaskedField
      MaskedProps={{
        ...defaultMaskedProps,
        ...MaskedProps,
      }}
      InputProps={{
        ...InputProps,
      }}
      {...props}
    />
  );
};

export default PhoneField;
