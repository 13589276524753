import { handleError } from 'utilities/utilities';
import { getApiHost } from '../host-by-env';
import { AvlVerificationStatusType, VerificationStatusType } from 'utilities/verification/types';

export type VerificationResponseType = {
  result?: VerificationStatusType;
  data?: VerificationStatusType;
  errorCode: number | string;
};
export async function validatedCode(validationCode: string): Promise<VerificationResponseType> {
  const url = `${getApiHost()}cpl/verification/${validationCode}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const json = await response.json();
  handleError(json);
  return json;
}

export type AvlVerificationResponseType = {
  result?: AvlVerificationStatusType;
  data?: AvlVerificationStatusType;
  errorCode: number | string;
};
export async function avlValidatedCode(validationCode: string): Promise<AvlVerificationResponseType> {
  const url = `${getApiHost()}firms/verify-avl/${validationCode?.trim()}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const json = await response.json();
  handleError(json);
  return json;
}
