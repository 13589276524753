import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import VerifiedUserIcon from 'ui-kit/icons/VerifiedUser';
import WarningIcon from 'ui-kit/icons/Warning';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

interface BottomAppBarProps {
  isMatched?: boolean;
  partyName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sdnCardWrap: {
      maxWidth: '300px',
      borderRadius: '4px',
      padding: theme.spacing(2),
      '& .MuiTypography-root': {
        fontWeight: 500,
      },
    },
    isMatched: {
      backgroundColor: '#FDECEA',
      '& .MuiTypography-root': {
        color: theme.palette.error.main,
      },
    },
    noMatch: {
      backgroundColor: '#EFF7EE',
      '& .MuiTypography-root': {
        color: theme.palette.success.main,
      },
    },
    result: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        marginLeft: theme.spacing(0.5),
      },
    },
    partyNoMatch: {
      color: `${theme.palette.text.primary} !important`,
    },
    partyName: {
      marginBottom: '2px',
    },
  }),
);

const SdnCard = ({ isMatched = false, partyName = '' }: BottomAppBarProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.sdnCardWrap, { [classes.isMatched]: isMatched, [classes.noMatch]: !isMatched })}>
      <Typography variant="body2" className={clsx(classes.partyName, { [classes.partyNoMatch]: !isMatched })}>
        {partyName}
      </Typography>
      <Typography variant="body2" className={classes.result}>
        {isMatched ? (
          <>
            SDN Match <WarningIcon />
          </>
        ) : (
          <>
            SDN <VerifiedUserIcon />
          </>
        )}
      </Typography>
    </div>
  );
};

export default SdnCard;
