import { getApiHost } from 'api/host-by-env';
import { handleError } from 'utilities/utilities';

export async function getPaymentIframe(
  token: string,
  firmId: string,
  isAch: boolean,
  underwriter?: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/admin/iframe/add-epay-account/${firmId}?isAch=${isAch}${
    underwriter ? `&underwriter=${underwriter}` : ''
  }`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function editPaymentIframe(
  token: string,
  firmId: string,
  isAch: boolean,
  aliasId: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/admin/iframe/edit-epay-account/${firmId}?aliasId=${aliasId}&isAch=${isAch}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function deletePayment(
  token: string,
  payload: any,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/admin/delete-epay-account/${payload.accountAliasId}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getEpayAccount(
  token: string,
  firmId: string,
  payload?: any,
): Promise<{ result: any; errorCode?: string | number }> {
  let queryParams = '';
  if (payload) {
    queryParams = Object.keys(payload)
      ?.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(payload[key]))
      .join('&');
  }
  const baseUrl = `${getApiHost()}remittance/pay/epay-accounts/${firmId}`;
  const url = queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function onSubmitPayment(
  token: string,
  payload?: any,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/pay/orders/payment`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getTransmittalSheet(
  token: string,
  paymentId: number,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/pay/transmittal-sheet/${paymentId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}
