import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'required';

export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (maxLength?: number): string => `Max length is ${maxLength}`;

const max_characters = {
  accountRegistrationFirstName: 25,
  accountRegistrationMiddleName: 20,
  accountRegistrationLastName: 25,
  accountRegistrationEmail: 100,
  accountRegistrationCompany: 75,
  accountRegistrationAddress: 55,
  accountRegistrationAddress2: 55,
  accountRegistrationCity: 30,
  accountRegistrationStateCode: 2,
  accountRegistrationZip: 10,
};

export const accountRegistrationSchema = yup.object().shape({
  accountRegistrationEmail: yup
    .string()
    .required(requiredFieldMessage('Email'))
    .email('Invalid Email.')
    .matches(/^(?!.*@firstam\.com$)/, 'No First American emails')
    .max(max_characters.accountRegistrationEmail, maxLengthFieldMessage(max_characters.accountRegistrationEmail)),
  accountRegistrationFirstName: yup
    .string()
    .required(requiredFieldMessage('First Name'))
    .max(
      max_characters.accountRegistrationFirstName,
      maxLengthFieldMessage(max_characters.accountRegistrationFirstName),
    ),
  accountRegistrationMiddleName: yup
    .string()
    .max(
      max_characters.accountRegistrationMiddleName,
      maxLengthFieldMessage(max_characters.accountRegistrationMiddleName),
    ),
  accountRegistrationLastName: yup
    .string()
    .required(requiredFieldMessage('Last Name'))
    .max(max_characters.accountRegistrationLastName, maxLengthFieldMessage(max_characters.accountRegistrationLastName)),
  accountRegistrationCompany: yup
    .string()
    .required(requiredFieldMessage('Company'))
    .max(max_characters.accountRegistrationCompany, maxLengthFieldMessage(max_characters.accountRegistrationCompany)),
  accountRegistrationAddress: yup
    .string()
    .required(requiredFieldMessage('Address'))
    .max(max_characters.accountRegistrationAddress, maxLengthFieldMessage(max_characters.accountRegistrationAddress)),
  accountRegistrationAddress2: yup
    .string()
    .max(max_characters.accountRegistrationAddress2, maxLengthFieldMessage(max_characters.accountRegistrationAddress2)),
  accountRegistrationCity: yup
    .string()
    .required(requiredFieldMessage('City'))
    .max(max_characters.accountRegistrationCity, maxLengthFieldMessage(max_characters.accountRegistrationCity)),
  accountRegistrationStateCode: yup
    .string()
    .required(requiredFieldMessage('State'))
    .max(
      max_characters.accountRegistrationStateCode,
      maxLengthFieldMessage(max_characters.accountRegistrationStateCode),
    ),
  accountRegistrationZip: yup
    .string()
    .required(requiredFieldMessage('Zip code'))
    .max(max_characters.accountRegistrationZip, maxLengthFieldMessage(max_characters.accountRegistrationZip)),
  accountRegistrationPhone: yup.string().required(requiredFieldMessage('Phone')),
});
