import { format } from 'date-fns';
import React, { useEffect, forwardRef, Fragment, useImperativeHandle, useRef, useState } from 'react';

interface Props {
  parentFilterInstance: any;
  filterParams?: any;
}

const FloatingFilterComponent = forwardRef((props: Props, ref) => {
  const [currentValue, setCurrentValue] = useState(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [inputType, setInputType] = useState('text');
  const [disabled, setDisabled] = useState(false);
  const [qaAttribute, setQAAttribute] = useState(props.filterParams?.colDef?.filterParams?.qaAttribute ?? '');

  useEffect(() => {
    const filterParams = props.filterParams?.colDef?.filterParams;
    setQAAttribute(filterParams?.qaAttribute ?? '');
    setInputType(filterParams?.type ?? 'text');
    setDisabled(filterParams?.disabled ?? false);
    () => {
      setQAAttribute('');
      setInputType('text');
      setDisabled(false);
    };
  }, [props.filterParams]);
  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel: any) {
        // When the filter is empty we will receive a null value here
        console.log(parentModel, inputType);
        if (!parentModel) {
          if (inputRef.current) inputRef.current.value = '';
          setCurrentValue(null);
        } else {
          if (inputRef.current) {
            if (parentModel.filterType === 'date') {
              if (parentModel.type !== 'inRange') {
                setInputType('date');
                setDisabled(false);
                inputRef.current.value = format(new Date(parentModel.dateFrom), 'yyyy-MM-dd');
              } else {
                setInputType('text');
                setDisabled(true);
                inputRef.current.value = `${format(new Date(parentModel.dateFrom), 'yyyy-MM-dd')} - ${format(
                  new Date(parentModel.dateTo),
                  'yyyy-MM-dd',
                )}`;
              }
            } else {
              inputRef.current.value = parentModel.filter ?? parentModel.type + '';
            }
          }
          setCurrentValue(parentModel.type);
        }
      },
    };
  });

  const onInputBoxChanged = (input: any) => {
    if (input.target.value === '') {
      // Remove the filter
      props.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    setCurrentValue(input.target.value);
    props.parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged('greaterThan', input.target.value);
    });
  };

  useEffect(() => {
    if (parentRef?.current?.parentElement) {
      parentRef.current.parentElement.style.width = '100%';
    }
  }, []);
  return (
    <Fragment>
      <div ref={parentRef} className="ag-floating-filter-input" role="presentation" style={{ padding: '11px 0' }}>
        <div role="presentation" className="ag-labeled ag-label-align-left ag-text-field ag-input-field">
          <div role="presentation" className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper">
            <input
              ref={inputRef}
              readOnly={disabled}
              onInput={onInputBoxChanged}
              type={inputType}
              className="ag-input-field-input ag-text-field-input"
              {...(qaAttribute ? { 'data-qa': qaAttribute } : {})}
              style={{
                width: '90%',
                fontSize: 'inherit',
                ...(disabled
                  ? {
                      backgroundColor: '#f1f2f4',
                      pointerEvents: 'none',
                      color: 'rgba(24, 29, 31, 0.5)',
                      border: '1px solid rgba(186, 191, 199, 0.3)',
                    }
                  : {}),
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
});

FloatingFilterComponent.displayName = 'FloatingFilterComponent';
export default FloatingFilterComponent;
