import React, { FunctionComponent } from 'react';
import { InputBaseComponentProps, TextFieldProps } from '@material-ui/core';
import ErrorSection from 'features/files/file-create/ErrorSection';
import MaskedField from 'react-masked-field';
import { FieldValidationError } from 'utilities/validation/validation';
import ObscurableField from '../ObscurableField';

export type SsnFieldProps = TextFieldProps & {
  showAllValidation?: boolean;
  validationErrors?: FieldValidationError[];
  resetVisibility?: boolean;
};

export function SsnField({
  showAllValidation,
  validationErrors,
  resetVisibility = false,
  ...props
}: SsnFieldProps): JSX.Element {
  return (
    <>
      <ObscurableField
        label="Social Security Number"
        {...props}
        obscurePattern={/\d/g}
        resetVisibility={resetVisibility}
        obscureReplacer={(match, offset) => {
          if (offset < 6) {
            return '•';
          }
          return match;
        }}
        InputProps={{
          inputComponent: MaskedField as unknown as FunctionComponent<InputBaseComponentProps>,
          inputProps: {
            inputComponent: MaskedField,
            mask: '999-99-9999',
            placeholder: 'XXX-XX-XXXX',
          },
        }}
      />
      {showAllValidation && <ErrorSection errs={validationErrors} field={'ssn'} />}
    </>
  );
}
export default SsnField;
