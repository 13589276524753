import React from 'react';

export const DocumentPDF = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        fill="#0074CA"
        d="M11.667 1.667H5c-.917 0-1.658.75-1.658 1.667l-.009 13.333c0 .917.742 1.667 1.659 1.667H15c.917 0 1.667-.75 1.667-1.667v-10l-5-5zM5 16.667V3.334h5.834V7.5H15v9.167H5z"
      ></path>
      <path
        fill="#0074CA"
        fillRule="evenodd"
        d="M10.852 9.53c-.007-.328-.028-1.197-.854-1.197h-.012c-.22.002-.393.073-.516.21-.232.26-.223.693-.184 1.084.05.49.26 1.036.34 1.231-.059.177-.223.656-.43 1.158l-.02.044-.009.022-.018.045-.02.044c-.22.513-.388.846-.459.98-.253.09-1.052.394-1.45.763-.245.226-.817.756-.413 1.303.122.166.338.262.593.264h.008c.432 0 .886-.27 1.25-.745.494-.646.775-1.1.877-1.273l.542-.146.05-.014c.227-.06.452-.119.57-.147.192-.047.414-.087.539-.108.243.222 1.006.855 1.783.826.388-.014.669-.117.836-.308a.619.619 0 00.153-.466l-.204.02.203-.021c-.013-.138-.142-.826-1.313-.798-.709.017-1.066.052-1.232.076-.188-.199-.69-.75-.926-1.207.084-.259.33-1.075.316-1.64zm-2.936 4.69c.047-.031.093-.061.14-.09-.217.316-.47.607-.664.662-.051.015-.077.005-.097-.007.027-.123.298-.348.59-.545l.031-.02zm4.209-1.3c.398-.032.817-.02.953.092.02.017.022.028.022.042 0 .073-.027.09-.06.104-.17.073-.552-.02-.915-.237zm-2.165-.23c.108-.24.216-.52.298-.743.151.22.317.43.447.589a7.848 7.848 0 00-.76.188l.015-.034zm0-3.75a.015.015 0 00.003-.005l.017.001.001.001.002.002c.01.015.049.083.051.294.002.162-.044.406-.1.639a2.37 2.37 0 01-.058-.499c0-.318.07-.414.084-.433z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const DocumentXLS = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" width={20} height={20}>
      <path
        fill="#0074CA"
        d="M18.667 2.667H8a2.663 2.663 0 00-2.653 2.667l-.014 21.333a2.663 2.663 0 002.654 2.667H24c1.467 0 2.667-1.2 2.667-2.667v-16l-8-8zM8 26.667V5.334h9.333V12H24v14.667H8z"
      ></path>
      <path
        fill="#0074CA"
        d="M16.126 18.605L14.539 16H12.48l2.458 3.551-2.605 3.887h1.944l1.764-2.994 1.818 2.994h2.059l-2.69-3.918L19.635 16H17.65l-1.523 2.605z"
      ></path>
      <path
        fill="#0074CA"
        fillRule="evenodd"
        d="M14.69 15.734l1.43 2.351 1.376-2.351h2.644l-2.588 3.785 2.872 4.186H17.71l-1.664-2.742-1.616 2.741h-2.595l2.782-4.15-2.645-3.82h2.718zm.248 3.817l-2.605 3.887h1.944l1.764-2.994 1.818 2.994h2.059l-2.69-3.918L19.635 16H17.65l-1.523 2.605L14.539 16H12.48l2.458 3.551z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const Document = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" width={20} height={20}>
      <path
        fill="#0074CA"
        d="M18.667 2.668H8a2.663 2.663 0 00-2.653 2.666l-.014 21.334a2.663 2.663 0 002.654 2.666H24c1.467 0 2.667-1.2 2.667-2.666v-16l-8-8zM8 26.668V5.334h9.333v6.667H24v14.667H8z"
      ></path>
    </svg>
  );
};
