import React, { useEffect, useState } from 'react';
import MailOutlineOutlinedIcon from '@material-ui/icons/EmailOutlined'; // Import the missing component
import { AvlLetterRequest } from 'api/Avl/types';
import { emailAvl, generateAvl } from 'api/Avl/avl-api';
import { openDocument } from 'utilities/utilities';
import { WindowFeatures, DocumentType, newWindow } from 'features/constants';
import { useAuth } from '@agentnet/auth';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import AgentNetEmailDialog from 'ui-kit/components/modal/AgentNetEmailDialog';
import PDF from 'ui-kit/icons/PDF';

const AvlAction: React.FC = (params: any) => {
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();
  const [selectedRow, setSelectedRow] = useState<AvlLetterRequest>({} as AvlLetterRequest);
  const [showGenerateAvlNotification, setShowGenerateAvlNotification] = useState<boolean>(false);
  const [showEmailSentNotification, setShowEmailSentNotification] = useState<boolean>(false);
  const [emailModal, setEmailModal] = useState(false);

  useEffect(() => {
    showGenerateAvlNotification &&
      addSnackbarMessage({
        message: `The Agency Verification Letter created successfully.`,
        type: 'success',
        onClose: () => {
          setShowGenerateAvlNotification(false);
        },
      });
  }, [showGenerateAvlNotification]);

  useEffect(() => {
    showEmailSentNotification &&
      addSnackbarMessage({
        message: `The email has been sent successfully.`,
        type: 'success',
        onClose: () => {
          setShowEmailSentNotification(false);
        },
      });
  }, [showEmailSentNotification]);

  async function onClickPdf(params: any) {
    if (params.isActionPending) return;

    params.setActionPendingFlag(true);
    const payload: AvlLetterRequest = {
      AgentAddress: params.agentAddress,
      AccountNumber: params.data.accountNumber,
      AgentName: params.data.agentName,
      ClientTypeId: params.clientTypeId,
      UnderwriterCode: params.data.underwriterCode,
      UnderwriterName: params.data.underwriterName,
      PropertyState: params.propertyState,
    };

    const token = await getAccessToken();
    const response = await generateAvl(payload, token);
    params.setActionPendingFlag(false);

    if (response?.Document?.Content) {
      openDocument(response?.Document?.Content, DocumentType, newWindow, WindowFeatures);
      setShowGenerateAvlNotification(true);
    }
  }

  function onClickEmail(params: any) {
    if (params.isActionPending) return;

    const avlRequest: AvlLetterRequest = {
      AgentAddress: params.agentAddress,
      AccountNumber: params.data.accountNumber,
      AgentName: params.data.agentName,
      ClientTypeId: params.clientTypeId,
      UnderwriterCode: params.data.underwriterCode,
      UnderwriterName: params.data.underwriterName,
      PropertyState: params.propertyState,
    };
    setSelectedRow(avlRequest);
    setEmailModal(true);
  }

  async function onSendEmail(emailObj: any) {
    params.setActionPendingFlag(true);
    const payload = {
      Avl: selectedRow,
      Message: emailObj.message,
      Email: emailObj.emails,
    };

    const token = await getAccessToken();
    const response = await emailAvl(payload, token);
    params.setActionPendingFlag(false);
    setEmailModal(false);
    if (response) {
      setShowEmailSentNotification(true);
    }
  }

  return (
    <>
      <div>
        <PDF className="grid-icon" fontSize="small" style={{ color: '#0074CA' }} onClick={() => onClickPdf(params)} />
        <MailOutlineOutlinedIcon
          className="grid-icon"
          fontSize="small"
          style={{ color: '#0074CA' }}
          onClick={() => onClickEmail(params)}
        />
      </div>
      <AgentNetEmailDialog
        title="Send Agency Verification Letter"
        open={emailModal}
        onClose={() => setEmailModal(false)}
        onSend={onSendEmail}
        isSendingMessage={params.isActionPending}
      />
    </>
  );
};

export default AvlAction;
