import React, { useCallback, useRef, useState } from 'react';
import { ListItemSecondaryAction, makeStyles, Menu, MenuItem, MenuItemProps, Theme } from '..';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

export enum MenuDirection {
  LEFT,
  RIGHT,
}

export interface NestedMenuItemProps extends Omit<MenuItemProps, 'button'> {
  isParentMenuOpen: boolean;
  label: string;
  menuDirection?: MenuDirection;
  dataTestId?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { open: boolean }) => ({
    backgroundColor: props.open ? theme.palette.action.selected : 'transparent',
  }),
  menu: {
    pointerEvents: 'none',
  },
  menuItem: {
    width: '100%',
  },
  parentContainer: {
    outline: 'none !important',
  },
  childrenContainer: {
    pointerEvents: 'auto',
  },
  chevronContainer: {
    paddingLeft: theme.spacing(3),
  },
  chevron: {
    display: 'block',
  },
}));

export const NestedMenuItem = (props: NestedMenuItemProps): JSX.Element => {
  const {
    isParentMenuOpen,
    label,
    tabIndex: tabIndexProp,
    children,
    menuDirection = MenuDirection.RIGHT,
    dataTestId,
    ...menuItemProps
  } = props;

  const menuItemRef = useRef<HTMLLIElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [menuContainerRef, setMenuContainerRef] = useState<HTMLDivElement | null>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isParentMenuItemFocused, setIsParentMenuItemFocused] = useState(false);
  const isOpen = () => isSubMenuOpen && isParentMenuOpen;

  const tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1; // required for keyboard nav

  const classes = useStyles({ open: isOpen() || isParentMenuItemFocused });

  const onMenuContainerRefChange = useCallback(
    (node) => {
      setMenuContainerRef(node);
      if (isSubMenuOpen && node && node.children && node.children[0]) {
        (node.children[0] as HTMLElement).focus();
      }
    },
    [isSubMenuOpen],
  );

  const handleClick = () => setIsSubMenuOpen(true);

  const handleMouseEnter = () => setIsSubMenuOpen(true);
  const handleMouseLeave = () => setIsSubMenuOpen(false);

  const handleFocus = () => setIsParentMenuItemFocused(true);
  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    if (menuContainerRef && !menuContainerRef.contains(event.relatedTarget as HTMLElement)) {
      setIsSubMenuOpen(false);
    }
    setIsParentMenuItemFocused(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isSubMenuOpen) event.stopPropagation();
    if (!isSubMenuOpen && event.key === 'Enter') event.preventDefault();

    const openKeys = ['Enter', 'ArrowRight', ' '];
    const closeKeys = ['Tab', 'ArrowLeft'];

    if (closeKeys.includes(event.key)) {
      containerRef.current?.focus();
      setIsSubMenuOpen(false);
    }

    const active = containerRef.current?.ownerDocument?.activeElement;
    if (openKeys.includes(event.key) && event.target === containerRef.current && event.target === active) {
      setIsSubMenuOpen(true);
    }
  };

  const handleClose = () => {
    setIsSubMenuOpen(false);
    containerRef.current?.focus();
  };

  return (
    <div
      ref={containerRef}
      onBlur={handleBlur}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
      className={classes.parentContainer}
    >
      <MenuItem
        className={clsx(classes.root, classes.menuItem)}
        ref={menuItemRef}
        data-testid={dataTestId}
        onClick={handleClick}
        {...menuItemProps}
      >
        {label}
        <ListItemSecondaryAction>
          <ChevronRightIcon className={classes.chevron} />
        </ListItemSecondaryAction>
      </MenuItem>
      <Menu
        className={classes.menu}
        anchorEl={menuItemRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: menuDirection === MenuDirection.LEFT ? 'left' : 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: menuDirection === MenuDirection.LEFT ? 'right' : 'left' }}
        open={isOpen()}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        MenuListProps={{ disableListWrap: true }}
        onEscapeKeyDown={handleClose}
        onClose={handleClose}
      >
        <div ref={onMenuContainerRefChange} className={classes.childrenContainer}>
          {children}
        </div>
      </Menu>
    </div>
  );
};

export default NestedMenuItem;
