import { getStateOptions } from 'api/profile/profile-utilities';
import React, { useContext } from 'react';
import { ProfileContext, ProfileContextInterface } from '../../../hooks/ProfileContext';
import { RoleNameValue } from '../../../api/file/constants';
import { useAuth } from '@agentnet/auth';
import { ProductFruits } from 'react-product-fruits';
import { FullStory, init as initFullStory, isInitialized as isFullStoryInitialized } from '@fullstory/browser';

const ProductFruitsFullStory = (): JSX.Element => {
  const { account } = useAuth();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile } = profileCtx;
  const stateOptions = userFirm?.offices ? getStateOptions(userFirm) : [];
  const pfUserInfo =
    !profile || !profile.login
      ? null
      : {
          username: profile?.login ?? '',
          email: profile?.emailAddress ?? '',
          firstname: profile?.firstName ?? '',
          lastname: profile?.lastName ?? '',
          signUpAt: '',
          role: RoleNameValue[profile?.roleId ?? 0],
          props: {
            idaasGuid: account?.localAccountId ?? '',
            firmName: userFirm?.name ?? '',
            states: stateOptions.length == 0 ? '' : Array.prototype.map.call(stateOptions, (s) => s.value).toString(),
            policySoftware: userFirm?.policySoftware ?? '',
            canRemit: profile?.activityRights?.filter((ar) => ar.ActivityRightId === 33) ? true : false,
            canPay: profile?.activityRights?.filter((ar) => ar.ActivityRightId === 61) ? true : false,
            isAgentNetAdmin: profile?.activityRights?.filter((ar) => ar.ActivityRightId === 36) ? true : false,
          },
        };

  const fSUserInfo =
    !profile || !profile.login
      ? null
      : {
          uid: profile?.login ?? '',
          properties: {
            email: profile?.emailAddress ?? '',
            firstName: profile?.firstName ?? '',
            lastName: profile?.lastName ?? '',
            role: RoleNameValue[profile?.roleId ?? 0],
            idaasGuid: account?.localAccountId ?? '',
            firmName: userFirm?.name ?? '',
            states: stateOptions.length == 0 ? '' : Array.prototype.map.call(stateOptions, (s) => s.value).toString(),
            policySoftware: userFirm?.policySoftware ?? '',
          },
        };

  if (fSUserInfo) {
    if (!isFullStoryInitialized()) {
      initFullStory({ orgId: 'o-1VHTDK-na1' });
    }
    FullStory('setIdentity', fSUserInfo);
  }

  return <>{pfUserInfo && <ProductFruits workspaceCode="t2D9aOCQfJddTekV" language="en" user={pfUserInfo} />}</>;
};

export default ProductFruitsFullStory;
