/* eslint-disable react/jsx-key */
import React, { ReactNode, useState } from 'react';
import { Drawer, createStyles, makeStyles, Theme, Typography, Hidden, Button } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

export type L3NavProps = {
  children?: ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuBtn: {
      alignItems: 'center',
      display: 'flex',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
    container: {
      minHeight: 560,
      wordWrap: 'break-word',
      [theme.breakpoints.down('md')]: {
        maxWidth: 'unset',
        minHeight: 'unset',
      },
    },
    drawerPaper: {
      backgroundColor: theme.palette.background.paper,
      minWidth: 250,
      paddingBottom: 84,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  }),
);

const L3Nav = ({ children, ...props }: L3NavProps): JSX.Element => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.container} {...props}>
      <Hidden smDown>{children}</Hidden>
      <Hidden mdUp>
        <Button variant="text" size="large" className={classes.menuBtn} onClick={handleToggle}>
          <MenuIcon />
          <Typography variant="button">{open ? 'Hide Menu' : 'Show Menu'}</Typography>
        </Button>
        <Drawer anchor="left" open={open} classes={{ paper: classes.drawerPaper }} onClose={handleToggle}>
          {children}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default L3Nav;
