import { InputBaseComponentProps, TextFieldProps } from '@material-ui/core';
import React, { FunctionComponent, ReactText } from 'react';
import ReactInputMask from 'react-input-mask';
import ObscurableField from '../ObscurableField';

export type UnknownFieldProps = TextFieldProps;

//Remove dashes & underscores applied by other section's mask fields
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeNonLetterCharacters = (value?: string): string | undefined => value?.replace(/\D/g, '');

const handleObscureReplacer = (match: string, offset: ReactText, value?: string) => {
  return value && offset < value.length - 4 ? '•' : match;
};

//Length of the input string should match the length of the provided string, up to 9 char
export function UnknownField({ ...props }: UnknownFieldProps): JSX.Element {
  const { value } = props;

  return (
    <ObscurableField
      label="Unknown Id Number"
      // commenting out until we can get this put in the right place when we implement saving
      // data-testid="tax-id-field"
      {...props}
      obscurePattern={/\d/g}
      obscureReplacer={(match: string, offset) =>
        handleObscureReplacer(match, offset, removeNonLetterCharacters(value as string))
      }
      InputProps={{
        inputComponent: ReactInputMask as unknown as FunctionComponent<InputBaseComponentProps>,
        inputProps: {
          inputComponent: ReactInputMask,
          mask: '999999999',
        },
      }}
    />
  );
}
export default UnknownField;
