import { ICreateBuyerSellerRequest } from '../interfaces/CreateBuyerSellerRequest';
import { ICreateLenderRequest } from '../interfaces/CreateLenderRequest';
import { PartyTypeValue } from '../../../../api/file/constants';

/* Display PartyType and Lender Details on Card */

const fullName = (namesList: ICreateBuyerSellerRequest & ICreateLenderRequest): string => {
  return `${
    namesList?.name ??
    namesList?.individual?.firstName ??
    namesList?.individual?.name ??
    namesList?.entity?.name ??
    namesList?.trustEstate?.name ??
    ''
  } ${namesList?.individual?.lastName ?? ''}`;
};

export const displayPartyNameToCard = (namesList: ICreateBuyerSellerRequest & ICreateLenderRequest): string => {
  if (namesList.partyType === PartyTypeValue.married) {
    return displayMarriedCouple(namesList);
  }
  if (namesList.partyType === PartyTypeValue.individual) {
    return displayIndividualCouple(namesList);
  }

  return fullName(namesList);
};

const displayMarriedCouple = (namesList: ICreateBuyerSellerRequest & ICreateLenderRequest): string => {
  const name = [];
  if (namesList?.couple?.spouse1?.firstName) {
    name.push(namesList?.couple?.spouse1?.firstName.trim());
    if (namesList?.couple?.spouse1?.lastName) {
      name.push(namesList?.couple?.spouse1?.lastName.trim());
    }
    name.push('&');
  }
  if (namesList?.couple?.spouse2?.firstName) {
    name.push(namesList?.couple?.spouse2?.firstName.trim());
    if (namesList?.couple?.spouse2?.lastName) {
      name.push(namesList?.couple?.spouse2?.lastName.trim());
    }
  }
  return name.join(' ');
};

const displayIndividualCouple = (namesList: ICreateBuyerSellerRequest & ICreateLenderRequest): string => {
  return fullName(namesList);
};
