import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import clsx from 'clsx';
import { ColDef } from 'ag-grid-community';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../files/files-summary/files-list/FilesList.scss';
import '../ServiceOrderInvoices/Invoice.scss';
import { useAuth } from '@agentnet/auth';
import { Grid } from '@material-ui/core';
import PDFIcon from '../../ui-kit/icons/PDF';
import { getStatementDocument, getStatements } from 'api/statements/statements';
import useAsync from 'hooks/useAsync';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { openDocument } from 'utilities/utilities';
import { DocumentType, newWindow, WindowFeatures } from 'features/constants';
import { filterParams } from './statementListConfig';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrap: {
      padding: `0 0 32rem`,
      minWidth: 1200,
    },
    mb: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      width: '926px',
    },
    userBannerWrap: {
      padding: theme.spacing(3),
      borderBottom: `1px solid #c4c4c4`,
    },
    userBannerContainer: {
      minWidth: 1200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    userBannerDetails: { display: 'flex', alignItems: 'center' },
    mb2: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(3),
    },
    userName: {
      marginBottom: theme.spacing(0.5),
    },
    heading: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(1.5),
    },
    captionDivider: {
      width: theme.spacing(2),
      display: 'inline-block',
    },
    fieldSection: {},
    fieldSectionLeft: {
      padding: theme.spacing(3),
    },
    fieldSectionRight: {
      padding: theme.spacing(3),
      marginTop: 0,
    },
    adornmentIcon: {
      padding: 0,
      '&:hover': {
        background: 'transparent',
      },
    },
    InfoIcon: {
      alignItems: 'left',
      marginRight: theme.spacing(3),
    },
    ctaBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    alertContainer: {
      marginBottom: '63px',
    },
    alertMsg: {
      width: '-webkit-fill-available',
      position: 'absolute',
      bottom: '63px',
      left: 0,
      padding: '0 35px',
    },
    tableStyles: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      height: 'calc(100vh - 200px) !important',
    },
    displayRow: {
      marginLeft: theme.spacing(3),
    },
    rowHover: {
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
  }),
);

const statements = () => {
  const classes = useStyles();
  const gridRef = useRef<any>(null);
  const { getAccessToken } = useAuth();
  const [rowData, setRowData] = useState<any>([]);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const firmId = userFirm?.firmId;
  const [docID, setdocID] = useState<null>();
  const [errMsgs, setErrMsgs] = useState([]);
  const { addGlobalMsg } = useGlobalMessages();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [firmLevelStatus, setFirmLevel] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);
  const { addSnackbarMessage } = useSnackBars();
  const getStatementsData = async () => {
    const token = await getAccessToken();
    const statementsresponse = await getStatements(token, firmId);
    const firmLevel = statementsresponse.every((element: any) => element.statementLevel == 'FIRM');
    if (firmLevel == true) {
      setFirmLevel(true);
    }
    setRowData(statementsresponse);
  };

  const { execute: executeStatementsData, status: getStatementData } = useAsync(getStatementsData, false);
  const actionStatus = getStatementData === 'pending' ? 'pending' : 'idle';
  useEffect(() => {
    executeStatementsData().then();
  }, []);
  //const getColumnConfig = () => (firmLevelStatus === true ? statementsFirmLevelConfig : statementsColumnsConfig);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      defaultValue: '-',
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const getStatementPdf = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getStatementDocument(docID, token);
  };
  const onViewPdfClick = (documentId: any) => {
    setdocID(documentId);
    addSnackbarMessage({
      message: `Generating PDF Summary...`,
      type: 'success',
    });
  };

  const {
    execute: executeGetStatementPdf,
    status: executeGetStatementPdfStatus,
    value: statementPdfResults,
    errors: statementPdfErrors,
  } = useAsync<any>(getStatementPdf, false);

  useEffect(() => {
    if (docID) {
      executeGetStatementPdf().then();
      setTimeout(() => setdocID(null), 3000);
    }
  }, [docID]);

  useEffect(() => {
    console.log('statementPdfResults', statementPdfResults);
    if (executeGetStatementPdfStatus === 'success') {
      if (statementPdfResults) {
        openDocument(statementPdfResults, DocumentType, newWindow, WindowFeatures);
      }
    }
  }, [executeGetStatementPdfStatus]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [...(statementPdfErrors ?? [])];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  useEffect(() => {
    const isError = executeGetStatementPdfStatus === 'error';
    setDisplayError(isError);
  }, [executeGetStatementPdfStatus]);

  const statementsFirmLevelConfig: any = [
    {
      headerName: 'Firm Name',
      field: 'firmName',
      tooltipField: 'FirmName',
      filter: 'agTextColumnFilter',
      minWidth: 650,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params: any) => {
        return params?.data?.firmName.length > 0 ? params?.data?.firmName : '-';
      },
    },
    {
      headerName: 'Date',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      field: 'statementDate',
      tooltipField: 'statementDate',
      sort: 'desc',
      minWidth: 200,
      comparator: (date1: string, date2: string) => {
        const date1Parts = date1.split('/');
        const date2Parts = date2.split('/');
        const date1Obj = new Date(Number(date1Parts[2]), Number(date1Parts[0]) - 1, Number(date1Parts[1]));
        const date2Obj = new Date(Number(date2Parts[2]), Number(date2Parts[0]) - 1, Number(date2Parts[1]));
        return date1Obj.getTime() - date2Obj.getTime();
      },
      cellRenderer: (params: any) => {
        return params?.data?.statementDate.length > 0 ? params?.data?.statementDate : '-';
      },
    },
    {
      headerName: '',
      filter: '',
      field: 'statementId',
      tooltipField: 'file',
      cellRenderer: (params: any) => {
        return (
          <span style={{ position: 'relative', top: '10px', cursor: 'pointer' }}>
            <PDFIcon onClick={() => onViewPdfClick(params?.data?.documentId)} fontSize="small" htmlColor="#0074CA" />
          </span>
        );
      },
      minWidth: 70,
    },
  ];
  const statementsColumnsConfig: any = [
    {
      headerName: 'Firm Name',
      field: 'firmName',
      tooltipField: 'FirmName',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      minWidth: 150,
      cellRenderer: (params: any) => {
        return params?.data?.firmName.length > 0 ? params?.data?.firmName : '-';
      },
    },
    {
      headerName: 'Account No.',
      field: 'accountNumber',
      tooltipField: 'accountNumber',
      filter: 'agTextColumnFilter',
      minWidth: 120,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params: any) => {
        return params?.data?.accountNumber.length > 0 ? params?.data?.accountNumber : '-';
      },
    },
    {
      headerName: 'Office Street Address',
      field: 'officeAddressStreet',
      tooltipField: 'officeAddressStreet',
      filter: 'agTextColumnFilter',
      minWidth: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params: any) => {
        return params?.data?.officeAddressStreet.length > 0 ? params?.data?.officeAddressStreet : '-';
      },
    },
    {
      headerName: 'Office City',
      minWidth: 110,
      filter: 'agTextColumnFilter',
      field: 'officeAddressCity',
      tooltipField: 'officeAddressCity',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params: any) => {
        return params?.data?.officeAddressCity.length > 0 ? params?.data?.officeAddressCity : '-';
      },
    },
    {
      headerName: 'Office State',
      field: 'officeAddressState',
      tooltipField: 'officeAddressState',
      filter: 'agTextColumnFilter',
      minWidth: 120,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params: any) => {
        return params?.data?.officeAddressState.length > 0 ? params?.data?.officeAddressState : '-';
      },
    },
    {
      headerName: 'Jurisdiction State',
      minWidth: 160,
      field: 'licensingState',
      tooltipField: 'licensingState',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params: any) => {
        return params?.data?.licensingState.length > 0 ? params?.data?.licensingState : '-';
      },
    },

    {
      headerName: 'Underwriter',
      minWidth: 120,
      filter: 'agSetColumnFilter',
      field: 'underwriter',
      tooltipField: 'underwriter',
      cellRenderer: (params: any) => {
        return params?.data?.underwriter.length > 0 ? params?.data?.underwriter : '-';
      },
    },
    {
      headerName: 'Date',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      field: 'statementDate',
      tooltipField: 'statementDate',
      minWidth: 100,
      sort: 'desc',
      comparator: (date1: string, date2: string) => {
        const date1Parts = date1.split('/');
        const date2Parts = date2.split('/');
        const date1Obj = new Date(Number(date1Parts[2]), Number(date1Parts[0]) - 1, Number(date1Parts[1]));
        const date2Obj = new Date(Number(date2Parts[2]), Number(date2Parts[0]) - 1, Number(date2Parts[1]));
        return date1Obj.getTime() - date2Obj.getTime();
      },
      cellRenderer: (params: any) => {
        return params?.data?.statementDate.length > 0 ? params?.data?.statementDate : '-';
      },
    },
    {
      headerName: '',
      filter: '',
      field: 'statementId',
      tooltipField: 'file',
      cellRenderer: (params: any) => {
        return (
          <span style={{ position: 'relative', top: '10px', cursor: 'pointer' }}>
            <PDFIcon onClick={() => onViewPdfClick(params?.data?.documentId)} fontSize="small" htmlColor="#0074CA" />
          </span>
        );
      },
      minWidth: 70,
    },
  ];

  const getColumnConfig = useMemo(
    () => (firmLevelStatus === true ? statementsFirmLevelConfig : statementsColumnsConfig),
    [getStatementData],
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const getDisplayedRowCount = () => {
    if (gridApi) {
      return gridApi.getDisplayedRowCount();
    }
    return 0;
  };

  const onFilterChanged = () => {
    if (gridApi?.getDisplayedRowCount() === 0) {
      gridApi?.showNoRowsOverlay();
    } else {
      gridApi?.hideOverlay();
    }
    setDisplayedRowCount(getDisplayedRowCount());
  };
  const CustomOverlayLoading = () => {
    return (
      <div className="files-container">
        <div className="no-rows">
          <LoadingSpinner status="pending" className="files-container--spinner-root" />
        </div>
      </div>
    );
  };

  if (actionStatus === 'pending') {
    return <> {CustomOverlayLoading()}</>;
  }

  return (
    <ContentContainer fullWidth>
      {getStatementData !== 'pending' && <AgentNetDivider title="Statements" />}
      {rowData.length !== 0 && (
        <div className={clsx('ag-theme-alpine table-grid', classes.tableStyles)}>
          <AgGridReact
            onGridReady={onGridReady}
            ref={gridRef}
            rowData={rowData}
            rowClass={classes.rowHover}
            columnDefs={getColumnConfig}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            onFilterChanged={onFilterChanged}
          />
        </div>
      )}
      {getStatementData !== 'pending' && (
        <Grid className="selected-rows">
          <span className={classes.displayRow}>
            Showing {getDisplayedRowCount()} of {rowData?.length} statements
          </span>
        </Grid>
      )}
    </ContentContainer>
  );
};

export default statements;
