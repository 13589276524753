const phoneMatchRegex = /^(?<countryCode>\+0?1)?[\s]?\(?(?<area>\d{3})\)?[\s.-]?(?<prefix>\d{3})[\s.-]?(?<suffix>\d{4})$/;

const formatPhoneNumber = (phoneNumber: string, ext?: string): string => {
  const phoneMatch = phoneNumber.match(phoneMatchRegex);

  const formattedPhoneNumber = phoneMatch?.groups
    ? `(${phoneMatch.groups['area']}) ${phoneMatch.groups['prefix']}-${phoneMatch.groups['suffix']}`
    : '';

  if (formattedPhoneNumber && ext) {
    return `${formattedPhoneNumber} ext ${ext}`;
  }

  return formattedPhoneNumber;
};

export default formatPhoneNumber;
