import { ApolloClient, DocumentNode, HttpLink, InMemoryCache } from '@apollo/client';
import { getContentfulAccessToken, getContentfulSpaceId, isContentfulPreview } from 'api/host-by-env';

const getApolloClient = () => {
  const contentfulUri = `https://graphql.contentful.com/content/v1/spaces/${getContentfulSpaceId()}`;

  // if (process.env.CONTENTFUL_ENVIRONMENT) {
  //   contentfulUri += `/environments/${process.env.CONTENTFUL_ENVIRONMENT}`;
  // }

  return new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
    link: new HttpLink({
      uri: contentfulUri,
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${getContentfulAccessToken()}`,
      },
    }),
  });
};

export default async function performQuery(
  query: DocumentNode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: any,
) {
  const response = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    res: null as any,
    hasError: false,
  };

  // If variables is null then set it to an empty object.
  variables ?? (variables = {});

  if (isContentfulPreview()) {
    variables.preview = true;
  }

  try {
    response.res = await getApolloClient().query({
      query: query,
      variables: variables,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(`GQL ERROR: ${e.message}`);

    if (e.graphQLErrors.length > 0) {
      e.graphQLErrors.forEach((item: { message: string }) => {
        console.log(item.message);
      });
    }

    if (e.networkError?.result?.errors?.length > 0) {
      e.networkError.result.errors.forEach((item: { message: string }) => {
        console.log(item.message);
      });
    }

    response.hasError = true;
  }

  return response;
}
