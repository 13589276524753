import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ReportsList from 'features/Remittance/ReportsList';
import './RemittanceContent.scss';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';

const ReportContent = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      list: {
        fontSize: '16px',
        padding: '0 16px',
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100%)',
      },
      contentClass: {
        margin: '0 auto auto',
      },
    }),
  );
  const classes = useStyles();

  return (
    <ContentContainer fullWidth>
      <div className={classes.contentWrap}>
        <ReportsList contentClassName={classes.contentClass} />
      </div>
    </ContentContainer>
  );
};

export default ReportContent;
