import { getApiHost } from 'api/host-by-env';
import { parseFirm } from 'api/profile/profile-utilities';
import { handleError } from 'utilities/utilities';

export async function getFirmList(
  token: string,
  type: string,
  id: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const isFirmName = type === 'name';
  const url = `${getApiHost()}firms/search/${type}/${!isFirmName ? id : ''}`;
  const response = await fetch(url, {
    method: isFirmName ? 'POST' : 'GET',
    ...(isFirmName && { body: JSON.stringify({ firmName: id }) }),
    // body: JSON.stringify({ firmName: id }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getFirmDetails(token: string, firmId: string): Promise<any> {
  const url = `${getApiHost()}firms/${firmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  // return json.result;
  return parseFirm(json.result);
}
