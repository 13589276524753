import React from 'react';
import { Container, Typography } from '@material-ui/core';
import PartyIcons from '../../../../common-assets/images/party-icons.svg';

import './PartyNotCreated.scss';

type PartyNotCreatedProps = {
  children?: React.ReactElement;
};

const PartyNotCreated: React.FC<PartyNotCreatedProps> = ({ children }: PartyNotCreatedProps) => {
  const renderPartyNotCreatedItem = (
    <div>
      <img src={PartyIcons} className="fa-img" alt="Party Icons" />
      <Typography variant="h3" className="party-not-cr">
        No Parties Found
      </Typography>
      {children && (
        <Typography variant="body1" paragraph={true} className="party-not-cr">
          Would you like to add one now?
        </Typography>
      )}
      {children}
    </div>
  );

  return (
    <>
      <Container className="party-not-created-container">{renderPartyNotCreatedItem}</Container>
    </>
  );
};
export default PartyNotCreated;
