import { AddressComponent, Place, PlaceAddress } from '../Models';
import { PlaceAddressMap } from './PlaceAddressMap';

export const processAddressComponent = (addressComponent: AddressComponent, placeAddress: PlaceAddress) => {
  for (const placeKey of Object.keys(PlaceAddressMap)) {
    if (addressComponent?.types?.includes(PlaceAddressMap[placeKey].componentName)) {
      return {
        ...placeAddress,
        [placeKey]: PlaceAddressMap[placeKey].shortName ? addressComponent.short_name : addressComponent.long_name,
      };
    }
  }

  return { ...placeAddress };
};

/* Takes address component and turns into the return object */
export const buildPlaceAddress = (place: Place) =>
  (place.address_components &&
    place.address_components.reduce((placeAddress, addressComponent) => {
      return { ...placeAddress, ...processAddressComponent(addressComponent, placeAddress) };
    }, {} as PlaceAddress)) ||
  ({} as PlaceAddress);

export const extractPlaceAddress = (place: Place, stateConstraint: any) => {
  if (stateConstraint !== 'VI' && stateConstraint !== 'PR') {
    const placeAddress = buildPlaceAddress(place); //
    const address2 = `${placeAddress.subpremise ?? ''} ${placeAddress.unit ?? ''} ${placeAddress.apartment ?? ''} ${
      placeAddress.premise ?? ''
    } ${placeAddress.establishment ?? ''}`;
    return {
      ...placeAddress,
      city: placeAddress.city ?? placeAddress.neighborhood ?? placeAddress.administrativeAreaLevelThree,
      address1: `${placeAddress.streetNumber ?? ''} ${placeAddress.route ?? ''}`,
      address2: address2.trim(),
    };
  }
  return findAddress(place?.adr_address ?? '');
};

function findAddress(address: string) {
  const address1Match = address.match(/<span class="street-address">(.*?)<\/span>/);
  const streetAddress = `${address.substring(0, address.indexOf(', <span'))}`;
  const isComma = streetAddress.indexOf('<span') === -1 ? ', ' : '';
  const address1 = `${streetAddress.indexOf('<span') === -1 ? streetAddress : ''}${
    address1Match?.[1] ? `${isComma}${address1Match?.[1].trim()}` : ''
  }`;
  const cityMatch = address.match(/<span class="locality">(.*?)<\/span>/);
  const city = cityMatch ? cityMatch[1].trim() : '';
  const countyMatch = address.match(/<span class="region">(.*?)<\/span>/);
  const county = countyMatch ? countyMatch[1].trim() : '';
  const zipcodeMatch = address.match(/<span class="postal-code">(.*?)<\/span>/);
  const zipcode = zipcodeMatch ? zipcodeMatch[1].trim() : '';
  const stateMatch = address.match(/<span class="country-name">(.*?)<\/span>/);
  const state = stateMatch ? stateMatch[1].trim() : '';

  // Creating the JavaScript object

  return {
    address1: address1,
    city: city,
    county: county,
    zipcode: zipcode,
    state: state === 'USVI' ? 'VI' : 'PR',
  };
}
