import { useRouteMatch } from 'react-router-dom';

export function useBaseRoute() {
  const { url } = useRouteMatch();
  const urlParts = url.split('/');
  const [first, second, ...rest] = urlParts;
  const baseRoute = first.concat('/', second);

  return { baseRoute, url };
}

export default useBaseRoute;
