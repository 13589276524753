import { Color } from '@material-ui/core';
import {
  red,
  green,
  blue,
  common,
  grey,
  amber,
  blueGrey,
  lightGreen,
  deepOrange,
  lightBlue,
  teal,
  lime,
} from '@material-ui/core/colors';
import { alpha, lighten } from '@material-ui/core/styles/colorManipulator';
import createPalette from '@material-ui/core/styles/createPalette';

export const primary: Color = {
  A100: '',
  A200: '#CEEEF9',
  A400: '#1c94b9',
  A700: '#146882',
  50: '#FFF',
  100: '#E8F4f8',
  200: '#D2eaf1',
  300: '#A4D4E3',
  400: '#49A9C7',
  500: '#1C94B9',
  600: '#167694',
  700: '#11596f',
  800: '#0E4A5D',
  900: '#0b3b4a',
};

export const secondary: Color = {
  A100: '',
  A200: '#d5dce2',
  A400: '#8297A7',
  A700: '#264156',
  50: '',
  100: '#F2F5F6',
  200: '#D9E0E5',
  300: '#C0CBD3',
  400: '#A7B6C1',
  500: '#8297A7',
  600: '#687986',
  700: '#5B6A75',
  800: '#4E5B64',
  900: '#343C43',
};

// declare module
const palette = createPalette({
  // THEME CONST

  primary,
  secondary,
  warning: {
    main: amber[600],
    dark: amber[800],
    light: amber[100],
  },
  info: {
    main: blue[500],
    dark: blue[700],
    light: blue[100],
  },
  error: {
    main: red[500],
    dark: red[700],
    light: red[100],
  },
  success: {
    main: green[500],
    dark: green[700],
    light: green[100],
  },

  // LIGHT

  // MUI Default Tokens -- Themed Light

  background: {
    paper: common.white,
    default: grey[50],
  },
  action: {
    active: secondary[700],
    hover: alpha(primary[500], 0.12),
    hoverOpacity: 0.12,
    selected: primary[200],
    disabled: secondary[500],
    disabledBackground: secondary[200],
  },
  text: {
    primary: secondary[900],
    secondary: secondary[700],
    disabled: secondary[400],
    hint: secondary[600],
  },
  divider: secondary[200],

  // Extended Design Tokens

  appBar: {
    colorSecondaryBackgroundColor: secondary[900],
  },
  button: {
    containedBackgroundColor: secondary[200],
    containedHoverBackgroundColor: secondary[400],
    containedDisabledBackgroundColor: secondary[100],
    containedDisabledColor: secondary[400],

    containedPrimaryDisabledColor: secondary[500],

    containedSecondaryDisabledBackgroundColor: secondary[100],
    containedSecondaryDisabledColor: secondary[400],

    OutlinedBorderColor: secondary[400],
    OutlinedHoverBackgroundColor: alpha(primary[500], 0.04),
    OutlinedDisabledBorderColor: secondary[300],
    OutlinedDisabledColor: secondary[300],

    OutlinedSecondaryBorderColor: secondary[500],
    OutlinedSecondaryDisabledBorderColor: secondary[300],
    OutlinedSecondaryDisabledColor: secondary[300],
  },
  icon: {
    folderColor: primary[500],
    fileColor: blueGrey[400],
    faDocFileColor: lighten(primary[500], 0.3),
    excelFileColor: lightGreen[900],
    pdfFileColor: red[900],
    powerpointFileColor: deepOrange[800],
    wordFileColor: lightBlue[900],
    emailFileColor: teal[500],
    imageFileColor: lightBlue[700],
    videoFileColor: lime[900],
  },

  // Extended Design Tokens Light

  input: {
    notchedOutlineBorderColor: secondary[200],
    filledHoverBackgroundColor: alpha(primary[100], 0.5),
    filledFocusBackgroundColor: primary[100],
    filledErrorBackgroundColor: red[50],
    filledErrorBorderBottomColor: red[700],
    standardUnderlineHover: secondary[300],
  },
  listItem: {
    avatarActiveSvgColor: primary[500],
    avatarHoverSvgColor: primary[500],
    avatarBackgroundColor: grey[200],
    avatarHoverBackgroundColor: secondary[200],
  },
  table: {
    headBackgroundColor: secondary[100],
    cellBorderColor: secondary[200],
  },
  transparency: {
    hidden: alpha(secondary[900], 0),
  },
});

export default palette;
