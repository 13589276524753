import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TopicContentBody } from './components/TopicBody';
import { Topic } from './types';
import { useTopicWithArticlesQuery } from './queries/useTopicWithArticlesQuery';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { ContentfulErrorContext, ContentfulErrorTypes } from './hooks/ContentfulErrorContext';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export function TopicPage() {
  const match = useRouteMatch<{ topic: string }>();
  const [topicContent, setTopicContent] = useState<Topic | null>();

  const { setContentfulErrorType } = React.useContext(ContentfulErrorContext);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      fullWidth: {
        width: '100%',
        background: '#fff',
      },
      loaderContainer: {
        height: '40vw',
        width: '65vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }),
  );
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      setTopicContent(null);
      const { data, error } = await useTopicWithArticlesQuery(match.params.topic || '', 20);

      if (error) {
        setContentfulErrorType(ContentfulErrorTypes.Error);
      }

      if (!data) {
        setContentfulErrorType(ContentfulErrorTypes.NoContent);
      }

      setTopicContent(data);
    };
    fetchData();
  }, [match.params.topic]);

  return (
    <div className={classes.fullWidth}>
      {!topicContent && <LoadingSpinner className={classes.loaderContainer} variant="circle" status={'pending'} />}
      {topicContent && <TopicContentBody topic={topicContent} />}
    </div>
  );
}
