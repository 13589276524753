export enum MaritalStatusValue {
  husband_and_wife = 'husband and wife',
  wife_and_husband = 'wife and husband',
  married_man = 'a married man',
  married_woman = 'a married woman',
  unmarried_man = 'an unmarried man',
  unmarried_woman = 'an unmarried woman',
  single_man = 'a single man',
  single_woman = 'a single woman',
  single_person = 'a single person',
  widow = 'a widow',
  widower = 'a widower',
  tbd = '(marital status to be determined)',
  unmarried_person = 'an unmarried person',
  registered_domestic_partner = 'a registered domestic partner',
  married_spouses = 'married spouses',
  registered_domestic_partners = 'as registered domestic partners',
  married_couple = 'a married couple',
  husband_and_husband = 'husband and husband',
  wife_and_wife = 'wife and wife',
}

export enum PartyTypeValue {
  default = '',
  married = 'A Married Couple',
  individual = 'Individual',
  entity = 'Entity',
  trust = 'Trust/Estate',
  toBeDetermined = 'Status to be determined',
}

export enum MyLenderDropdown {
  noMatchingLender = 'No Matching Lender',
  default = '',
}
export enum LenderClauseTypeValue {
  care_of = 'Care of',
  dba = 'DBA',
  standard = 'Standard',
  successor_ownership = 'Successor Ownership',
  fha = 'FHA',
  va = 'VA',
  mers = 'MERS',
  other = 'Other',
}

export enum TransactionTypeValue {
  saleCash = 'Sale/Cash',
  saleMortgage = 'Sale w/Mortgage',
  refinance = 'Refinance',
  equityLoan = 'Equity Loan',
  saleConstructionLoan = 'Sale w/Construction Loan',
  other = 'Other',
  default = 'Sale w/Mortgage',
}

export enum RoleNameValue {
  agent = 1,
  user = 2,
  system_administrator = 3,
  avl_interface = 4,
  on_behalf_of = 5,
  title_and_escrow_user = 7,
  title_only_user = 10,
  escrow_only_user = 11,
  cpl_only = 12,
  staff_administrator = 13,
}

export const MAX_BUYERS = 8;
export const MAX_SELLERS = 8;
export const MAX_LENDERS = 4;
