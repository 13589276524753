import { gql } from '@apollo/client';
import performQuery from 'features/help/queries/contentfulClient';
import { SiteSettings } from '../types/siteSettings';
import { HELP_SITE_SETTINGS_ID } from '../constants';

export type GetTopicCollectionArgs = {
  iconWidth: number;
  iconHeight: number;
};

const SITE_SETTINGS_QUERY = gql`
  query siteSettings {
    anHelpSettings(id: "${HELP_SITE_SETTINGS_ID}") {
      paginationLimit
    }
  }
`;

export default async function useSiteSettingsQuery() {
  const response = await performQuery(SITE_SETTINGS_QUERY, {});

  if (response.hasError) {
    return {
      data: null,
      error: true,
    };
  }

  return {
    data: response.res?.data?.anHelpSettings as SiteSettings | null,
    error: false,
  };
}
