import React from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface IFormSectionProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    headerRoot: {
      width: 'inherit',
    },
  }),
);

export const FormSection = ({ header, body }: IFormSectionProps): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3} className={classes.headerRoot}>
        {header && (
          <Grid item xs={12} component={'header'} className={classes.headerRoot}>
            {header}
          </Grid>
        )}

        {body && (
          <Grid item xs={12} component={'div'}>
            <form> {body}</form>
          </Grid>
        )}
      </Grid>
    </>
  );
};
