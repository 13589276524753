import { handleError } from 'utilities/utilities';
import { getApiHost } from '../host-by-env';
import { AccountRegistrationDTO } from './types';

export async function accountRegistrationSubmit(payload: AccountRegistrationDTO): Promise<boolean> {
  try {
    const registerUrl = `${getApiHost()}user/account-registration`;
    const response = await fetch(registerUrl, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
    });
    const json = await response.json();
    handleError(json);
    return json.result;
  } catch (error) {
    console.error('Error in accountRegistration', error);
    return false;
  }
}
