import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

export type FormHeadingProps = {
  children: ReactNode;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      paddingTop: theme.spacing(2),
    },
  }),
);

const FormHeading: FC<FormHeadingProps> = ({ children, className }: FormHeadingProps) => {
  const classes = useStyles();

  return (
    <Typography className={clsx(classes.heading, className)} color="primary" variant="h6">
      {children}
    </Typography>
  );
};

export default FormHeading;
