import React, { ReactNode } from 'react';
import { Container, createStyles, makeStyles, AppBar, Tabs, Theme } from '@material-ui/core';
import clsx from 'clsx';

export type L2NavMenuProps = {
  children?: ReactNode;
  className?: string;
  scrollable?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowX: 'scroll',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    tabMenu: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: 64,
      '& .MuiTabScrollButton-root': {
        position: 'absolute',
        zIndex: 1,
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        transitionProperty: 'opacity',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        '&:last-child': {
          right: 0,
        },
      },
    },
    hideScroll: {
      display: 'flex',
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
);

export const L2NavMenu = ({ children, scrollable, className }: L2NavMenuProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Container disableGutters className={classes.container}>
      {scrollable ? (
        <AppBar position="static" color="inherit" elevation={0}>
          <Tabs value={false} className={classes.tabMenu} variant="scrollable" scrollButtons="on">
            {children}
          </Tabs>
        </AppBar>
      ) : (
        <div className={clsx(classes.hideScroll, className)}>{children}</div>
      )}
    </Container>
  );
};

export default L2NavMenu;
