import React from 'react';

export const Error = (): JSX.Element => {
  const openProductFruitsFeedback = () => {
    if (window.productFruitsIsReady) {
      window.productFruits?.api.feedback.showModal(null, null);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 0',
      }}
    >
      <h1 style={{ fontWeight: 500, marginBottom: '50px' }}>An unexpected error occurred.</h1>
      <svg width="195" height="171" viewBox="0 0 195 171" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M94.2698 169.212C140.627 169.212 178.21 131.629 178.21 85.106C178.21 38.5828 140.462 1 94.2698 1C47.9122 1 10.3294 38.5828 10.3294 85.106C10.3294 131.629 47.9122 169.212 94.2698 169.212Z"
          fill="#F1F3F9"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M194.693 148.711C194.573 152.798 190.967 155.924 186.88 155.924H133.988C132.065 155.924 130.502 157.366 130.382 159.169C130.261 161.213 131.824 162.775 133.868 162.775H147.331C148.172 162.775 148.894 163.497 148.894 164.338C148.894 165.18 148.172 165.901 147.331 165.901H67.9933C63.9062 165.901 60.4202 162.775 60.1798 158.688C60.0595 154.481 63.4254 150.995 67.6327 150.995H79.2929C80.9758 150.755 82.4184 149.312 82.4184 147.509C82.4184 145.586 80.8556 144.023 78.9323 144.023H43.1101C37.0997 144.023 31.9307 139.575 31.5701 133.565C31.0893 127.073 36.2582 121.664 42.7495 121.664H148.293C154.423 121.664 159.472 126.713 159.472 132.843C159.472 135.488 158.51 137.892 157.068 139.816C157.789 140.176 158.39 140.537 158.991 141.018H187.601C191.448 141.138 194.813 144.504 194.693 148.711Z"
          fill="#9FA8BA"
        />
        <path
          d="M116.013 44.3975L84.3983 50.5281L31.3863 60.866L8.06578 65.434C3.85848 66.2754 0.973475 70.1221 1.3341 74.4496L5.90203 125.538C6.26266 129.746 9.86891 133.111 14.0762 133.111L124.067 134.434L116.013 44.3975Z"
          fill="white"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M129.115 48.004L136.448 128.664C136.448 129.265 136.088 129.866 135.487 129.986L126.711 131.91L118.898 46.5615L127.913 46.8019C128.514 46.8019 128.995 47.403 129.115 48.004Z"
          fill="white"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M109.043 41.2722L117.938 138.882C118.058 139.603 118.78 140.084 119.381 139.723L128.036 136.357C129.118 135.876 129.839 134.674 129.719 133.472L121.665 44.1572C121.544 42.9551 120.703 41.9934 119.501 41.6328L110.365 40.0701C109.644 39.9499 109.043 40.5509 109.043 41.2722Z"
          fill="white"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M85.6024 50.4082L32.5904 60.7461C31.9894 63.6312 31.7489 66.5162 32.1096 69.5214C33.5521 85.7495 47.8569 97.6502 63.9649 96.2077C80.0728 94.7652 91.9735 80.4604 90.531 64.3524C90.0501 59.1834 88.3672 54.4953 85.6024 50.4082Z"
          fill="#F1F3F9"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
      <p className="p-b-8" style={{ fontSize: '14px', marginBottom: 0, marginTop: '50px' }}>
        Please try again or{' '}
        <a style={{ color: '#0074CA', textDecoration: 'underline' }} onClick={openProductFruitsFeedback}>
          contact support.
        </a>
      </p>
    </div>
  );
};
