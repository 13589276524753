import { getApiHost } from '../host-by-env';
import { AvlLetterRequest, EmailAvlLetterRequest } from './types';

export const generateAvl = async (payload: AvlLetterRequest, token: string) => {
  const url = `${getApiHost()}firms/avl`;
  let errorMsg;
  let generateAvlResponse: any = {};
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch(() => {
    errorMsg = 'Please try again later.';
  });

  if (response) {
    const json = await response.json();
    generateAvlResponse = json.result;
  } else {
    generateAvlResponse.error = errorMsg;
  }

  return generateAvlResponse;
};

export const emailAvl = async (payload: EmailAvlLetterRequest, token: string) => {
  const url = `${getApiHost()}firms/email-avl`;
  let errorMsg;
  let emailAvlResponse: any = {};
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch(() => {
    errorMsg = 'Please try again later.';
  });

  if (response) {
    const json = await response.json();
    emailAvlResponse = json.result;
  } else {
    emailAvlResponse.error = errorMsg;
  }

  return emailAvlResponse;
};
