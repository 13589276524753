import React from 'react';
import clsx from 'clsx';
import { Snackbar, SnackbarProps, Typography } from '@material-ui/core';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import './Notification.scss';

export interface NotificationProps extends AlertProps {
  error?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  title?: string;
  msg?: string;
  action?: React.ReactNode;
  handleClose?: () => void;
  snackbar?: boolean;
  alertClass?: string;
  snackBarClass?: string;
  inline?: boolean;
  duration?: number;
  disableYPos?: boolean;
  sticky?: boolean;
  stickyClass?: string;
  disablePadding?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { borderWidth: 1, borderStyle: 'solid', color: '#FF9800', width: '100%' },
    alert: {
      padding: '8px 16px',
      '& li': {
        padding: '0 8px',
        margin: 0,
      },
    },
    alertTitle: {
      fontWeight: 500,
      fontSize: '1.6rem',
      lineHeight: '1.9rem',
      color: theme.palette.text.primary,
    },
    alertWrapInline: {
      position: 'relative',
      width: '100%',
      boxShadow: 'none',
      filter: 'none',
      '& .MuiAlert-icon': {
        padding: '6px 0px 0 16px',
      },
    },
    alertWrapStandalone: {
      position: 'absolute',
      width: '100%',
      top: 0,
      maxWidth: 800,
    },
    disablePadding: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    info: {
      backgroundColor: '#EAF4FC',
      color: '#0D3C61',
    },
    infoText: {
      color: '#0D3C61',
    },
  }),
);

export const Notification = (props: NotificationProps, sProps: SnackbarProps): JSX.Element => {
  const classes = useStyles();
  return props.snackbar ? (
    <div className={`snackbar-wrapper ${props.snackBarClass ? props.snackBarClass : ''}`}>
      <Snackbar
        className={`snackbar-fixed ${props.disableYPos ? 'snackbar-disable-transform' : ''}`}
        open={true}
        autoHideDuration={props.duration ? props.duration : 6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...sProps}
      >
        <Alert
          className={`alert-wrap ${props.alertClass ? props.alertClass : ''}`}
          severity={props.severity}
          {...props}
          onClose={props.handleClose}
        >
          {props.title && <AlertTitle>{props.title}</AlertTitle>}
          {props.msg}
        </Alert>
      </Snackbar>
    </div>
  ) : props.sticky ? (
    <div>
      <Snackbar
        open={true}
        hidden={false}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...sProps}
        className={`${props.stickyClass ? props.stickyClass : ''}`}
      >
        <Alert className={classes.root} severity={props.severity} {...props} onClose={props.handleClose}>
          {props.title && <AlertTitle className={classes.alertTitle}>{props.title}</AlertTitle>}
          <Typography variant="body2" color="textSecondary">
            {props.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  ) : (
    <Alert
      className={clsx(
        classes.alert,
        props.inline ? classes.alertWrapInline : classes.alertWrapStandalone,
        props.alertClass ? props.alertClass : '',
        { [classes.disablePadding]: props.disablePadding, [classes.info]: props.severity === 'info' },
      )}
      severity={props.severity}
      {...props}
      onClose={props.handleClose}
    >
      {props.title && (
        <AlertTitle className={clsx(classes.alertTitle, { [classes.infoText]: props.severity === 'info' })}>
          {props.title}
        </AlertTitle>
      )}
      <Typography
        variant="body2"
        color="textSecondary"
        className={clsx({ [classes.infoText]: props.severity === 'info' })}
      >
        {props.msg}
        {props.children}
      </Typography>
    </Alert>
  );
};
