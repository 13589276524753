import React from 'react';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';

interface StandaloneEndorsementActionProps {
  hasSavedEndorsements?: boolean;
  saveStandaloneEndorsement: () => void;
  cancelStandaloneEndorsement?: () => void;
  isSaveDisabled?: boolean;
}
const StandaloneEndorsementActions: React.FC<StandaloneEndorsementActionProps> = ({
  hasSavedEndorsements,
  saveStandaloneEndorsement,
  cancelStandaloneEndorsement,
  isSaveDisabled,
}: StandaloneEndorsementActionProps) => {
  return (
    <BottomAppBar
      onCancel={() => {
        cancelStandaloneEndorsement && cancelStandaloneEndorsement();
      }}
      primaryActionLabel={hasSavedEndorsements ? 'Update Endorsements' : 'Create Endorsements'}
      onPrimaryClick={() => saveStandaloneEndorsement && saveStandaloneEndorsement()}
      disableCancel={isSaveDisabled}
      cancelDataQa={'StandaloneEndorsementCancel'}
      primaryActionDataQa={hasSavedEndorsements ? 'StandaloneEndorsementUpdate' : 'StandaloneEndorsementCreate'}
      showPrimary={true}
      disablePrimary={isSaveDisabled}
      accordionView
    />
  );
};
export default StandaloneEndorsementActions;
