import moment from 'moment';
import { ValidationError } from 'yup';

export type FieldValidationError = { field: string; message: string };

export type FileValidationErrors = {
  fileInfoErrors: FieldValidationError[];
  propertyErrors: FieldValidationError[];
  partyErrors: FieldValidationError[];
};
/* eslint-disable */
/**
 * Given yup style errors, filter for messages by index
 * errs=[
 *    {"field": "properties[0].address1", "message": "blah"}
 *    {"field": "properties[1].address1", "message": "blah"}
 * ]
 * filerErrorsByIndex(1, errs)
 *    returns [{"field": "address1", "message": "blah"}]
 * See the test for this function.
 */
export function filterErrorsByIndex(index: number, errs?: FieldValidationError[]): FieldValidationError[] {
  if (!errs) return [];

  const filteredErrs = errs.filter((err) => err.field.indexOf(`[${index}]`) !== -1);

  return filteredErrs.map((err) => {
    const splits = err.field.split(/\]\./);
    const cleanFieldName = splits[1];
    return { field: cleanFieldName, message: err.message };
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export function doValidate<T>(dataObj: T, yupSchema: any): any {
  return (
    yupSchema
      .validate(dataObj, { abortEarly: false })
      .then(() => {
        // Returns null when there are no errors.
        return null;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        if (err instanceof ValidationError) {
          const errorList: FieldValidationError[] = [];
          err.inner.forEach(({ path, message }) => {
            if (path != null) {
              const errMsg = message.replace(path, '');
              errorList.push({ field: path, message: errMsg });
            }
          });
          return errorList;
        }
      })
  );
}

// TODO: change to date-fns
export function formatDateStr(dateStr?: string): string {
  let formattedDateStr = '';
  try {
    formattedDateStr = moment(dateStr).format();
  } catch (e) {
    formattedDateStr = '';
  }

  return formattedDateStr;
}
