import Sqids from 'sqids';

class HashUtils {
  static sqids = new Sqids({
    alphabet: 'abcdefghijklmnopqrstuvwxyz1234567890',
    minLength: 12,
  });

  public static encode(value: number): string {
    return this.sqids.encode([value]);
  }

  public static decode(id: string): number {
    return this.sqids.decode(id)['0'];
  }
}

export default HashUtils;
