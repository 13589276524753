import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

function searchToObject<T>(search: string) {
  const searchParams = new URLSearchParams(search);
  const params = Object.fromEntries(searchParams.entries());
  return params as unknown as T;
}

export function useSearchParams<T>() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState<T>(searchToObject(location.search));

  useEffect(() => {
    setSearchParams(searchToObject(location.search));
  }, [location.search]);

  return searchParams;
}
