import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@agentnet/auth';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';

type IdlePrompterMessageType = 'active' | 'idle' | 'prompt';

const promptBeforeIdle = 300 * 1000; // 5 minutes in MS
const throttleTime = 500; // 500ms

const IdlePrompter: React.FC = () => {
  const history = useHistory();
  const timeout = 5400 * 1000; // 90 minutes in MS
  const [remaining, setRemaining] = useState<number>(timeout);
  const [isInactiveDialogueOpen, setIsInactiveDialogueOpen] = useState<boolean>(false);
  const interval = useRef<NodeJS.Timer | null>(null);

  const { signOut } = useAuth();

  const handleLogout = useCallback((): void => {
    signOut();
  }, [history]);

  const onActive = (): void => {
    setIsInactiveDialogueOpen(false);
  };

  const onIdle = (): void => {
    setIsInactiveDialogueOpen(false);
    handleLogout();
  };

  const onPrompt = (): void => {
    setIsInactiveDialogueOpen(true);
  };

  /**
   * Invokes the passed in function and sends a message to other instances of IdlePrompter (such as a new tab).
   * It's important to wrap the function with this method before passing it to `useIdleTimer` to avoid circular dependencies.
   * @param messageType The message type to send.
   * @param fn The function to invoke.
   * @returns A function that invokes the passed in function and sends a message to other instances of IdlePrompter.
   */
  const withMessage = (messageType: IdlePrompterMessageType, fn: () => void): (() => void) => {
    return () => {
      fn();
      message(messageType);
    };
  };

  const onMessage = (messageType: IdlePrompterMessageType): void => {
    // the `| undefined` is a bit of defensive programming in case the definition of `IdlePrompterMessageType` is not exhaustive.
    const fnMap: Record<IdlePrompterMessageType, (() => void) | undefined> = {
      active: onActive,
      idle: onIdle,
      prompt: onPrompt,
    };

    fnMap[messageType]?.();
  };

  const checkSession = useCallback(
    (callback?: () => void) => {
      callback?.();
    },
    [handleLogout],
  );

  const { getRemainingTime, activate, message } = useIdleTimer({
    onIdle: withMessage('idle', onIdle),
    onActive: withMessage('active', onActive),
    onPrompt: withMessage('prompt', onPrompt),
    onMessage,
    timeout,
    promptBeforeIdle,
    throttle: throttleTime,
    crossTab: true,
    leaderElection: true,
    syncTimers: throttleTime,
  });

  useEffect(() => {
    if (!interval.current) {
      interval.current = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, throttleTime);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [getRemainingTime]);

  const handleStillHere = (): void => {
    checkSession(activate);
    setIsInactiveDialogueOpen(false);
  };

  const minutes = Math.floor(remaining / 60);
  const seconds = (remaining % 60).toString().padStart(2, '0');

  return (
    <AgentNetConfirmationDialog
      open={isInactiveDialogueOpen}
      dialogTitle={'Session Timeout'}
      dialogTextHTML={
        <>
          <Typography variant="body1">
            For security reasons and protection of data, your session will expire in&nbsp;
            <Typography variant={'inherit'} component={'strong'}>
              {minutes}:{seconds}
            </Typography>
            .
          </Typography>
          <Typography>
            <p className="mb0">{'Click "Logout" to end your session or "Continue" to extend it.'}</p>
          </Typography>
        </>
      }
      dialogBtnContent={'Continue'}
      secondaryActionBtnContent={'Logout'}
      onDismissAction={handleLogout}
      onConfirm={handleStillHere}
      disableBackDropClick
    />
  );
};

export default IdlePrompter;
