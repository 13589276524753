import React from 'react';

const CardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
      <path
        d="M52 102.6C80 102.6 102.7 79.9 102.7 51.8C102.7 23.7 80 1 52 1C24 1 1.30005 23.7 1.30005 51.8C1.30005 79.9 24 102.6 52 102.6Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M83.4 22.2V63.9C83.4 66.4 81.2999 68.5 78.7999 68.5H9.79995C7.29995 68.5 5.19995 66.5 5.19995 63.9V22.2C5.19995 19.7 7.29995 17.6 9.79995 17.6H78.7999C81.2999 17.6 83.4 19.7 83.4 22.2Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M95.7 89.5H31.6C27.7 89.5 24.6 86.4 24.6 82.5V45.6C24.6 41.7 27.7 38.6 31.6 38.6H95.7C99.6 38.6 102.7 41.7 102.7 45.6V82.5C102.7 86.4 99.6 89.5 95.7 89.5Z"
        fill="white"
        stroke="#D6DCE8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M44.4 78H34.2C33.3 78 32.6 77.3 32.6 76.4C32.6 75.5 33.3 74.8 34.2 74.8H44.4C45.3 74.8 46 75.5 46 76.4C46 77.3 45.3 78 44.4 78Z"
        fill="#D6DCE8"
      />
      <path
        d="M60.9 78H50.7C49.8 78 49.1 77.3 49.1 76.4C49.1 75.5 49.8 74.8 50.7 74.8H60.9C61.8 74.8 62.5 75.5 62.5 76.4C62.5 77.3 61.8 78 60.9 78Z"
        fill="#D6DCE8"
      />
      <path
        d="M77.4 78H67.2C66.3 78 65.6 77.3 65.6 76.4C65.6 75.5 66.3 74.8 67.2 74.8H77.4C78.3 74.8 79 75.5 79 76.4C79 77.3 78.3 78 77.4 78Z"
        fill="#D6DCE8"
      />
      <path
        d="M93.9 78H83.7C82.8 78 82.1 77.3 82.1 76.4C82.1 75.5 82.8 74.8 83.7 74.8H93.9C94.8 74.8 95.5 75.5 95.5 76.4C95.5 77.3 94.8 78 93.9 78Z"
        fill="#D6DCE8"
      />
      <path
        d="M90.2 58.8C93.5137 58.8 96.2 56.1137 96.2 52.8C96.2 49.4863 93.5137 46.8 90.2 46.8C86.8862 46.8 84.2 49.4863 84.2 52.8C84.2 56.1137 86.8862 58.8 90.2 58.8Z"
        fill="#AAB2C5"
      />
      <path
        d="M82.2999 58.8C85.6136 58.8 88.2999 56.1137 88.2999 52.8C88.2999 49.4863 85.6136 46.8 82.2999 46.8C78.9862 46.8 76.2999 49.4863 76.2999 52.8C76.2999 56.1137 78.9862 58.8 82.2999 58.8Z"
        fill="#D6DCE8"
      />
      <path d="M83.3999 26.1H5.29993V35.9H83.3999V26.1Z" fill="#AAB2C5" />
      <path
        d="M45.3001 60.7H34.1001C33.3001 60.7 32.6001 60 32.6001 59.2V52C32.6001 51.2 33.3001 50.5 34.1001 50.5H45.2001C46.0001 50.5 46.7001 51.2 46.7001 52V59.2C46.8001 60 46.1001 60.7 45.3001 60.7Z"
        fill="#D6DCE8"
      />
    </svg>
  );
};
export default CardIcon;
