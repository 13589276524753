import React, { ElementType, ReactElement } from 'react';
import clsx from 'clsx';
import {
  createStyles,
  ListItemAvatar,
  ListItemAvatarProps,
  ListItemText,
  ListItemTextProps,
  makeStyles,
  MenuItem,
  MenuItemProps,
  Theme,
} from '@material-ui/core';

type AppSwitcherItemProps = {
  icon: ReactElement;
  ListItemAvatarProps?: ListItemAvatarProps;
  ListItemTextProps?: ListItemTextProps;
} & Omit<MenuItemProps<ElementType, { component?: ReactElement }>, 'button'>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ListItemAvatar: {
      color: theme.palette.text.secondary,
      minWidth: 24,
      paddingRight: theme.spacing(1),
    },
    ListItemText: {
      color: theme.palette.common.black,
    },
    MenuItem: {
      [theme.breakpoints.up('sm')]: {
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        width: `calc(50% - ${theme.spacing(2)}px)`,
      },
      '&:hover $ListItemAvatar': {
        color: theme.palette.primary.main,
      },
      whiteSpace: 'normal',
      width: '100%',
    },
  }),
);

const AppSwitcherItem = React.forwardRef<HTMLLIElement, AppSwitcherItemProps>(
  (
    {
      alignItems = 'flex-start',
      className,
      icon,
      ListItemAvatarProps,
      ListItemTextProps,
      ...other
    }: AppSwitcherItemProps,
    ref,
  ): ReactElement | null => {
    const classes = useStyles();

    return (
      <MenuItem alignItems={alignItems} ref={ref} {...other} className={clsx(classes.MenuItem, className)}>
        <ListItemAvatar
          {...ListItemAvatarProps}
          className={clsx(classes.ListItemAvatar, ListItemAvatarProps?.className)}
        >
          {icon}
        </ListItemAvatar>
        <ListItemText
          secondaryTypographyProps={{ noWrap: false }}
          {...ListItemTextProps}
          className={clsx(classes.ListItemText, ListItemTextProps?.className)}
        />
      </MenuItem>
    );
  },
);

AppSwitcherItem.displayName = 'AppSwitcherItemProps';

export default AppSwitcherItem;
