import React from 'react';
import { FieldValidationError } from '../../../utilities/validation/validation';
import { makeStyles, Theme } from '@material-ui/core/styles';

function getErrorsByField(errData: FieldValidationError[], field: string): FieldValidationError[] {
  return errData?.filter((err) => err.field === field);
}

interface ErrorSectionProps {
  errs?: FieldValidationError[];
  field: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: { ...theme.typography.caption, color: theme.palette.error.main, lineHeight: 1.66, marginTop: '3px' },
}));

const ErrorSection = ({ errs, field }: ErrorSectionProps): JSX.Element => {
  const classes = useStyles();
  if (!errs) {
    return <></>;
  }
  const theErrs: FieldValidationError[] = getErrorsByField(errs, field);
  return (
    <div>
      {theErrs.map((err, ndx) => (
        <div key={ndx} className={classes.error}>
          {err.message}
        </div>
      ))}
    </div>
  );
};

export default ErrorSection;
