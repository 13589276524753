import { createStyles, makeStyles, SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 32,
      height: 32,
    },
  }),
);

const FluentLogo: FC<SvgIconProps> = () => {
  const classes = useStyles();
  return (
    <SvgIcon className={classes.root} viewBox="0 0 32 32" data-testid="FluentLogo">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M30.5438 8.24642L25.5548 5.26953C25.4175 6.04666 24.9308 6.73193 24.1653 7.19096L17.5972 11.1288L21.6869 13.5807C22.4878 14.0609 23.1431 15.2394 23.1431 16.1998V30.2543C23.1444 30.7169 23.322 31.1602 23.637 31.4874C23.952 31.8145 24.3789 31.9989 24.8245 32.0003H30.3185C30.764 31.9989 31.1909 31.8145 31.506 31.4874C31.821 31.1602 31.9986 30.7169 31.9999 30.2543V10.8654C31.9999 9.90514 31.3447 8.72656 30.5438 8.24642Z"
          fill="#1C94B9"
        />
        <path
          d="M30.5438 8.24642L25.5548 5.26953C25.4175 6.04666 24.9308 6.73193 24.1653 7.19096L17.5972 11.1288L21.6869 13.5807C22.4878 14.0609 23.1431 15.2394 23.1431 16.1998V30.2543C23.1444 30.7169 23.322 31.1602 23.637 31.4874C23.952 31.8145 24.3789 31.9989 24.8245 32.0003H30.3185C30.764 31.9989 31.1909 31.8145 31.506 31.4874C31.821 31.1602 31.9986 30.7169 31.9999 30.2543V10.8654C31.9999 9.90514 31.3447 8.72656 30.5438 8.24642Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M23.4689 3.94454L17.5212 0.362123C17.0738 0.114482 16.5727 -0.00997447 16.0658 0.000624572C15.5888 -0.00744214 15.1166 0.10111 14.6875 0.317514C14.6619 0.331392 14.6353 0.344327 14.6106 0.359112L1.45613 8.24592C0.655245 8.72605 0 9.90463 0 10.8649V30.254C0.00130762 30.7166 0.178868 31.16 0.493901 31.4871C0.808935 31.8142 1.23584 31.9986 1.68136 32H7.17522C7.62074 31.9986 8.04764 31.8142 8.36267 31.4871C8.6777 31.1599 8.85527 30.7166 8.85658 30.254V27.5095C8.85789 27.0468 9.03545 26.6035 9.35048 26.2764C9.66551 25.9492 10.0924 25.7648 10.5379 25.7635H14.3857C14.8312 25.7621 15.2581 25.5777 15.5731 25.2506C15.8882 24.9234 16.0657 24.4801 16.067 24.0175V18.3123C16.0657 17.8496 15.8882 17.4063 15.5731 17.0792C15.2581 16.752 14.8312 16.5676 14.3857 16.5663H10.5381C9.61326 16.5663 8.8567 16.0909 8.8567 15.5098C8.8567 14.9288 9.51197 14.0606 10.3129 13.5805L23.4674 5.69368C24.2681 5.2135 24.2689 4.42638 23.4689 3.94454Z"
          fill="#1C94B9"
        />
      </svg>
    </SvgIcon>
  );
};

export default FluentLogo;
