import React from 'react';
import { GridCellValue } from '@mui/x-data-grid-pro';

type fileStatusProps = {
  value?: GridCellValue | string;
  statusClassName?: string;
  statusDotClassName?: string;
  statusColor?: string;
  valueStyle?: string;
  children?: React.ReactElement;
};

function FileStatus(props: fileStatusProps): React.ReactElement {
  const { value, statusClassName, statusDotClassName, statusColor, valueStyle } = props;
  return (
    <div className={`${statusClassName} custom-ellipsis`} style={{ color: statusColor }}>
      <span className={statusDotClassName} /> &nbsp;<span className={valueStyle}>{value}</span>
    </div>
  );
}
export default FileStatus;
