import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { createStyles, Grid, GridProps, IconButton, makeStyles, Theme } from '@material-ui/core';
import { AddCircle, RemoveCircleOutline } from '@material-ui/icons';

import EmailField, { EmailFields } from './EmailField';
import StarAdornment from './StarAdornment';

import cuid from 'cuid';
import ConfirmationDialog from '../ConfirmationDialog';

export type MultiRowEmailFieldProps = {
  ContainerComponentProps?: GridProps;
  value?: EmailFields[];
  onChange: (value: EmailFields[]) => void;
  onBlur?: (value: EmailFields[]) => void;
  className?: string;
  errors?: (string | undefined)[];
  validationTrigger?: 'onChange' | 'onBlur';
  confirmModal?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    delete: {
      color: theme.palette.text.secondary,
    },
    add: {
      color: theme.palette.primary.main,
    },
  }),
);

/**
 * Returns new array without item at index.
 */
export function removeAtIndex<T>(value: readonly T[], index: number): T[] {
  return value.filter((_, currentIndex) => currentIndex !== index);
}

/**
 * Returns new array where head is item at index and tail is remaining items in original array.
 */
export function moveIndexToHead<T>(value: readonly T[], index: number): T[] {
  const head = value[index];

  const tail = removeAtIndex(value, index);

  return [head, ...tail];
}

const generateDefaultValue = (value?: EmailFields) => {
  const id = value?.id || cuid();

  return {
    id,
    emailAddress: '',
    isPrimary: false,
    ...value,
  } as EmailFields;
};

export const MultiRowEmailField = (props: MultiRowEmailFieldProps): JSX.Element => {
  const {
    ContainerComponentProps,
    errors,
    onBlur,
    onChange,
    value,
    validationTrigger = 'onChange',
    confirmModal = true,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const state = useMemo(
    () => (value?.length ? value : [generateDefaultValue({ isPrimary: true })]).map(generateDefaultValue),
    [value],
  );

  const revalidate = (newState: EmailFields[]) => {
    // NOTE: We need to cause validation to retrigger on the specified user event
    const validationHandler = (props as MultiRowEmailFieldProps)[validationTrigger];
    validationHandler?.(newState);
  };

  const handleChange = (value: EmailFields, index: number) => {
    const newState = [...state];
    newState[index] = value;

    onChange(newState);
    revalidate(newState);
  };

  const handleBlur = () => {
    onBlur?.(state);
  };

  const handleRowEvent = (state: EmailFields[]) => {
    onChange(state);
    onBlur?.(state);
  };

  const handleToggle = (indexToMove: number) => {
    const newState = moveIndexToHead(state, indexToMove).map((item, index) => ({
      ...item,
      isPrimary: index === 0,
    }));

    handleRowEvent(newState);
  };

  const deleteRow = (indexToDelete: number) => {
    const newState = removeAtIndex(state, indexToDelete);

    handleRowEvent(newState);
  };

  const addRow = () => {
    const newRow: EmailFields = generateDefaultValue();

    const [head, ...tail] = state;

    const newState = [head, newRow, ...tail];

    handleRowEvent(newState);
  };

  const handleOpen = (index: number) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    deleteRow(currentIndex);
    handleClose();
  };

  return (
    <Grid container {...ContainerComponentProps}>
      {state.map((emailFieldset, index) => {
        const error = errors?.[index];

        const handleEmailChange = (emailFields: EmailFields) => handleChange(emailFields, index);
        const handleStarToggle = () => handleToggle(index);
        const handleIconClick = () => (confirmModal ? handleOpen(index) : deleteRow(index));
        const handleEmailBlur = () => handleBlur();

        const adornment =
          state.length > 1 ? (
            <StarAdornment
              color="primary"
              edge="end"
              isFilled={emailFieldset.isPrimary as boolean}
              onToggle={handleStarToggle}
              data-testid="primary-email-star"
            />
          ) : null;

        return (
          <Grid item container xs={12} key={emailFieldset.id} role="row" id={emailFieldset.id}>
            <Grid item xs={11}>
              <EmailField
                EmailFieldProps={{
                  InputProps: {
                    endAdornment: adornment,
                  },
                  label: `Email`,
                  placeholder: 'someone@email.com',
                }}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                value={emailFieldset}
                error={error}
              />
            </Grid>
            <Grid item xs={1}>
              {index == 0 && (
                <IconButton
                  key={index}
                  onClick={addRow}
                  className={clsx(classes.add)}
                  aria-label="add-row-button"
                  data-testid="add-email-button"
                >
                  <AddCircle />
                </IconButton>
              )}

              {state.length > 1 && index != 0 && (
                <IconButton
                  key={index}
                  onClick={handleIconClick}
                  className={clsx(classes.delete)}
                  aria-label="delete-row-button"
                  data-testid="delete-email-button"
                >
                  <RemoveCircleOutline />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })}
      <ConfirmationDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        dialogText="
          You're about to remove an email from this record. Do you wish to continue?"
      />
    </Grid>
  );
};

export default MultiRowEmailField;
