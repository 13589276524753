import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { RecordingFee } from 'api/file/interfaces/get-file-summary-report';

type RecordingFeeCostProps = {
  recordingFeeCost: RecordingFee;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    fixedWidth: {
      width: '300px',
    },
    f10: {
      fontSize: '11pt',
      paddingLeft: theme.spacing(1),
    },
    f9: {
      fontSize: '10pt',
      paddingLeft: theme.spacing(1),
    },
  }),
);

const RecordingFeeCost: React.FC<RecordingFeeCostProps> = ({ recordingFeeCost }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Typography className={classes.f10}>
          <strong>{recordingFeeCost.documentName}</strong>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography></Typography>
      </Grid>
      <Grid item xs={2}>
        {(recordingFeeCost.documentAmountBuyer ?? -1) >= 0 && (
          <Typography className={classes.f9}>
            {Number(recordingFeeCost.documentAmountBuyer).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        {(recordingFeeCost.documentAmountSeller ?? -1) >= 0 && (
          <Typography className={classes.f9}>
            {Number(recordingFeeCost.documentAmountSeller).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        {(recordingFeeCost.documentAmount ?? -1) >= 0 && (
          <Typography className={classes.f9}>
            {Number(recordingFeeCost.documentAmount).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </Typography>
        )}
      </Grid>
      {recordingFeeCost.documentPayableTo && (
        <Grid item xs={12}>
          <Typography className={classes.f9}>Payable To: {recordingFeeCost.documentPayableTo}</Typography>
        </Grid>
      )}
      {recordingFeeCost.documentTaxAssessedBy && (
        <Grid item xs={12}>
          <Typography className={classes.f9}>Assessed By: {recordingFeeCost.documentTaxAssessedBy}</Typography>
        </Grid>
      )}
      {recordingFeeCost.questionList && recordingFeeCost.questionList.length > 0 && (
        <Grid item xs={12}>
          <Typography>
            {recordingFeeCost.questionList.map((question, index) => (
              <div key={index} className={classes.f9}>
                {question.questionText}: {question.questionValue}
              </div>
            ))}
          </Typography>
        </Grid>
      )}
      {recordingFeeCost.notes && recordingFeeCost.notes.length > 0 && (
        <Grid item xs={12}>
          <Typography>
            {recordingFeeCost.notes.map((note, index) => (
              <div className={classes.f9} key={index}>
                {note}
              </div>
            ))}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default RecordingFeeCost;
