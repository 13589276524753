import React from 'react';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';

interface JacketActionProps {
  hasJackets?: boolean;
  saveJacket: () => void;
  cancelJacket?: () => void;
  isSaveDisabled?: boolean;
  isCancelDisabled?: boolean;
}
const JacketActions: React.FC<JacketActionProps> = ({
  hasJackets,
  saveJacket,
  cancelJacket,
  isSaveDisabled,
  isCancelDisabled,
}: JacketActionProps) => {
  return (
    <BottomAppBar
      onCancel={() => {
        cancelJacket && cancelJacket();
      }}
      primaryActionLabel={hasJackets ? 'Update Jacket' : 'Create Jackets'}
      onPrimaryClick={() => saveJacket && saveJacket()}
      disableCancel={isCancelDisabled}
      cancelDataQa={'JacketCancel'}
      primaryActionDataQa={'JacketCreate'}
      showPrimary={true}
      disablePrimary={isSaveDisabled}
      accordionView
    />
  );
};
export default JacketActions;
