import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Menu, MenuProps, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Menu: {
      marginTop: theme.spacing(2),
    },
    MenuList: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
        maxWidth: 572,
        minHeight: 150,
      },
    },
  }),
);

export type AppSwitcherProps = MenuProps;

const AppSwitcher = ({ children, className, ...props }: AppSwitcherProps): ReactElement<AppSwitcherProps> => {
  const classes = useStyles();

  return (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      className={clsx(classes.Menu, className)}
      getContentAnchorEl={null}
      MenuListProps={{ className: classes.MenuList }}
      {...props}
    >
      {children}
    </Menu>
  );
};

export default AppSwitcher;
