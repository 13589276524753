import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SignUpUserContextProps {
  userId: string | null;
  setUserId: (id: string | null) => void;
  previousPage: string | null;
  setPreviousPage: (page: string | null) => void;
  loginUserId: string | null;
  setLoginUserId: (loginUserId: string | null) => void;
}

const SignUpUserContext = createContext<SignUpUserContextProps | undefined>(undefined);

export const SignUpUserProvider = ({ children }: { children: ReactNode }) => {
  const [userId, setUserId] = useState<string | null>(null);
  const [loginUserId, setLoginUserId] = useState<string | null>(null);
  const [previousPage, setPreviousPage] = useState<string | null>(null);

  return (
    <SignUpUserContext.Provider
      value={{ userId, setUserId, previousPage, setPreviousPage, loginUserId, setLoginUserId }}
    >
      {children}
    </SignUpUserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(SignUpUserContext);
  if (!context) {
    throw new Error('useUser must be used within a SignUpUserContext');
  }
  return context;
};
