import React, { ReactElement } from 'react';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import Logo from 'ui-kit/icons/AgentNetLogo';
import { Content } from './Content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      backgroundColor: '#013A6F',
    },
    logoContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);
function HelpHeader(): ReactElement | null {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Container maxWidth="lg">
        <div data-testid="knowledge-base-logoBar" className={classes.logoContainer}>
          <Logo />
        </div>
        <Content />
      </Container>
    </div>
  );
}

export { HelpHeader };
