import { getApiHost } from 'api/host-by-env';
import { handleError } from 'utilities/utilities';
import { AdhocSdnSearchResult, FileSdnSearchResult, SdnParty } from './interfaces/sdn-search';

export async function searchSdnName(payload: string[], token: string): Promise<AdhocSdnSearchResult> {
  const url = `${getApiHost()}sdn/adhoc`;

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ names: payload }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch((error) => {
    throw Error(`Error occurred while searching names ${payload} in Sdn endpoint: ${error}`);
  });
  const json = await response.json();
  return json.result;
}

export async function updateFileSdn(
  fileId: string,
  fileNumber: string,
  officeId: number,
  payload: SdnParty[],
  userId: number,
  token: string,
): Promise<FileSdnSearchResult> {
  const url = `${getApiHost()}sdn/files`;

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      fileId: fileId,
      fileNumber: fileNumber,
      officeId: officeId,
      parties: payload,
      userId: userId,
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch((error) => {
    throw Error(`Error occurred while performing file sdn search. payload - ${payload}  error: ${error}`);
  });
  const json = await response.json();
  return json.result;
}
export async function getFileDocument(fileId: string, accountNumber: number, token: string): Promise<string> {
  const url = `${getApiHost()}sdn/document`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      fileId: fileId,
      accountNumber: accountNumber,
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result.Document.Content;
}
