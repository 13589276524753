import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { verifiedUserIconPath } from './constants';

function VerifiedUserIcon(props: SvgIconProps): React.ReactElement {
  const { htmlColor } = props;
  return (
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path fill={htmlColor ? htmlColor : `#3B873E`} d={verifiedUserIconPath}></path>
    </SvgIcon>
  );
}
export default VerifiedUserIcon;
