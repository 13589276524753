import React from 'react';
import { Switch } from 'react-router-dom';
import { routes } from './routes/Router';
import RouteWithSubRoutes from './routes/RouteWithSubRoutes';

const AppCore = (): JSX.Element => {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
};

export default AppCore;
