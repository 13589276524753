export enum AddressComponentType {
  ZipCode = 'postal_code',
  City = 'locality',
  AdministrativeAreaLevelThree = 'administrative_area_level_3',
  Neighborhood = 'neighborhood',
  State = 'administrative_area_level_1',
  County = 'administrative_area_level_2',
  Route = 'route',
  StreetNumber = 'street_number',
  Subpremise = 'subpremise',
  Unit = 'unit',
  Apartment = 'apartment',
  Premise = 'premise',
  Establishment = 'establishment',
}
