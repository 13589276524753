import React from 'react';
import { ICreateFileResponse } from 'api/file/interfaces/create-file';
import { IAgentNetBaseFormGroupProps, withBaseFormGroup } from 'utilities/form/components/base-form';
import FileCreateForm from './FileCreateForm';

const FileCreateFormWithBaseFormGroup = (props: IAgentNetBaseFormGroupProps<ICreateFileResponse>): JSX.Element => {
  const formWithBase = withBaseFormGroup<ICreateFileResponse, IAgentNetBaseFormGroupProps<ICreateFileResponse>>(
    FileCreateForm,
    {
      ...props,
    },
  );
  return <>{formWithBase}</>;
};
export default FileCreateFormWithBaseFormGroup;
