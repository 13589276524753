import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'is a required field.';

export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;

export const propertySchema = yup.object().shape({
  address1: yup.string().max(50, 'Maximum of 50 characters').required(requiredFieldMessage('Address 1')),
  address2: yup.string().max(50, 'Maximum of 50 characters'),
  apn: yup.string().max(200, 'Maximum of 200 characters').nullable(true),
  city: yup.string().min(2, 'Minimum of 2 characters').max(50).required(requiredFieldMessage('City')),
  state: yup.string().min(2).max(2).required('State is a required field.'),
  postalCode: yup
    .string()
    .matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.')
    .required('Postal code is a required field'),
  kyTaxCodeResult: yup.object().shape({
    taxCode: yup.string().max(10, 'Maximum of 10 characters'),
  }),
});

/* Party Group Validation */
export const buyerAndSellerSchema = yup.object().shape({
  firstName: yup.string().max(40, 'Maximum of 40 characters').required(requiredFieldMessage('First Name')),
  middleName: yup.string().max(40, 'Maximum of 40 characters'),
  lastName: yup.string().max(40, 'Maximum of 40 characters').required(requiredFieldMessage('Last Name')),
  suffix: yup.string().max(5, 'Maximum of 5 characters'),
  taxId: yup.string().max(9, 'Maximum of 9 characters'),
  emailAddress: yup
    .string()
    .trim()
    .email('Invalid Email, @ and . characters required.')
    .max(100, 'Maximum of 100 characters.')
    .nullable(),
  phoneNumber: yup.string().test('is-10-digits', 'Incomplete Phone Number.', (value) => {
    if (!value || value.trim() === '') {
      return true; // Skip validation if value is empty
    }
    return /^\d{3}-\d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
  }),

  ssn: yup
    .string()
    .max(11, 'Maximum of 9 characters')
    .matches(/^$|(^(\*{3}|\d{3})-(\*{2}|\d{2})-(\*{4}|\d{4}))$/, 'Incomplete SSN'),
});

export const lenderSchema = yup.object().shape({
  myLenders: yup
    .mixed()
    .test('mylenders', 'My Lenders is required', function () {
      const lenderTypeOptions = this.resolve(yup.ref('lenderTypeOptions'));
      const lenderAddressBookId = this.resolve(yup.ref('lenderAddressBookId'));

      if (lenderTypeOptions !== undefined && lenderTypeOptions !== null) {
        // lenderTypeOptions exists, so it must be a non-empty string
        return (
          lenderTypeOptions !== undefined &&
          lenderTypeOptions !== null &&
          ((typeof lenderTypeOptions === 'string' && lenderTypeOptions.trim() !== '') ||
            (typeof lenderTypeOptions === 'number' && !isNaN(lenderTypeOptions)))
        );
      } else if (lenderAddressBookId !== undefined && lenderAddressBookId !== null) {
        // lenderAddressBookId exists, so it must be a non-null number
        return (
          lenderAddressBookId !== undefined &&
          lenderAddressBookId !== null &&
          typeof lenderAddressBookId === 'number' &&
          !isNaN(lenderAddressBookId)
        );
      } else {
        // neither lenderTypeOptions nor lenderAddressBookId exist, so myLenders is allowed to be null or undefined
        return true;
      }
    })
    .nullable(),
  lenderTypeOptions: yup.string().nullable(),
  lenderAddressBookId: yup.number().nullable(),
  name: yup.string().max(255, 'Maximum of 255 characters').required(requiredFieldMessage('Company Name')),
  loanNumber: yup.string().max(50, 'Maximum of 50 characters'),
  loanAmount: yup.string().max(14, 'Maximum of 14 characters'),
  emailAddress: yup
    .string()
    .trim()
    .email('Invalid Email, @ and . characters required.')
    .max(100, 'Maximum of 100 characters.')
    .nullable(),
  phoneNumber: yup.string().test('is-10-digits', 'Incomplete Phone Number.', (value) => {
    if (!value || value.trim() === '') {
      return true; // Skip validation if value is empty
    }
    return /^\d{3}-\d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
  }),
  attention: yup.string().nullable().max(100, 'Maximum of 100 characters'),
  lenderTypeClause: yup.string().max(255, 'Maximum of 255 characters'),
});

export const lenderSchemaWithoutLenderTypeOptions = yup.object().shape({
  name: yup.string().max(255, 'Maximum of 255 characters').required(requiredFieldMessage('Company Name')),
  loanNumber: yup.string().max(50, 'Maximum of 50 characters'),
  loanAmount: yup.string().max(14, 'Maximum of 14 characters'),
  phoneNumber: yup.string().nullable(),
  emailAddress: yup.string().trim().email('Invalid email.').max(100, 'Maximum of 100 characters.').nullable(),
  attention: yup.string().nullable().max(100, 'Maximum of 100 characters'),
  lenderTypeClause: yup.string().max(255, 'Maximum of 255 characters'),
});

export const lenderSchemaForNameOnly = yup.object().shape({
  name: yup.string().trim().max(255, 'Maximum of 255 characters').required(requiredFieldMessage('Company Name')),
  emailAddress: yup
    .string()
    .trim()
    .email('Invalid Email, @ and . characters required.')
    .max(100, 'Maximum of 100 characters.')
    .nullable(),
  phoneNumber: yup.string().test('is-10-digits', 'Incomplete Phone Number.', (value) => {
    if (!value || value.trim() === '') {
      return true; // Skip validation if value is empty
    }
    return /^\d{3}-\d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
  }),
});

export const fileSchema = yup.object().shape({
  accountId: yup.number().required('Account Id is a required field'),
  closingDate: yup
    .date()
    .nullable()
    .min('0500-01-01')
    .max('2100-01-01')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(() => 'Invalid Date'),
  fileNumber: yup.string().max(40, 'Maximum of 40 characters').required(requiredFieldMessage('File number')),
  fileStatus: yup.string().max(40, 'Maximum of 40 characters').required(requiredFieldMessage('File Status')),
  firmId: yup.string().max(40, 'Maximum of 40 characters'),
  itemType: yup.string().max(40, 'Maximum of 40 characters'),
  loanAmount: yup.number(),
  officeId: yup.string().max(40, 'Maximum of 40 characters'),
  properties: yup.array().of(propertySchema).compact(),
  propertyState: yup.string().max(2, 'Maximum of 40 characters'),
  salesPrice: yup.number(),
  office: yup.string().required(requiredFieldMessage('Office')),
  transactionType: yup.string().max(40, 'Maximum of 40 characters').required(requiredFieldMessage('Transaction type')),
});

export const createPropertySchema = yup.object().shape({
  propertyType: yup.string().required('NAIC is a required field.'),
  apn: yup.string().max(200, 'Maximum of 200 characters').nullable(true),
  kyTaxCodeResult: yup.object().shape({
    taxCode: yup.string().max(10, 'Maximum of 10 characters'),
  }),
  //   result.properties[0].kyTaxCodeResult.taxCode
});

export const nameSchema = yup.object().shape({
  name: yup.string().max(130, 'Maximum of 130 characters').required(requiredFieldMessage('Name')),
});

export const partyTypesSchema = yup.object().shape({
  name: yup.string().max(130, 'Maximum of 130 characters').required(requiredFieldMessage('Name')),
  taxId: yup
    .string()

    .matches(/^$|(^(\*{2}|\d{2})-(\*{7}|\d{7}))$/, 'Valid Tax ID Numbers Required'),
});
export const buyerSellerSchema = yup.object().shape({
  partyType: yup.string().required(requiredFieldMessage('Party Type')),
});

export const partiesMethodSchema = yup.object().shape({
  partyMethod: yup.string().required(requiredFieldMessage('Party')),
});

export const buyerSellerDometicAddressSchema = yup.object().shape({
  address1: yup.string().max(55, 'Maximum of 55 characters'),
  address2: yup.string().optional().max(55, 'Maximum of 55 characters'),
  city: yup.string().optional().max(50, 'Maximum of 50 characters'),
  postalCode: yup
    .string()
    .max(10, 'Maximum of 9 characters')
    .matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.'),
});

export const fileInfoCreateFormSchema = yup.object().shape({
  officeId: yup.mixed().test('is-required', 'Office is a required field.', (value: any) => {
    if (!isNaN(value)) {
      return value > 10;
    }
    return !(value === undefined || value === null || value === '');
  }),
  stateCode: yup.string().required('State is a required field.'),
  underwriterCode: yup.string().required('Underwriter is a required field.'),
  closingDate: yup
    .date()
    .nullable()
    .min('0500-01-01')
    .max('2100-01-01')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('Invalid Date'),
  fileNumber: yup.string().max(40, 'Maximum of 40 characters').required('File number is a required field.'),
  transactionType: yup.string().required('Transaction Type is a required field.'),
});

export const fileInfoEditFormSchema = yup.object().shape({
  officeId: yup.number().min(10, 'Office is a required field').required('Office is a required field.'),
  underwriterCode: yup.string().required('Underwriter is a required field.'),
  closingDate: yup
    .date()
    .nullable()
    .min('0500-01-01')
    .max('2100-01-01')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('Invalid Date'),
  fileNumber: yup.string().max(40, 'Maximum of 40 characters').required('File number is a required field.'),
  transactionType: yup.string().required('Transaction Type is a required field.'),
});

export const domesticAddressSchema = yup.object().shape({
  address1: yup.string().max(55, 'Maximum of 55 characters'),
  address2: yup.string().nullable().max(55, 'Maximum of 55 characters'),
  city: yup.string().optional().max(50, 'Maximum of 50 characters'),
  postalCode: yup.string().matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.'),
});
export const domesticAddressSchemaRequired = yup.object().shape({
  address1: yup.string().max(55, 'Maximum of 55 characters').required(requiredFieldMessage('Address 1')),
  address2: yup.string().optional().max(55, 'Maximum of 55 characters'),
  city: yup.string().max(50, 'Maximum of 50 characters').required(requiredFieldMessage('City')),
  postalCode: yup
    .string()
    .matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.')
    .required(requiredFieldMessage('Zip')),
});

export const lenderDomesticAddressSchema = yup.object().shape({
  address1: yup.string().max(55, 'Maximum of 55 characters').required(requiredFieldMessage('Address 1')),
  address2: yup.string().nullable().max(55, 'Maximum of 55 characters'),
  city: yup.string().max(50, 'Maximum of 50 characters').required(requiredFieldMessage('City')),
  stateOrProvince: yup.string().required(requiredFieldMessage('State')),
  postalCode: yup
    .string()
    .matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.')
    .required(requiredFieldMessage('Zip Code')),
});

export const propertyDomesticAddressSchema = yup.object().shape({
  address1: yup.string().max(55, 'Maximum of 55 characters').required(requiredFieldMessage('Address 1')),
  address2: yup.string().optional().max(55, 'Maximum of 55 characters'),
  city: yup.string().max(50, 'Maximum of 50 characters').required(requiredFieldMessage('City')),
  state: yup.string().required(requiredFieldMessage('State')),
  county: yup.string().required(requiredFieldMessage('County')),
  postalCode: yup
    .string()
    .matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.')
    .required(requiredFieldMessage('Zip Code')),
});

export const foreignAddressSchema = yup.object().shape({
  address1: yup.string().max(55, 'Maximum of 55 characters'),
  address2: yup.string().max(55, 'Maximum of 55 characters'),
  city: yup.string().max(50, 'Maximum of 50 characters'),
  country: yup
    .string()
    .max(50, 'Maximum of 50 characters')
    .matches(/^[a-zA-Z0-9 ]*$/, 'No special characters'),
  stateOrProvince: yup.string().max(50, 'Maximum of 50 characters'),
  postalCode: yup.string().max(25, 'Maximum of 25 characters'),
});

export const taxIdSchema = yup.object().shape({
  taxId: yup.object({
    value: yup
      .string()
      .matches(/^\d{0}$|^\d{2}-\d{7}$|^\d{0}$|^\d{3}-\d{2}-\d{4}|^\d{9}$/g, 'Valid Tax ID Numbers Required'),
  }),
});
