import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import NestedRouteLayout from '../../NestedRouteLayout';
import './FileContent.scss';
import BreadCrumbComponent from 'ui-kit/components/breadcrumb/BreadCrumb';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { ExpandMore } from '@material-ui/icons';
import { fileStatusFileInformation } from 'features/constants';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { useAuth } from '@agentnet/auth';
import useAsync from 'hooks/useAsync';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { saveFileReopen, saveFileStatus } from 'api/file/file-api';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import PDFIcon from 'ui-kit/icons/PDF';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import AgentNetConfirmFileReopenModal from 'ui-kit/components/modal/AgentNetConfirmFileReopenModal';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { generateFileSummaryReport } from 'api/pdf/pdf-api';

export const Files = ({ routes }: never): JSX.Element => {
  const location = useLocation();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const {
    fileData,
    loadFileData,
    openPendingCpl,
    setOpenPendingCpl,
    setResetCpl,
    fileDataUpdated,
    setfileDataUpdated,
    hasRemittableCpl,
    setRemmittableCpl,
  } = fileDataCtx;

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile } = profileCtx;
  // For some paths, we want to hide the tab nav
  const pathsWithoutFileNav = ['/files'];
  const isNotFilesList = !pathsWithoutFileNav.includes(location.pathname);
  const [currentFileStatus, setCurrentFileStatus] = useState(fileStatusFileInformation);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: '11.3rem',
        display: 'flex',
        flexDirection: 'column',
      },
      routeContainer: {
        marginTop: '4.4rem',
        width: '100%',
      },
      list: {
        fontSize: '16px',
      },
      toggleSnapshot: {
        marginRight: theme.spacing(1),
      },
      fileSnapshotPDF: {},
      reopenFile: {
        background: '#C77700',
        marginLeft: theme.spacing(1),
        '&:hover': {
          backgroundColor: '#AD6904 !important',
        },
      },
      loader: {
        minHeight: 'fit-content',
        marginLeft: theme.spacing(1),
      },
      alignRow: {
        display: 'flex',
        alignItems: 'center',
      },
      filesContainer: {
        marginTop: '49px',
        transition: '.3s all',
      },
      filesContainerSnapshotExpanded: {
        marginTop: '0 !important',
      },
    }),
  );
  const classes = useStyles();
  const { addSnackbarMessage } = useSnackBars();

  const hasActiveCpl = useMemo(() => {
    if (openPendingCpl === undefined || openPendingCpl === -1) {
      return fileData?.serviceStatus?.hasActiveCpl ?? false;
    }
    return openPendingCpl > 0;
  }, [openPendingCpl, fileData]);

  const remmitableCpl = useMemo(() => {
    if (hasRemittableCpl === undefined) {
      return fileData?.serviceStatus?.hasRemittableCpl ?? false;
    }
    return hasRemittableCpl;
  }, [hasRemittableCpl, fileData]);

  useEffect(() => {
    if (fileData?.serviceStatus) {
      const status = ['Opened in Error', 'Cancelled', 'Closed'];

      // Remove 'Closed' Option
      if (fileData.serviceStatus.hasActiveJacket || remmitableCpl || fileData.serviceStatus.hasStandAloneEndorsement) {
        status.pop();
      }

      // Remove 'Opened in Error' and 'Cancelled' Option
      if (fileData.serviceStatus.hasRemittedCpl || fileData.serviceStatus.hasRemittedJacket) {
        status.shift();
        status.shift();
      }

      setCurrentFileStatus(status.map((keys: any) => ({ name: keys, value: keys })));
    }
  }, [fileData?.serviceStatus, hasRemittableCpl]);

  const [snapshot, showSnapshot] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [tempStatus, setTempStatus] = useState<{ name?: string; id?: number }>({});
  const [statusConfirmationModal, setStatusConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fileStatus, setFileStatus] = useState<{ name?: string; id?: number }>({ name: 'Open', id: 0 });
  const [fileContentError, setFileContentError] = useState<string[]>([]);
  const [fileReopenModal, setFileReopenModal] = useState(false);
  const [fileReopenFailed, setFileReopenFailed] = useState(false);
  const [isFileReopenInProgress, setFileReopenInProgress] = useState(false);
  const [downloadingSummary, setDownloadingSummary] = useState(false);

  const { getAccessToken } = useAuth();

  const changeStatus = async () => {
    const token = await getAccessToken();
    const payload = {
      fileId: fileData?.fileId,
      firmId: fileData?.firmId,
      officeId: fileData?.officeId,
      accountId: fileData?.accountId,
      status: fileStatus.name,
      serviceStatus: {
        hasActiveCpl,
        hasActiveJacket: fileData?.serviceStatus?.hasActiveJacket ?? false,
      },
    };
    return saveFileStatus(payload, token);
  };
  const {
    execute: statusExecute,
    value: statusValue,
    status: statusError,
    errors: statusErrorMsg,
  } = useAsync(changeStatus, false);

  const { addGlobalMsg } = useGlobalMessages();

  const downloadReportPdf = async () => {
    setDownloadingSummary(true);
    addSnackbarMessage({
      message: `Generating File Summary Report...`,
      type: 'success',
    });

    const token = await getAccessToken();
    await generateFileSummaryReport(fileData?.fileId ?? '', fileData?.fileNumber ?? '', token, addGlobalMsg);

    setDownloadingSummary(false);
  };

  const showMessage = (errorMessage: string[]) => {
    errorMessage?.map((err) => {
      addGlobalMsg({
        message: err,
        type: 'error',
      });
    });
    setFileContentError([]);
  };

  useEffect(() => {
    if (fileContentError.length > 0) showMessage(fileContentError);
  }, [fileContentError]);

  useEffect(() => {
    if (Array.isArray(statusErrorMsg) && statusErrorMsg.length > 0 && statusError === 'error') {
      setFileContentError([...statusErrorMsg]);
    }
  }, [statusErrorMsg, statusError]);

  const handleFileStatus = (e: any, i: any) => {
    setTempStatus({ ...fileStatus });
    setFileStatus({ ...i });
    setAnchorEl(null);
    setStatusConfirmationModal(true);
  };

  useEffect(() => {
    if (statusValue) {
      addSnackbarMessage({
        message: `File saved successfully`,
        type: 'success',
      });
      setResetCpl && setResetCpl(true);
      setStatusLoader(false);
      setStatusConfirmationModal(false);
      setTempStatus({});
      setFileStatus({
        name: statusValue.status,
        id: currentFileStatus.findIndex((status) => status.name === statusValue.status),
      });
      setOpenPendingCpl && setOpenPendingCpl(0);
      setRemmittableCpl && setRemmittableCpl(false);
      loadFileData && loadFileData({ fileId: fileData?.fileId });
    }
  }, [statusValue]);

  const resetLoader = () => {
    setStatusLoader(false);
    setStatusConfirmationModal(false);
    setFileStatus({ ...tempStatus });
    setTempStatus({});
  };

  useEffect(() => {
    if (statusError === 'error') {
      setStatusLoader(false);
      setFileStatus({ ...tempStatus });
      setTempStatus({});
      setStatusConfirmationModal(false);
    }
  }, [statusError]);

  useEffect(() => {
    if (fileDataUpdated || fileData) {
      setfileDataUpdated && setfileDataUpdated(false);
      if (fileData?.fileStatus) {
        setFileStatus({
          name: fileData?.fileStatus,
          id: currentFileStatus.findIndex((status) => status.name === fileData?.fileStatus),
        });
      }
      if (fileData?.fileStatusOptions) {
        setCurrentFileStatus(fileData.fileStatusOptions.map((status: any) => ({ name: status, value: status })));
      } else {
        setCurrentFileStatus([]);
      }
    }
  }, [fileData, fileDataUpdated]);

  //Activity right Remit - 33
  const canReopenFile = useMemo(() => {
    return (
      profile?.activityRights?.some((right) => right.ActivityRightId === 33) &&
      !fileReopenFailed &&
      fileData?.serviceStatus?.isReopenEligible
    );
  }, [fileData, profile, fileReopenFailed]);

  function onFileReopen() {
    if (isFileReopenInProgress) return;

    setFileReopenModal(true);
  }

  async function onConfirmFileReopen(fileReopenObj: any) {
    setFileReopenInProgress(true);
    const payload = {
      fileId: fileData?.fileId,
      reason: fileReopenObj.reason,
    };

    const token = await getAccessToken();
    const response = await saveFileReopen(payload, token);
    setFileReopenInProgress(false);
    setFileReopenModal(false);
    if (response?.result) {
      window.location.reload();
      addSnackbarMessage({
        message: `File reopened successfully`,
        type: 'success',
      });
    } else {
      setFileContentError(response?.messages);
      setFileReopenFailed(true);
    }
  }

  const warningMessage: React.ReactNode = (
    <>
      {!hasActiveCpl && fileData?.serviceStatus?.hasActiveJacket === false ? (
        <Typography>
          If you change the file status, then you will not be able to make future changes to the file.
        </Typography>
      ) : (
        <>
          <Typography>If you change the File Status, it will:</Typography>
          <ul>
            {hasActiveCpl && (
              <>
                <li className={classes.list}>void open CPLs</li>
                <li className={classes.list}>delete pending CPLs</li>
              </>
            )}
            {fileData?.serviceStatus?.hasActiveJacket === true && <li className={classes.list}>void open Jackets</li>}
          </ul>
        </>
      )}
      <Typography
        style={!hasActiveCpl && fileData?.serviceStatus?.hasActiveJacket === false ? { marginTop: '8px' } : {}}
      >
        Press Continue to make the changes or Cancel to discard them.
      </Typography>
    </>
  );

  return (
    <ContentContainer fullWidth={!isNotFilesList}>
      {isNotFilesList && (
        <>
          <BreadCrumbComponent disableCrumbs>
            <div className={classes.alignRow}>
              <Typography variant="h3" color="textPrimary">
                File No. {fileData?.fileNumber}
              </Typography>
            </div>
            <div>
              {canReopenFile && (
                <AgentNetButton
                  color="primary"
                  variant="contained"
                  Data-QA="ReopenFile"
                  onClick={() => onFileReopen()}
                  className={classes.reopenFile}
                >
                  Reopen File <HistoryOutlinedIcon fontSize="small" />
                </AgentNetButton>
              )}
              <AgentNetButton
                Data-QA="FileSummaryStatus"
                variant="text"
                color="primary"
                onClick={(e) => {
                  if (fileStatus.name === 'Open' && currentFileStatus.length > 0) setAnchorEl(e.currentTarget);
                }}
              >
                Status: {fileStatus.name}
                {fileStatus.name === 'Open' && currentFileStatus.length > 0 && <ExpandMore fontSize="small" />}
              </AgentNetButton>
              <Menu
                id={`status-menu`}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl) && fileStatus.name === 'Open'}
                onClose={() => setAnchorEl(null)}
                keepMounted
                getContentAnchorEl={null}
              >
                {currentFileStatus.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={(e) => handleFileStatus(e, { name: item.name, id: index })}
                    selected={item.name === fileStatus.name}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Menu>
              <AgentNetButton
                color="primary"
                variant="outlined"
                Data-QA="FileSummaryPDF"
                onClick={() => downloadReportPdf()}
                className={classes.fileSnapshotPDF}
                disabled={downloadingSummary}
              >
                File Summary
                {downloadingSummary ? (
                  <LoadingSpinner className={classes.loader} variant="circle" status={'pending'} showLabel={false} />
                ) : (
                  <PDFIcon htmlColor="#0074CA" fontSize="small" />
                )}
              </AgentNetButton>
            </div>
          </BreadCrumbComponent>

          <AgentNetConfirmFileReopenModal
            open={fileReopenModal}
            onClose={() => setFileReopenModal(false)}
            onConfirm={onConfirmFileReopen}
            isActionInProgress={isFileReopenInProgress}
          />
        </>
      )}
      <NestedRouteLayout
        {...{ routes }}
        className={clsx({
          [classes.routeContainer]: isNotFilesList,
        })}
      />

      <AgentNetConfirmationDialog
        onConfirm={() => {
          setStatusLoader(true);
          statusExecute().then();
        }}
        open={statusConfirmationModal || false}
        disabled={statusLoader}
        disableCrossIconClick={statusLoader}
        onDismissAction={resetLoader}
        onExitButtonAction={resetLoader}
        dialogTitle="Warning!"
        dialogBtnContent="Continue"
        secondaryActionBtnContent="Cancel"
        dialogTextHTML={warningMessage}
        disableBackDropClick
        qaAttrPrefix="Confirmation"
      />
    </ContentContainer>
  );
};
