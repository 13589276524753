import { useState } from 'react';
import useInterval from './useInterval';
import { getApiHost } from 'api/host-by-env';
import { IGetBuyerSellerResponse } from 'features/files/parties/interfaces/CreateBuyerSellerRequest';
import { IGetLenderResponse } from 'features/files/parties/interfaces/CreateLenderRequest';
import { fileInfoType } from 'api/file/interfaces/get-file';

type pollingError = {
  error: string;
} | null;

type pollingResult = {
  buyers: IGetBuyerSellerResponse[] | undefined;
  sellers: IGetBuyerSellerResponse[] | undefined;
  lenders: IGetLenderResponse[] | undefined;
  searchDate: any;
} | null;

function validate(result: any, fileData: fileInfoType | null | undefined): boolean {
  if (!result) {
    return false;
  }
  const partyNames: string[] = [];
  const sdnPartyNames: string[] = [];

  const buyers = fileData?.buyers;
  const sellers = fileData?.sellers;
  const lenders = fileData?.lenders;

  if (buyers) {
    for (let index = 0; index < buyers.length; index++) {
      partyNames.push(...getBuyerSellerPartyName(buyers[index]));
    }
  }
  if (sellers) {
    for (let index = 0; index < sellers.length; index++) {
      partyNames.push(...getBuyerSellerPartyName(sellers[index]));
    }
  }
  if (lenders) {
    for (let index = 0; index < lenders.length; index++) {
      partyNames.push(lenders[index].name);
    }
  }
  for (let i = 0; i < result.SearchResult.length; i++) {
    sdnPartyNames.push(result.SearchResult[i].PartyName);
  }

  let validationResult = true;
  for (let i = 0; i < partyNames.length; i++) {
    if (sdnPartyNames.indexOf(partyNames[i]) === -1) {
      validationResult = false;
    }
  }
  return validationResult;
}

function getBuyerSellerPartyName(element: any) {
  let partyName = '';
  let spouse1Name = '';
  let spouse2Name = '';
  const result: string[] = [];
  switch (element.partyType) {
    case 'Individual':
      partyName = [
        element.individual.firstName,
        element.individual.middleName,
        element.individual.lastName,
        element.individual.suffix,
      ]
        .filter((val) => val)
        .join(' ');
      result.push(partyName);
      break;

    case 'Trust/Estate':
      partyName = element.trustEstate.name;
      result.push(partyName);

      break;

    case 'Entity':
      partyName = element.entity.name;
      result.push(partyName);

      break;

    case 'A Married Couple':
      spouse1Name = [
        element.couple.spouse1?.firstName,
        element.couple.spouse1?.middleName,
        element.couple.spouse1?.lastName,
        element.couple.spouse1?.suffix,
      ]
        .filter((val) => val)
        .join(' ');
      result.push(spouse1Name);

      spouse2Name = [
        element.couple.spouse2?.firstName,
        element.couple.spouse2?.middleName,
        element.couple.spouse2?.lastName,
        element.couple.spouse2?.suffix,
      ]
        .filter((val) => val)
        .join(' ');
      result.push(spouse2Name);

      break;
  }

  return result;
}

function mapSdnStatus(parties: IGetBuyerSellerResponse[], sdnElement: any) {
  for (let i = 0; i < parties?.length; i++) {
    if (getBuyerSellerPartyName(parties[i]).indexOf(sdnElement.PartyName) > -1) {
      parties[i].isSdnMatched =
        parties[i].partyType === 'A Married Couple' && parties[i].isSdnMatched
          ? parties[i].isSdnMatched
          : sdnElement.Status === 'MATCHED';
    }
  }
}

function mapLenderSdnStatus(parties: IGetLenderResponse[], sdnElement: any) {
  for (let i = 0; i < parties?.length; i++) {
    if (sdnElement.PartyName === parties[i].name) {
      parties[i].isSdnMatched = sdnElement.Status === 'MATCHED';
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const sdnPolling = (token: string, fileData: fileInfoType | null | undefined, pollingInterval: number | null) => {
  const [delay, setDelay] = useState(pollingInterval);
  const [pollingError, setPollingError] = useState<pollingError>(null);
  const [pollingResult, setPollingResult] = useState<pollingResult>(null);
  const [startPolling, setStartPolling] = useState<boolean>(false);
  const [hasPollingResult, setHasPollingResult] = useState<boolean>(false);

  const [retryCount, setRetryCount] = useState<number>(0);
  const maxRetryCount = 5;

  useInterval(async () => {
    try {
      if (startPolling) {
        setHasPollingResult(false);
        setRetryCount(retryCount + 1);
        const url = `${getApiHost()}sdn/files/search-result`;
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            fileId: fileData?.fileId,
            accountNumber: fileData?.accountId,
          }),
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        });
        const json = await response.json();
        if (validate(json.result, fileData)) {
          const buyers = fileData?.buyers;
          const sellers = fileData?.sellers;
          const lenders = fileData?.lenders;

          json.result.SearchResult.forEach((sdnElement: any) => {
            mapSdnStatus(buyers ?? [], sdnElement);
            mapSdnStatus(sellers ?? [], sdnElement);
            mapLenderSdnStatus(lenders ?? [], sdnElement);
          });

          setPollingResult({
            buyers: buyers,
            sellers: sellers,
            lenders: lenders,
            searchDate: json.result.SearchDate,
          });
          setHasPollingResult(true);
          setDelay(null);
          setRetryCount(0);
          setStartPolling(false);
          token = '';
        }
        if (retryCount >= maxRetryCount) {
          setRetryCount(0);
          setDelay(null);
          setStartPolling(false);
          token = '';
        }
      }
    } catch (error) {
      setHasPollingResult(false);
      setRetryCount(retryCount + 1);
      setPollingError({ error: 'Error occurred' });
      console.log(error);
      if (retryCount >= maxRetryCount) {
        setRetryCount(0);
        setDelay(null);
        setStartPolling(false);
        token = '';
      }
    }
  }, delay);

  return { setStartPolling, setDelay, pollingResult, pollingError, hasPollingResult };
};

export default sdnPolling;
