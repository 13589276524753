import React, { FocusEvent, useRef, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
  useForkRef,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export type ObscurableFieldProps = {
  /**
   * obscurePattern: substring or regexp to obscure defaults to /./g
   */
  obscurePattern?: string | RegExp;
  /**
   * obscureReplacer: replacement text string or function used to return replacement text see
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_function_as_a_parameter
   **/
  obscureReplacer?: string | ((substring: string, ...args: Array<string | number>) => string);
} & TextFieldProps;

/**
 * Component that handles obscuring the input
 */
function ObscurableInput({
  isVisible,
  pattern = /./g,
  replacer = '•',
  inputRef,
  onChange,
  inputComponent: InputComponent = 'input',
  ...props
}: InputBaseComponentProps) {
  // Fork ref so we can access the value
  const innerRef = useRef<HTMLInputElement | null>(null);
  const handleRef = useForkRef(innerRef, inputRef);
  return (
    <>
      <input
        style={{ ...(isVisible && { height: '0', width: '0', position: 'absolute' }) }}
        {...props}
        aria-label="obscurable-display"
        /* Focus InputComponent onFocus and onBlur so not to lose focus - needed for accessibility across different browsers*/
        onFocus={() => innerRef.current?.focus()}
        onBlur={() => innerRef.current?.focus()}
        value={(props.value || innerRef?.current?.value || '').replace(pattern, replacer)}
        readOnly
        tabIndex={-1}
      />
      <InputComponent
        {...props}
        aria-label="obscurable-input"
        style={{ ...(!isVisible && { height: '0', width: '0', position: 'absolute' }) }}
        onChange={onChange}
        ref={handleRef}
      />
    </>
  );
}

export function ObscurableField({
  obscurePattern = /./g,
  obscureReplacer = '•',
  onFocus = () => undefined,
  onBlur = () => undefined,
  InputProps = {},
  disabled,
  ...props
}: ObscurableFieldProps): JSX.Element {
  const [isVisible, setVisible] = useState(false);
  const toggleVisible = () => setVisible(!isVisible);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setVisible(true);
    onFocus(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setVisible(false);
    onBlur(event);
  };

  return (
    <TextField
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      InputProps={{
        ...InputProps,
        inputComponent: ObscurableInput,
        inputProps: {
          isVisible,
          pattern: obscurePattern,
          replacer: obscureReplacer,
          inputComponent: InputProps.inputComponent,
          ...InputProps.inputProps,
        },
        endAdornment: !disabled ? (
          <InputAdornment position="end" tabIndex={-1}>
            <IconButton size="small" tabIndex={-1} onClick={toggleVisible}>
              {!isVisible ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
}
export default ObscurableField;
