import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import backgroundImage from './update-account-bg.jpg';
import { CheckCircleOutline, ErrorRounded } from '@material-ui/icons';
import { useAuth } from '@agentnet/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 'calc(100vh - 200px) !important',
      backgroundImage: `url(${backgroundImage})`,
      overflow: 'auto',
      width: '100%', // Ensure the root covers 100% of the width
      paddingTop: '80px', // Adjust the top padding as needed
      paddingBottom: '80px',
    },
    container: {
      width: '30%',
      padding: theme.spacing(4),
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Center items horizontally
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    iconSuccess: {
      fontSize: '100px', // Adjust the size as needed
      color: 'green',
      paddingTop: 8,
    },
    iconError: {
      fontSize: '100px', // Adjust the size as needed
      color: 'red',
      paddingTop: 8,
    },
    text: {
      fontSize: '10pt', // New class for matching font size
    },
    header: {
      fontSize: '20pt', // New class for matching font size
      textAlign: 'center',
    },
  }),
);

const UserStatus = (): JSX.Element => {
  const classes = useStyles();
  const { logout } = useAuth();
  const isSignedInSuccess = () => {
    return location?.pathname.toLowerCase().includes('/authorization/userstatus/500');
  };
  const isUnauthorized = () => {
    return location?.pathname.toLowerCase().includes('/authorization/userstatus/401');
  };

  const redirect = async () => {
    await logout();
  };

  return (
    <div className={classes.root}>
      {isSignedInSuccess() ? (
        <Grid container className={classes.container}>
          <CheckCircleOutline className={classes.iconSuccess} />
          <Grid item xs={12}>
            <h1 className={classes.header}>Success</h1>
            <p className={classes.text}>
              You have successfully created your AgentNet User ID and Password. When you click on Finish you will be
              returned to the Sign In screen. From now on please use your new credential&apos;s to access AgentNet.
            </p>
          </Grid>
          <Grid item xs={12} className={classes.button}>
            <Button variant="contained" color="primary" onClick={redirect}>
              Finish
            </Button>
          </Grid>
        </Grid>
      ) : isUnauthorized() ? (
        <Grid container className={classes.container}>
          <ErrorRounded className={classes.iconError} />
          <Grid item xs={12}>
            <h1 className={classes.header}>Unauthorized Access</h1>
            <p className={classes.text} style={{ textAlign: 'center' }}>
              You are not authorized to access this page.
            </p>
          </Grid>
          <Grid item xs={12} className={classes.button}>
            <Button variant="contained" color="primary" onClick={redirect}>
              Ok
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.container}>
          <ErrorRounded className={classes.iconError} />
          <Grid item xs={12}>
            <h1 className={classes.header}>Already Signed In</h1>
            <p className={classes.text} style={{ textAlign: 'center' }}>
              You have already signed in to AgentNet. Please sign out or use a different browser.
            </p>
          </Grid>
          <Grid item xs={12} className={classes.button}>
            <Button variant="contained" color="primary" onClick={redirect}>
              Ok
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default UserStatus;
