import moment from 'moment';

export const filterParams = {
  comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
    const dateParts = moment(cellValue, 'MM/DD/YYYY').format('MM/DD/YYYY').split('/');
    const cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
      return -1;
    }
    if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
      return 1;
    }
    return 0;
  },
  browserDatePicker: true,
  dateFormat: 'MM/DD/YYYY',
  inRangeInclusive: true,
};
