//  Validation schema for My Reports
import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'Required';
export const INVALID_FIELD_MESSAGE_TEXT = 'Invalid';

export const requiredFieldMessage = (fieldName: any): string => {
  return `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
};

export const MyReportSchema = yup.object().shape({
  startDate: yup
    .string()
    .required('From Date is Required')
    .test('is-valid-date', 'Invalid From Date', function (value) {
      if (!value) {
        return true; // Allow blank values
      }
      return yup.date().min(new Date('0500-01-01')).max(new Date('2100-01-01')).isValidSync(value);
    }),

  endDate: yup
    .string()
    .required('To Date is Required')
    .test('is-valid-date', 'Invalid To Date', function (value) {
      if (!value) {
        return true; // Allow blank values
      }
      return yup.date().min(new Date('0500-01-01')).max(new Date('2100-01-01')).isValidSync(value);
    }),

  serviceOrderLogStatus: yup.array().of(yup.string()).min(1, 'At least one Order Status is required'),
  letterStatus: yup.array().of(yup.string()).min(1, 'At least one Letter Status is required'),
});
