import React from 'react';
import { createStyles, makeStyles, Typography, TypographyProps, Theme } from '@material-ui/core';

export type L2NavTitleProps = TypographyProps & {
  label: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      marginRight: theme.spacing(-1),
      whiteSpace: 'nowrap',
      alignSelf: 'center',
      textTransform: 'uppercase',
    },
  }),
);

export const L2NavTitle = ({ label, ...props }: L2NavTitleProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Typography variant="h6" {...props} className={classes.padding}>
      {label}
    </Typography>
  );
};

export default L2NavTitle;
