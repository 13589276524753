export class PolicyUploadMapper {
  public static getPolicyUploadHistory(data: any[]) {
    return data.map((policyUpload: any) => {
      return {
        fileId: policyUpload.FileId,
        fileName: policyUpload.FileName,
        uploadedDateTime: new Date(policyUpload.UploadedDateTime).toLocaleString().concat(' PST').replaceAll(',', ''),
        status: policyUpload.Status,
        inRPS: policyUpload.InRPS,
        isError: policyUpload.IsError,
      };
    });
  }
}
