import React from 'react';

function NoPartiesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="122" height="112" fill="none">
      <path
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M65.391 105.826c28 0 50.7-22.7 50.7-50.8 0-28.1-22.8-50.8-50.7-50.8-28 0-50.7 22.7-50.7 50.8 0 28.1 22.7 50.8 50.7 50.8z"
      ></path>
      <path
        fill="#EAEEF9"
        d="M112.791 21.726a4.1 4.1 0 100-8.2 4.1 4.1 0 000 8.2zm6-16a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6zM17.39 21.626a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6zM5.61 82.154a5.2 5.2 0 100-10.4 5.2 5.2 0 000 10.4z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M105.854 88.172H24.928a6.749 6.749 0 01-6.743-6.743V29.57a6.749 6.749 0 016.743-6.743h80.926a6.748 6.748 0 016.743 6.743v52.09c-.232 3.488-3.255 6.511-6.743 6.511z"
      ></path>
      <path
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M32.249 25.217h15.347v5.153H32.249zm50.41 0h14.995v5.153H82.659z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M44.927 13.526h-10V27.59h10V13.526zm50.235 0h-10V27.59h10V13.526z"
      ></path>
      <path
        stroke="#9FA8BA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        d="M47.486 58.407a7.674 7.674 0 100-15.348 7.674 7.674 0 000 15.348zM35.16 70.967c0-6.743 5.581-12.324 12.325-12.324 6.744 0 12.325 5.58 12.325 12.324m12.79-21.63h24.882M72.6 61.895h9.767"
      ></path>
      <path
        fill="#D6DCE8"
        stroke="#AAB2C5"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M80.821 97.974c-.322 1.37-.806 2.822-1.37 4.031-1.532 2.983-3.951 5.322-6.934 6.854-3.064 1.532-6.692 2.177-10.32 1.37-8.547-1.774-14.03-10.159-12.256-18.706 1.773-8.546 10.078-14.11 18.625-12.255 3.064.645 5.724 2.177 7.982 4.273 3.79 3.79 5.402 9.273 4.273 14.433z"
      ></path>
      <path
        fill="#fff"
        stroke="#AAB2C5"
        strokeWidth="2"
        d="M69.913 92.517h-2.26v-2.26c0-1.166-.916-2.232-2.231-2.232-1.166 0-2.232.917-2.232 2.231v2.26h-2.26c-1.166 0-2.232.917-2.232 2.232 0 .602.215 1.173.637 1.595.423.422.993.637 1.595.637h2.26v2.26c0 1.166.917 2.232 2.232 2.232 1.165 0 2.231-.917 2.231-2.232v-2.26h2.26c1.166 0 2.232-.917 2.232-2.232 0-1.315-1.066-2.231-2.231-2.231z"
      ></path>
    </svg>
  );
}

export default NoPartiesIcon;
