import React from 'react';
import ReactDOM from 'react-dom';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import './index.css';
import App from './App';

LicenseManager.setLicenseKey(
  'CompanyName=Insight (Orem, UT)_on_behalf_of_The First American Corp,LicensedApplication=Agentnet,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=0,AssetReference=AG-033279,SupportServicesEnd=12_December_2023_[v2]_MTcwMjMzOTIwMDAwMA==2252d7aeae75b61fe43fe4928c36e161',
);
// ReactDOM.render(
//   // <React.StrictMode>
//   <>
//     <App />
//     </>
//   // </React.StrictMode>,
//   document.getElementById('root'),
// );
ReactDOM.render(
  <App />,

  document.getElementById('root'),
);

// If you want to start measuring performance in your agentnet, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
