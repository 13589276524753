import { getAgencyApiHost, getApiHost } from '../host-by-env';
import { Statements } from './interfaces';
import { handleBusinessError } from 'utilities/utilities';

export const getStatements = async (token: string, firmId: any): Promise<any> => {
  const url = `${getApiHost()}remittance/statements/${firmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  const statmentsResponse: Statements = json.result;
  return statmentsResponse;
};

export async function getStatementDocument(
  documentId: any,
  token: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getAgencyApiHost()}remittance-service/v1/statements/document?documentId=${documentId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleBusinessError(json);
  return json.Result;
}
