import React, { FunctionComponent } from 'react';
import ReactInputMask from 'react-input-mask';
import { InputBaseComponentProps, TextFieldProps } from '..';
import ObscurableField from '../ObscurableField';

export type EinFieldProps = TextFieldProps;

export function EinField({ ...props }: EinFieldProps): JSX.Element {
  return (
    <ObscurableField
      label="Employer ID Number"
      // commenting out until we can get this put in the right place when we implement saving
      // data-testid="tax-id-field"
      {...props}
      obscurePattern={/\d/g}
      obscureReplacer={(match, offset) => {
        if (offset < 6) {
          return '•';
        }
        return match;
      }}
      InputProps={{
        inputComponent: ReactInputMask as unknown as FunctionComponent<InputBaseComponentProps>,
        inputProps: {
          inputComponent: ReactInputMask,
          mask: '99-9999999',
        },
      }}
    />
  );
}
export default EinField;
