import React, { FunctionComponent } from 'react';
import MaskedField from 'react-masked-field';
import { InputBaseComponentProps, TextFieldProps } from '@material-ui/core';
import ObscurableField from '../ObscurableField';
import { FieldValidationError } from '../../../utilities/validation/validation';
// import { taxIdSchema } from '../../../utilities/validation/schemas';
import ErrorSection from 'features/files/file-create/ErrorSection';

export type EinFieldProps = TextFieldProps & {
  showAllValidation?: boolean;
  validationErrors?: FieldValidationError[];
  resetVisibility?: boolean;
};

export function EinField({
  showAllValidation,
  validationErrors,
  resetVisibility = false,
  ...props
}: EinFieldProps): JSX.Element {
  return (
    <>
      <ObscurableField
        label="Employer ID Number"
        // commenting out until we can get this put in the right place when we implement saving
        // data-testid="tax-id-field"
        {...props}
        // onChange={(e) => {
        //   onChangeWithValidation(e);
        // }}
        resetVisibility={resetVisibility}
        obscurePattern={/\d/g}
        obscureReplacer={(match, offset) => {
          if (offset < 6) {
            return '•';
          }
          return match;
        }}
        InputProps={{
          inputComponent: MaskedField as unknown as FunctionComponent<InputBaseComponentProps>,
          inputProps: {
            inputComponent: MaskedField,
            mask: '99-9999999',
            placeholder: 'XX-XXXXXXX',
          },
        }}
      />
      {showAllValidation && <ErrorSection errs={validationErrors} field={props?.name ?? 'taxId'} />}
    </>
  );
}
export default EinField;
