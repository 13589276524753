import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import FileStatus from 'ui-kit/icons/FileStatus';

export const invoiceFilters = [
  { name: 'All Invoices', value: 'allInvoices' },
  { name: 'Invoice Contains Premium', value: 'premiumOnlyFiles' },
  { name: 'Invoice Does not Contain Premium', value: 'excludePremiumOnlyFiles' },
];

export const restrictReportingText =
  'You cannot pay for files across multiple underwriters at the same time. Please go back and ensure you have selected files from only one underwriter before clicking Report & Pay.\n\nTo avoid this in future, please filter your list by underwriter before selecting items for payment.';
export const paymentStatusCodes = ['All', 'ePay in Process', 'Manual Pay in Process', 'Open', 'Closed'];

export const noFilesText = {
  pay: 'Please ensure that you have reported your eligible files.',
  invoice: 'Please check the Pay tab for files.',
};

export const ErrorRenderer = (params: GridRenderCellParams): React.ReactElement => {
  const { value } = params;
  const statusDotClassName = 'dot',
    statusClassName = 'error-status ',
    valueStyle = 't-u';
  return FileStatus({
    value: value,
    statusDotClassName: statusDotClassName,
    statusClassName: statusClassName,
    valueStyle: valueStyle,
  });
};

export const filterParams = {
  comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
    const dateParts = moment(cellValue, 'YYYY-MM-DD').format('MM/DD/YYYY').split('/');
    const cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
      return -1;
    }
    if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
      return 1;
    }
    return 0;
  },
  browserDatePicker: true,
  dateFormat: 'yyyy-mm-dd',
  inRangeInclusive: true,
};

export const formatDate = (date: string) => {
  if (!date) return '';
  return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
};

export const numberValueFormatter = function (params: any, nonAgValue = false) {
  const value = nonAgValue ? params?.toFixed(2) : params?.value?.toFixed(2);
  return '$' + value?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
