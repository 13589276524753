import { fileInfoType } from '../api/file/interfaces/get-file';
import { LenderClauseTypeValue, PartyTypeValue, TransactionTypeValue } from '../api/file/constants';
import { PropertyTypeOptions } from '../features/constants';

export const DefaultNewFile: fileInfoType = {
  closingDate: null,
  fileStatus: 'Open',
  propertyState: '',
  fileNumber: '',
  accountId: 0,
  fileId: '',
  firmId: 0,
  underwriterCode: '',
  transactionType: TransactionTypeValue.saleMortgage,
  officeId: 0,
  loanAmount: 0,
  buyers: [
    {
      partyType: PartyTypeValue.individual,
    },
  ],
  lenders: [
    {
      addToAddressBook: true,
      name: '',
      loanNumber: '',
      loanAmount: 0,
      attention: '',
      lenderClauseType: LenderClauseTypeValue.standard,
      lenderClauseText: '',
      address: {
        isForeignAddress: false,
        address1: '',
        address2: '',
        city: '',
        state: '',
        stateOrProvince: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      faxNumber: '',
      emailAddress: '',
    },
  ],
  properties: [
    {
      isOutsideJurisdiction: false,
      county: '',
      propertyId: '',
      propertyType: PropertyTypeOptions[0].value,
      state: '',
      city: '',
      postalCode: '',
      address1: '',
      address2: '',
      apn: '',
      premiumTaxCode: '',
    },
  ],
  sellers: [
    {
      trustEstate: {
        name: '',
      },
      partyType: PartyTypeValue.trust,
      partyId: '',
    },
  ],
  office: {
    name: '',
    dba: '',
    city: 'Atlanta',
    state: 'GA',
    zip: '30328',
  },

  underwriterName: 'First American Title Insurance Company of Louisiana',
};
