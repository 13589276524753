import React from 'react';

function NoBacktitle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="125" height="108" fill="none">
      <path
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M63.442 104.089c26.7 0 48.4-21.7 48.4-48.4s-21.7-48.4-48.4-48.4c-26.7.1-48.4 21.7-48.4 48.5 0 26.7 21.7 48.3 48.4 48.3z"
      ></path>
      <path
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M95.242 47.889v35.9c0 2.2-1.8 4-4 4h-56.9c-2.2 0-4-1.8-4-4v-35.9c0-2.2 1.9-4 4-4h56.9c1.7 0 3.1 1 3.8 2.5.1.4.2.9.2 1.5z"
      ></path>
      <path
        fill="#F1F3F9"
        d="M123.342 43.688c1.6-1.5 1.7-4 .3-5.6-1.5-1.6-4-1.7-5.6-.3-1.6 1.5-1.7 4-.3 5.6 1.5 1.7 4 1.8 5.6.3zm.3-17.499c.7-.6.7-1.7.1-2.3-.6-.7-1.7-.7-2.3-.1-.6.6-.7 1.7-.1 2.3.6.7 1.6.7 2.3.1zm-41.8-24.2c.5-.4.5-1.2.1-1.6-.4-.5-1.1-.5-1.6-.1s-.5 1.1-.1 1.6 1.1.5 1.6.1z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M115.842 24.889l-14.2 25.2c-.5.8-1.6 1.1-2.4.6l-24.2-14.1c-.8-.5-1.1-1.6-.6-2.4l18.7-32.1c.5-.8 1.6-1.1 2.4-.6l17.6 10.3 2.7 13.1z"
      ></path>
      <path
        fill="#D6DCE8"
        d="M112.942 11.688l-4 6.8c-.5 1-.2 2.2.7 2.8l6.1 3.5m-19.4-6.5c.4-.1.8-.1 1.1-.1h1.2c.4.1.9.2 1.3.3.5.2.9.4 1.3.6.6.3 1.1.7 1.6 1.2.4.4.7.9.9 1.6.2.5.2 1.1.2 1.7-.1.6-.3 1.2-.6 1.8-.3.5-.6.9-1 1.3-.4.3-.7.5-1.2.7-.4.2-.8.3-1.2.3l-1.1.1c-.3 0-.6.1-1 .2-.3.1-.5.2-.7.4l-1.1 1.5-1.9-1 .8-1.9c.1-.3.4-.6.6-.8.3-.2.6-.3.9-.4.4-.1.7-.1 1.1-.2.4 0 .8-.1 1.2-.2.4-.1.7-.2 1.1-.4.4-.2.6-.5.8-.9.1-.2.2-.5.3-.8 0-.3 0-.5-.1-.7-.1-.3-.2-.5-.4-.7-.2-.2-.4-.4-.7-.6-.3-.2-.7-.4-1.1-.5-.3-.1-.6-.1-.9-.1h-.7c-.1 0-.3 0-.4-.1-.2-.1-.4-.4-.4-.6l.1-1.7zm-5.5 14.1c.1-.2.3-.4.5-.6.2-.1.4-.3.6-.3.5-.1 1-.1 1.5.2.2.1.4.3.6.5l.3.6c.3 1-.3 2.1-1.3 2.4-.5.1-1 0-1.5-.2-.2-.1-.4-.3-.6-.5-.1-.2-.3-.4-.3-.6-.1-.2-.1-.5 0-.7 0-.4.1-.6.2-.8z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M24.842 13.989l16.3 16.9c.5.6.5 1.5-.1 2.1l-16.6 15.6c-.6.5-1.5.5-2.1-.1l-20.6-21.9c-.5-.6-.5-1.5.1-2.1l12-11.3 11 .8z"
      ></path>
      <path
        fill="#D6DCE8"
        d="M13.842 13.088l4.5 4.7c.6.6 1.7.6 2.4 0l4.1-3.9m-9.8 13.7c0-.3.1-.6.2-.9l.3-.9c.1-.3.3-.6.5-.9.2-.3.5-.6.8-.9.4-.4.8-.7 1.3-.9.4-.2.9-.3 1.3-.3s.9.1 1.3.2c.4.2.8.5 1.2.8.3.3.6.7.7 1.1.1.3.2.7.3 1.1v1c-.1.3-.1.6-.2.9 0 .3-.1.5-.1.8 0 .2 0 .4.2.6l.8 1.2-1.2 1.1-1.2-1.1c-.2-.2-.4-.4-.5-.7-.1-.3-.1-.5-.1-.8 0-.3.1-.6.1-.9.1-.3.1-.6.1-.9 0-.3 0-.6-.1-.9-.1-.3-.3-.6-.5-.9-.1-.2-.3-.3-.5-.4-.2-.1-.4-.1-.6-.1-.2 0-.4.1-.6.2-.2.1-.4.2-.6.4-.2.2-.4.5-.6.7-.1.2-.2.4-.3.7-.1.2-.1.4-.1.5 0 .1-.1.2-.2.3-.1.2-.4.2-.6.1l-1.1-.2zm9.8 7.5c-.1-.1-.2-.3-.3-.5-.1-.2-.1-.4-.1-.6 0-.4.2-.8.5-1.1.1-.1.3-.2.5-.3.2-.1.4-.1.6-.1.6 0 1.2.4 1.5 1 .1.2.1.4.1.6 0 .2-.1.4-.1.6-.1.2-.2.3-.4.5-.1.1-.3.3-.5.3-.4.1-.8.1-1.1 0-.4-.1-.6-.2-.7-.4z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M67.042 40.589l-6.3 22.5c-.2.7-1 1.2-1.8.9l-21.9-6.4c-.7-.2-1.2-1-.9-1.8l8.5-28.9c.2-.7 1-1.2 1.8-.9l15.9 4.6 4.7 10z"
      ></path>
      <path
        fill="#AAB3C5"
        d="M95.242 47.889v35.9c0 2.2-1.8 4-4 4h-56.9c-2.2 0-4-1.8-4-4v-27.7h23.3c2.3 0 4.6-.8 6.3-2.3l6.3-5.2c1.8-1.4 4-2.3 6.3-2.3h22.4c.2.5.3 1 .3 1.6z"
        opacity="0.1"
      ></path>
      <path
        fill="#D6DCE8"
        d="M62.342 30.689l-1.9 6.3c-.2.8.3 1.8 1.1 2.1l5.5 1.7m-16.6-1.7l.9-.3c.3-.1.6-.2 1-.2h1.1c.4 0 .8.1 1.2.2.5.1 1 .4 1.5.7.4.3.7.6 1 1 .2.4.4.8.5 1.2.1.5 0 1-.1 1.5-.1.4-.3.8-.6 1.2-.2.3-.5.6-.8.8-.3.2-.6.4-.9.5l-.9.5c-.2.1-.5.2-.7.3-.2.1-.4.3-.4.5l-.6 1.3-1.7-.5.3-1.7c0-.3.2-.5.4-.7.2-.2.4-.4.7-.5.3-.1.6-.2.8-.3.3-.1.6-.2.9-.4.3-.1.5-.3.8-.5.2-.2.4-.5.5-.9.1-.2.1-.4.1-.6 0-.2-.1-.4-.2-.6-.1-.2-.3-.3-.5-.5-.2-.1-.4-.2-.7-.3-.3-.1-.6-.1-1-.1-.2 0-.5 0-.7.1-.2 0-.4.1-.5.1-.1 0-.2.1-.4 0-.2 0-.4-.2-.4-.4l-.6-1.4zm-1.6 12.3c.1-.2.1-.4.3-.5.1-.2.3-.3.4-.4.4-.2.8-.2 1.1-.1.2.1.4.2.5.3l.4.4c.4.7.1 1.7-.6 2.1-.4.2-.8.2-1.1.1-.2-.1-.4-.1-.5-.3-.2-.1-.3-.3-.4-.4-.1-.2-.1-.3-.2-.5 0-.3 0-.5.1-.7z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M118.342 78.989l-20.6 11.2c-.7.4-1.6.1-1.9-.6l-10.4-20.1c-.3-.7-.1-1.6.6-1.9l26.8-14.2c.7-.4 1.6-.1 1.9.6l7.5 14.6-3.9 10.4z"
      ></path>
      <path
        fill="#D6DCE8"
        d="M122.242 68.689l-5.8 3c-.7.4-1.1 1.5-.7 2.3l2.6 5.1m-10.4-13.1c.3.1.6.3.9.4.3.1.6.3.9.6l.8.8c.3.3.5.7.7 1 .3.4.4 1 .5 1.5.1.5 0 1 0 1.5-.2.4-.3.9-.5 1.2-.3.4-.8.7-1.1.9-.4.2-.8.4-1.3.4-.4.1-.8.1-1.2 0-.4-.1-.7-.2-1-.3l-.9-.4c-.2-.1-.5-.2-.7-.3-.2-.1-.5-.1-.7.1l-1.4.5-.8-1.5 1.4-.9c.2-.2.5-.2.8-.2.3 0 .6 0 .9.2.3.1.6.3.8.4.3.1.6.3.9.4.3.1.6.2.9.2.3 0 .7-.1 1-.3.4-.2.7-.6.8-1 .1-.2 0-.4 0-.7-.1-.2-.1-.4-.3-.7-.1-.3-.4-.5-.6-.9-.1-.1-.4-.4-.6-.4-.1-.1-.4-.2-.4-.3-.1-.1-.2-.1-.3-.3-.1-.1-.1-.4 0-.6l.5-1.3zm-9.9 7.4c.2-.1.4-.2.6-.1.2-.1.4 0 .6 0 .4.2.7.4.9.7.1.2.1.4.1.6v.6c-.1.4-.5.7-.8.9-.2.1-.4.1-.6.1h-.6c-.4-.2-.7-.4-.9-.7v-.7c-.1-.2 0-.4 0-.6.1-.2.2-.4.2-.5.2-.1.4-.2.5-.3z"
      ></path>
      <path
        stroke="#1676EE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M96.342 66.888v17.7c0 2.3-1.9 4.1-4.1 4.1h-58.5c-2.3 0-4.1-1.9-4.1-4.1v-19.1"
      ></path>
      <path
        fill="#fff"
        d="M94.042 55.589h-20c-1.9 0-3.8.7-5.3 1.9l-5.3 4.3c-1.5 1.2-3.4 1.9-5.3 1.9h-22.8c-2.3 0-4.2 1.8-4.2 4.1 0 .2 0 .4.1.6l4.1 21.9c.3 2.1 2.1 3.6 4.2 3.6h48.6c2.1 0 3.8-1.5 4.2-3.5l5.8-30c.3-2.2-1.1-4.4-3.5-4.7-.2-.1-.4-.1-.6-.1z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M99.642 51.589h-24c-2.3 0-4.6.8-6.3 2.3l-6.3 5.1c-1.8 1.5-4 2.3-6.3 2.3h-27.3c-2.7 0-5 2.2-5 5 0 .2 0 .5.1.7l5 26.5c.4 2.5 2.5 4.4 5 4.4h58c2.5 0 4.6-1.8 5-4.2l6.9-36.3c.4-2.7-1.3-5.3-4.1-5.7-.2-.1-.5-.1-.7-.1z"
      ></path>
      <path
        fill="#AAB2C5"
        d="M73.542 78.989c1.4 0 2.6-1.2 2.6-2.6 0-1.4-1.2-2.6-2.6-2.6-1.4 0-2.6 1.2-2.6 2.6 0 1.5 1.1 2.6 2.6 2.6zm-4.6 4.399h-1.3c-1.1-2.4-4-3.3-6.3-2.1-.9.4-1.7 1.2-2.1 2.1h-1.3c1.1-3.1 4.7-4.7 7.7-3.5 1.4.6 2.7 1.8 3.3 3.5z"
      ></path>
      <path
        fill="#D6DCE8"
        stroke="#AAB2C5"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M106.342 106.489c-1.2 0-.8.1-9.8-8.9-8.8 6.5-20.8.2-20.8-10.4 0-7.2 5.8-13 13-13 10.4 0 16.7 11.6 10.7 20.4l8.4 8.4c1.2 1.3.3 3.5-1.5 3.5zm-17.6-28.2c-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9c.5 0 1.1 0 1.6-.1s1-.2 1.5-.4 1-.4 1.4-.7c.5-.3 1-.7 1.4-1.1.4-.3.7-.7 1-1.1.3-.4.6-.9.9-1.3.2-.5.5-1 .6-1.5.2-.5.3-1 .3-1.5.1-.4.1-.8.1-1.2.1-4.9-3.9-8.9-8.8-8.9z"
      ></path>
      <path
        fill="#AAB2C5"
        d="M53.542 78.989c1.4 0 2.6-1.2 2.6-2.6 0-1.4-1.2-2.6-2.6-2.6-1.4 0-2.6 1.2-2.6 2.6.1 1.5 1.2 2.6 2.6 2.6z"
      ></path>
    </svg>
  );
}

export default NoBacktitle;
