import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'required';

export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (maxLength?: number): string => `Max length is ${maxLength}`;

const max_characters = {
  message: 1000,
};

export const fileReopenSchema = (): any => {
  return yup.object().shape({
    reason: yup
      .string()
      .required('Failed to reopen: missing required fields')
      .max(max_characters.message, maxLengthFieldMessage(max_characters.message)),
  });
};
