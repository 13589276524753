import { getApiHost } from '../host-by-env';
import { SavePreferredOffices } from './interfaces';

export const getPrefferedOffices = async (token: string) => {
  const url = `${getApiHost()}user/preferred-offices`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  const prefferedOfficeResponse = json.result;
  return prefferedOfficeResponse;
};

export const setPrefferedOffices = async (token: string, request: SavePreferredOffices) => {
  const url = `${getApiHost()}user/preferred-offices`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(request),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  const saveResponse = json.result;
  return saveResponse;
};
