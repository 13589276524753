import { MaritalStatusValue, PartyTypeValue, TransactionTypeValue } from 'api/file/constants';
import { ICreateFileResponse } from 'api/file/interfaces/create-file';
import { IGetLenderResponse } from '../features/files/parties/interfaces/CreateLenderRequest';
import { PropertyTypeOptions } from '../features/constants';

export const DefaultCreateFile: ICreateFileResponse = {
  fileNumber: '',
  transactionType: TransactionTypeValue.default,
  closingDate: null,
  underwriterCode: '0',
  firmId: 0,
  officeId: 0,
  accountId: 0,
  propertyState: '',
  salesPrice: 0,
  loanAmount: 0,
  stateCode: '',
  properties: [
    {
      isOutsideJurisdiction: false,
      propertyType: PropertyTypeOptions[0].value, // Residential (1-4 family)
      address1: '',
      address2: '',
      state: '',
      city: '',
      postalCode: '',
      county: '',
      apn: '',
    },
  ],
  buyers: [
    {
      partyType: PartyTypeValue.default,
      entity: {
        name: '',
        taxId: '',
        phoneNumber: '',
        faxNumber: '',
        emailAddress: '',
      },
      individual: {
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        maritalStatus: MaritalStatusValue.married_couple,
        ssn: '',
        phoneNumber: '',
        faxNumber: '',
        emailAddress: '',
      },
      trustEstate: {
        name: '',
        taxId: '',
        phoneNumber: '',
        faxNumber: '',
        emailAddress: '',
      },
      couple: {
        maritalStatus: '',
        spouse1: {
          firstName: '',
          middleName: '',
          lastName: '',
          suffix: '',
          maritalStatus: MaritalStatusValue.husband_and_wife,
          ssn: '',
          phoneNumber: '',
          faxNumber: '',
          emailAddress: '',
        },
        spouse2: {
          firstName: '',
          middleName: '',
          lastName: '',
          suffix: '',
          maritalStatus: MaritalStatusValue.husband_and_husband,
          ssn: '',
          phoneNumber: '',
          faxNumber: '',
          emailAddress: '',
        },
      },
    },
  ],
  sellers: [
    {
      partyType: PartyTypeValue.default,
      entity: {
        name: 'string',
        taxId: 'string',
        phoneNumber: 'string',
        faxNumber: 'string',
        emailAddress: 'string',
      },
      individual: {
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        maritalStatus: MaritalStatusValue.unmarried_man,
        ssn: 'string',
        phoneNumber: 'string',
        faxNumber: 'string',
        emailAddress: 'string',
      },
      trustEstate: {
        name: '',
        taxId: '',
        phoneNumber: '',
        faxNumber: '',
        emailAddress: '',
      },
      couple: {
        maritalStatus: '',
        spouse1: {
          firstName: '',
          middleName: '',
          lastName: '',
          suffix: '',
          maritalStatus: MaritalStatusValue.registered_domestic_partners,
          ssn: '',
          phoneNumber: '',
          faxNumber: '',
          emailAddress: '',
        },
        spouse2: {
          firstName: '',
          middleName: '',
          lastName: '',
          suffix: '',
          maritalStatus: MaritalStatusValue.registered_domestic_partners,
          ssn: '',
          phoneNumber: '',
          faxNumber: '',
          emailAddress: '',
        },
      },
    },
  ],
  lenders: [
    {
      addToAddressBook: false,
      name: '',
      loanNumber: '',
      loanAmount: '0',
      attention: '',
      lenderClauseType: '',
      lenderClauseText: '',
      phoneNumber: '',
      faxNumber: '',
      emailAddress: '',
    },
  ],
};

export const DefaultLender: IGetLenderResponse = {
  addToAddressBook: false,
  loanNumber: '',
  loanAmount: 0,
  attention: '',
  lenderTypeOptions: '',
  firmId: '',
  lenderClauseType: '',
  lenderClauseText: '',
  name: '',
};
