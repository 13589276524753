import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'required';

export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (maxLength?: number): string => `Max length is ${maxLength}`;

const max_characters = {
  contactUsFullName: 50,
  contactUsFirm: 75,
  contactUsEmail: 100,
  contactUsMessage: 1000,
};

export const contactUsSchema = (isSignedIn?: boolean): any => {
  return isSignedIn
    ? yup.object().shape({
        contactUsReason: yup.string().required(requiredFieldMessage('What can we help you with')),
        contactUsMessage: yup
          .string()
          .required(requiredFieldMessage('Message'))
          .max(max_characters.contactUsMessage, maxLengthFieldMessage(max_characters.contactUsMessage)),
      })
    : yup.object().shape({
        contactUsFullName: yup
          .string()
          .required(requiredFieldMessage('Full Name'))
          .max(max_characters.contactUsFullName, maxLengthFieldMessage(max_characters.contactUsFullName)),
        contactUsFirm: yup
          .string()
          .required(requiredFieldMessage('Firm'))
          .max(max_characters.contactUsFirm, maxLengthFieldMessage(max_characters.contactUsFirm)),
        contactUsEmail: yup
          .string()
          .required(requiredFieldMessage('Email'))
          .email('Invalid Email, @ and . characters required.')
          .max(max_characters.contactUsEmail, maxLengthFieldMessage(max_characters.contactUsEmail)),
        contactUsPhone: yup.string().test('is-10-digits', 'Incomplete Phone Number.', (value) => {
          if (!value || value.trim() === '') {
            return true; // Skip validation if value is empty
          }
          return /^\d{3}-\d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
        }),
        contactUsReason: yup.string().required(requiredFieldMessage('What can we help you with')),
        contactUsMessage: yup
          .string()
          .required(requiredFieldMessage('Message'))
          .max(max_characters.contactUsMessage, maxLengthFieldMessage(max_characters.contactUsMessage)),
      });
};
