import React, { ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Container } from '@material-ui/core';
import Logo from 'ui-kit/icons/AgentNetLogo';
import { SearchBarRow } from './SearchBarRow';
import { useHistory } from 'react-router-dom';
import useBaseRoute from 'features/help/hooks/useBaseRoute';
import { ContentfulErrorContext } from 'features/help/hooks/ContentfulErrorContext';
import { HELP_ROUTE } from 'features/help/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
      position: 'sticky',
      top: '0',
      zIndex: 1000,
      backgroundColor: '#013A6F',
    },
    logoArea: {
      minWidth: theme.spacing(35),
      display: 'flex',
      alignItems: 'center',
    },
    flex: {
      display: 'flex',
    },
    logoPlaceholderArea: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '280px',
    },
  }),
);
function TopicPageHeader(): ReactElement | null {
  const baseRoute = useBaseRoute();
  const classes = useStyles();
  const history = useHistory();

  const { setContentfulErrorType } = React.useContext(ContentfulErrorContext);

  const handleClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      const path = `${HELP_ROUTE}`;
      history.push(path);
      setContentfulErrorType(null);
      e.preventDefault();
    },
    [history],
  );

  return (
    <div data-testid="knowledge-base-header" className={classes.header}>
      <Container maxWidth={'lg'} className={classes.container}>
        <div className={classes.logoArea}>
          <a onClick={handleClick}>
            <Logo />
          </a>
        </div>
        <SearchBarRow buttonSize="medium" />
        <div className={classes.logoPlaceholderArea}></div>
      </Container>
    </div>
  );
}

export { TopicPageHeader };
