import React from 'react';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import PDFIcon from 'ui-kit/icons/PDF';

const PdfCellRenderer = (props: any) => {
  if (
    props?.paymentId === props?.data?.paymentId &&
    props?.orderNumber === props?.data?.orderNumber &&
    props?.executeTransmittalSheetStatus === 'pending'
  )
    return <LoadingSpinner status="pending" variant="circle" showLabel={false} />;

  return props?.data?.paymentId ? (
    <span
      style={{ marginTop: '10px' }}
      onClick={() => props?.onClick(props?.data?.paymentId, props?.data?.orderNumber)}
    >
      {PDFIcon({ fontSize: 'small' })}
    </span>
  ) : null;
};

export default PdfCellRenderer;
