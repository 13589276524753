import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { FileDataContext, FileDataContextInterface } from '../../../../hooks/FileDataContext';
import AgentNetDropdownSelector from '../../../../ui-kit/inputs/AgentNetDropdownSelector';
import { PartyTypeValue } from '../../../../api/file/constants';
import { ICreateBuyerSellerRequest } from '../interfaces/CreateBuyerSellerRequest';
import { IGetLenderResponse } from '../interfaces/CreateLenderRequest';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { buyerSellerSchema } from 'utilities/validation/schemas';
import './AddPartyForm.scss';
import PartyTypeFormWithValidation from './PartyTypesFormWithBaseFormGroup';
import { EntityType, getFileStatus, getMaritalStatus } from '../../../constants';

interface AddPartyFormProperties<T extends ICreateBuyerSellerRequest> {
  value: T;
  onChange: (value: T) => void;
  handleClose?: () => void;
  showValidation: boolean;
  setIsValid: (errs: boolean) => void;
  hideErrorTitleNotification?: boolean;
  qaAttrPrefix?: string;
}
const AddPartyForm = <T extends ICreateBuyerSellerRequest & IGetLenderResponse>({
  value,
  onChange,
  setIsValid: setIsFormValid,
  showValidation,
  hideErrorTitleNotification,
  qaAttrPrefix = '',
}: AddPartyFormProperties<T>): JSX.Element => {
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;
  const { errors } = useForm();
  const currentPartyType = value.partyType;
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [isPartyTypeFormGroupValid, setIsPartyTypeFormGroupValid] = useState<boolean>(false);
  const [updateValidations, setUpdateValidations] = useState<boolean>(false);

  const [resetVisibility, setResetVisibility] = useState<boolean>(false);
  const defaultValue = useRef(value);
  /* Handle  Form Validation */
  const onChangeWithValidation = async (partyFields: T, validationUpdate?: boolean) => {
    const errs = (await doValidate(partyFields, buyerSellerSchema)) || [];
    setIsFormValid(errs.length === 0 && isPartyTypeFormGroupValid);
    setValidationErrors(errs);
    if (onChange) onChange(partyFields);
    if (validationUpdate) {
      setUpdateValidations(true);
    }
  };

  useEffect(() => {
    if (showValidation) onChangeWithValidation({ ...value });
  }, [showValidation]);

  useEffect(() => {
    onChangeWithValidation(value);
  }, [isPartyTypeFormGroupValid]);

  /* Handle Partytype Change */
  const handlePartyTypeChange = (val: string) => {
    const newPartyType = val as PartyTypeValue;
    let tempValue;
    if (defaultValue.current?.partyType === newPartyType) {
      tempValue = { ...defaultValue.current, partyType: newPartyType };
    } else {
      tempValue = { ...value, partyType: newPartyType };
      if (tempValue.address) {
        tempValue.address = {
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        };
      }
    }
    const { individual, couple, entity, trustEstate, ...rest } = tempValue;
    if (newPartyType === PartyTypeValue.married) {
      onChangeWithValidation({ ...rest, couple } as T, true);
    } else if (newPartyType === PartyTypeValue.individual) {
      onChangeWithValidation({ ...rest, individual } as T, true);
    } else if (newPartyType === PartyTypeValue.entity) {
      onChangeWithValidation({ ...rest, entity } as T, true);
    } else if (newPartyType === PartyTypeValue.trust) {
      onChangeWithValidation({ ...rest, trustEstate } as T, true);
    } else {
      onChangeWithValidation({ ...value } as T);
    }
    setResetVisibility(true);
  };
  const displayMaritalDropdown = () => {
    if (currentPartyType === PartyTypeValue.married || currentPartyType === PartyTypeValue.individual) {
      const partyKey = currentPartyType === PartyTypeValue.married ? 'couple' : 'individual';

      return (
        <Grid item xs={6}>
          <AgentNetDropdownSelector
            disabled={fileData?.fileStatus !== 'Open'}
            data-qa={`${qaAttrPrefix}MaritalStatus`}
            name="maritalStatus"
            label="Marital Status"
            defaultValue={value.partyType === PartyTypeValue.individual ? PartyTypeValue.toBeDetermined : ''}
            options={getMaritalStatus}
            error={errors.maritalStatus}
            value={value.individual?.maritalStatus ?? ('' || value?.couple?.maritalStatus) ?? ''}
            menuOption={(val) =>
              onChange({
                ...value,
                [partyKey]: { ...value[partyKey], maritalStatus: val },
              })
            }
            dropdowntype="outlined"
          />
        </Grid>
      );
    } else if (currentPartyType === PartyTypeValue.entity) {
      return (
        <Grid item xs={6}>
          <AgentNetDropdownSelector
            disabled={fileData?.fileStatus !== 'Open'}
            label="Entity Type"
            name="EntityType"
            Data-QA={`${qaAttrPrefix}EntityType`}
            defaultValue=""
            options={EntityType}
            value={value?.entity?.entityType ?? ''}
            menuOption={(val: string) => {
              onChange({ ...value, entity: { ...value.entity, entityType: val === '' ? undefined : val } });
            }}
            dropdowntype="outlined"
          />
        </Grid>
      );
    }
  };
  return (
    <>
      <Grid container spacing={3} className="add-party-form-container">
        <Grid item xs={6}>
          <AgentNetDropdownSelector
            disabled={fileData?.fileStatus !== 'Open'}
            name="partyType"
            id="partyType"
            label="Party Type"
            defaultValue="Individual"
            options={getFileStatus}
            errs={validationErrors}
            showValidation={showValidation}
            required
            value={value.partyType ?? 'Individual'}
            menuOption={handlePartyTypeChange}
            dropdowntype="outlined"
            data-qa={`${qaAttrPrefix}PartyType`}
          />
        </Grid>
        {value.partyType && (
          <>
            {displayMaritalDropdown()}
            <Grid item xs={12}>
              <PartyTypeFormWithValidation
                setIsFormValid={setIsPartyTypeFormGroupValid}
                showAllValidation={showValidation}
                value={value}
                resetVisibility={resetVisibility}
                setResetVisibility={setResetVisibility}
                onChange={(val: T) => onChangeWithValidation(val)}
                qaAttrPrefix={qaAttrPrefix}
                setUpdateValidations={setUpdateValidations}
                updateValidations={updateValidations}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default AddPartyForm;
