import { Overrides } from '@material-ui/core/styles/overrides';
import palette from '../palette';
import spacing from '../spacing';
import breakpoints from '../breakpoints';
import typography from '../typography';

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        marginBottom: spacing(4),
      },
    },
  },

  // APPBAR

  MuiAppBar: {
    colorSecondary: {
      backgroundColor: palette.appBar.colorSecondaryBackgroundColor,
    },
  },

  // BUTTONS

  MuiButton: {
    contained: {
      backgroundColor: palette.button.containedBackgroundColor,
      '&:hover': {
        backgroundColor: palette.button.containedHoverBackgroundColor,
      },
      '&$disabled': {
        color: palette.button.containedDisabledColor,
        backgroundColor: palette.button.containedDisabledBackgroundColor,
      },
    },
    containedPrimary: {
      '&:hover': {},
      '&$disabled': {
        color: palette.button.containedPrimaryDisabledColor,
        backgroundColor: palette.action.disabledBackground,
      },
    },
    containedSecondary: {
      backgroundColor: palette.secondary.main,
      '&:hover': {
        backgroundColor: palette.action.active,
      },
      '&$disabled': {
        color: palette.button.containedSecondaryDisabledColor,
        backgroundColor: palette.button.containedSecondaryDisabledBackgroundColor,
      },
    },
    outlined: {
      borderColor: palette.button.OutlinedBorderColor,
      color: palette.text.secondary,
      '&:hover': {
        backgroundColor: palette.button.OutlinedHoverBackgroundColor,
      },
      '&$disabled': {
        borderColor: palette.button.OutlinedDisabledBorderColor,
        color: palette.button.OutlinedDisabledColor,
      },
    },
    outlinedPrimary: {
      borderColor: palette.primary.main,
      color: palette.primary.main,
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
    },
    outlinedSecondary: {
      borderColor: palette.button.OutlinedSecondaryBorderColor,
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
      '&$disabled': {
        borderColor: palette.button.OutlinedSecondaryDisabledBorderColor,
        color: palette.button.OutlinedSecondaryDisabledColor,
      },
    },
  },
  MuiIconButton: {
    root: {
      '&$disabled': {
        color: palette.text.disabled,
      },
    },
    edgeEnd: {
      marginRight: -5,
    },
    edgeStart: {
      marginLeft: -5,
    },
  },

  // CARDS
  MuiCard: {
    root: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.divider,
    },
  },
  MuiCardHeader: {
    root: {
      padding: spacing(2.625, 4, 2.375),
    },
    action: {
      marginTop: spacing(-0.5),
      marginRight: spacing(-1),
      marginBottom: spacing(-0.5),
      '& .MuiIconButton-root': {
        // Styling from IconButton-sizeSmall to force small action buttons
        padding: 3,
        fontSize: typography.pxToRem(18),
      },
      '& [class*="MuiTextField-root"]': {
        paddingTop: spacing(0.375), // 3
      },
    },
    title: {
      textTransform: 'uppercase',
      minHeight: spacing(3),
    },
  },
  MuiCardContent: {
    root: {
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
      paddingTop: spacing(3),
      '&:last-child': {
        paddingBottom: spacing(3),
      },
    },
  },
  MuiCardActions: {
    root: {
      padding: spacing(2, 4, 3),
    },
  },

  // CONTAINERS

  MuiContainer: {
    maxWidthLg: {
      // Custom content width utilized for entirety of application.
      // All page content should be wrapped in a container with maxWidthLg as well as contextual areas of L1 / L2 Nav.
      [breakpoints.up('lg')]: {
        maxWidth: 1440,
      },
    },
  },

  // FORMS

  MuiInputLabel: {
    root: {
      ...typography.caption,
      // These styles are targeting the input padding when a label is present
      '& ~ .MuiOutlinedInput-root .MuiOutlinedInput-input, & ~ .MuiFilledInput-root .MuiFilledInput-input': {
        paddingTop: spacing(3),
        paddingBottom: spacing(1),
      },
      '& ~ .MuiOutlinedInput-root .MuiOutlinedInput-inputMultiline, & ~ .MuiFilledInput-root .MuiFilledInput-inputMultiline':
        {
          paddingTop: spacing(1.25),
          paddingBottom: 0,
        },
      '&$marginDense ~ .MuiOutlinedInput-root .MuiOutlinedInput-input, &$marginDense ~ .MuiFilledInput-root .MuiFilledInput-input':
        {
          paddingTop: spacing(2.375),
          paddingBottom: spacing(0.75),
        },
      '& ~ .MuiOutlinedInput-root .MuiSelect-select, & ~ .MuiFilledInput-root .MuiSelect-select': {
        paddingBottom: spacing(0.625),
      },
      '&$error': {
        color: palette.error.dark,
      },
    },
    formControl: {
      transform: 'translate(0, 1.69em) scale(1.34)',
      '&$disabled': {
        color: palette.text.secondary,
      },
    },
    shrink: {
      transform: 'translate(0, 1.5px) scale(1)',
      transformOrigin: 'top left',
      fontWeight: typography.fontWeightRegular,
      color: palette.text.primary,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      zIndex: 1,
    },
    outlined: {
      color: palette.text.secondary,
      width: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transform: 'translate(.85em, 1.0625em) scale(1)',
      '&$shrink': {
        transform: `translate(1em, .5em) scale(0.75)`,
      },
      '&$disabled': {
        color: palette.text.disabled,
      },
    },
    filled: {
      transform: 'translate(1.25em, 1em) scale(1.5)',
      '&$shrink': {
        transform: 'translate(1.33em, .5em) scale(1)',
      },
      '&$disabled': {
        color: palette.text.disabled,
      },
    },
    marginDense: {
      transform: 'translate(0, 1.3em) scale(1.34)',
      '&$shrink': {
        transform: 'translate(0, 1.5px) scale(1)',
      },
      '&$filled&$filled': {
        transform: 'translate(0.75em, 0.75em) scale(1.5)',
        '&$shrink': {
          transform: `translate(0.9375em, 0.3em) scale(1)`,
        },
      },
      '&$outlined&$outlined': {
        transform: 'translate(0.875em, 0.25em) scale(1)',
        '&$shrink': {
          transform: `translate(0.875em, 0.25em) scale(1)`,
        },
      },
    },
  },
  MuiSelect: {
    select: {
      '&&:not($outlined)': {
        paddingTop: spacing(0.25), // 2,
        paddingBottom: spacing(0.375), // 3,
      },
      '&&[class*="MuiInput-inputMarginDense"]:not($outlined)': {
        paddingTop: 0,
        paddingBottom: spacing(0.25), // 2,
        lineHeight: 1.25,
      },
      '&:focus': {
        backgroundColor: palette.background.paper,
      },
      '&$disabled': {
        cursor: 'not-allowed',
      },
    },
    root: {
      '&.MuiFilledInput-input:focus': {
        backgroundColor: palette.input.filledFocusBackgroundColor,
      },
    },
  },
  MuiInput: {
    /* Styles applied to the root element if the component is a descendant of `FormControl`. */
    formControl: {
      'label + &': {
        marginTop: 19,
      },
    },
    underline: {
      '& .MuiSelect-icon': {
        top: 'calc(50% - 16px)',
      },
      '&:before': {
        borderBottomColor: palette.divider,
      },
      '& .MuiInputAdornment-root': {
        marginBottom: spacing(0.375),
        '& .MuiIconButton-root': {
          padding: 3,
          fontSize: spacing(2.25),
          marginBottom: spacing(0.5),
        },
      },
      '&:hover:not($disabled):before': {
        borderBottomColor: palette.input.standardUnderlineHover,
        '@media (hover: none)': {
          borderBottomColor: palette.divider,
        },
      },
      '& $disabled': {
        color: palette.text.hint,
      },
    },
  },
  MuiInputBase: {
    input: {
      ...typography.body1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: palette.text.secondary,
      padding: `${spacing(0.375)}px 0 ${spacing(0.875)}px`, // 3px 0 7px
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        boxShadow: `0 0 0 20px ${palette.background.paper} inset`,
        '-webkit-text-fill-color': `${palette.text.secondary} !important`,
      },
      '&$disabled': {
        color: palette.text.disabled,
        cursor: 'not-allowed',
      },
    },
    inputMarginDense: {
      paddingTop: 0,
      paddingBottom: spacing(0.375), // 3,
    },
    inputMultiline: {
      whiteSpace: 'normal',
      textOverflow: 'initial',
      overflow: 'initial',
    },
  },
  MuiFilledInput: {
    root: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      backgroundColor: palette.transparency.hidden,
      '&:hover': {
        backgroundColor: palette.input.filledHoverBackgroundColor,
        '@media (hover: none)': {
          backgroundColor: palette.transparency.hidden,
        },
      },
      '&.Mui-focused': {
        backgroundColor: palette.input.filledFocusBackgroundColor,
      },
      '&.Mui-error': {
        backgroundColor: palette.input.filledErrorBackgroundColor,
      },
      '&.Mui-disabled': {
        backgroundColor: palette.background.default,
      },
    },
    adornedStart: {
      paddingLeft: 16,
    },
    adornedEnd: {
      paddingRight: 16,
    },
    input: {
      color: palette.text.primary,
      paddingTop: spacing(2),
      paddingRight: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(2),
    },
    inputMarginDense: {
      paddingTop: spacing(0.75),
      paddingRight: spacing(1.375),
      paddingBottom: spacing(0.75),
      paddingLeft: spacing(1.375),
    },
    underline: {
      '&:hover:before': {
        borderBottom: `1px solid ${palette.primary.light}`,
      },
      '&$error:after': {
        borderBottomColor: palette.input.filledErrorBorderBottomColor,
      },
      '&$disabled:before': {
        borderBottomColor: palette.transparency.hidden,
      },
    },
  },
  MuiInputAdornment: {
    marginDense: {
      '&&$positionStart': {
        marginBottom: spacing(0.25), // 2,
        marginRight: 0,
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      backgroundColor: palette.background.paper,
      '&$disabled': {
        backgroundColor: palette.background.default,
      },
      '&:hover $notchedOutline': {
        borderColor: palette.action.selected,
      },
      '&$focused $notchedOutline': {
        borderColor: palette.primary.main,
        borderWidth: 1,
      },
      '&$error $notchedOutline': {
        borderColor: palette.error.dark,
      },
      '&$disabled $notchedOutline': {
        borderColor: palette.action.disabledBackground,
      },
    },
    input: {
      color: palette.text.primary,
      paddingTop: spacing(2),
      paddingRight: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(2),
      '&$disabled': {
        backgroundColor: palette.background.default,
      },
    },
    notchedOutline: {
      borderColor: palette.input.notchedOutlineBorderColor,
      '& > [class*="legendLabelled"] > span': {
        display: 'none',
      },
    },
    inputMarginDense: {
      paddingTop: spacing(0.875),
      paddingRight: spacing(1.375),
      paddingBottom: spacing(0.75),
      paddingLeft: spacing(1.375),
    },
    inputMultiline: {
      paddingLeft: spacing(0.25),
      paddingRight: spacing(0.25),
    },
  },
  MuiAutocomplete: {
    hasClearIcon: {
      '& $inputRoot[class*="MuiInput-marginDense"]': {
        paddingRight: spacing(3.25), // 26,
        '& $endAdornment': {
          top: 'calc(50% - 15px)',
        },
      },
    },
    inputRoot: {
      '&[class*="MuiInput-root"]': {
        paddingBottom: 1,
        '& $input': {
          padding: spacing(0.5), // 4,
        },
        '& $input:first-child': {
          padding: `${spacing(0.375)}px 0 ${spacing(0.75)}px`, // 3px 0 6px
        },
        '&[class*="MuiInput-marginDense"] $input:first-child': {
          padding: `0 0 ${spacing(0.25)}px`, // 0 0 2px
        },
        '& $endAdornment': {
          top: 'calc(50% - 18px)',
        },
      },
      '&[class*="MuiOutlinedInput-root"]': {
        padding: spacing(1),

        '& $input': {
          padding: spacing(2, 0, 0, 1),
          '&:first-child': {
            paddingLeft: spacing(1),
          },
        },
      },
    },
  },
  MuiFormHelperText: {
    root: {
      color: palette.text.secondary,
      '&$disabled': {
        color: palette.text.disabled,
      },
      '&$error': {
        color: palette.error.dark,
      },
    },
    contained: {
      marginLeft: spacing(2),
      marginRight: spacing(2),
      '&$marginDense': {
        marginLeft: spacing(1.375),
        marginRight: spacing(1.375),
      },
    },
  },

  // PICKERS

  MuiPickersCalendar: {
    // NOTE: Only way to style the calendar is through an override. "for-component" classes are not allowed.
  },

  // LISTS

  MuiList: {},

  MuiListItem: {
    root: {
      paddingLeft: spacing(4),
      '&$focusVisible': {
        backgroundColor: palette.action.selected,
      },
      '&$selected, &$selected:hover': {
        backgroundColor: palette.action.selected,
      },
    },
    container: {
      '&.active': {
        backgroundColor: palette.action.selected,
      },
    },
    secondaryAction: {
      paddingRight: spacing(8),
    },
  },

  MuiListItemAvatar: {
    root: {
      minWidth: spacing(8),
      '& .MuiAvatar-root': {
        width: spacing(6),
        height: spacing(6),
        '& svg': {
          width: spacing(3),
          height: spacing(3),
        },
      },
      '& .MuiAvatar-colorDefault': {
        backgroundColor: palette.listItem.avatarBackgroundColor,
        color: palette.text.hint,
      },
      '&:hover, &.active': {
        '& .MuiAvatar-root': {
          backgroundColor: palette.listItem.avatarHoverBackgroundColor,
        },
        '& svg': {
          fill: palette.listItem.avatarActiveSvgColor,
          visibility: 'visible',
        },
      },
    },
  },

  MuiListItemText: {
    primary: {
      color: palette.text.primary,
    },
    secondary: {
      color: palette.text.hint,
    },
    multiline: {
      marginTop: spacing(0.25), // 2,
      marginBottom: spacing(0.25), // 2,
    },
  },

  // TABLES

  MuiTable: {},
  MuiTableRow: {
    root: {
      '&$hover:hover': {
        backgroundColor: palette.action.hover,
        cursor: 'pointer',
      },
      '&$selected, &$selected:hover': {
        backgroundColor: palette.action.selected,
      },
    },
  },
  MuiTableCell: {
    root: {
      alignItems: 'center',
      lineHeight: 1.5,
      padding: spacing(0.5, 2, 0.375),
      borderBottom: 'initial',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: palette.table.cellBorderColor,
      '&[style*="flex"]': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSkeleton-root': {
          flex: 1,
        },
      },
      '&:not(.MuiTableCell-sizeSmall)': {
        height: spacing(7),
        '&[style*="flex"]': {
          height: 'auto',
          minHeight: spacing(7),
        },
      },
    },
    head: {
      ...typography.body2,
      fontWeight: typography.fontWeightMedium,
      backgroundColor: palette.table.headBackgroundColor,
      borderWidth: '1px 0 1px',
      borderStyle: 'solid',
      borderColor: palette.table.cellBorderColor,
    },
    stickyHeader: {
      backgroundColor: palette.table.headBackgroundColor,
    },
    sizeSmall: {
      padding: 0,

      // Inline editable cell styling -- This is accounting for Filled variant inputs within small table cells
      '& > [class*="MuiTextField"], & > [class*="MuiInputBase"]': {
        '&[class*="-marginDense"]': {
          margin: 0,
          minHeight: `calc(${spacing(7)}px - 1px)`,
        },
        '& [class*="MuiFilledInput-root"]': {
          minHeight: `calc(${spacing(7)}px - 1px)`,
          height: '100%',
        },
        '& [class*="MuiFilledInput-root"], & [class*="MuiSelect-root"]': {
          '& input, &[class*="MuiSelect"]': {
            ...typography.body2,
            '&:not(:focus):not(:active)': {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
          },
        },
      },
      '&:last-child': {
        paddingRight: 0,
      },
      '&.MuiTableCell-head': {
        height: spacing(5),
        '&[style*="flex"]': {
          height: 'auto',
          minHeight: spacing(5),
        },
      },
    },
  },

  // TABS

  MuiTab: {
    root: {
      [breakpoints.up('sm')]: {
        minWidth: '90px',
      },
      padding: spacing(0, 1),
    },
  },

  MuiTabs: {
    flexContainer: {
      justifyContent: 'space-between',
    },
    scroller: {
      paddingBottom: 1,
    },
  },
};

export default overrides;
