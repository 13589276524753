import React, { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

export type DrawerContentProps = {
  children?: ReactNode;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 4),
    overflow: 'auto',
    flex: 1,
  },
}));

const DrawerContent = ({ children, className, ...props }: DrawerContentProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} data-testid="drawer-content" {...props}>
      {children}
    </div>
  );
};

export default DrawerContent;
