import React, { ChangeEvent, FocusEvent } from 'react';
import { Grid, GridProps, MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import EinField from '../EinField/EinField';
import SsnField from '../SsnField/SsnField';
import ItinField from '../ItinField/ItinField';
import ObscurableField from '../ObscurableField';
import UnknownField from '../UnknownField';

export type TaxIdTypes = 'EIN' | 'SSN' | 'ITIN' | 'UNKNOWN' | '';
export type TaxIdValue = Record<string, string | TaxIdTypes>;

export type TaxIdFieldGroupProps = {
  ContainerComponentProps?: GridProps;
  taxIdTypes?: string[];
  TypeFieldProps?: TextFieldProps;
  IdFieldProps?: TextFieldProps;
  value?: TaxIdValue;
  className?: string;
  onChange?: (value: TaxIdValue) => void;
  errorMessage?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
};

const EIN = 'EIN';
const SSN = 'SSN';
const ITIN = 'ITIN';
const UNKNOWN = 'UNKNOWN';

const InputPlaceholder = () => {
  return <ObscurableField label="Tax ID Number" disabled={true} fullWidth />;
};

type inputFieldComponent = (props: TextFieldProps) => JSX.Element;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentMap: Record<string, inputFieldComponent> = {
  [EIN]: EinField,
  [SSN]: SsnField,
  [ITIN]: ItinField,
  [UNKNOWN]: UnknownField,
  ['']: InputPlaceholder,
};

const TaxIdFieldGroup = ({
  ContainerComponentProps,
  onChange = () => undefined,
  onBlur = () => undefined,
  value,
  TypeFieldProps = {},
  IdFieldProps = {},
  taxIdTypes = [EIN, SSN, ITIN, UNKNOWN],
  errorMessage,
}: TaxIdFieldGroupProps): JSX.Element => {
  const typeKey = TypeFieldProps.name || 'type';
  const idKey = IdFieldProps.name || 'id';
  const valueProp = { [typeKey]: value?.[typeKey] ?? '', [idKey]: value?.[idKey] ?? '' };
  const InputComponent = componentMap[(TypeFieldProps.value || (valueProp && valueProp[typeKey]) || '') as TaxIdTypes];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    onChange({ ...valueProp, [name]: value });
  };

  const menuItems: JSX.Element[] = taxIdTypes.map((idType) => (
    <MenuItem value={idType} key={idType}>
      {idType}
    </MenuItem>
  ));

  return (
    <Grid container {...ContainerComponentProps}>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          label="Tax ID Type"
          onBlur={onBlur}
          value={valueProp[typeKey]}
          name="type"
          onChange={handleChange}
          {...TypeFieldProps}
          select
          className={TypeFieldProps.className}
        >
          {menuItems}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={7}>
        <InputComponent
          fullWidth
          onChange={handleChange}
          onBlur={onBlur}
          error={!!errorMessage}
          helperText={errorMessage}
          value={valueProp[idKey]}
          name="taxIdNumber"
          {...IdFieldProps}
        />
      </Grid>
    </Grid>
  );
};

export default TaxIdFieldGroup;
