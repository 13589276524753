import React from 'react';
import {
  ArticleOutlined,
  AssessmentOutlined,
  ChecklistOutlined,
  ContactMailOutlined,
  CreditCardOutlined,
  DescriptionOutlined,
  DrawOutlined,
  EqualizerOutlined,
  FilledActionCalculator,
  GppGoodOutlined,
  InsightsOutlined,
  ListAltOutlined,
  ListOutlined,
  ManageSearchOutlined,
  OtherHousesOutlined,
  PeopleAltOutlined,
  PersonOutlined,
  ReceiptOutlined,
  SecurityOutlined,
  SourceOutlined,
  TaskOutlined,
  UploadFileOutlined,
  VerifiedOutlined,
} from './LeftNavIcons';
import { NavLinkType } from './NavLinkType';
import { getFileInfo } from '../../api/file/file-api';

export const FilesLinks: NavLinkType[] = [
  {
    linkText: 'Order Insights',
    path: '/files/:fileId/order-insights',
    icon: <InsightsOutlined />,
    expanded: false,
    testId: 'GlobalNavFilesOrderInsights',
  },
  {
    linkText: 'File Info',
    path: '/files/:fileId/file-info',
    icon: <ListOutlined />,
    expanded: false,
    testId: 'GlobalNavFiles',
  },
  {
    linkText: 'Back Title',
    path: '/files/:fileId/back-title',
    icon: <OtherHousesOutlined />,
    expanded: false,
    testId: 'GlobalNavFilesBackTitle',
  },
  {
    linkText: 'CPL',
    path: '/files/:fileId/cpl',
    icon: <ArticleOutlined />,
    expanded: false,
    testId: 'GlobalNavFilesCPL',
  },
  {
    linkText: 'Service Orders',
    path: '/files/:fileId/service-orders',
    icon: <ChecklistOutlined />,
    expanded: false,
    testId: 'GlobalNavFilesServiceOrders',
  },
  {
    linkText: 'Underwriting',
    path: '/files/:fileId/underwriting',
    icon: <GppGoodOutlined />,
    expanded: false,
    testId: 'GlobalNavFilesUnderwriting',
  },
  {
    linkText: 'Jackets',
    path: '/files/:fileId/jackets',
    icon: <SourceOutlined />,
    expanded: false,
    testId: 'GlobalNavFilesJackets',
  },
  {
    linkText: 'Rates & Fees',
    path: '#ratesfees', //opens a drawer
    icon: <FilledActionCalculator />,
    expanded: false,
    testId: 'GlobalNavFilesRatesFeesDrawer',
    badgeContentFunction: async (token: string, fileProps = { fileId: null }) => {
      const { fileId = '' } = fileProps;
      if (fileId) {
        const fileApiData = await getFileInfo(fileProps?.fileId ?? '', token);
        const { fileStatus } = fileApiData;

        const {
          hasRemittableCpl = false,
          hasActiveJacket = false,
          hasStandAloneEndorsement = false,
          isPricingValid = false,
          isRemitValid = false,
        } = fileApiData.serviceStatus ?? {};

        const openRemittableProductsNotCalculated =
          (hasRemittableCpl || hasActiveJacket || hasStandAloneEndorsement) &&
          (!isPricingValid || (isPricingValid && !isRemitValid));
        const openRemittableProductsCalculated =
          (hasRemittableCpl || hasActiveJacket || hasStandAloneEndorsement) && isPricingValid && isRemitValid;
        const noRemittableProducts =
          !hasRemittableCpl && !hasActiveJacket && !hasStandAloneEndorsement && !isPricingValid;

        if (fileStatus?.toLowerCase() !== 'open' || noRemittableProducts) {
          return 'idle';
        } else if (openRemittableProductsCalculated) {
          return 'success';
        } else if (openRemittableProductsNotCalculated) {
          return 'warning';
        }
      } else {
        console.warn('Could not compute fileId');
      }
    },
  },
  {
    linkText: 'Documents',
    path: '/files/:fileId/documents',
    icon: <DescriptionOutlined />,
    expanded: false,
    testId: 'GlobalNavFilesDocuments',
  },
  // {
  //   linkText: 'Notes',
  //   path: '/files/:fileId/notes',
  //   icon: <EditNoteOutlined />,
  //   expanded: false,
  //   testId: 'GlobalNavFilesNotes',
  // },
];

export const AccountingLinks: NavLinkType[] = [
  {
    linkText: 'Report & Pay',
    path: '/accounting/remittance',
    icon: <AssessmentOutlined />,
    sublinks: [
      {
        linkText: 'Review',
        path: '/accounting/remittance/review',
        testId: 'GlobalNavAccountingReportPayReview',
        notificationStatus: 'default',
        // *** Commenting this out to prevent redundant badgecontent calls ***
        // badgeContentFunction: async (token: string, fileProps = { fileId: null, firmId: '' }) => {
        //   console.log('fileProps', fileProps);
        //   // TODO: Fix the output of the searchReports function to be an array
        //   const result = (await searchReports(token, fileProps.firmId, 'Review')) as unknown as unknown[];
        //   // and return appropriate badge content
        //   return result?.length || 0;
        // },
      },
      {
        linkText: 'Report',
        path: '/accounting/remittance/report',
        testId: 'GlobalNavAccountingReportPayReport',
      },
      {
        linkText: 'Pay',
        path: '/accounting/remittance/pay',
        testId: 'GlobalNavAccountingReportPayPay',
      },
    ],
    expanded: true,
    testId: 'GlobalNavAccountingReportPay',
  },
  {
    linkText: 'Service Order Invoices',
    path: '/accounting/service-order-invoices',
    icon: <ReceiptOutlined />,
    sublinks: [
      {
        linkText: 'Pay',
        path: '/accounting/service-order-invoices/pay',
        testId: 'GlobalNavAccountingServiceOrderInvoicesPay',
      },
      {
        linkText: 'View Invoices',
        path: '/accounting/service-order-invoices/view-invoices',
        testId: 'GlobalNavAccountingServiceOrderInvoicesView',
      },
    ],
    expanded: true,
    testId: 'GlobalNavAccountingServiceOrderInvoices',
  },
  {
    linkText: 'Statements',
    path: '/statements',
    icon: <ListAltOutlined />,
    expanded: false,
    testId: 'GlobalNavAccountingStatements',
  },
];

export const ServicesLinks: NavLinkType[] = [
  {
    linkText: 'Agency Verification Letters',
    path: '/services/agency-verification-letters',
    icon: <VerifiedOutlined />,
    expanded: false,
    testId: 'GlobalNavServicesAVL',
  },
  {
    linkText: 'Back Title Search',
    path: '/services/back-title-search',
    icon: <ManageSearchOutlined />,
    expanded: false,
    testId: 'GlobalNavServicesBackTitle',
  },
  {
    linkText: 'Property Reports',
    path: '/services/property-reports',
    icon: <TaskOutlined />,
    expanded: false,
    testId: 'GlobalNavServicesPropertyReports',
  },
  {
    linkText: 'Policy Upload',
    path: '/services/policy-upload',
    icon: <UploadFileOutlined />,
    expanded: true,
    testId: 'GlobalNavServicesPolicyUpload',
    sublinks: [
      {
        linkText: 'Upload',
        path: '/services/policy-upload/upload',
        testId: 'GlobalNavServicesPolicyUploadUpload',
      },
      {
        linkText: 'History',
        path: '/services/policy-upload/history',
        testId: 'GlobalNavServicesPolicyUploadHistory',
      },
    ],
  },
  {
    linkText: 'SDN Search',
    path: '/services/sdn-search',
    icon: <SecurityOutlined />,
    expanded: false,
    testId: 'GlobalNavServicesSDN',
  },
];

export const ReportsLinks: NavLinkType[] = [
  {
    linkText: 'Reports',
    path: '/reporting', // currently /reports
    icon: <EqualizerOutlined />,
    // TODO: Uncomment these when we have sub pages for each report
    // sublinks: [
    //   {
    //     linkText: 'CPL Activity Log',
    //     path: '/reporting/cpl-activity-log',
    //     testId: 'GlobalNavReportsCPLActivity',
    //   },
    //   {
    //     linkText: 'Incomplete Files',
    //     path: '/reporting/incomplete-files',
    //     testId: 'GlobalNavReportsIncompleteFiles',
    //   },
    //   {
    //     linkText: 'Policy Log',
    //     path: '/reporting/policy-log',
    //     testId: 'GlobalNavReportsPolicyLog',
    //   },
    //   {
    //     linkText: 'Service Order Activity Log',
    //     path: '/reporting/service-order-activity-log',
    //     testId: 'GlobalNavReportsServiceOrderActivity',
    //   },
    // ],
    expanded: false,
    testId: 'GlobalNavReports',
  },
];

export const AdminLinks: NavLinkType[] = [
  {
    category: 'User',
    linkText: 'User Profile & Preferences',
    path: '/admin/user/profile',
    icon: <PersonOutlined />,
    sublinks: [
      {
        linkText: 'Profile',
        path: '/admin/user/profile',
        testId: 'AdminNavUserProfile',
      },
      // TODO: Uncomment when new page is ready.
      // {
      //   linkText: 'Policy Preferences',
      //   path: '/admin/user/policy-preferences',
      //   testId: 'AdminNavPolicyPreferences',
      // },
      {
        linkText: 'Preferred Offices',
        path: '/admin/user/preferred-offices',
        testId: 'AdminNavPreferredOffices',
      },
    ],
    expanded: true,
    testId: 'AdminNavUser',
  },
  {
    category: 'Firm',
    linkText: 'Address Book',
    path: '/admin/address-book',
    icon: <ContactMailOutlined />,
    expanded: false,
    testId: 'AdminNavAddress',
  },
  {
    category: 'Firm',
    linkText: 'Billing',
    path: '/admin/billing',
    icon: <CreditCardOutlined />,
    expanded: false,
    testId: 'AdminNavBilling',
  },
  {
    category: 'Firm',
    linkText: 'Auto-Reporting',
    path: '/admin/auto-reporting',
    icon: <AssessmentOutlined />,
    expanded: false,
    testId: 'AdminNavAutoReporting',
  },
  {
    category: 'Firm',
    linkText: 'e-Signatures',
    path: '/admin/e-signatures',
    icon: <DrawOutlined />, //ok
    expanded: false,
    testId: 'AdminNavSignatures',
  },
  // {
  //   category: 'Firm',
  //   linkText: 'Mappings',
  //   path: '',
  //   icon: <AccountTreeOutlined />,
  //   expanded: false,
  //   testId: 'AdminNavMappings',
  //   sublinks: [
  //     {
  //       linkText: 'Firm Mappings',
  //       path: '/admin/mapping/firms',

  //       testId: 'AdminNavMappingsFirm',
  //     },
  //     {
  //       linkText: 'Rate Type Mappings',
  //       path: '/admin/mapping/rate-type',

  //       testId: 'AdminNavMappingsRateType',
  //     },
  //     {
  //       linkText: 'Service Order Mappings',
  //       path: '/admin/mapping/service-order',

  //       testId: 'AdminNavMappingsServiceOrder',
  //     },
  //   ],
  // },
  // {
  //   category: 'Firm',
  //   linkText: 'Preset Overrides',
  //   path: '',
  //   icon: <BuildOutlined />,
  //   expanded: false,
  //   testId: 'AdminNavPresetOverrides',
  // },
  {
    category: 'Firm',
    linkText: 'Users',
    path: '/admin/users',
    icon: <PeopleAltOutlined />,
    expanded: false,
    testId: 'AdminNavUsers',
    sublinks: [
      {
        linkText: 'Manage Users',
        path: '/admin/users/manage',
        testId: 'AdminNavManageUser',
      },
      // {
      //   linkText: 'User Registration',
      //   path: '/admin/users/registration',
      //   testId: 'AdminNavRegistrationUser',
      // },
    ],
  },
  // {
  //   category: 'Staff',
  //   linkText: 'ASC Support',
  //   path: '',
  //   icon: <SupportAgentOutlined />,
  //   expanded: false,
  //   testId: 'AdminNavASCSupport',
  // },
  // {
  //   category: 'Staff',
  //   linkText: 'Service Orders',
  //   path: '',
  //   icon: <ChecklistOutlined />, //ok
  //   expanded: false,
  //   testId: 'AdminNavServiceOrders',
  // },
  // {
  //   category: 'Staff',
  //   linkText: 'Vendor Mappings',
  //   path: '',
  //   icon: <AccountTreeOutlined />,
  //   expanded: false,
  //   testId: 'AdminNavVendorMappings',
  // },
];
