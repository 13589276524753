import React, { ReactElement, ReactNode, useState } from 'react';
import {
  TextField,
  ListItemTextProps,
  ListItemProps,
  SvgIconProps,
  ListItem,
  ListItemAvatar,
  Avatar,
  makeStyles,
  Theme,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Create as PencilIcon, AccountCircle, Lock } from '@material-ui/icons';
import clsx from 'clsx';

export type RenderInputProps = {
  onBlur: () => void;
  value: unknown;
  label: ReactNode;
};

export type EditableListItemProps = {
  icon?: ReactElement<SvgIconProps>;
  ListItemTextProps?: ListItemTextProps;
  emptyValueText?: string;
  label?: ReactNode;

  renderInput?: (props: RenderInputProps) => JSX.Element;
  renderValue?: (props: ReactNode | string) => ReactNode;
  renderLabel?: (props: ReactNode) => ReactNode;
} & ListItemProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(10.5),
    paddingTop: 17.5,
    paddingBottom: 17.4,
    alignItems: 'flex-start',
    '&$disabled': {
      opacity: 1,
    },
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  textItem: {
    marginTop: 2,
    marginBottom: 2,
  },
  focusVisible: {},
  disabled: {},
  rootActiveHover: {
    '&:hover, &.active': {
      '& $icon': {
        color: theme.palette.primary.main,
        visibility: 'visible',
      },
      '& .MuiAvatar-root': {
        backgroundColor: theme.palette.action.disabledBackground,
      },
      '& svg': {
        fill: theme.palette.primary.main,
        visibility: 'visible',
      },
      '& .MuiListItem-root': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  iconValue: {
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  inputContainer: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  icon: {
    visibility: 'hidden',
  },
  lockContainer: {
    padding: theme.spacing(1.5),
  },
  lock: {
    fill: theme.palette.text.disabled,
  },
}));

const EditableListItem = ({
  icon = <AccountCircle />,
  label,
  value,
  renderValue = (value) => value as ReactNode,
  renderLabel = (labelValue) => labelValue,
  renderInput = (props) => <TextField {...props} fullWidth autoFocus data-testid="editable-list-item-input" />,
  emptyValueText = 'Secondary',
  ListItemTextProps,
  divider = true,
  disabled = false,
  ContainerComponent,
}: EditableListItemProps): JSX.Element => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const primaryValue = renderLabel(label);
  const secondaryText = value ? value : emptyValueText;
  const secondaryValue = renderValue(secondaryText);

  const blurHandler = () => {
    setIsEdit(false);
  };

  return (
    <ListItem
      // 0 allows to be in native tab flow, this is for when the component is in display
      // -1 removes from native tab flow, allowing us to skip the LI when going backwards
      tabIndex={isEdit || disabled ? -1 : 0}
      id="list-item-root"
      ContainerProps={{ className: clsx(!disabled && classes.rootActiveHover, isEdit && 'active') }}
      ContainerComponent={ContainerComponent}
      disabled={disabled}
      divider={divider}
      classes={{
        root: classes.root,
        disabled: classes.disabled,
        focusVisible: classes.focusVisible,
      }}
      data-testid="editable-list-item"
      disableGutters={true}
      onClick={() => {
        if (!disabled) {
          setIsEdit(true);
        }
      }}
      onFocus={() => {
        if (!disabled) {
          setIsEdit(true);
        }
      }}
    >
      <ListItemAvatar>
        <Avatar variant="rounded" className={clsx(value && classes.iconValue)}>
          {icon}
        </Avatar>
      </ListItemAvatar>
      {isEdit ? (
        <div className={classes.inputContainer} id="input-container">
          {renderInput({
            value,
            onBlur: blurHandler,
            label,
          })}
        </div>
      ) : (
        <>
          <ListItemText
            primary={primaryValue}
            secondary={secondaryValue}
            className={clsx(classes.textItem, ListItemTextProps?.className)}
            primaryTypographyProps={{
              noWrap: true,
            }}
            secondaryTypographyProps={{
              noWrap: true,
            }}
            {...ListItemTextProps}
          />
        </>
      )}
      <ListItemSecondaryAction>
        {disabled ? (
          <div className={classes.lockContainer} data-testid={'editable-list-lock-icon'}>
            <Lock className={classes.lock} />
          </div>
        ) : (
          <IconButton onClick={() => setIsEdit(true)} data-testid={'editable-list-pencil-icon'} tabIndex="-1">
            <PencilIcon className={classes.icon} />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default EditableListItem;
