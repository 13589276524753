export interface UserOffices {
  OfficeId: string;
  Name: string;
  Address: Address;
  PhoneNumber: string;
  EmailAddress: string;
  AltaId: string;
  FirmLicense: string;
}

export interface Address {
  Address1: string;
  Address2: string;
  City: string;
  PostalCode: string;
  State: string;
  County: string;
}

export interface BopsOptions {
  id: number;
  name: string;
}

export interface UserAccessKeyOptions {
  ThirdPartyVendorId: number;
  vendorName: string;
}

export const bopsOptions: BopsOptions[] = [
  { id: 3317, name: 'AgentNet Standard XML' },
  { id: 3301, name: 'AIM' },
  { id: 3302, name: 'AccuTitle' },
  { id: 3304, name: 'DisplaySoft' },
  { id: 3305, name: 'DoubleTime' },
  { id: 3306, name: 'Landtech Manual Import' },
  { id: 3307, name: 'RamQuest XML' },
  { id: 3308, name: 'RamQuest Order Entry' },
  { id: 3309, name: 'SoftPro PXT' },
  { id: 3312, name: 'Title Express' },
  { id: 3315, name: 'Manual Import XML' },
  { id: 3316, name: 'zzSoftPro PXT' },
  { id: 3330, name: 'FL ALTA Loan Policy' },
  { id: 3331, name: 'FL ALTA Owner Policy' },
  { id: 3332, name: 'TitleSoft PXT' },
  { id: 3333, name: 'Qualia JSON' },
  { id: 3334, name: 'SnapClose XML' },
  { id: 3350, name: 'FA Standard XML' },
];

export const sortedBopsoptions: BopsOptions[] = bopsOptions.sort((a, b) => a.name.localeCompare(b.name));

export const userAccessKeyOptions: UserAccessKeyOptions[] = [
  {
    ThirdPartyVendorId: 1,
    vendorName: 'ClosingMarket',
  },
  {
    ThirdPartyVendorId: 2,
    vendorName: 'FAST',
  },
  {
    ThirdPartyVendorId: 3,
    vendorName: 'SoftPro',
  },
  {
    ThirdPartyVendorId: 4,
    vendorName: 'StreamLine',
  },
  {
    ThirdPartyVendorId: 5,
    vendorName: 'ThirdPartySimulator',
  },
  {
    ThirdPartyVendorId: 6,
    vendorName: 'AGENTNETWS',
  },
  {
    ThirdPartyVendorId: 7,
    vendorName: 'TARA',
  },
  {
    ThirdPartyVendorId: 8,
    vendorName: 'SnapClosePro',
  },
  {
    ThirdPartyVendorId: 9,
    vendorName: 'LandTitleGuarantee',
  },
  {
    ThirdPartyVendorId: 10,
    vendorName: 'AccuTitle',
  },
  {
    ThirdPartyVendorId: 11,
    vendorName: 'e-Closing',
  },
  {
    ThirdPartyVendorId: 12,
    vendorName: 'TitleExpress',
  },
  {
    ThirdPartyVendorId: 13,
    vendorName: 'Landtech',
  },
  {
    ThirdPartyVendorId: 14,
    vendorName: 'ResWare',
  },
  {
    ThirdPartyVendorId: 15,
    vendorName: 'ValuAmerica',
  },
  {
    ThirdPartyVendorId: 16,
    vendorName: 'Closeline',
  },
  {
    ThirdPartyVendorId: 17,
    vendorName: 'TitleMax',
  },
  {
    ThirdPartyVendorId: 18,
    vendorName: 'Filetrak',
  },
  {
    ThirdPartyVendorId: 19,
    vendorName: 'VisionNet',
  },
  {
    ThirdPartyVendorId: 20,
    vendorName: 'TrackerPRO',
  },
  {
    ThirdPartyVendorId: 21,
    vendorName: 'MDK',
  },
  {
    ThirdPartyVendorId: 22,
    vendorName: 'ClosersChoice',
  },
  {
    ThirdPartyVendorId: 23,
    vendorName: 'Impact',
  },
  {
    ThirdPartyVendorId: 24,
    vendorName: 'QualiaLabs',
  },
  {
    ThirdPartyVendorId: 25,
    vendorName: 'EasyCDF',
  },
  {
    ThirdPartyVendorId: 26,
    vendorName: 'Timios',
  },
  {
    ThirdPartyVendorId: 27,
    vendorName: 'TitleSoft',
  },
  {
    ThirdPartyVendorId: 28,
    vendorName: 'Tryon',
  },
  {
    ThirdPartyVendorId: 29,
    vendorName: 'iClosings',
  },
  {
    ThirdPartyVendorId: 30,
    vendorName: 'Mortgage Connect',
  },
  {
    ThirdPartyVendorId: 31,
    vendorName: 'First International Title',
  },
  {
    ThirdPartyVendorId: 32,
    vendorName: 'Rita',
  },
  {
    ThirdPartyVendorId: 33,
    vendorName: 'AgentLinx',
  },
  {
    ThirdPartyVendorId: 34,
    vendorName: 'Title365',
  },
  {
    ThirdPartyVendorId: 35,
    vendorName: 'TVDDG',
  },
  {
    ThirdPartyVendorId: 36,
    vendorName: 'Solidifi',
  },
  {
    ThirdPartyVendorId: 37,
    vendorName: 'Settlor',
  },
  {
    ThirdPartyVendorId: 38,
    vendorName: 'Silk Title',
  },
  {
    ThirdPartyVendorId: 39,
    vendorName: 'Fluent',
  },
  {
    ThirdPartyVendorId: 40,
    vendorName: 'Townsgate',
  },
  {
    ThirdPartyVendorId: 41,
    vendorName: 'Team Title',
  },
  {
    ThirdPartyVendorId: 42,
    vendorName: 'Spruce Land Services',
  },
  {
    ThirdPartyVendorId: 43,
    vendorName: 'TurboTitle',
  },
];

export const sortedUserAccessKeyOptions: UserAccessKeyOptions[] = userAccessKeyOptions.sort((a, b) =>
  a.vendorName.localeCompare(b.vendorName),
);
