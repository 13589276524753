import React from 'react';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { FormGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './CheckBoxGroup.scss';

export type CheckboxGroupProps = {
  label?: string;
  row?: boolean;
  className?: string;
  required?: boolean;
  removeMarginTop?: boolean;
  children: any;
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: '1.3rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.125),
  },
  required: {
    color: theme.palette.secondary.dark,
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  removeMarginTop: {
    marginTop: '-6px',
  },
}));

export default function AgentNetCheckBoxGroup({
  label,
  row = true,
  className,
  required,
  removeMarginTop,
  children,
}: CheckboxGroupProps): JSX.Element {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={clsx({ [classes.removeMarginTop]: removeMarginTop }, className)}>
      {label && (
        <FormLabel component="legend">
          <Typography variant="body1" className={classes.label}>
            {label} {required && <span className={classes.required}>*</span>}
          </Typography>
        </FormLabel>
      )}
      <FormGroup row={row}>{children}</FormGroup>
    </FormControl>
  );
}
