import { Article } from '../types';
import { gql } from '@apollo/client';
import performQuery from './contentfulClient';

export const ARTICLE_QUERY = gql`
  query ArticleQuery($slug: String!, $preview: Boolean) {
    kbArticleCollection(where: { slug: $slug }, limit: 1, preview: $preview) {
      items {
        sys {
          id
          publishedAt
        }
        articleTitle
        articleSummary
        articleTip
        slug
        articleBody {
          json
          links {
            entries {
              hyperlink {
                ... on KbArticle {
                  sys {
                    id
                  }
                  slug
                  articleTitle
                  topicCollection(limit: 1) {
                    items {
                      topicName
                      slug
                    }
                  }
                }
              }
            }
            assets {
              hyperlink {
                sys {
                  id
                }
                title
                url
              }
              block {
                sys {
                  id
                }
                fileName
                contentType
                url
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;

export const useArticleQuery = async (slug: string) => {
  const response = await performQuery(ARTICLE_QUERY, { slug });

  if (response.hasError) {
    return {
      data: null,
      error: true,
    };
  }

  return {
    data: response.res?.data?.kbArticleCollection?.items?.[0] as Article | null,
    error: false,
  };
};
