import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { useAuth } from '@agentnet/auth';
import { deletePayment, editPaymentIframe, getEpayAccount, getPaymentIframe } from 'api/payment/api';
import useAsync from 'hooks/useAsync';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import PaymentCards from './PaymentCards';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '11.4rem',
    },
    tabWrap: {
      borderBottom: `1px solid #C4C4C4`,
    },
    tabsRoot: {
      marginLeft: '24px',
    },
    iframe: {
      border: '0px #ffffff none',
      position: 'absolute',
      left: '8px',
      width: '100%',
      maxWidth: '370px',
      minHeight: '800px',
      marginTop: '16px',
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '24px',
      marginTop: '32px',
    },
    cardtext: {
      textAlign: 'center',
      gap: '8px',
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      marginBottom: '24px',
    },
    responsiveGrid: {
      transition: 'all 0.3s ease-in-out',
    },
  }),
);

const paymentMode = [
  { label: 'Credit or Debit Card', value: 'CC' },
  { label: 'ACH Transfer/Bank Account', value: 'ACH' },
];

const Billing = () => {
  const classes = useStyles();
  const [isAch, setAch] = useState(false);
  const [openDrawer, setDrawer] = useState(false);
  const [openDeleteModal, showOpenModal] = useState(false);
  const [selectedPaymentMode, setselectedPaymentMode] = useState<RadioProps>(paymentMode[0]);
  const [editPayload, setEditPayload] = useState<{ accountAliasId?: string; isAch?: boolean }>({});
  const [deletePayload, setDeletePayload] = useState<{
    accountAliasId?: string;
    isAch?: boolean;
    paymentType?: string;
  }>({});

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile } = profileCtx;
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const { addSnackbarMessage } = useSnackBars();

  const hideBilling = useMemo(() => {
    //payRights
    const payRights =
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 61)) ||
      //pay manual rights
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 83));
    return !payRights;
  }, [profile?.activityRights]);

  const handleOpenDrawer = () => {
    setDrawer(true);
  };

  const onEdit = (accountAliasId: string, isAch: boolean) => {
    const paymentMethod = paymentMode?.find((option) => option.value === (isAch ? 'ACH' : 'CC')) ?? selectedPaymentMode;
    setAch(paymentMethod.value === `ACH`);
    setselectedPaymentMode(paymentMethod);
    setEditPayload((prev: any) => ({
      ...prev,
      accountAliasId: accountAliasId,
      isAch: paymentMethod.value === `ACH`,
    }));
    handleOpenDrawer();
  };

  const onDelete = (accountAliasId: string, isAch: boolean, accountName: string, paymentType: string) => {
    const paymentMethod = paymentMode?.find((option) => option.value === (isAch ? 'ACH' : 'CC')) ?? selectedPaymentMode;
    setAch(paymentMethod.value === `ACH`);
    setselectedPaymentMode(paymentMethod);
    setDeletePayload((prev: any) => ({
      ...prev,
      firmId: userFirm?.firmId,
      firmName: userFirm?.name,
      accountAliasId,
      isAch: paymentMethod.value === `ACH`,
      accountName,
      paymentType,
    }));
    showOpenModal(true);
  };

  const getIframeData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    if (editPayload?.accountAliasId) {
      return await editPaymentIframe(token, userFirm?.firmId ?? '', isAch, editPayload?.accountAliasId);
    }
    return await getPaymentIframe(token, userFirm?.firmId ?? '', isAch);
  };

  const {
    execute: executeframeData,
    status: executeframeDataStatus,
    value: frameDataResults,
    errors: frameDataErrors,
  } = useAsync<any>(getIframeData, false);

  const deletePaymentDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await deletePayment(token, deletePayload);
  };

  const {
    execute: executeDelete,
    status: deleteStatus,
    value: deleteResults,
    errors: deleteErrors,
  } = useAsync<any>(deletePaymentDetails, false);

  const getEpayAccounts = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getEpayAccount(token, userFirm?.firmId ?? '');
  };

  const {
    execute: executeEpay,
    value: paymentMethodDetails,
    status: paymentMethodStatus,
    errors: getEpayErrors,
  } = useAsync<any>(getEpayAccounts, false);

  useEffect(() => {
    if (executeframeDataStatus === 'error' || paymentMethodStatus === 'error' || deleteStatus === 'error') {
      const errorMessages = [...(frameDataErrors ?? []), ...(getEpayErrors ?? []), ...(deleteErrors ?? [])];
      errorMessages?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
      if (deleteStatus === 'error') {
        showOpenModal(false);
        setDeletePayload({});
      }
    }
  }, [paymentMethodStatus, executeframeDataStatus, deleteStatus]);

  useEffect(() => {
    if (deleteResults) {
      executeEpay().then();
      showOpenModal(false);
      addSnackbarMessage({
        message: deleteResults.message,
        type: 'success',
      });
      setDeletePayload({});
    }
  }, [deleteResults]);

  useEffect(() => {
    // call on page load
    if (!hideBilling && userFirm?.firmId) executeEpay().then();
  }, [userFirm?.firmId, hideBilling]);

  const closeDrawer = () => {
    setAch(false);
    setselectedPaymentMode(paymentMode[0]);
    setDrawer(false);
    setEditPayload({});
  };

  const handlePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    const paymentMethod = paymentMode?.find((option) => option.value === e.target.value) ?? selectedPaymentMode;
    setAch(paymentMethod.value === `ACH`);
    setselectedPaymentMode(paymentMethod);
    setEditPayload((prev: any) => ({ ...prev, isAch: paymentMethod.value === `ACH` }));
  };

  useEffect(() => {
    if (openDrawer) {
      executeframeData().then();
    }
  }, [openDrawer, isAch]);

  const processApiResponse = (data: any) => {
    if (data?.account) {
      executeEpay().then();
      closeDrawer();
      addSnackbarMessage({
        message: `Successfully saved Payment Method`,
        type: 'success',
      });
    }
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event?.data) {
        processApiResponse(event?.data);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  if (hideBilling || !userFirm?.firmId) {
    return null;
  }

  return (
    <ContentContainer>
      <AgentNetDivider
        typoVariant="h1"
        title="Payment Methods"
        {...((paymentMethodDetails?.accounts ?? []).length > 0 && {
          onClickPrimary: handleOpenDrawer,
          primaryButtonName: 'Add New Payment Method',
        })}
      />
      <div style={{ padding: '0 24px' }}>
        {paymentMethodStatus === 'pending' && <LoadingSpinner variant="circle" status={'pending'} />}
        {paymentMethodStatus === 'success' && (
          <Grid container spacing={2} className={classes.responsiveGrid}>
            <PaymentCards
              paymentMethodData={paymentMethodDetails?.accounts ?? []}
              handleOpenDrawer={handleOpenDrawer}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </Grid>
        )}
      </div>

      <FormDrawerComponent
        title={editPayload?.accountAliasId ? 'Edit Payment Method' : 'Add New Payment Method'}
        width={386}
        open={openDrawer}
        disableEnforceFocus
        crossQAPrefix="PaymentMethodDrawer"
        isPaddingRightZero
        showDrawerActions={false}
        onDismissAction={closeDrawer}
      >
        {executeframeDataStatus === 'pending' && <LoadingSpinner variant="circle" status={'pending'} />}
        {executeframeDataStatus === 'success' && (
          <>
            <Grid item sm={12} className={classes.heading}>
              <AgentNetDivider typoVariant="h3" title={'Payment Method'} disablePadding />
            </Grid>
            <Grid item sm={12}>
              <RadioGroup
                selected={selectedPaymentMode}
                options={paymentMode}
                onChange={handlePaymentMethod}
                disabled={!!editPayload?.accountAliasId}
              />
            </Grid>
            <Grid item sm={12}>
              <iframe
                src={`${frameDataResults.sessionTransferUrl}`}
                className={classes.iframe}
                scrolling="no"
                height="800px"
                width="370px"
              ></iframe>
            </Grid>
          </>
        )}
      </FormDrawerComponent>
      <AgentNetConfirmationDialog
        onConfirm={() => {
          executeDelete().then();
        }}
        open={openDeleteModal || false}
        disabled={deleteStatus === 'pending'}
        disableCrossIconClick={deleteStatus === 'pending'}
        onDismissAction={() => {
          setDeletePayload({});
          showOpenModal(false);
        }}
        onExitButtonAction={() => {
          setDeletePayload({});
          showOpenModal(false);
        }}
        dialogTitle="Delete Payment Method"
        dialogBtnContent="Yes, Continue"
        secondaryActionBtnContent="Cancel"
        dialogText="Are you sure you want to delete this payment method?"
        disableBackDropClick
        qaAttrPrefix="DeleteConfirmation"
      />
    </ContentContainer>
  );
};

export default Billing;
