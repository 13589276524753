/* eslint-disable react/jsx-key */
import React, { ElementType } from 'react';
import clsx from 'clsx';
import { createStyles, ListItem, ListItemProps, makeStyles, ListItemText, Theme } from '@material-ui/core';

export type L3NavItemProps = ListItemProps<ElementType, { component?: ElementType }>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.75, 2, 0.75, 3),
      borderLeftWidth: 2.3,
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.type === 'light' ? theme.palette.secondary[300] : theme.palette.divider,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        minWidth: 250,
      },
      '& *': {
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
          wordBreak: 'break-word',
        },
      },
      '&.active': {
        borderLeftColor: theme.palette.primary.main,
        '& *': {
          fontWeight: theme.typography.fontWeightBold,
        },
        '&.nested': {
          '& *': {
            color: theme.palette.primary.main,
            transition: 'color ' + theme.transitions.duration.short + 'ms ' + theme.transitions.easing.easeInOut,
          },
        },
      },
      '&.nested': {
        borderLeft: 'none',
        paddingLeft: theme.spacing(5),
      },
    },
  }),
);

const L3NavItem = ({ active, className, children, nested, ...props }: L3NavItemProps): JSX.Element => {
  const classes = useStyles();
  return (
    <ListItem
      {...(props as ElementType)}
      disableGutters={true}
      className={clsx(classes.root, className, active && 'active', nested && 'nested')}
    >
      <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{children}</ListItemText>
    </ListItem>
  );
};

export default L3NavItem;
