import React, { useContext, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import './ServiceOrdersContainer.scss';
import { useAuth } from '@agentnet/auth';
import { getDecryptedFileId, getServiceOrder } from '../../api/serviceorder-api';
import { Order, ServiceOrderRequest } from './type';
import NoResults from 'ui-kit/components/noResults/NoResults';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useAsync from 'hooks/useAsync';
import ServiceOrderFC from './ServiceOrder';
import { createNewServiceOrder, noServiceOrderSupport, openFile } from './constants';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { useParams } from 'react-router-dom';
import { getFileInfoProps } from 'api/file/file-api';
import { useViewState } from 'hooks/ViewStateContext';
const ServiceOrdersContainer = (): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
    }),
  );

  const classes = useStyles();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { fileData, loadFileData } = fileDataCtx;
  const { userFirm } = profileCtx;
  const { fileId } = useParams<getFileInfoProps>();
  const encryptedFileId = fileId;
  const { routerParamValue, setRouterParamValue } = useViewState();
  useEffect(() => {
    setRouterParamValue(fileId || '');
  }, [routerParamValue, setRouterParamValue, fileId]);
  const associatedOffice = userFirm?.offices?.find((office) => office.officeId === fileData?.officeId);
  const associatedAccount = associatedOffice?.accounts?.find((account) => account.accountId === fileData?.accountId);
  const isFileActive =
    userFirm?.status?.toLowerCase() === 'active' &&
    associatedOffice?.status?.toLowerCase() === 'active' &&
    associatedAccount?.status?.toLowerCase() === 'active';

  const { getAccessToken } = useAuth();
  const [showServiceOrder, setShowServiceOrder] = useState(false);
  const [isAddNewServiceOrder, setIsAddNewServiceOrder] = useState(false);
  const [showServiceOrderList, setShowServiceOrderList] = useState(false);

  const handleNewServiceOrder = () => {
    setShowServiceOrderList(false);
    setShowServiceOrder(true);
    setIsAddNewServiceOrder(true);
  };

  const hideCreateServiceOrder = () => {
    if (getServiceOrderValue && getServiceOrderValue.length > 0) {
      setShowServiceOrderList(true);
    }
    setShowServiceOrder(false);
    setIsAddNewServiceOrder(false);
  };

  const refreshServiceOrderData = () => {
    console.log('refresh triggered');
    executeGetServiceOrders().then();
    hideCreateServiceOrder();
  };

  const fetchServiceOrder = async (): Promise<Order[]> => {
    const token = await getAccessToken();
    const payload: ServiceOrderRequest = {
      fileId: fileData?.fileId,
    };
    return getServiceOrder(payload, token);
  };

  const {
    execute: executeGetServiceOrders,
    value: getServiceOrderValue,
    status: getServiceOrderStatus,
  } = useAsync<Order[]>(fetchServiceOrder, false);

  const fetchDecryptedFileId = async () => {
    const token = await getAccessToken();
    return getDecryptedFileId(fileData?.fileId ?? '', token);
  };

  const {
    execute: executeGetDecrypteFileId,
    value: getDecryptedFileIdValue,
    status: getDecryptedFileIdStatus,
    errors: getDecryptedFileIdError,
  } = useAsync<number>(fetchDecryptedFileId, false);

  useEffect(() => {
    if (!fileData?.fileId) loadFileData && loadFileData({ fileId: encryptedFileId });
    if (!getServiceOrderValue) executeGetServiceOrders().then();
    if (!getDecryptedFileIdValue) executeGetDecrypteFileId().then();
  }, [fileData?.fileId]);

  useEffect(() => {
    if (getServiceOrderValue && getServiceOrderValue.length > 0) {
      setShowServiceOrderList(true);
      setShowServiceOrder(false);
    } else if (
      getServiceOrderValue?.length == 0 &&
      getServiceOrderStatus !== 'idle' &&
      fileData?.fileStatus == openFile
    ) {
      handleNewServiceOrder();
    }
  }, [getServiceOrderValue]);

  const showNoResult =
    getServiceOrderStatus !== 'pending' &&
    getServiceOrderStatus !== 'idle' &&
    !showServiceOrder &&
    !showServiceOrderList;

  return (
    <>
      <div className={classes.root}>
        <div className="paper-layout contained">
          <div
            className={
              getServiceOrderStatus === 'pending' || getServiceOrderStatus === 'idle' ? 'loading-container' : ''
            }
          >
            <LoadingSpinner status={getServiceOrderStatus} size={30} />
          </div>
          {showServiceOrderList && getServiceOrderStatus !== 'pending' && (
            <ServiceOrderFC
              serviceOrderList={getServiceOrderValue ?? []}
              fileData={fileData}
              decryptedFileId={getDecryptedFileIdValue}
              isAddNewServiceOrderClicked={isAddNewServiceOrder}
              OnCancel={hideCreateServiceOrder}
              handleAddNewServiceOrder={handleNewServiceOrder}
              refreshServiceOrderData={refreshServiceOrderData}
            />
          )}
          {showServiceOrder && (
            <ServiceOrderFC
              serviceOrderList={getServiceOrderValue ?? []}
              isAddNewServiceOrderClicked={isAddNewServiceOrder}
              OnCancel={hideCreateServiceOrder}
              refreshServiceOrderData={refreshServiceOrderData}
              fileData={fileData}
              decryptedFileId={getDecryptedFileIdValue}
            />
          )}
          {fileData?.fileStatus != openFile && getServiceOrderValue?.length == 0 && getServiceOrderStatus !== 'idle' && (
            <div className="serviceOrdersWrap">
              <NoResults
                showButton={isFileActive && fileData?.fileStatus === openFile}
                type="results"
                titleText="No Service Orders Found"
                pText={isFileActive && fileData?.fileStatus != openFile ? noServiceOrderSupport : createNewServiceOrder}
                buttonText="Add New Service Order"
                dataQA="AddNewServiceOrder"
                onClick={handleNewServiceOrder}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceOrdersContainer;
