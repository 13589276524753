import { fileInfoType } from 'api/file/interfaces/get-file';
//import { iNameOption } from 'api/jackets/interfaces';
import { mortgageClause, MortgageClauseTextOptions } from './parties/form/MortgageClause';
import { IGetLenderResponse } from './parties/interfaces/CreateLenderRequest';
import { ILenderFirm } from './parties/interfaces/LenderModels';

/** County is defined as the county of the first property in the file's properties array. */
export function getPropertyCounty(fileData: fileInfoType): string {
  if (fileData.properties && fileData.properties.length > 0) return fileData.properties[0].county || '';
  return '';
}

// export function getLenderNames(fileData: fileInfoType): iNameOption[] {
//   if (!fileData.lenders) return [];
//   return fileData.lenders.map((lender: IGetLenderResponse) => {
//     return { name: lender.name };
//   });
// }

interface iLenderOption {
  name: string;
  value: number;
}

/** Does given lender id exist in the list of lender options? */
export function lenderExistsInList(lenderId: number, lenderOptions: iLenderOption[]): boolean {
  const matched = lenderOptions.find((l) => l.value === lenderId);
  return !!matched;
}

export function updateLenderWithFirm(
  lender: IGetLenderResponse | undefined,
  lenderFirm: ILenderFirm | undefined,
): IGetLenderResponse {
  if (lender && lenderFirm) {
    const {
      AddressBookEntryId,
      LenderAttentionName,
      LenderClauseType,
      LenderClauseText,
      PhoneNumber,
      EmailAddress,
      Address,
      Name,
    } = lenderFirm || {};
    const { Address1, Address2, City, County, PostalCode, State } = Address || {};
    return {
      ...lender,
      lenderAddressBookId: AddressBookEntryId,
      attention: LenderAttentionName || '',
      lenderClauseType: LenderClauseType || '',
      lenderClauseText: LenderClauseText || '',
      phoneNumber: PhoneNumber || '',
      emailAddress: EmailAddress || '',
      address: {
        address1: Address1 || '',
        address2: Address2 || '',
        city: City || '',
        county: County || '',
        postalCode: PostalCode || '',
        state: State || '',
      },
      name: Name || '',
      lenderId: lender.lenderId,
    };
  } else return { ...lender };
}

export function getResetLender(lender: IGetLenderResponse, isCreateForm: boolean): IGetLenderResponse {
  return {
    ...lender,
    name: '',
    attention: '',
    loanNumber: '',
    loanAmount: 0,
    lenderClauseType: isCreateForm ? mortgageClause[0].name : '',
    lenderClauseText: isCreateForm ? MortgageClauseTextOptions[mortgageClause[0].name].value : '',
    emailAddress: '',
    phoneNumber: '',
    lenderTypeOptions: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      postalCode: '',
      state: '',
    },
  };
}

export function isPropertyReportExceptionState(state: string): boolean {
  const exceptionStates: Array<string> = ['ID', 'MT', 'UT'];
  return exceptionStates.some((s) => s === state);
}
