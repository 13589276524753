import { handleBusinessError, handleError } from 'utilities/utilities';
import { getAgencyApiHost } from 'api/host-by-env';

export async function getDocuments(
  fileId: number | null,
  token: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getAgencyApiHost()}file-service/v1/files/${fileId}/documents`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleBusinessError(json);
  return json.Result;
}

export async function getDocumentBytes(
  fileId: number | null,
  documentId: number,
  token: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getAgencyApiHost()}file-service/v1/files/${fileId}/document/${documentId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleBusinessError(json);
  return json.Result;
}

export async function addDocuments(payload: any, token: string): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getAgencyApiHost()}file-service/v1/files/documents`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}

export async function deleteDocument(
  fileId: number | null,
  documentId: number,
  token: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getAgencyApiHost()}file-service/v1/files/${fileId}/document/${documentId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleBusinessError(json);
  return json.Result;
}
