import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DateFieldString from '../../../ui-kit/inputs/DateField/DateFieldString';
import { format as formatDate } from 'date-fns';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import AgentNetDropdownSelector from 'ui-kit/inputs/AgentNetDropdownSelector';
import { AgentNetCheckBox, CurrencyField } from 'ui-kit/inputs';
import Endorsements from 'features/jackets/Endorsements';
import { Endorsement } from 'features/jackets/types';
import CPLCalcTable from './CPLCalcTable';
import QuestionItem from 'ui-kit/components/tables/AdditionalQuestionItem';
import Policy from './Policy';
import { StatCodePdfWindowName, WindowFeatures } from 'features/jackets/constants';
import NewMexicoStatCodePdf from '../../../static/Stat_Code_New_Mexico_State.pdf';
import NewYorkStatCodePdf from '../../../static/Stat_Code_New_York_State.pdf';
import TexasStatCodePdf from '../../../static/Stat_Code_Texas_State.pdf';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { CalcCriteriaStandaloneSchema } from 'utilities/validation/schemas/jacket-schema';
import { useAuth } from '@agentnet/auth';
import { getEndorsementsByPolicyDate } from 'api/jacket-api';

interface CalcCriteriaTabProps {
  calcCriteriaData: any;
  updateCalcCriteriaData: any;
  endorsementsList: Endorsement[];
  fileLevelValidationError: any;
  disableFields?: boolean;
  savedJacketData: any;
  updateJacketEndorsement: (
    jacketId: string | null,
    randomId: string | null,
    rflId: number | null,
    name: string | null,
    amount: number | null,
    reason: string,
    type: string,
    starsNumber: string | null,
    index: number,
  ) => void;
  updateSaEndorsement: (
    jacketId: string | null,
    id: string | null,
    rflId: number | null,
    name: string | null,
    amount: number | null,
    reason: string,
    type: string,
    starsNumber: string | null,
  ) => void;
  filterStatCode: (statCode: any, rateType: any) => any;
  additionalQuestionRef: any;
  validationErrors: FieldValidationError[];
  shouldShowValidation: boolean;
  setFormValid: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '16px 35px 0 8px',
    },
    heading: {
      marginBottom: '16px',
      marginTop: '8px',
    },
    marginZero: {
      margin: 0,
    },
    paddingTopZero: {
      paddingTop: '0px !important',
    },
    endorsement: {
      padding: '16px 8px 8px 8px',
    },
  }),
);

const CalcCriteriaTab = ({
  endorsementsList,
  calcCriteriaData,
  updateCalcCriteriaData,
  updateJacketEndorsement,
  updateSaEndorsement,
  filterStatCode,
  additionalQuestionRef,
  shouldShowValidation,
  validationErrors,
  setFormValid,
  fileLevelValidationError,
  savedJacketData,
  disableFields = false,
}: CalcCriteriaTabProps): JSX.Element => {
  const classes = useStyles();
  const { getAccessToken } = useAuth();

  const [endorsementType, setEndorsementType] = useState<Endorsement[]>([]);
  const [seValidationError, setSEValidationError] = useState<FieldValidationError[]>([]);
  const [cplValidationError, setCplValidationError] = useState<FieldValidationError[]>([]);
  const [rateEffectiveDate, setRateEffectiveDate] = useState<string>('');
  const [loadEndorsement, setLoadEndorsement] = useState<boolean>();
  const statCodeState = ['TX', 'NM', 'NY'];
  const statCodeOptionList = (index: number) => {
    const statCode = calcCriteriaData.jacketRates[index].fields.filter((x: any) => x.name === 'StatCode')[0];
    const rateType = calcCriteriaData.jacketRates[index].fields.filter((x: any) => x.name === 'RateType')[0];
    const { filteredOption } = filterStatCode(statCode, rateType);
    return filteredOption;
  };

  const openStatCodePDF = () => {
    switch (calcCriteriaData.propertyState) {
      case 'NM':
        return window.open(NewMexicoStatCodePdf, StatCodePdfWindowName, WindowFeatures);
      case 'NY':
        return window.open(NewYorkStatCodePdf, StatCodePdfWindowName, WindowFeatures);
      case 'TX':
        return window.open(TexasStatCodePdf, StatCodePdfWindowName, WindowFeatures);
    }
  };

  const fetchEndorsements = async (stateCode?: string, underwriterCode?: string, effectiveDate?: string) => {
    const token = await getAccessToken();
    const endorsements = await getEndorsementsByPolicyDate(
      stateCode ?? '',
      underwriterCode ?? '',
      effectiveDate ?? '',
      token,
    );
    const miscEndorsement: Endorsement = {
      endorsementName: 'Miscellaneous Endorsement',
      endorsementFormNumber: 'Miscellaneous Endorsement',
      policyCategories: [],
      policyCoverages: [],
    };
    const allEndorsements = [...endorsements, miscEndorsement];
    setEndorsementType(allEndorsements);
  };

  useEffect(() => {
    const errs = [
      ...validationErrors.flat(),
      ...seValidationError.flat(),
      ...cplValidationError.flat(),
      ...fileLevelValidationError.flat(),
    ];
    setFormValid(errs.length === 0);
  }, [validationErrors, seValidationError, cplValidationError, fileLevelValidationError]);

  const renderFieldComponent = (
    field: any,
    type: string,
    validationError?: any,
    index?: any,
    subIndex?: any,
    filterRateTypeByExtended?: boolean,
  ) => {
    switch (field.dataType) {
      case 'date':
        return (
          <Grid item sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}>
            <DateFieldString
              label={field.label}
              name={field.name}
              qaAttribute={'CalcCriteria' + field.name}
              maxDate="12/31/9999"
              value={(() => {
                const dateParsed = Date.parse(field.value || '');
                return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : field.value;
              })()}
              onChange={(val: any) => {
                updateCalcCriteriaData(type, field.name, val, index, subIndex);
              }}
              disabled={field.isDisabled || disableFields}
              required={field.isRequired}
              errs={validationError}
              showValidation={shouldShowValidation}
            />
          </Grid>
        );
      case 'select': {
        const options = () => {
          const currentOption =
            field.name === 'StatCode'
              ? statCodeOptionList(index)
              : field?.options?.map((x: any, i: any) => {
                  return { ...x, name: x.text, value: x.value };
                });

          if (field.name === 'RateType') {
            return currentOption.filter((rate: any) => rate.isExtendedCoverage === filterRateTypeByExtended);
          }

          return currentOption;
        };

        return (
          <Grid item sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}>
            <AgentNetDropdownSelector
              label={field.label}
              name={field.name}
              qaAttribute={'CalcCriteria' + field.name}
              options={options()}
              pdfIcon={field.name === 'StatCode'}
              pdfLabel={'Stat Codes'}
              onPdfClick={openStatCodePDF}
              value={field.value}
              menuOption={(val: string) => {
                updateCalcCriteriaData(type, field.name, val, index, subIndex);
              }}
              dropdowntype="outlined"
              disabled={field.isDisabled || disableFields}
              required={field.isRequired}
              errs={validationError}
              showValidation={shouldShowValidation}
            />
          </Grid>
        );
      }
      case 'currency':
        return (
          <Grid item sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}>
            <CurrencyField
              variant="outlined"
              fullWidth
              label={field.label}
              allowedBlank
              name={field.name}
              id={field.name}
              Data-QA={'CalcCriteria' + field.name}
              value={field.value ?? ''}
              max={100000000000}
              allowNegative={false}
              min={0}
              onChange={(e) => {
                updateCalcCriteriaData(
                  type,
                  field.name,
                  e.target.value === '' ? '' : (e.target.value as unknown as number).toFixed(2),
                  index,
                  subIndex,
                );
              }}
              disabled={field.isDisabled || disableFields}
              required={field.isRequired}
              errs={validationError}
              alwaysDisplayError={shouldShowValidation}
              showValidationOnBlur={shouldShowValidation}
            />
          </Grid>
        );
      case 'checkbox':
        return (
          <Grid
            item
            className={classes.paddingTopZero}
            sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}
          >
            <AgentNetCheckBox
              label={field.label}
              name={field.name}
              Data-QA={'CalcCriteria' + field.name}
              value={field.isChecked}
              checkHandler={(val: any) => {
                updateCalcCriteriaData(type, field.name, val, index, subIndex, true);
              }}
              disabled={field.isDisabled || disableFields}
              required={field.isRequired}
            />
          </Grid>
        );
      default:
        return;
    }
  };

  const isReissueRateType = (index: any) => {
    const rateType = calcCriteriaData.jacketRates[index].fields.filter((item: any) => item.name === 'RateType')[0];
    const currentOption = rateType.options.filter((item: any) => item.value === rateType.value)[0];
    const savedRateType = savedJacketData?.[index]?.fields?.filter((item: any) => item.name === 'RateType')?.[0] ?? '';
    const savedOption = savedRateType
      ? savedRateType.options.filter((item: any) => item.value === savedRateType.value)[0]
      : '';

    if (savedOption?.text?.toLowerCase()?.includes('reissue')) {
      return false;
    }
    return currentOption?.text?.toLowerCase()?.includes('reissue') ?? false;
  };

  const callbacks = {
    updateCalcCriteriaData,
    renderFieldComponent,
    updateJacketEndorsement,
    openStatCodePDF,
    additionalQuestionRef,
  };

  const validateStandAloneEndorsement = async (data: any) => {
    const errs: FieldValidationError[] = (await doValidate(data, CalcCriteriaStandaloneSchema)) || [];
    setSEValidationError(errs);
  };

  useEffect(() => {
    if (
      calcCriteriaData?.standAloneEndorsementRates?.endorsements ||
      calcCriteriaData?.standAloneEndorsementRates?.questions
    ) {
      validateStandAloneEndorsement(calcCriteriaData?.standAloneEndorsementRates);
    }
  }, [calcCriteriaData?.standAloneEndorsementRates]);

  useEffect(() => {
    if (calcCriteriaData.fields) {
      calcCriteriaData.fields.forEach((field: any) => {
        if (field.name === 'RateEffectiveDate') {
          setRateEffectiveDate(field.value ?? '');
        }
      });
      setLoadEndorsement(true);
    }
  }, [calcCriteriaData.fields]);

  useEffect(() => {
    if (loadEndorsement)
      fetchEndorsements(calcCriteriaData.propertyState, calcCriteriaData.underwriterCode, rateEffectiveDate);
  }, [rateEffectiveDate, loadEndorsement]);

  return (
    <Grid className={classes.root}>
      <Grid container spacing={2}>
        {calcCriteriaData?.fields?.length > 0 && (
          <Grid item sm={12} className={classes.heading}>
            <AgentNetDivider typoVariant="h2" title={'File Level Settings'} disablePadding />
          </Grid>
        )}
        {calcCriteriaData?.fields?.length > 0 &&
          calcCriteriaData?.fields?.map((field: any) => (
            <>{renderFieldComponent(field, 'updateFileLevelSettings', fileLevelValidationError)}</>
          ))}

        {calcCriteriaData?.jacketRates?.length > 0 &&
          calcCriteriaData?.jacketRates.map((item: any, index: number) => {
            return (
              <Policy
                key={`${item.policyNumber}-${index}`}
                index={index}
                item={item}
                validationErrors={validationErrors[index] ?? []}
                shouldShowValidation={shouldShowValidation}
                callbacks={callbacks}
                isReissue={isReissueRateType(index)}
                endorsementsList={endorsementType}
                propertyState={calcCriteriaData.propertyState}
                disableFields={disableFields}
                rateEffectiveDate={rateEffectiveDate}
                underwriterCode={calcCriteriaData.underwriterCode}
              />
            );
          })}

        {calcCriteriaData?.standAloneEndorsementRates?.endorsements && (
          <>
            <Grid item sm={12} className={classes.heading}>
              <AgentNetDivider typoVariant="h3" title={'Standalone Endorsements'} disablePadding />
            </Grid>
            <Grid container className={classes.endorsement}>
              <Endorsements
                isEditDisabled={disableFields}
                onChangeEndorsement={(...args) => updateSaEndorsement(...args)}
                endorsements={calcCriteriaData?.standAloneEndorsementRates?.endorsements}
                endorsementOptions={endorsementType}
                validationErrors={seValidationError}
                openStatCodePDF={openStatCodePDF}
                shouldDisableScroll
                shouldShowValidation={shouldShowValidation}
                showStatCode={statCodeState.includes(calcCriteriaData?.propertyState)}
              />
            </Grid>
          </>
        )}
        {calcCriteriaData?.standAloneEndorsementRates?.questions?.length > 0 && (
          <Grid container spacing={2} className={classes.marginZero}>
            <Grid item sm={12} className={classes.heading}>
              <AgentNetDivider typoVariant="h3" title={'Additional Questions'} disablePadding />
            </Grid>
            <Grid item sm={12}>
              {calcCriteriaData?.standAloneEndorsementRates?.questions.map((question: any, index: number) => (
                <QuestionItem
                  {...question}
                  additionalQuestionRef={additionalQuestionRef}
                  validationErrors={seValidationError}
                  isDisabled={disableFields || question?.isDisabled}
                  shouldShowValidation={shouldShowValidation}
                  key={question.randomId}
                  onChange={(val: any) => {
                    updateCalcCriteriaData('updateStandAloneQuestions', 'standAloneEndorsementRates', val, index);
                  }}
                />
              ))}
            </Grid>
          </Grid>
        )}
        {calcCriteriaData?.cplRates?.length > 0 && (
          <Grid container spacing={2} className={classes.marginZero}>
            <Grid item sm={12} className={classes.heading}>
              <AgentNetDivider typoVariant="h2" title={'Closing Protection Letters'} disablePadding />
            </Grid>
            <CPLCalcTable
              letters={calcCriteriaData?.cplRates ?? []}
              propertyState={calcCriteriaData?.propertyState}
              updateCalcCriteriaData={updateCalcCriteriaData}
              cplValidationError={cplValidationError}
              setCplValidationError={setCplValidationError}
              shouldShowValidation={shouldShowValidation}
              disableFields={disableFields}
            />
            {calcCriteriaData.propertyState === 'OH' &&
              calcCriteriaData?.cplRates.flatMap((element: any) => element.questions).length > 0 && (
                <Grid item sm={12} className={classes.heading}>
                  <AgentNetDivider typoVariant="h3" title={'Additional Questions'} disablePadding />
                </Grid>
              )}
            <Grid item sm={12}>
              {calcCriteriaData?.propertyState === 'OH' &&
                calcCriteriaData?.cplRates.map((element: any, index: number) =>
                  (element?.questions ?? []).map((question: any, subindex: number) => (
                    <QuestionItem
                      {...question}
                      isDisabled={disableFields || question?.isDisabled}
                      additionalQuestionRef={additionalQuestionRef}
                      key={question.randomId}
                      onChange={(val: any) => {
                        updateCalcCriteriaData('updateQuestions', 'cplRates', val, index, subindex);
                      }}
                      validationErrors={cplValidationError[index] ?? []}
                      shouldShowValidation={shouldShowValidation}
                    />
                  )),
                )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default CalcCriteriaTab;
