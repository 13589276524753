import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function ManageSearchIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <path
        fill="#0074CA"
        d="M4.833 2.5H.666V.833h4.167V2.5zm0 2.5H.666v1.667h4.167V5zm11.325 5.833l-3.192-3.191a4.197 4.197 0 01-2.3.691 4.168 4.168 0 01-4.167-4.166C6.5 1.867 8.366 0 10.666 0s4.167 1.867 4.167 4.167c0 .85-.259 1.633-.692 2.291l3.192 3.2-1.175 1.175zm-2.992-6.666c0-1.375-1.125-2.5-2.5-2.5a2.507 2.507 0 00-2.5 2.5c0 1.375 1.125 2.5 2.5 2.5s2.5-1.125 2.5-2.5zm-12.5 6.666h8.333V9.167H.666v1.666z"
      ></path>
    </SvgIcon>
  );
}

export default ManageSearchIcon;
