import React from 'react';
import { ICreateFileResponse } from 'api/file/interfaces/create-file';
import { IAgentNetBaseFormGroupProps, withBaseFormGroup } from 'utilities/form/components/base-form';
import FileInfoCreateForm from './FileInfoCreateForm';

const FileInfoCreateFormWithBaseFormGroup = (props: IAgentNetBaseFormGroupProps<ICreateFileResponse>): JSX.Element => {
  const formWithBase = withBaseFormGroup<ICreateFileResponse, IAgentNetBaseFormGroupProps<ICreateFileResponse>>(
    FileInfoCreateForm,
    props,
  );
  return <>{formWithBase}</>;
};
export default FileInfoCreateFormWithBaseFormGroup;
