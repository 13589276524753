import React, { useContext, useEffect, useRef, useState } from 'react';
import LetterType from './cpl-edit-components/CPLEditComponentsLetterType';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import CoveredParties from './cpl-edit-components/CoveredParties';
import useAsync from 'hooks/useAsync';
import {
  additionalPartyFields,
  coveredParty,
  cplData,
  cplFormDataType,
  cplList,
  CPLStatusType,
  CreateCPLType,
  partyRequirement,
  partySection,
  savedCPL,
  SecondPartyOptions,
  SecondPartyType,
} from '../types';
import {
  createCPLInfo,
  editCPLInfo,
  getFullListAttorenys,
  getFullListSecondParties,
  saveCPLInfo,
  voidCPL,
} from 'api/cpl-api';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import PartyInformation from './cpl-edit-components/PartyInformation';
import { Box, Container, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { getFileInfoProps } from '../../../api/file/file-api';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { useAuth } from '@agentnet/auth';
import AdditionalParties from './cpl-edit-components/AdditionalParties';
import moment, { MomentInput } from 'moment';
import './CPLEditComponents.scss';
import CPLActions from './cpl-edit-components/CPLActions';
import { AgentNetDuplicateCPLDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import { FieldValidationError } from 'utilities/validation/validation';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { openDocument } from 'utilities/utilities';
import { DocumentType, newWindow, WindowFeatures } from '../../constants';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CPLStatus, MAX_OPEN_OR_PENDING_CPLS, openCplsLimit } from '../cpl-list/constants';
import { Notification } from 'ui-kit/components/notification/Notification';

interface CplEditProps {
  index?: number;
  cplId?: string;
  cplsCount?: number;
  cplListData: cplList | null;
  onCancel: () => void;
  isStarsStatusActive?: boolean;
  noStarsStatusActive?: boolean;
  isEditMode?: boolean;
  isNewCpl?: boolean;
  onViewMode?: () => void;
  setEditedPartyName?: (val: string) => void;
  accordionView?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionView: {
      padding: 0,
      '& .additional-parties': {
        marginBottom: 0,
      },
    },
    warningMsg: { marginBottom: theme.spacing(3) },
  }),
);

const CPLEdit: React.FC<CplEditProps> = ({
  index,
  onCancel,
  cplsCount,
  cplId = '',
  cplListData,
  isStarsStatusActive,
  noStarsStatusActive,
  isEditMode = false,
  isNewCpl = false,
  onViewMode,
  setEditedPartyName,
  accordionView,
}): JSX.Element => {
  const { getAccessToken } = useAuth();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData, loadFileData, activeTab } = fileDataCtx;
  const { fileId } = useParams<getFileInfoProps>();
  if (fileData?.fileStatus !== CPLStatus.open && activeTab !== 'CPL') {
    onCancel();
  }
  const duplicationCPLDialogOptions: RadioProps[] = [
    {
      label: 'Void Existing and Create New',
      value: 'voidExistingAndCreateNew',
      dataQA: 'CPLDuplicateVoidCreateOption',
    },

    { label: 'Create Additional CPL', value: 'createNew', dataQA: 'CPLDuplicateCreateOption' },
  ];

  const { addSnackbarMessage } = useSnackBars();
  const { addGlobalMsg } = useGlobalMessages();

  const classes = useStyles();

  const loadCreateCPLForm = async (): Promise<CreateCPLType> => {
    const token = await getAccessToken();
    return createCPLInfo(fileData, token);
  };
  const editCPLForm = async (): Promise<CreateCPLType> => {
    const token = await getAccessToken();
    return editCPLInfo(cplId, fileData, token);
  };

  const saveCPLForm = async (): Promise<savedCPL> => {
    const token = await getAccessToken();
    return saveCPLInfo(cplFormData.current, token);
  };

  const updateCPLForm = async (): Promise<savedCPL> => {
    const token = await getAccessToken();
    return saveCPLInfo(cplFormData.current, token, 'PUT');
  };
  const {
    execute,
    value: loadCPLValue,
    status: loadingStatus,
    errors: loadCplError,
  } = useAsync<CreateCPLType>(loadCreateCPLForm, false);
  const {
    execute: editFormExecute,
    value: editFormValue,
    status: editFormStatus,
    errors: editCplError,
  } = useAsync<CreateCPLType>(editCPLForm, false);
  const {
    execute: saveCPL,
    value: cplDetails,
    status: saveStatus,
    errors: saveCplError,
  } = useAsync<savedCPL>(saveCPLForm, false);
  const {
    execute: updateCPL,
    value: updateCPLDetails,
    status: updateStatus,
    errors: updateCplError,
  } = useAsync<savedCPL>(updateCPLForm, false);

  const fullListAttorneys = async (): Promise<CreateCPLType> => {
    const token = await getAccessToken();
    return getFullListAttorenys(fileData, token);
  };

  const {
    execute: getAttorneys,
    value: attorneysList,
    status: attorneysStatus,
  } = useAsync<CreateCPLType>(fullListAttorneys, false);

  const fullListTitleParties = async (): Promise<SecondPartyOptions[]> => {
    const token = await getAccessToken();
    return getFullListSecondParties(fileData, token, 'title');
  };

  const fullListEscrowParties = async (): Promise<SecondPartyOptions[]> => {
    const token = await getAccessToken();
    return getFullListSecondParties(fileData, token, 'escrow');
  };

  const {
    execute: getTitleParties,
    value: titleParties,
    status: titlePartiesStatus,
  } = useAsync<SecondPartyOptions[]>(fullListTitleParties, false);

  const {
    execute: getEscrowParties,
    value: escrowParties,
    status: escrowPartiesStatus,
  } = useAsync<SecondPartyOptions[]>(fullListEscrowParties, false);

  const cplActionStatus =
    loadingStatus === 'pending' ||
    saveStatus === 'pending' ||
    updateStatus === 'pending' ||
    editFormStatus === 'pending'
      ? 'pending'
      : 'idle';
  const [coveredParties, setCoveredParties] = useState<coveredParty[]>([]);
  const [remainingCPLs, setRemainingCPLs] = useState<number>(MAX_OPEN_OR_PENDING_CPLS - (cplsCount ?? 0));
  const [partiesSection, setPartiesSection] = useState<partySection>({});
  const [additionalPartiesFields, setAdditionalPartiesFields] = useState<additionalPartyFields>({});
  const [closingDate, setClosingDate] = useState<string | null>(null);
  const [cplIssueDate, setCplIssueDate] = useState<string | null>(moment(new Date()).format('MM/DD/YYYY'));
  const [secondPartyOptions, setSecondPartyOptions] = useState<SecondPartyType>({});
  const [isLenderChecked, setLenderChecked] = useState<boolean | undefined>(false);
  const [isBuyerChecked, setBuyerChecked] = useState<boolean | undefined>(false);
  const [isSellerChecked, setSellerChecked] = useState<boolean | undefined>(false);
  const [showMaxLenders, setShowMaxLenders] = useState<boolean>(true);
  const [value, setValue] = useState<CreateCPLType | null>(null);
  const [isPartyNotSelected, setIsPartyNotSelected] = useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<CPLStatusType>('');
  const [isEditForm, setEditForm] = useState<boolean>(false);
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [showCplError, setCplError] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [isLenderAddressValid, setLenderAddressValid] = useState<boolean>(false);
  const [isBuyerAddressValid, setBuyerAddressValid] = useState<boolean>(false);
  const [dialogOptionSelected, setDialogOptionSelected] = useState<RadioProps>(duplicationCPLDialogOptions[0]);
  const [openDuplicationCPLDialog, setopenDuplicationCPLDialog] = useState<boolean>(false);
  const [isSellerAddressValid, setSellerAddressValid] = useState<boolean>(true);
  const [isCplVoiding, setCplVoiding] = useState<boolean>(false);
  const closingAttorneys = value?.myClosingAttorneys?.length ? value?.myClosingAttorneys : value?.agentClosingAttorneys;
  const attorneyType = value?.myClosingAttorneys?.length
    ? { label: 'My Closing Attorney', key: 'myClosingAttorney' }
    : { label: 'Agent Closing Attorney', key: 'agentClosingAttorney' };
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);

  const cplFormData = useRef<cplFormDataType>({
    status: 'Pending',
    fileId: fileData?.fileId,
    cplId,
    firmId: fileData?.firmId,
    accountId: fileData?.accountId,
    underwriterCode: fileData?.underwriterCode,
    stateCode: fileData?.propertyState,
    coveredParties: coveredParties.filter((party) => party?.checked).map((party) => party?.coveredPartyName),
    maxedFileLender: fileData?.lenders?.length === 4,
    file: fileData,
  });

  const lenderRequirements: partyRequirement = {
    name: isLenderChecked
      ? partiesSection?.lender?.selected?.name?.require
      : partiesSection?.lender?.default?.name?.require,
    address: isLenderChecked
      ? partiesSection?.lender?.selected?.address?.require
      : partiesSection?.lender?.default?.address?.require,
  };
  const buyerRequirements: partyRequirement = {
    name: isBuyerChecked
      ? partiesSection?.buyer?.selected?.name?.require
      : partiesSection?.buyer?.default?.name?.require,
    address: isBuyerChecked
      ? partiesSection?.buyer?.selected?.address?.require
      : partiesSection?.buyer?.default?.address?.require,
    displayName: isBuyerChecked
      ? partiesSection?.buyer?.selected?.name?.display
      : partiesSection?.buyer?.default?.name?.display,
    displayAddress: isBuyerChecked
      ? partiesSection?.buyer?.selected?.address?.display
      : partiesSection?.buyer?.default?.address?.display,
  };

  const sellerRequirements: partyRequirement = {
    name: isSellerChecked
      ? partiesSection?.seller?.selected?.name?.require
      : partiesSection?.seller?.default?.name?.require,
    address: isSellerChecked
      ? partiesSection?.seller?.selected?.address?.require
      : partiesSection?.seller?.default?.address?.require,
    displayName: isSellerChecked
      ? partiesSection?.seller?.selected?.name?.display
      : partiesSection?.seller?.default?.name?.display,
    displayAddress: isSellerChecked
      ? partiesSection?.seller?.selected?.address?.display
      : partiesSection?.seller?.default?.address?.display,
  };

  useEffect(() => {
    cplsCount && setRemainingCPLs(MAX_OPEN_OR_PENDING_CPLS - (cplsCount ?? 0));
  }, [cplsCount]);

  useEffect(() => {
    if (saveStatus === 'success' || updateStatus === 'success') {
      cplDetails?.cpls?.map((cpl) => {
        if (cpl.pdfData) openDocument(cpl.pdfData, DocumentType, newWindow, WindowFeatures);
      });
      updateCPLDetails?.cpls?.map((cpl) => {
        if (cpl.pdfData) openDocument(cpl.pdfData, DocumentType, newWindow, WindowFeatures);
      });
      if (fileId) loadFileData && loadFileData({ fileId });
      onCancel();
      // onViewMode && onViewMode();
    }
    setDisplayError(
      loadingStatus === 'error' || editFormStatus === 'error' || saveStatus === 'error' || updateStatus === 'error',
    );
  }, [saveStatus, updateStatus, loadingStatus, editFormStatus]);

  useEffect(() => {
    const errorMessages = loadCplError?.length
      ? loadCplError
      : editCplError?.length
      ? editCplError
      : saveCplError?.length
      ? saveCplError
      : updateCplError?.length
      ? updateCplError
      : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (!fileData?.fileId && loadFileData) {
      loadFileData && loadFileData({ fileId });
    } else {
      const valueFormatted = fileData?.closingDate
        ? moment(fileData?.closingDate as MomentInput).format('MM/DD/YYYY')
        : null;
      setClosingDate(valueFormatted);
      if (!cplId) {
        execute().then();
      }
      if (cplId) {
        editFormExecute().then();
      }
    }
  }, [fileData?.fileId]);

  useEffect(() => {
    if (loadCPLValue) {
      setCoveredParties(
        loadCPLValue.cplTypes[0]?.coveredParties.map((party) => ({ coveredPartyName: party, checked: false })),
      );
      setPartiesSection(loadCPLValue.cplTypes[0]?.parties);
      setAdditionalPartiesFields(loadCPLValue.cplTypes[0]?.additionalParties);
      setSecondPartyOptions({
        escrowSecondParties: loadCPLValue.cplTypes[0]?.escrowSecondParties,
        titleSecondParties: loadCPLValue.cplTypes[0]?.titleSecondParties,
      });
    }
  }, [loadCPLValue]);

  useEffect(() => {
    if (loadCPLValue) {
      setValue(loadCPLValue);
    }
    if (editFormValue) {
      setValue(editFormValue);
      setEditForm(true);
      if (editFormValue?.cpl) {
        const editedCplType = editFormValue?.cplTypes?.find(
          (cpl) => cpl.letterTypeName === editFormValue?.cpl?.letterType,
        );
        setFormStatus(editFormValue?.cpl.status as CPLStatusType);
        const closingDateFormat = editFormValue?.cpl?.closingDate
          ? moment(editFormValue?.cpl?.closingDate).format('MM/DD/YYYY')
          : null;
        setClosingDate(closingDateFormat);
        const issuedDateFormat = editFormValue?.cpl?.issuedDate
          ? moment(editFormValue?.cpl?.issuedDate).format('MM/DD/YYYY')
          : null;
        setCplIssueDate(issuedDateFormat);
        setSecondPartyOptions({
          escrowSecondParties: editedCplType?.escrowSecondParties,
          titleSecondParties: editedCplType?.titleSecondParties,
        });
        setPartiesSection(editedCplType?.parties ?? {});
        setAdditionalPartiesFields(editedCplType?.additionalParties ?? {});
      }
    }
  }, [editFormValue, loadCPLValue]);

  useEffect(() => {
    if (value) {
      getAttorneys().then();
      getEscrowParties().then();
      getTitleParties().then();
    }
  }, [value]);

  useEffect(() => {
    cplFormData.current = {
      ...cplFormData.current,
      fileId: fileData?.fileId,
      cplId,
      firmId: fileData?.firmId,
      accountId: fileData?.accountId,
      underwriterCode: fileData?.underwriterCode,
      stateCode: fileData?.propertyState,
      coveredParties: coveredParties.filter((party) => party?.checked).map((party) => party?.coveredPartyName),
      maxedFileLender: fileData?.lenders?.length === 4,
      file: fileData,
    };
  }, [fileData, cplId]);

  useEffect(() => {
    cplFormData.current = {
      ...cplFormData.current,
      coveredParties: coveredParties.filter((party) => party?.checked).map((party) => party?.coveredPartyName),
    };
    const isLenderChecked = coveredParties
      .map((ele: coveredParty) => {
        if (ele.coveredPartyName === 'Lender' || ele.coveredPartyName === 'Lender / Borrower(s)') {
          return ele.checked;
        }
      })
      .some((checked) => checked);
    const isBuyerChecked = coveredParties
      .map((ele: coveredParty) => {
        if (
          ele.coveredPartyName === 'Borrower(s) / Buyer(s)' ||
          ele.coveredPartyName === 'Borrower(s)' ||
          ele.coveredPartyName === 'Buyer(s)'
        ) {
          return ele.checked;
        }
      })
      .some((checked) => checked);
    const isSeller = coveredParties.find((ele: coveredParty) => ele.coveredPartyName === 'Seller(s)');
    setLenderChecked(isLenderChecked);
    setBuyerChecked(isBuyerChecked);
    setSellerChecked(isSeller?.checked);
  }, [coveredParties]);

  const handleCoveredParties = (ele: coveredParty, bool: boolean) => {
    const updatedCoveredParties = coveredParties.map((party) => {
      if (party.coveredPartyName === ele.coveredPartyName) {
        return { ...party, checked: bool };
      }
      return party;
    });
    setCoveredParties(updatedCoveredParties);
  };

  const getOpenStatusCplList = () => {
    const openCplList: cplData[] = [];

    if (coveredParties.length) {
      const selectedCoveredParties = coveredParties.filter((party) => party.checked);
      selectedCoveredParties.forEach((party) => {
        const openCplListOneCP =
          cplListData?.cpls.filter((item) => item.status === 'Open' && item.coveredParty === party.coveredPartyName) ??
          [];
        openCplList.push(...openCplListOneCP);
      });
      return openCplList;
    } else {
      return cplListData?.cpls.filter((item) => item.status === 'Open') ?? openCplList;
    }
  };

  const isAllAddressesValid = () => {
    const isAddressValid = [];
    isAddressValid.push(isLenderAddressValid);
    if (buyerRequirements.address) {
      isAddressValid.push(isBuyerAddressValid);
    }
    if (sellerRequirements.address) {
      isAddressValid.push(isSellerAddressValid);
    }
    return isAddressValid.every((bool) => bool);
  };

  const showToaster = React.useCallback((notificationHook: (show: boolean) => void, duration = 6000) => {
    notificationHook(true);
    changeValueWithDelay(notificationHook, duration, false);
  }, []);

  const changeValueWithDelay = React.useCallback(
    (notificationHook: (show: boolean) => void, duration = 6000, value = false) => {
      setTimeout(() => {
        notificationHook(value);
      }, duration);
    },
    [],
  );
  const shouldSaveCpl = (status: 'Open' | 'Pending' | 'Void') => {
    cplFormData.current = {
      ...cplFormData.current,
      ...(cplFormData.current?.issuedDate ? { issuedDate: new Date(cplFormData.current?.issuedDate) } : {}),
      ...(cplFormData.current?.closingDate ? { closingDate: new Date(cplFormData.current?.closingDate) } : {}),
      status,
    };
    if (coveredParties.length < 1 || coveredParties.some((party) => party.checked)) {
      if (status === 'Open') {
        setSubmitAttempted(true);
      }
      setIsPartyNotSelected(false);
      if (validationErrors?.length || !isAllAddressesValid()) {
        showToaster(setCplError);
      } else if (
        status === 'Pending' ||
        ((!validationErrors || validationErrors?.length < 1) && isAllAddressesValid())
      ) {
        if (!isEditForm) {
          if (status !== 'Pending' && getOpenStatusCplList()?.length) {
            setopenDuplicationCPLDialog(true);
          } else {
            saveCPL().then();
          }
        } else {
          updateCPL().then();
        }
      }
    } else {
      showToaster(setIsPartyNotSelected);
    }
  };

  const handleCloseDialog = () => {
    if (saveStatus === 'pending' || isCplVoiding) return;
    setopenDuplicationCPLDialog(false);
    setDialogOptionSelected(duplicationCPLDialogOptions[0]);
  };

  const handleDuplicatedCplActions = async () => {
    switch (dialogOptionSelected.value) {
      case 'createNew': {
        saveCPL().then(() => {
          handleCloseDialog();
        });
        break;
      }
      case 'voidExistingAndCreateNew': {
        const voidMultipeCPLs = async () => {
          setCplVoiding(true);
          const token = await getAccessToken();
          await Promise.all(
            getOpenStatusCplList().map(({ cplId }) => {
              return new Promise((resolve) =>
                voidCPL(fileId ?? '', cplId, token, fileData?.accountId).then((response) => resolve(response)),
              );
            }),
          );
          saveCPL().then(() => {
            setCplVoiding(false);
            handleCloseDialog();
          });
        };
        await voidMultipeCPLs();
        break;
      }
      default:
        handleCloseDialog();
    }
  };

  useEffect(() => {
    if (showMaxLenders && fileData?.lenders?.length === 4) {
      addSnackbarMessage({
        message: `Lender cannot be added to File: Max 4 Lenders`,
        type: 'warning',
        onClose: () => setShowMaxLenders(false),
      });
    }
  }, [showMaxLenders]);

  useEffect(() => {
    isPartyNotSelected &&
      addSnackbarMessage({
        message: `Must Select at least one Covered Party to save`,
        type: 'error',
        onClose: () => setIsPartyNotSelected(false),
      });
  }, [isPartyNotSelected]);

  useEffect(() => {
    if (!isPartyNotSelected && showCplError) {
      showCplError &&
        addSnackbarMessage({
          message: `Create CPL Failed`,
          type: 'error',
          onClose: () => {
            setCplError(false);
          },
        });
    }
  }, [showCplError, isPartyNotSelected]);

  // Will be removing this once lender ID is changed to number type in File API response
  const parsedFileData = fileData?.lenders?.map((lender) => {
    return { ...lender, lenderId: parseInt(lender.lenderId ?? '') };
  });

  const { agentClosingAttorneys, approvedAttorneys, escrowSecondParties, myClosingAttorneys, titleSecondParties } =
    additionalPartiesFields;

  const getFilteredErrors = (errs: FieldValidationError[], fields: string[]) => {
    if (!validationErrors) {
      setValidationErrors(errs);
    } else if (errs) {
      setValidationErrors((prevErrs: any) => {
        return [...prevErrs.filter((err: { field: string }) => !fields.includes(err.field)), ...errs];
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      setValidationErrors((prevErrs: any) => {
        return prevErrs.filter((err: { field: string }) => {
          return !(fields.includes(err.field) && !errs);
        });
      });
    }
  };

  useEffect(() => {
    if (displayError) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [displayError, errMsgs]);

  return (
    <>
      <LoadingSpinner status={cplActionStatus} variant="linear" />
      {!accordionView && (
        <AgentNetDivider
          typoVariant="h1"
          {...(isEditForm
            ? { title: 'CPL', status: formStatus.toLowerCase() as 'open' | 'pending' | 'void' }
            : { title: 'Create CPLs' })}
          id={`CPL${fileData?.propertyState}`}
        />
      )}
      {!accordionView && remainingCPLs <= 0 && (
        <Notification inline severity="warning" className={classes.warningMsg}>
          {openCplsLimit}
        </Notification>
      )}

      <Container
        className={clsx('CPLEditFormContainer', {
          [classes.accordionView]: accordionView === true,
        })}
      >
        <Box pb={3}>
          <Typography variant="body2">
            Please Note: Information shown on this page is for this CPL only and will not affect information stored
            elsewhere in the system.
          </Typography>
        </Box>

        <LetterType
          index={index}
          loadCPLData={value}
          closingDate={closingDate}
          issueDate={cplIssueDate}
          setCoveredParties={(arr) => setCoveredParties(arr)}
          setPartiesSection={(parties) => setPartiesSection(parties)}
          additionalPartiesFields={additionalPartiesFields}
          setAdditionalPartiesFields={(fields) => setAdditionalPartiesFields(fields)}
          setSecondPartyOptions={(options) => setSecondPartyOptions(options)}
          setClosingDate={(dateVal: string | null) => setClosingDate(dateVal)}
          setCplIssueDate={(dateVal: string | null) => setCplIssueDate(dateVal)}
          setCplFormData={(data) =>
            (cplFormData.current = {
              ...cplFormData.current,
              ...data,
            })
          }
          cplFormData={cplFormData.current}
          isStarsStatusActive={isStarsStatusActive || !isEditMode}
          noStarsStatusActive={noStarsStatusActive}
          setValidationErrors={setValidationErrors}
          showValidation={submitAttempted}
          validationErrors={validationErrors}
          getFilteredErrors={getFilteredErrors}
        />
        {coveredParties.length > 1 && (
          <CoveredParties
            index={index}
            remainingCPLs={remainingCPLs}
            setRemainingCPLs={setRemainingCPLs}
            coveredParties={coveredParties}
            handleCoveredParties={handleCoveredParties}
            disabled={isEditForm}
            status={formStatus}
            isStarsStatusActive={isStarsStatusActive || !isEditMode}
            noStarsStatusActive={noStarsStatusActive}
            hideSubtitle={accordionView}
          />
        )}
        <PartyInformation
          index={index}
          fileData={{ ...fileData, lenders: parsedFileData }}
          lenderRequirements={lenderRequirements}
          buyerRequirements={buyerRequirements}
          sellerRequirements={sellerRequirements}
          firmLenders={value?.lenderList}
          value={value}
          setCplFormData={(data, key: string) => (cplFormData.current = { ...cplFormData.current, [key]: data })}
          showValidation={submitAttempted}
          validationErrors={validationErrors}
          setLenderAddressValid={setLenderAddressValid}
          setBuyerAddressValid={setBuyerAddressValid}
          setSellerAddressValid={setSellerAddressValid}
          isStarsStatusActive={isStarsStatusActive || !isEditMode}
          noStarsStatusActive={noStarsStatusActive}
          getFilteredErrors={getFilteredErrors}
          setEditedPartyName={setEditedPartyName}
        />
        {agentClosingAttorneys?.display ||
        approvedAttorneys?.display ||
        escrowSecondParties?.display ||
        myClosingAttorneys?.display ||
        titleSecondParties?.display ? (
          <AdditionalParties
            index={index}
            value={value}
            setValue={setValue}
            status={formStatus}
            approvedAttorneys={value?.approvedAttorneys}
            closingAttorneys={{ Attorneys: closingAttorneys, attorneyType }}
            secondPartyOptions={secondPartyOptions}
            additionalPartiesFields={additionalPartiesFields}
            setCplFormData={(data, key) => (cplFormData.current = { ...cplFormData.current, [key]: data })}
            isStarsStatusActive={isStarsStatusActive || !isEditMode}
            noStarsStatusActive={noStarsStatusActive}
            attorneysList={attorneysList}
            escrowParties={escrowParties}
            titleParties={titleParties}
            showValidation={submitAttempted}
            validationErrors={validationErrors}
            getFilteredErrors={getFilteredErrors}
          />
        ) : null}
      </Container>
      {isEditMode ? (
        <CPLActions
          index={index}
          status={formStatus}
          saveCPL={(status) => shouldSaveCpl(status)}
          isNewCpl={isNewCpl}
          cancelCPL={onCancel}
          onViewMode={onViewMode}
          isSaveDisabled={saveStatus === 'pending' || updateStatus === 'pending' || editFormStatus === 'pending'}
          isStarsStatusActive={isStarsStatusActive || !isEditMode}
          noStarsStatusActive={noStarsStatusActive}
        />
      ) : null}
      <AgentNetDuplicateCPLDialog
        disabled={saveStatus === 'pending' || isCplVoiding}
        onConfirm={handleDuplicatedCplActions}
        open={openDuplicationCPLDialog}
        onDismissAction={handleCloseDialog}
        selectedOptions={dialogOptionSelected}
        options={duplicationCPLDialogOptions}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const optionToSelect =
            duplicationCPLDialogOptions?.find((option) => option.value === e.target.value) ?? dialogOptionSelected;
          setDialogOptionSelected(optionToSelect);
        }}
        dialogTitle="Warning"
        dialogBtnContent="Submit"
        dialogText="One or more CPLs with the same covered party already exist within the file."
      />
    </>
  );
};
export default CPLEdit;
