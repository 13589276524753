/**
 * Override component default props here
 */
import { ComponentsProps } from '@material-ui/core/styles/props';

const props: ComponentsProps = {
  MuiInputBase: {
    autoComplete: 'off', //Disable browser autocomplete on all inputs
  },
  MuiButton: {
    variant: 'contained',
    disableElevation: true,
  },
  MuiCardHeader: {
    titleTypographyProps: {
      variant: 'body1',
    },
    subheaderTypographyProps: {
      variant: 'body2',
    },
  },
  MuiChip: {
    variant: 'default',
    color: 'secondary',
  },
  MuiFormControl: {
    variant: 'standard',
  },
  // MuiGrid: {
  //   spacing: 2,
  // },
  MuiLink: {
    underline: 'always',
  },
  MuiListItemText: {},
  MuiOutlinedInput: {
    notched: false,
  },
  MuiTextField: {
    variant: 'standard',
  },
  MuiSelect: {
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
    },
  },
  MuiTabs: {
    textColor: 'secondary',
    indicatorColor: 'primary',
  },
};

export default props;
