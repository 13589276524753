import React, { ReactNode, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import InvoiceSummaryTable from 'ui-kit/components/payment/InvoiceSummaryTable';
import { orderStatuses } from 'features/Remittance/reportsListConfig';

interface PayDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (isOpen: boolean) => void;
  drawerData: any;
  setDrawerData: any;
  setSelectedRows: any;
  hasPayRight: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertContainer: {
      marginBottom: '63px',
    },
    alertMsg: {
      width: '-webkit-fill-available !important',
      maxWidth: 'unset !important',
      position: 'absolute',
      bottom: '63px',
      left: 0,
      padding: '0 35px',
      zIndex: 3,
    },
  }),
);

const PayDrawer = ({
  openDrawer,
  setOpenDrawer,
  drawerData,
  setDrawerData,
  setSelectedRows,
  hasPayRight,
}: PayDrawerProps): JSX.Element => {
  const classes = useStyles();
  const { orderDetails, payAndInvoiceData, payAndInvoiceStatus } = drawerData ?? {};
  const { orderNumber, orderStatus } = orderDetails ?? {};
  const [isErrorMsgVisible, showErrorMessage] = useState(false);

  const statusArray = [
    orderStatuses['open'],
    orderStatuses['inProcess'],
    orderStatuses['manualPay'],
    orderStatuses['wirePay'],
  ];

  const closeDrawer = () => {
    setOpenDrawer(false);
    setDrawerData({});
    showErrorMessage(false);
  };

  const handleExport = () => {
    setSelectedRows([{ ...orderDetails, initiateExport: true }]);
  };

  const viewInvoiceAndPay = () => {
    if (orderStatus === 'In Process') {
      showErrorMessage(true);
    } else {
      setSelectedRows([{ ...orderDetails, initiateInvoiceAndPay: true }]);
      closeDrawer();
    }
  };

  const actionItems: ReactNode = (
    <AgentNetButton color="primary" data-qa="InvoiceExport" onClick={handleExport} variant="outlined">
      Export
    </AgentNetButton>
  );

  return (
    <FormDrawerComponent
      title={`Order No: ${orderNumber}`}
      open={openDrawer}
      width={960}
      disableEnforceFocus
      primaryActionProps={{
        'data-qa': 'viewInvoiceAndPay',
      }}
      dismissActionProps={{
        'data-qa': 'viewInvoiceAndPayCancel',
      }}
      hidePrimaryAction={!hasPayRight && statusArray.includes(orderStatus ?? 'Open')}
      crossQAPrefix="RatesAndFees"
      {...(orderStatus && {
        primaryActionLabel: statusArray.includes(orderStatus) ? 'View Invoice & Pay' : 'View Summary',
      })}
      disabled={orderDetails?.isReopened}
      isPaddingRightZero
      actionItems={actionItems}
      onPrimaryAction={viewInvoiceAndPay}
      onDismissAction={closeDrawer}
    >
      {payAndInvoiceStatus === 'loading' && (
        <div>
          <LoadingSpinner variant="circle" status={'pending'} />
        </div>
      )}
      {payAndInvoiceStatus === 'success' && (
        <InvoiceSummaryTable
          orderDate={payAndInvoiceData?.orders?.[0]?.orderDate}
          underwriter={payAndInvoiceData?.orders?.[0]?.invoices?.[0]?.underwriter}
          orderNumber={payAndInvoiceData?.orders?.[0]?.orderNumber}
          invoices={payAndInvoiceData?.orders?.[0]?.invoices}
          isTitleRequired={false}
          modalView
          showInvoiceNo
        />
      )}
      {isErrorMsgVisible && (
        <>
          <div className={classes.alertContainer}></div>
          <Alert className={classes.alertMsg} severity={'error'}>
            Orders can not be paid while Order status is In Process
          </Alert>
        </>
      )}
      {orderDetails?.isReopened && (
        <>
          <div className={classes.alertContainer}></div>
          <Alert className={classes.alertMsg} severity={'warning'}>
            Resubmitted files will not be eligible for payment until an invoice is generated, to ensure proper credits
            are applied.
          </Alert>
        </>
      )}
    </FormDrawerComponent>
  );
};

export default PayDrawer;
