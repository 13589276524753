import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function CalculatorIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="20" height="20" {...props}>
      <path
        fill={props.htmlColor ? props.htmlColor : '#0074CA'}
        d="M15.893 1.667H4.107c-.857 0-1.607.73-1.607 1.562v13.542c0 .834.75 1.563 1.607 1.563h11.786c.857 0 1.607-.73 1.607-1.563V3.23c0-.833-.75-1.562-1.607-1.562zM6.786 15.834c0 .208-.215.416-.429.416H5.071c-.214 0-.428-.208-.428-.416v-1.25c0-.209.214-.417.428-.417h1.286c.214 0 .429.208.429.417v1.25zm0-4.167c0 .208-.215.417-.429.417H5.071c-.214 0-.428-.209-.428-.417v-1.25c0-.208.214-.417.428-.417h1.286c.214 0 .429.209.429.417v1.25zm4.285 4.167c0 .208-.214.416-.428.416H9.357c-.214 0-.428-.208-.428-.416v-1.25c0-.209.214-.417.428-.417h1.286c.214 0 .428.208.428.417v1.25zm0-4.167c0 .208-.214.417-.428.417H9.357c-.214 0-.428-.209-.428-.417v-1.25c0-.208.214-.417.428-.417h1.286c.214 0 .428.209.428.417v1.25zm4.286 4.167c0 .208-.214.416-.428.416h-1.286c-.214 0-.429-.208-.429-.416v-5.417c0-.208.215-.417.429-.417h1.286c.214 0 .428.209.428.417v5.417zm0-8.334c0 .209-.214.417-.428.417H5.07c-.214 0-.428-.208-.428-.417V4.167c0-.208.214-.417.428-.417h9.858c.214 0 .428.209.428.417V7.5z"
      ></path>
    </SvgIcon>
  );
}

export default CalculatorIcon;
