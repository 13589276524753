import createTypography from '@material-ui/core/styles/createTypography';
import palette from '../palette';
import 'fontsource-rubik/300.css';
import 'fontsource-rubik/500.css';
import 'fontsource-rubik/700.css';
import 'fontsource-rubik';

const typography = createTypography(palette, {
  fontFamily: [
    '"Rubik"',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  // Current font weight settings are default to MUI but here for promoted visibility
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    lineHeight: '112px',
    fontWeight: 300,
    letterSpacing: '1.5px',
  },
  h2: {
    fontWeight: 300,
    letterSpacing: '-0.5px',
  },
  h3: {
    letterSpacing: '0px',
  },
  h4: {
    letterSpacing: '0.25px',
  },
  h5: {
    letterSpacing: '0px',
  },
  h6: {
    fontSize: '0.875rem',
    letterSpacing: '0.15px',
    fontWeight: 400,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  subtitle1: {
    letterSpacing: '0.15px',
  },
  subtitle2: {
    letterSpacing: '1px',
  },
  body1: {
    letterSpacing: '0.15px',
  },
  body2: {
    letterSpacing: '0.15px',
  },
  button: {
    letterSpacing: '0.4px',
  },
  caption: {},
  overline: {},
});

export default typography;
