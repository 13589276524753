import { getApiHost } from '../host-by-env';
import { PrismGenerateReport, PrismUserProfile } from './types';

export const createPrismUser = async (payload: PrismUserProfile, token: string) => {
  const url = `${getApiHost()}propertyProfile/createPrismUser`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  const createPrismUserResponse = json.result;
  return createPrismUserResponse;
};

export const generateReport = async (payload: PrismGenerateReport, token: string) => {
  const url = `${getApiHost()}propertyProfile/generateReport`;
  let errorMsg;
  let generateReportResponse: any = {};
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch(() => {
    errorMsg = 'Please try again later or visit Property Reports located in the main menu under Services.';
  });

  if (response) {
    const json = await response.json();
    generateReportResponse = json.result;
  } else {
    generateReportResponse.error = errorMsg;
  }

  return generateReportResponse;
};
