import React, { ReactElement } from 'react';
import { Card, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Topic } from '../../types';
import { Link, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      minHeight: '145px',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(2),
      flex: '1',
      border: `1px solid #D9E0E5`,
      background: theme.palette.background.paper,
      '&:hover': {
        border: '1px solid #0074CA',
      },
    },
    helpLink: {
      textDecoration: 'none',
    },
    icon: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      flexShrink: 0,
      alignItems: 'center',
    },
    cardContent: {
      padding: theme.spacing(0, 0, 0, 2),
      textAlign: 'left',
    },
    gridLayout: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: '100%',
    },
    description: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      lineHeight: '150%',
    },
  }),
);
function TopicTile({ topic }: { topic: Topic }): ReactElement | null {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const slug = url.endsWith('/') ? topic.slug : `/${topic.slug}`;
  const topicClass = topic.topicName.replaceAll(' ', '-').toLowerCase();

  return (
    !!topic && (
      <Grid item xs={3} className={classes.gridLayout}>
        <Link
          to={{ pathname: `${url}${slug}`, state: { topic: topic } }}
          className={clsx(classes.helpLink, topicClass)}
        >
          <Card variant="outlined" className={classes.container}>
            {topic.topicIcon?.url && (
              <div>
                <img src={topic.topicIcon.url} className={classes.icon} />
              </div>
            )}
            <div className={classes.cardContent}>
              <Typography gutterBottom variant="h2" color="primary">
                {topic.topicName}
              </Typography>
              <Typography variant="body1" className={classes.description}>
                {topic.previewDescription}
              </Typography>
            </div>
          </Card>
        </Link>
      </Grid>
    )
  );
}

export { TopicTile };
