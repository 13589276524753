import React, { useEffect, useState } from 'react';
import { TopicTile } from './TopicTile';
import { Topic } from '../../types';
import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import useBrowseByTopicQuery from '../../queries/useBrowseByTopicQuery';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import clsx from 'clsx';
import { ContentfulErrorContext, ContentfulErrorTypes } from 'features/help/hooks/ContentfulErrorContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(375px, max-content))',
      gridGap: '0',
      justifyContent: 'center',
      padding: 'initial',
    },
    mb2: {
      marginBottom: '24px !important',
    },
    container: {
      display: 'flex',
      margin: theme.spacing(4, 0),
      justifyContent: 'center',
    },
    h1: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
      fontSize: '30px',
    },
    gridLayout: {
      padding: theme.spacing(8, 10),
      textAlign: 'center',
      justifyContent: 'center',
    },
    w1400: {
      maxWidth: '1440px',
    },
    loaderContainer: {
      width: '100vw',
      height: '60vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    gridBg: {
      backgroundColor: '#FAFAFA',
      maxWidth: '100%',
      height: '100%',
    },
    flex: {
      display: 'flex',
      justifyContent: 'center',
      gap: '80px',
      paddingTop: theme.spacing(3),
    },
    gap: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '5px',
    },
  }),
);

export default function HomeContainer() {
  const classes = useStyles();
  const [topics, setTopics] = useState<Topic[] | null>();
  const { setContentfulErrorType } = React.useContext(ContentfulErrorContext);

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await useBrowseByTopicQuery();

      if (error) {
        setContentfulErrorType(ContentfulErrorTypes.Error);
      }

      if (!data) {
        setContentfulErrorType(ContentfulErrorTypes.NoContent);
      }

      setTopics(data);
    };
    fetchData();
  }, []);

  return (
    <>
      <Container className={classes.gridBg}>
        {!topics && <LoadingSpinner className={classes.loaderContainer} variant="circle" status={'pending'} />}
        {topics && (
          <Grid container spacing={0} className={classes.gridLayout}>
            <Grid item xs={12} className={classes.w1400}>
              <Typography variant="h1" component="h1" className={clsx(classes.h1, classes.mb2)}>
                Browse by Topic
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.w1400}>
              <Grid container className={classes.gridContainer} spacing={3}>
                {topics?.map((topic: Topic) => (
                  <TopicTile key={topic.topicName} topic={topic} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}
