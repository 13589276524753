import React, { ReactNode, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavPermissions } from 'hooks/useNavPermissions';

interface ContentContainerProps {
  children: ReactNode;
  fullWidth?: boolean;
  mTop?: string;
}

const ContentContainer: FC<ContentContainerProps> = ({ children, fullWidth, mTop }: ContentContainerProps) => {
  const { hasPermission } = useNavPermissions();
  const useStyles = makeStyles((theme) => ({
    contentContainer: {
      flexGrow: 1,
      marginTop: hasPermission.hasFirmId ? (mTop ? mTop : '11.3rem') : '6.4rem',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: fullWidth ? 'none' : 1298,
      background: theme.palette.common.white,
      width: fullWidth ? '100%' : 'auto',
      borderRight: fullWidth ? 'none' : `1px solid ${theme.palette.divider}`,
    },
  }));

  const classes = useStyles();
  return <div className={classes.contentContainer}>{children}</div>;
};

export default ContentContainer;
