import { SelectPartyType } from 'features/files/parties/enums/PartyTypeCategory';
import {
  ICreateBuyerSellerRequest,
  IGetBuyerSellerResponse,
} from '../../features/files/parties/interfaces/CreateBuyerSellerRequest';
import { ICreateLenderRequest, IGetLenderResponse } from '../../features/files/parties/interfaces/CreateLenderRequest';
import { getApiHost } from '../host-by-env';
import { ILenderFirm } from '../../features/files/parties/interfaces/LenderModels';
import { handleError } from 'utilities/utilities';
/* Use Async to Handle Property Details */

/* Use Async POST Request */
export const createPartyType = async (
  payload: ICreateBuyerSellerRequest,
  fileId: string,
  party: SelectPartyType,
  token: string,
): Promise<IGetBuyerSellerResponse> => {
  const url = `${getApiHost()}file/${fileId}/${party}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};

/* Use Async PUT Request */
export const updatePartyType = async (
  payload: ICreateBuyerSellerRequest,
  fileId: string,
  party: SelectPartyType,
  partyId: string,
  token: string,
): Promise<ICreateBuyerSellerRequest> => {
  const url = `${getApiHost()}file/${fileId}/${party}/${partyId}`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};

export const deletPartyType = async (
  fileId: string,
  party: SelectPartyType,
  partyId: string,
  token: string,
): Promise<ICreateBuyerSellerRequest> => {
  const url = `${getApiHost()}file/${fileId}/${party}/${partyId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};

/* Use Async GET, POST, PUT, DELETE Request for Party Type: Lender */
export const getLenderData = async (fileId: string, lenderId: string, token: string): Promise<IGetLenderResponse> => {
  const url = `${getApiHost()}file/${fileId}/lender/${lenderId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  return json.result;
};

export const createLenderType = async (
  payload: ICreateLenderRequest,
  fileId: string,
  token: string,
): Promise<IGetLenderResponse> => {
  const url = `${getApiHost()}file/${fileId}/lender`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result ? json.result : json;
};

export const updateLenderType = async (
  payload: ICreateLenderRequest,
  fileId: string,
  lenderId: string,
  token: string,
): Promise<ICreateLenderRequest> => {
  const url = `${getApiHost()}file/${fileId}/lender/${lenderId}`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};

export const deleteLenderType = async (
  fileId: string,
  lenderId: string,
  token: string,
): Promise<IGetLenderResponse> => {
  const url = `${getApiHost()}file/${fileId}/lender/${lenderId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};

/* Use Async GET, POST, PUT, DELETE Request for UserFirm */
export const getLenderListFromFirmID = async (firmId: string, token: string): Promise<ILenderFirm[]> => {
  const url = `${getApiHost()}firms/${firmId}/lenders`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};
