/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TextField, TextFieldProps, makeStyles, Theme, InputAdornment } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import clsx from 'clsx';
import { RevertButton } from './components/RevertButton';
import { DateFieldProps } from '../DateField';

export type OverrideInputProps = Omit<DateFieldProps, 'onChange'> &
  TextFieldProps & {
    isOverridden?: boolean;
    value?: any;
    sourceValue?: any;
    renderInput?: (props?: any) => JSX.Element;
    InputProps?: Record<string, unknown>;
    onChange?: (value: any) => void;
    onRevert?: () => void;
  };

const useStyles = makeStyles((theme: Theme) => ({
  override: {
    '&.override': {
      '& label': {
        color: theme.palette.warning.dark,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: theme.palette.warning.dark,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.warning.dark,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.warning.dark,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.warning.dark,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.warning.dark,
        },
      },
      '& .MuiFilledInput-root, & .MuiFilledInput-root:focus, & .MuiFilledInput-root:hover': {
        backgroundColor: alpha(theme.palette.warning.light, 0.2),
      },
      '& .MuiFilledInput-underline:before': {
        borderBottomColor: theme.palette.warning.dark,
      },
      '& .MuiFilledInput-underline:after': {
        borderBottomColor: theme.palette.warning.dark,
      },
    },
  },
}));

export default function OverrideInput({
  isOverridden = false,
  value,
  sourceValue,
  renderInput = (props) => <TextField {...props} fullWidth />,
  InputProps,
  onChange = () => undefined,
  onRevert = () => undefined,
  ...props
}: OverrideInputProps): JSX.Element {
  const classes = useStyles();
  return (
    <>
      {renderInput({
        ...props,
        'data-testid': 'override-input',
        fullWidth: true,
        value: isOverridden ? value : sourceValue,
        onChange: onChange,
        className: clsx(classes.override, isOverridden && !props.error && 'override'),
        InputProps: {
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              {!isOverridden && InputProps?.endAdornment}
              {isOverridden && <RevertButton onRevert={onRevert} />}
            </InputAdornment>
          ),
        },
      })}
    </>
  );
}
