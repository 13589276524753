import { DownloadDocumentRequest, OrderPageData, UpdateOrderData } from './type';

export class ServiceOrderMapper {
  public static postServiceOrder(data: OrderPageData) {
    return {
      clientRequestId: 'clientRequestId',
      fileId: data.fileId,
      accountNumber: data.accountNumber,
      serviceOrderRequest: {
        productName: data.orders[0].productName,
        productField: data.orders[0].productField,
        orderNotificationEmail: data.orders[0].orderNotificationEmail,
        notes: data.orders[0].note,
        documents: data.orders[0].userUploadedDocuments,
      },
    };
  }

  public static postServiceOrderDocumentDownload(data: DownloadDocumentRequest) {
    return {
      clientRequestId: 'clientRequestId',
      fileId: data.fileId,
      serviceOrderReferenceId: data.serviceOrderReferenceId,
      //accountNumber: data.accountNumber,
      downloadFormat: data.downloadFormat,
      URI: data.URI,
    };
  }

  public static putServiceOrder(data: UpdateOrderData) {
    return {
      clientRequestId: 'clientRequestId',
      accountNumber: data.accountNumber,
      fileId: data.fileId,
      fileRequest: null,
      updateType: data.order.updateType,
      serviceOrderReferenceId: data.order.serviceOrderReferenceId,
      orderNotificationEmail: data.order.orderNotificationEmail,
      notes: data.order.note,
      documents: data.order.userUploadedDocuments,
    };
  }
}
