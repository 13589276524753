import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, List, makeStyles, Pagination, Theme, Typography } from '@agentnet/components';
import { useSearchParams } from './hooks/useSearchParams';
import { Link } from 'react-router-dom';
import { useSearchArticlesQuery } from './queries/useSearchArticlesQuery';
import { HELP_ROUTE } from './constants';
import { boldSearchPhrase } from './util/boldSearchPhrase';
import { Article } from './types';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { ContentfulErrorContext, ContentfulErrorTypes } from './hooks/ContentfulErrorContext';
import useSiteSettingsQuery from './queries/useSiteSettingsQuery';
import noResults from '../../common-assets/images/no-results.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    topicHeading: {
      alignSelf: 'stretch',
      '&:hover': {
        opacity: '0.9',
      },
    },
    header: {
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(3),
      borderBottom: '1px solid #D9E0E5',
    },
    h2: {
      marginBottom: theme.spacing(3),
    },
    li: {
      display: 'flex',
      paddingBottom: '0px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(3),
      borderBottom: '1px solid #e5e5e5',
    },
    body: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontSize: '16px',
    },
    a: { textDecoration: 'none' },
    nav: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    fullWidth: {
      width: '100%',
    },
    pagination: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(8),
      alignSelf: 'center',
    },
    Image: {
      maxWidth: '125px',
      margin: '0 auto',
    },
    m2: {
      marginTop: theme.spacing(2),
    },
    center: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    p15: {
      paddingLeft: '15px',
      fontSize: '14px',
      li: {
        marginBottom: '5px',
        color: theme.palette.text.secondary,
      },
    },
    brdTop: {
      borderTop: '1px solid #D9E0E5',
    },
    loaderContainer: {
      height: '40vw',
      width: '65vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export function SearchResultPage() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [paginationLimit, setPaginationLimit] = useState<number>(10);
  const query = useSearchParams<{ q?: string }>();

  const [search, setSearch] = useState<Article[] | null>();
  const [total, setTotal] = useState<number>(0);

  const { setContentfulErrorType } = React.useContext(ContentfulErrorContext);

  useEffect(() => {
    const fetchData = async () => {
      setSearchString(query.q);

      const siteSettings = await useSiteSettingsQuery();
      const paginationLimit = siteSettings?.data?.paginationLimit ?? 10;

      if (paginationLimit) {
        setPaginationLimit(paginationLimit);
      }

      const { data, total, error } = await useSearchArticlesQuery(
        query.q?.trim(),
        Math.max(0, Math.floor((page - 1) * paginationLimit)),
        paginationLimit,
      );

      if (error) {
        setContentfulErrorType(ContentfulErrorTypes.Error);
      }

      if (!data) {
        setContentfulErrorType(ContentfulErrorTypes.NoContent);
      }

      setSearch(data);
      setTotal(total);
    };
    fetchData();
  }, [query.q, page]);

  const boldSearch = boldSearchPhrase.bind(null, searchString?.toLowerCase());

  const handleChangePage = useCallback((e, nextPage: number) => setPage(nextPage), []);

  return (
    <div className={classes.fullWidth}>
      <div className={classes.container}>
        {!search && <LoadingSpinner variant="circle" status={'pending'} className={classes.loaderContainer} />}
        {search && (
          <>
            <div className={classes.header}>
              <Typography data-testid="kb-search-results-header" variant="h1" className={classes.h2}>
                Search Results
              </Typography>
              <Typography data-testid="kb-search-results-found" variant="body1" color="textSecondary">
                {total || 0} Results Found: {searchString}
              </Typography>
            </div>
            {total === 0 && (
              <>
                <img src={noResults} alt="No Search Results" className={classes.Image} />
                <div className={classes.center}>
                  <Typography variant="h3" className={classes.m2}>
                    No Results Found
                  </Typography>
                  <Typography variant="body1" className={classes.m2} color="textSecondary">
                    There are no results matching your search. Please try again.
                  </Typography>
                </div>
                <div className={classes.brdTop}>
                  <Typography variant="h3" className={classes.m2}>
                    Search Suggestions:
                  </Typography>
                  <ul className={classes.p15}>
                    <li>Check the spelling of your keywords</li>
                    <li>Use more general search terms</li>
                    <li>Search alternate keywords</li>
                    <li>Enter fewer keywords</li>
                  </ul>
                </div>
              </>
            )}
            <List component={'nav'} className={classes.nav}>
              {search.map((article) => (
                <li key={article.sys.id} data-testid={`${article.articleTitle}-list-item`} className={classes.li}>
                  <Link
                    key={article.articleTitle}
                    to={`${HELP_ROUTE}/${article.topicCollection.items[0]?.slug}/${article.slug}`}
                    className={classes.a}
                  >
                    <Typography variant="h2" color="primary" className={classes.topicHeading}>
                      {article.articleTitle}
                    </Typography>
                  </Link>
                  <Typography variant="body1" color="textPrimary" className={classes.body}>
                    {boldSearch(article.articleSummary)}
                  </Typography>
                </li>
              ))}
            </List>
          </>
        )}

        <div data-testid="kb-search-results-pagination" className={classes.pagination}>
          <Pagination
            count={Math.ceil((total || 0) / paginationLimit)}
            page={Math.max(1, Math.min(page, Math.ceil((total || 0) / paginationLimit)))}
            onChange={handleChangePage}
            shape="rounded"
            color="primary"
          />
        </div>
      </div>
    </div>
  );
}
