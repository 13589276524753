import { handleError } from 'utilities/utilities';
import { getApiHost } from '../host-by-env';
import { SaveStaffProfileDetails, SaveUserProfileDetails } from './interfaces';

export const getProfileDetails = async (token: string) => {
  const url = `${getApiHost()}user/me`;

  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  const profileDetailsResponse = json.result;
  return profileDetailsResponse;
};

export const getUserAcessKeys = async (token: string) => {
  const url = `${getApiHost()}user/access-keys`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  const UserAcessKeysResponse = json.result;
  return UserAcessKeysResponse;
};

export const getPrefferedOffice = async (token: string) => {
  const url = `${getApiHost()}user/preferred-offices`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  const prefferedOfficeResponse = json.result;
  return prefferedOfficeResponse;
};

//Delete the serviceOrderEmailAddress
export async function deleteEmailNotification(userEmailNotificationId: number, token: string): Promise<boolean> {
  const url = `${getApiHost()}user/profile/service-order/notification/?userEmailNotificationId=${userEmailNotificationId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

//Update/Save update Contact Information based on Idaas event Trigger

export async function updateUserProfileContact(token: string, request: SaveStaffProfileDetails): Promise<boolean> {
  const url = `${getApiHost()}user/profile/contact-info`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(request),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result.Status;
}

export const setUserProfileDetails = async (token: string, userProfileDetailRequest: SaveUserProfileDetails) => {
  const url = `${getApiHost()}user/profile/save`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(userProfileDetailRequest),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch((error) => {
    throw Error(
      `Error occurred while saving profile default location. payload - ${userProfileDetailRequest} , error - : ${error}`,
    );
  });
  const json = await response.json();
  return json.result;
};

export const updateBopsPreferences = async (token: string, BopsRequest: any) => {
  const url = `${getApiHost()}user/profile/service-order/download-preferences`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(BopsRequest),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch((error) => {
    throw Error(`Error occurred while saving Bops preferences. payload - ${BopsRequest} , error - : ${error}`);
  });
  const json = await response.json();
  return json.result;
};

export const UnmaskUserAccessKey = async (token: string, userAccessKeyId: any) => {
  const url = `${getApiHost()}user/access-keys/view/${userAccessKeyId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  const unmaskKeyResponse = json.result;
  return unmaskKeyResponse;
};

export const DeleteUserAccessKey = async (token: string, userAccessKeyId: any) => {
  const url = `${getApiHost()}user/access-keys/${userAccessKeyId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  const DeleteResponse = json.result;
  return DeleteResponse;
};

export const AddUserAcessKeys = async (token: string, vendorData: any) => {
  const url = `${getApiHost()}user/access-keys`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(vendorData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  console.log('responsee of add', response);
  const json = await response.json();
  handleError(json);
  return json.result;
};

export const revokeUserAccessKey = async (token: string, userAccesskeyData: any) => {
  const url = `${getApiHost()}user/access-keys/revoke`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(userAccesskeyData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};
