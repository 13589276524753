import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { UserRoleTypes } from '../constants';
import React, { useState, useEffect } from 'react';
import { ArrowBack, Restore } from '@material-ui/icons';
import MailOutlineOutlinedIcon from '@material-ui/icons/EmailOutlined';
import ViewHistory from './ViewHistory';
import { useAuth } from '@agentnet/auth';
import { postUserActivationEmail } from 'api/manageUsers/manage-users-api';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

interface userBannerProps {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  userId?: number | string;
  payConnectionId?: string;
  roleId?: any;
  id?: number;
  email?: string;
  roleType?: number;
  userStatus?: string;
}

const UserBanner = ({
  firstName,
  middleName,
  lastName,
  userId,
  payConnectionId,
  roleId,
  id,
  email,
  roleType,
  userStatus,
}: userBannerProps) => {
  const formattedFirstName = firstName ? firstName[0].toUpperCase() + firstName.slice(1).toLowerCase() : '';
  const formattedLastName = lastName ? lastName[0].toUpperCase() + lastName.slice(1).toLowerCase() : '';
  const history = useHistory();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      contentWrap: {
        padding: `0 0 32rem`,
        maxWidth: 1200,
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      userBannerWrap: {
        padding: theme.spacing(3),
        borderBottom: `1px solid #c4c4c4`,
      },
      userBannerContainer: {
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      userBannerDetails: { display: 'flex', alignItems: 'center', width: '100%' },
      userDetails: {
        marginLeft: theme.spacing(2),
      },
      userName: {
        marginBottom: theme.spacing(0.5),
        textTransform: 'none',
      },
      avatar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '46px',
        height: '46px',
        borderRadius: '46px',
        border: `1px solid #013A6F`,
        textAlign: 'center',
        color: theme.palette.primary.dark,
        '& span': {
          marginTop: '4px',
        },
      },
      captionDivider: {
        width: theme.spacing(2),
        display: 'inline-block',
      },
      fieldSection: {},
      fieldSectionLeft: {
        padding: theme.spacing(3),
      },
      fieldSectionRight: {
        padding: theme.spacing(3),
        marginTop: 0,
      },
      adornmentIcon: {
        padding: 0,
        '&:hover': {
          background: 'transparent',
        },
      },
      ctaBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(3),
        width: '100%',
      },
      alertContainer: {
        marginBottom: '63px',
      },
      alertMsg: {
        width: '-webkit-fill-available',
        position: 'absolute',
        bottom: '63px',
        left: 0,
        padding: '0 35px',
      },
    }),
  );
  console.log(
    'roleId',
    roleId,
    'firstName',
    formattedFirstName,
    'lastName',
    formattedLastName,
    'userId',
    userId,
    'payConnectionId',
    payConnectionId,
  );
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [OpenModelFirm, setOpenModelFirm] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();

  function nameTag(str?: any) {
    const fname = firstName ? firstName[0].toUpperCase() : '';
    const lname = lastName ? lastName[0].toUpperCase() : '';
    return `${fname}${lname}`;
  }
  function isUserIdDateFormat() {
    const dateFormat = 'MM/DD/YYYY h:mm:ss A';
    // Use moment to check if userId matches the date format
    return userId && userStatus === 'Not Activated' && moment(userId, dateFormat, true).isValid();
  }

  useEffect(() => {
    if (userStatus === 'Not Activated' && roleType === 100) {
      setShowEmail(true);
    } else {
      setShowEmail(false);
    }
  }, []);

  const dismissSendActivationEmail = () => {
    setOpenModelFirm(false);
  };

  const sendActivationEmail = async () => {
    const token = await getAccessToken();
    const responsedata = await postUserActivationEmail(token, id);
    if (responsedata) {
      addSnackbarMessage({
        message: 'Activation Email Successfully Sent',
        type: 'success',
      });
    } else {
      addSnackbarMessage({
        message: 'Email Could Not Be Sent. Try Again Later!',
        type: 'error',
      });
    }
    setOpenModelFirm(false);
    setSendEmail(false);
  };

  const dialogText = `Account information and login credential will be sent to ${email}. Do you want to proceed?`;

  return (
    <div className={classes.userBannerWrap}>
      <AgentNetButton
        variant="text"
        size="medium"
        startIcon={<ArrowBack />}
        onClick={() => window.location.reload()}
        data-qa="backToUserList"
      >
        Back To User List
      </AgentNetButton>
      <div className={classes.userBannerContainer}>
        <div
          className={classes.userBannerDetails}
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginRight: '1rem' }}
        >
          <div className={classes.userDetails}>
            <Typography variant="h1" className={classes.userName}>
              {'Edit User - ' +
                ((formattedFirstName && formattedFirstName.trim()) ?? '') +
                (formattedFirstName && (middleName || formattedLastName) ? ' ' : '') +
                ((middleName && middleName.trim()) ?? '') +
                (middleName && formattedLastName ? ' ' : '') +
                (formattedLastName && formattedLastName.trim()) ?? ''}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {userId && !isUserIdDateFormat() && `User ID: ${userId} `}
              <span className={classes.captionDivider}></span>
              {/* {payConnectionId && `Pay Connection ID: ${payConnectionId} `} */}
            </Typography>
          </div>
          <div className="userBannerPw">
            {showEmail === true && (
              <AgentNetButton
                onClick={() => {
                  setSendEmail(true);
                  setOpenModelFirm(true);
                }}
                style={{ margin: '10px', padding: '4px 8px' }}
                variant="outlined"
                data-qa="sendEmail"
              >
                <MailOutlineOutlinedIcon fontSize="small" htmlColor="#0074CA" />
                Send Account Information
              </AgentNetButton>
            )}
            <AgentNetButton
              onClick={() => {
                console.log('View History clicked');
                setDrawerOpen(true);
              }}
              style={{ margin: '10px', padding: '4px 8px' }}
              variant="outlined"
              data-qa="openHistory"
            >
              <Restore fontSize="small" htmlColor="#0074CA" />
              History
            </AgentNetButton>
          </div>
        </div>
        {drawerOpen && id ? (
          <ViewHistory userId={id} handleDrawerOpen={drawerOpen} closeDrawer={() => setDrawerOpen(false)} />
        ) : null}
        {roleId === UserRoleTypes.Agent ? (
          <div className="userBannerPw">
            <AgentNetButton variant="contained" size="medium" data-qa="UserProfileChangePassword">
              Change Password
            </AgentNetButton>
          </div>
        ) : null}
        {sendEmail && (
          <AgentNetConfirmationDialog
            qaAttrPrefix="ConfirmationCancel"
            onConfirm={sendActivationEmail}
            open={OpenModelFirm}
            onDismissAction={dismissSendActivationEmail}
            dialogTitle="Resend Activation Email"
            dialogBtnContent="Yes"
            secondaryActionBtnContent="No"
            dialogText={dialogText}
          />
        )}
      </div>
    </div>
  );
};

export default UserBanner;
