import { AddressComponentType } from '../Models';

/* Maps google type to our local address model */

export const PlaceAddressMap: Record<string, { componentName: string; shortName?: boolean }> = {
  zipcode: { componentName: AddressComponentType.ZipCode },
  city: { componentName: AddressComponentType.City },
  administrativeAreaLevelThree: { componentName: AddressComponentType.AdministrativeAreaLevelThree },
  neighborhood: { componentName: AddressComponentType.Neighborhood },
  state: { componentName: AddressComponentType.State, shortName: true },
  county: { componentName: AddressComponentType.County, shortName: true },
  route: { componentName: AddressComponentType.Route },
  streetNumber: { componentName: AddressComponentType.StreetNumber, shortName: true },
  subpremise: { componentName: AddressComponentType.Subpremise },
  unit: { componentName: AddressComponentType.Unit },
  apartment: { componentName: AddressComponentType.Apartment },
  premise: { componentName: AddressComponentType.Premise },
  establishment: { componentName: AddressComponentType.Establishment },
};
