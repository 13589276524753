import React, { useEffect, useRef, useState } from 'react';

interface IMap {
  mapType?: google.maps.MapTypeId;
  mapTypeControl?: boolean;
  zoomControl?: boolean;
  addresses?: string[];
  zoomLevel?: number;
}

interface IMarker {
  address: string;
  latitude: number;
  longitude: number;
}

type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;
type GoogleMarker = google.maps.Marker;
type GooglePolyline = google.maps.Polyline;

export const Map: React.FC<IMap> = ({
  mapType = google.maps.MapTypeId.ROADMAP,
  mapTypeControl = false,
  addresses,
  zoomControl = false,
  zoomLevel = 17,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<GoogleMap>();
  const [marker, setMarker] = useState<IMarker>();
  const [homeMarker, setHomeMarker] = useState<GoogleMarker>();
  const [googleMarkers, setGoogleMarkers] = useState<GoogleMarker[]>([]);
  const [listenerIdArray, setListenerIdArray] = useState<any[]>([]);
  const [LastLineHook, setLastLineHook] = useState<GooglePolyline>();

  const startMap = (): void => {
    const addMarkers = async () => {
      try {
        const geocoder = new google.maps.Geocoder();
        for (const address of addresses ?? []) {
          await geocoder.geocode({ address }, function (results, status) {
            if (status === 'OK') {
              setMarker({
                address: results[0].formatted_address,
                latitude: results[0].geometry.location.lat(),
                longitude: results[0].geometry.location.lng(),
              });
            }
          });
        }
      } catch (e) {
        // don't need console logs
      }
    };

    const init = async (address: string) => {
      try {
        const geocoder = new google.maps.Geocoder();
        await geocoder.geocode({ address }, function (results, status) {
          if (status === 'OK') {
            results.length > 0 &&
              defaultMapStart(results[0].geometry.location.lat(), results[0].geometry.location.lng());
          }
        });
      } catch (e) {
        // don't need console logs
      }
    };
    if (addresses && addresses?.length > 0) {
      const [firstAddress] = addresses;
      if (!map) {
        init(firstAddress);
      } else {
        addMarkers(); //Re-render all markers if map does exist
      }
    }
  };
  useEffect(() => {
    startMap();
  }, [map, JSON.stringify(addresses)]);

  const defaultMapStart = (latitude: number, longitude: number): void => {
    const defaultAddress = new google.maps.LatLng(latitude, longitude);
    initMap(defaultAddress);
  };

  useEffect(() => {
    if (marker) {
      addMarker(new google.maps.LatLng(marker.latitude, marker.longitude));
    }
  }, [marker]);

  const addMarker = (location: GoogleLatLng): void => {
    const marker: GoogleMarker = new google.maps.Marker({
      position: location,
      map: map,
    });
  };

  useEffect(() => {
    listenerIdArray.forEach((listenerId) => {
      google.maps.event.removeListener(listenerId);
    });

    setGoogleMarkers([]);
    googleMarkers.forEach((googleMarker) => {
      const markerPosition = googleMarker.getPosition();
      if (markerPosition) {
        addMarker(markerPosition);
      }
    });
  }, [LastLineHook]);

  const addHomeMarker = (location: GoogleLatLng): GoogleMarker => {
    const homeMarkerConst: GoogleMarker = new google.maps.Marker({
      position: location,
      map: map,
      icon: {
        url: window.location.origin + '/assets/images/homeAddressMarker.png',
      },
    });

    homeMarkerConst.addListener('click', () => {
      map?.panTo(location);
      map?.setZoom(6);
    });

    return homeMarkerConst;
  };

  const getIconAttributes = (iconColor: string) => {
    return {
      path: 'M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z',
      fillColor: iconColor,
      fillOpacity: 1,
      strokeColor: 'transparent',
      strokeWeight: 0,
      anchor: new google.maps.Point(30, 50),
    };
  };

  const initMap = (address: GoogleLatLng): void => {
    if (ref.current) {
      setMap(
        new google.maps.Map(ref.current, {
          zoom: zoomLevel,
          center: address,
          mapTypeControl: mapTypeControl,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: true,
          fullscreenControl: false,
          panControl: false,
          zoomControl: zoomControl,
          gestureHandling: 'cooperative',
          mapTypeId: mapType,
          draggableCursor: 'pointer',
        }),
      );
    }
  };

  return (
    <div className="map-container">
      <div ref={ref} className="map-container__map"></div>
    </div>
  );
};
