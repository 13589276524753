import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAuth } from '@agentnet/auth';
import EmptyImg from '../../../common-assets/images/empty.svg';
import { getFileInfoProps, refreshFileOrderInsights } from 'api/file/file-api';
import {
  capitalizeFirstLetter,
  disclaimerText,
  filePartyTypeMap,
  getParentSeverity,
  onFileOwnerAddresses,
  onFileOwnerNames,
  OrderInsightJacket,
  Party,
  Property,
  RiskCategory,
  toFullAddress,
} from 'api/file/interfaces/get-file-order-insights';
import useAsync from 'hooks/useAsync';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { useViewState } from 'hooks/ViewStateContext';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import OrderInsightUpdateButton from './components/OrderInsightUpdateButton';
import PartyOrderInsights from './PartyOrderInsights';
import PropertyOrderInsights from './PropertyOrderInsights';
import PartyNotCreated from '../parties/PartyNotCreated/PartyNotCreated';
import ContactUsDialog from 'ui-kit/components/modal/ContactUsDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryWrap: {
      display: 'flex',
    },
    summaryWrapRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    iconWrap: {
      backgroundColor: 'transparent',
      borderRadius: '4px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: `0 ${theme.spacing(1)}px`,
    },
    discTrigger: {
      padding: '0 0 8px 0',
      '&:hover': {
        background: 'transparent',
      },
    },
    lowerCase: {
      textTransform: 'none',
    },
    lowerCase2: {
      textTransform: 'none',
      paddingBottom: theme.spacing(3),
    },
    truncate: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      minHeight: '21px',
      maxWidth: '500px',
    },
    subtitleWrap: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    subtitle: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
      maxWidth: '350px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    titleWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: `0 ${theme.spacing(1)}px`,
      height: '43px',
    },
    summaryActions: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        fontSize: '2.1rem',
      },
    },
    summaryActionBtn: {
      marginLeft: `1.2rem`,
    },
    expandIcon: {
      margin: `0 ${theme.spacing(1)}px`,
      transform: 'scale(1.8) rotate(-90deg)',
      transition: `.156s all`,
      fill: theme.palette.text.secondary,
      alignSelf: 'center',
    },
    titleOnly: {
      lineHeight: '3.8rem',
    },
    isEditing: {
      backgroundColor: '#FFF7EB',
      '& .MuiAccordionSummary-content': {
        backgroundColor: 'transparent',
      },
    },
    selectableText: {
      userSelect: 'text',
      cursor: 'text',
    },
    center: {
      textAlign: 'center',
      margin: theme.spacing(4, 0),
    },
    disclaimer: {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '16px',
      padding: theme.spacing(3, 0, 1, 0),
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '16px',
      flexDirection: 'column',
      //margin: theme.spacing(3, 0),
    },
    flexRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: theme.spacing(2),
    },
    container: {
      flex: '1',
      padding: theme.spacing(0, 3),
    },
    propertyType: {
      backgroundColor: '#0C6961',
    },
    buyerType: {
      backgroundColor: '#6B21A8',
    },
    sellerType: {
      backgroundColor: '#B63F0C',
    },
    lenderType: {
      backgroundColor: '#901949',
    },
    gIcon: {
      background: '#0C6961',
      color: '#fff',
      fontSize: theme.spacing(4),
      margin: theme.spacing(0, 2),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
    pIcon: {
      background: '#6B21A8',
      color: '#fff',
      fontSize: theme.spacing(4),
      margin: theme.spacing(0, 2),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
    rIcon: {
      background: theme.palette.error.main,
      color: '#fff',
      fontSize: theme.spacing(4),
      margin: theme.spacing(0, 2),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
    drIcon: {
      background: '#901949',
      color: '#fff',
      fontSize: theme.spacing(4),
      margin: theme.spacing(0, 2),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
    w100: {
      width: '100%',
    },
    warning: {
      color: theme.palette.error.main,
      fontSize: theme.spacing(2),
    },
    greyTxt: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      maxWidth: '90%',
    },
    success: {
      color: theme.palette.success.main,
      fontSize: theme.spacing(2),
    },
    link: {
      color: theme.palette.text.secondary,
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8',
      },
    },
  }),
);

export default function OrderInsightsContent(): JSX.Element {
  // #region Constants
  const classes = useStyles();
  const { fileId } = useParams<getFileInfoProps>();
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const { setRouterParamValue } = useViewState();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData, loadFileData, fileOrderInsightsData, loadFileOrderInsightsData, isDiscoveringOrderInsights } =
    fileDataCtx;
  const [properties, setProperties] = React.useState<Property[]>();
  const [parties, setParties] = React.useState<Party[]>();
  const [policyLiabilityJacket, setPolicyLiabilityJacket] = React.useState<OrderInsightJacket>();
  const [lastUpdated, setLastUpdated] = React.useState<string>('');
  const [isActionInProgress, setActionInProgress] = React.useState<string>('');
  const [orderInsightsError, setOrderInsightsError] = React.useState<string[]>([]);
  const [contactUsModal, setContactUsModal] = React.useState(false);

  const refreshOrderInsights = async (): Promise<boolean> => {
    const token = await getAccessToken();
    return refreshFileOrderInsights(fileId ?? '', token) as Promise<boolean>;
  };
  const {
    execute: executeRefreshOrderInsights,
    status: refreshOrderInsightsStatus,
    value: refreshOrderInsightsData,
    errors: refreshOrderInsightsError,
  } = useAsync<boolean>(refreshOrderInsights, false);

  const showErrorMessage = (errorMessage: string[]) => {
    errorMessage?.map((err) => {
      addGlobalMsg({
        message: err,
        type: 'error',
      });
    });
    setOrderInsightsError([]);
  };

  const handleRefreshOrderInsights = () => {
    setActionInProgress('pending');
    executeRefreshOrderInsights().then();
  };

  const handleExpandProperties = (isExpand: boolean) => {
    const tempProperty =
      properties?.map((propertyValue) => {
        propertyValue.expanded = isExpand;
        return propertyValue;
      }) ?? [];
    setProperties([...tempProperty]);
  };

  const handleExpandSingleProperty = (isExpand: boolean, id: string | number) => {
    const tempProperty =
      properties?.map((propertyValue, index) => {
        if (index === id) {
          propertyValue.expanded = isExpand;
        }
        return propertyValue;
      }) ?? [];
    setProperties([...tempProperty]);
  };

  const handleExpandParties = (isExpand: boolean) => {
    const tempParty =
      parties?.map((partyValue) => {
        partyValue.expanded = isExpand;
        return partyValue;
      }) ?? [];
    setParties([...tempParty]);
  };

  const handleExpandSingleParty = (isExpand: boolean, id: string | number) => {
    const tempParty =
      parties?.map((partyValue, index) => {
        if (index === id) {
          partyValue.expanded = isExpand;
        }
        return partyValue;
      }) ?? [];
    setParties([...tempParty]);
  };

  const propertySubtitle = (property: Property) => {
    const subtitle = [property.propertyType];
    if (property.apn) {
      subtitle.push(`APN: ${property.apn}`);
    }
    if (property.address?.county) {
      subtitle.push(`${capitalizeFirstLetter(property.address.county)} County`);
    }
    return subtitle;
  };

  const propertySeverity = (property: Property) => {
    let severity;
    if (property.risks?.some((x) => x.categoryName === 'Property' && x.typeName === 'MissingData')) {
      severity = 'Alert';
    } else {
      severity = getParentSeverity(property.risks ?? [], RiskCategory.Property);
      if (severity === 'None' || severity === 'Error') {
        severity = 'Warning';
      }
    }
    return severity;
  };

  const getTimeDifference = (pastDate: Date) => {
    const now = new Date();
    const diffInMs = now.getTime() - pastDate.getTime();

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(diffInMs / (1000 * 60));
    const hours = Math.floor(diffInMs / (1000 * 60 * 60));
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (seconds < 60) {
      return 'Just Now';
    } else if (minutes < 60) {
      return `${minutes} Minutes Ago`;
    } else if (hours < 24) {
      return `${hours} Hours Ago`;
    } else if (days < 7) {
      return `${days} Days Ago`;
    } else if (days < 22) {
      const weeks = Math.floor(days / 7);
      return `${weeks} Weeks Ago`;
    } else {
      const formattedDate = pastDate.toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      });
      return formattedDate;
    }
  };

  // #endregion
  useEffect(() => {
    if (fileId) {
      setRouterParamValue(fileId);
      setActionInProgress('pending');
      loadFileOrderInsightsData && loadFileOrderInsightsData(fileId, true);
      if (fileData?.fileId === '' || fileData?.fileId !== fileId) {
        loadFileData && loadFileData({ fileId: fileId });
      }
    }
  }, [fileId]);

  useEffect(() => {
    if (fileOrderInsightsData?.fileId !== fileId) {
      return;
    }

    if (fileOrderInsightsData?.hasError) {
      setActionInProgress('');
    }
    const lastDtUpdate = fileOrderInsightsData?.lastGeneratedDate;
    if (lastDtUpdate && !fileOrderInsightsData.isGenerating) {
      const timeDifference = getTimeDifference(new Date(lastDtUpdate));
      setLastUpdated(timeDifference);
    }
    if (fileOrderInsightsData?.properties) {
      const filePartyType =
        fileOrderInsightsData?.parties.filter((party) => party.filePartyType === 'Seller').length > 0
          ? 'Seller'
          : 'Buyer';
      const mappedProperties = fileOrderInsightsData.properties.map((property) => {
        return {
          ...property,
          onFileOwnerAddressWithPartyName: onFileOwnerAddresses(fileOrderInsightsData?.parties, filePartyType),
          onFileOwnerNames: onFileOwnerNames(fileOrderInsightsData?.parties, filePartyType),
          expanded: false,
        };
      });
      setProperties(mappedProperties);
    }
    if (fileOrderInsightsData?.jackets) {
      const policyLiabilityJacket = fileOrderInsightsData.jackets.find((jacket) => jacket.isMaxLiabilityAmount);
      setPolicyLiabilityJacket(policyLiabilityJacket);
    }
    if (fileOrderInsightsData?.parties) {
      const mappedParties = fileOrderInsightsData.parties.map((party) => {
        return { ...party, expanded: false };
      });
      setParties(mappedParties);
    }
  }, [fileOrderInsightsData]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setActionInProgress(isDiscoveringOrderInsights ?? '');
    }
    return () => {
      isMounted = false;
    };
  }, [isDiscoveringOrderInsights]);

  useEffect(() => {
    if (
      Array.isArray(refreshOrderInsightsError) &&
      refreshOrderInsightsError.length > 0 &&
      refreshOrderInsightsStatus === 'error'
    ) {
      setOrderInsightsError([...refreshOrderInsightsError]);
    }
  }, [refreshOrderInsightsError, refreshOrderInsightsStatus]);

  useEffect(() => {
    if (orderInsightsError.length > 0) showErrorMessage(orderInsightsError);
  }, [orderInsightsError]);

  useEffect(() => {
    if (refreshOrderInsightsData && loadFileOrderInsightsData) loadFileOrderInsightsData(fileId ?? '');
  }, [refreshOrderInsightsData]);

  const isPartiesExist = React.useMemo(() => parties && parties.length > 0, [parties]);

  return (
    <div className={classes.container}>
      <LoadingSpinner status={isActionInProgress} variant="linear" />
      <>
        <div className={classes.flexRow}>
          <span>
            <Typography variant="h1">Order Insights</Typography>
            <Typography variant="body2" className={classes.greyTxt}>
              Real-time Order Insights, our proactive fraud alert system, validates available party and property
              characteristics, including watchlists, claims, vacant land, name mismatches, and more.
            </Typography>
          </span>
          <div className={classes.subtitleWrap}>
            {/* on hover */}
            {lastUpdated && isActionInProgress !== 'pending' && (
              <>
                <OrderInsightUpdateButton
                  btnText={lastUpdated}
                  onMouseHoverBtnText={'Check For Updates'}
                  onClick={handleRefreshOrderInsights}
                  data-qa={'RefreshOrderInsights'}
                ></OrderInsightUpdateButton>
              </>
            )}
          </div>
          {!lastUpdated && !fileOrderInsightsData?.hasError && isActionInProgress !== 'pending' && (
            <div className={classes.subtitleWrap}>
              <AgentNetButton variant="text" onClick={handleRefreshOrderInsights} data-qa={'RefreshOrderInsights'}>
                Check For Updates
              </AgentNetButton>
            </div>
          )}
          {isActionInProgress === 'pending' && (
            <Typography
              variant="body2"
              style={{
                color: '#0074CA',
                fontWeight: 500,
                fontSize: '13px',
                display: 'flex',
                margin: '1em 0',
                whiteSpace: 'nowrap',
              }}
            >
              Discovering Insights
              <CircularProgress
                style={{
                  marginLeft: '6px',
                }}
                size={15}
                className="MuiAgentNetButton-Spinner"
              />
            </Typography>
          )}
        </div>
      </>
      {fileOrderInsightsData && fileOrderInsightsData.hasError && (
        <div className={classes.center}>
          <img src={EmptyImg} alt="contact-us" />
          <Typography variant="h3">Unable to Load Order Insights</Typography>
          <Typography component="span" variant="body2" className={classes.greyTxt}>
            Please try again or{' '}
            <a href="mailto:AgencySupport@firstam.com" className={classes.link}>
              contact us
            </a>{' '}
            for assistance
          </Typography>
          <ContactUsDialog open={contactUsModal} onClose={() => setContactUsModal(false)} />
        </div>
      )}
      {fileOrderInsightsData && !fileOrderInsightsData.hasError && (
        <>
          <div className={classes.flex}>
            <AgentNetDivider
              title={'Properties'}
              typoVariant="h2"
              buttonName={'Close All'}
              buttonName2={'Expand All'}
              onClick={() => {
                handleExpandProperties(false);
              }}
              onClick2={() => {
                handleExpandProperties(true);
              }}
              buttonNameQaAttr="PropertiesCloseAll"
              buttonName2QaAttr="PropertiesExpandAll"
              disablePaddingX
            />
          </div>
          <div className={classes.flex}>
            {properties &&
              properties.map((property: Property, index: number) => {
                return (
                  <AccordionContent
                    key={index}
                    id={index}
                    title={toFullAddress(property.address)}
                    expanded={property.expanded}
                    subtitle={propertySubtitle(property)}
                    onExpandChange={handleExpandSingleProperty}
                    accordianQAAttribute={`OrderInsightPropertySummary${index}`}
                    icon="property"
                    type="property"
                    orderInsightsRiskSeverity={
                      isActionInProgress !== 'pending' && lastUpdated ? propertySeverity(property) : ''
                    }
                  >
                    {isActionInProgress !== 'pending' && lastUpdated ? (
                      <PropertyOrderInsights
                        property={property}
                        policyLiabilityJacket={policyLiabilityJacket}
                        isPdf={false}
                        index={index}
                        key={index}
                      />
                    ) : undefined}
                  </AccordionContent>
                );
              })}
          </div>
          <div className={classes.flex}>
            <AgentNetDivider
              title={'Parties'}
              typoVariant="h2"
              buttonName={isPartiesExist ? 'Close All' : ''}
              buttonName2={isPartiesExist ? 'Expand All' : ''}
              onClick={() => {
                handleExpandParties(false);
              }}
              onClick2={() => {
                handleExpandParties(true);
              }}
              buttonNameQaAttr="PartiesCloseAll"
              buttonName2QaAttr="PartiesExpandAll"
              disablePaddingX
            />
          </div>
          <div className={classes.flex}>
            {parties &&
              parties.map((party: Party, index: number) => {
                return (
                  <AccordionContent
                    key={index}
                    id={index}
                    title={party.partyName}
                    expanded={party.expanded}
                    subtitle={[filePartyTypeMap[party.filePartyType], party.partyType]}
                    onExpandChange={handleExpandSingleParty}
                    accordianQAAttribute={`OrderInsightPartySummary${index}`}
                    icon={party.partyType}
                    type={party.filePartyType.toLowerCase()}
                    orderInsightsRiskSeverity={
                      isActionInProgress !== 'pending' && lastUpdated
                        ? getParentSeverity(party.risks ?? [], RiskCategory.Party)
                        : ''
                    }
                  >
                    {isActionInProgress !== 'pending' && lastUpdated ? (
                      <PartyOrderInsights party={party} key={index} isPdf />
                    ) : undefined}
                  </AccordionContent>
                );
              })}
            {!isPartiesExist && <PartyNotCreated></PartyNotCreated>}
          </div>
          <div className={classes.disclaimer}>
            <Typography variant="h5" className={classes.lowerCase}>
              Disclaimer:
            </Typography>
          </div>
          <Typography variant="h6" className={classes.lowerCase2}>
            {disclaimerText}
          </Typography>
        </>
      )}
    </div>
  );
}
