import React, { useState } from 'react';
import { doValidate, FieldValidationError } from '../utilities/validation/validation';
import { fileSchema } from '../utilities/validation/schemas';
import { ICreateFileResponse } from 'api/file/interfaces/create-file';
import { DefaultCreateFile } from 'models/create-file-model';

export interface FileCreateContextInterface {
  fileCreateData?: ICreateFileResponse | null;
  setFileCreateData?: (data: ICreateFileResponse | null) => void;
  children?: React.ReactNode;
  validationErrors?: FieldValidationError[];
  setValidationErrors?: (errs: FieldValidationError[]) => void;
}
const FileCreateContext = React.createContext<FileCreateContextInterface | null>(null);

const FileCreateProvider = (props: FileCreateContextInterface): JSX.Element => {
  const [fileCreateData, setFileCreateData] = useState<ICreateFileResponse>(DefaultCreateFile);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

  const updateFileData = async (newFileInfo: ICreateFileResponse | null) => {
    if (newFileInfo) {
      newFileInfo.closingDate = newFileInfo.closingDate ? newFileInfo.closingDate : null;
      setFileCreateData(newFileInfo);

      /* Do the clientside validation */
      const errs: FieldValidationError[] = await doValidate(newFileInfo, fileSchema);
      setValidationErrors(errs);
    }
  };

  const ctx: FileCreateContextInterface = {
    fileCreateData,
    setFileCreateData: updateFileData,
    validationErrors,
    setValidationErrors,
  };

  return <FileCreateContext.Provider value={ctx}>{props.children}</FileCreateContext.Provider>;
};

export { FileCreateContext, FileCreateProvider };
