import React from 'react';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from '../routes/RouteWithSubRoutes';
import Grid from '@material-ui/core/Grid';

type RouteContentWrapperProps = {
  routes: Array<never>;
  className?: string;
};

// A wrapper for <Route> that handles "sub"-routes by passing them as
// `routes` prop to the component it renders.
const NestedRouteLayout = ({ routes, className }: RouteContentWrapperProps): JSX.Element => {
  return (
    <div id="core-content-container" className={className && className}>
      <Grid container>
        <>
          <Grid item xs={12}>
            {/* ROUTES */}
            <Switch>
              {routes?.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} isNested />
              ))}
            </Switch>
          </Grid>
        </>
      </Grid>
    </div>
  );
};
export default NestedRouteLayout;
