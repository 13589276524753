import { fieldType, partyMaxLength } from 'features/cpl/types';
import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'required';

export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (fieldName?: string, maxLength?: number): string =>
  `${fieldName} Max length is ${maxLength}`;

export const letterTypeSchema = yup.object().shape({
  issueDate: yup
    .mixed()
    .required('CPL Issue Date is a required field.')
    .test('is-valid-date', 'Invalid Date', function (value) {
      if (!value) {
        return true; // Allow blank values
      }
      return yup.date().min(new Date('0500-01-01')).max(new Date('2100-01-01')).isValidSync(value);
    }),

  closingDate: yup
    .date()
    .nullable()
    .min('0500-01-01')
    .max('2100-01-01')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('Invalid Date'),
});

export const lenderSchema = (fieldTypes?: partyMaxLength, isRequired?: boolean): any => {
  return yup.object().shape({
    name: yup
      .string()
      .max(fieldTypes?.name?.maxLength ?? 255, maxLengthFieldMessage('Company Name', fieldTypes?.name?.maxLength))
      .when('attention', {
        is: () => isRequired,
        then: yup.string().required(requiredFieldMessage('Company Name')),
      }),
    lenderClauseText: yup
      .string()
      .max(
        fieldTypes?.clauseText?.maxLength ?? 255,
        maxLengthFieldMessage('Clause', fieldTypes?.clauseText?.maxLength),
      ),
    attention: yup
      .string()
      .max(
        fieldTypes?.attention?.maxLength ?? 100,
        maxLengthFieldMessage('Attention', fieldTypes?.attention?.maxLength),
      ),
    loanNumber: yup
      .string()
      .max(
        fieldTypes?.loanNumber?.maxLength ?? 50,
        maxLengthFieldMessage('Loan Number', fieldTypes?.loanNumber?.maxLength),
      ),
  });
};

export const buyerSchema = (fieldTypes?: fieldType, isRequired?: boolean): any => {
  return yup.object().shape({
    buyerAddress: yup.string(),
    buyerName: yup
      .string()
      .max(fieldTypes?.maxLength ?? 500, maxLengthFieldMessage('Borrower/Buyer Name', fieldTypes?.maxLength))
      .when('buyerAddress', {
        is: () => isRequired,
        then: yup.string().required(requiredFieldMessage('Borrower/Buyer Name')),
      }),
  });
};

export const sellerSchema = (fieldTypes?: fieldType, isRequired?: boolean): any => {
  return yup.object().shape({
    sellerAddress: yup.string(),
    sellerName: yup
      .string()
      .max(fieldTypes?.maxLength ?? 500, maxLengthFieldMessage('Seller Name', fieldTypes?.maxLength))
      .when('sellerAddress', {
        is: () => isRequired,
        then: yup.string().required(requiredFieldMessage('Seller Name')),
      }),
  });
};

export const additionalPartiesSchema = (isRequired?: any): any => {
  return yup.object().shape({
    sellerAddress: yup.string(),
    approvedAttorney: yup.string().when('sellerAddress', {
      is: () => isRequired?.[0],
      then: yup.string().required(requiredFieldMessage('Approved Attorney')),
    }),
    closingAttorney: yup.string().when('sellerAddress', {
      is: () => isRequired?.[1] || isRequired?.[2],
      then: yup.string().required(requiredFieldMessage('Closing Attorney')),
    }),
    secondParty: yup.string().when('sellerAddress', {
      is: () => isRequired?.[3],
      then: yup.string().required(requiredFieldMessage('Second Party')),
    }),
  });
};

export const domesticAddressSchemaRequired = (fieldTypes: any, isRequired?: boolean): any => {
  return yup.object().shape({
    address1: yup
      .string()
      .max(fieldTypes?.address1?.maxLength ?? 55, maxLengthFieldMessage('Address', fieldTypes?.address1?.maxLength))
      .when('address2', {
        is: () => isRequired,
        then: yup.string().required(requiredFieldMessage('Address 1')),
      }),
    address2: yup
      .string()
      .optional()
      .max(fieldTypes?.address2?.maxLength ?? 55, maxLengthFieldMessage('Address2', fieldTypes?.address2?.maxLength)),
    city: yup
      .string()
      .max(fieldTypes?.city?.maxLength ?? 50, maxLengthFieldMessage('City', fieldTypes?.city?.maxLength))
      .when('address2', {
        is: () => isRequired,
        then: yup.string().required(requiredFieldMessage('City')),
      }),
    stateOrProvince: yup
      .string()
      .max(fieldTypes?.state?.maxLength ?? 2, maxLengthFieldMessage('State', fieldTypes?.state?.maxLength))
      .when('address2', {
        is: () => isRequired,
        then: yup.string().required(requiredFieldMessage('State')),
      }),
    postalCode: yup
      .string()
      .matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.')
      .max(fieldTypes?.zip?.maxLength ?? 10, maxLengthFieldMessage('Zip', fieldTypes?.zip?.maxLength))
      .when('address2', {
        is: () => isRequired,
        then: yup.string().required(requiredFieldMessage('Zip')),
      }),
  });
};
