import createBreakpoints, { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1280,
    lg: 1440,
    xl: 1537,
    xxl: 1920,
  },
} as BreakpointsOptions;

export default createBreakpoints(breakpoints);
