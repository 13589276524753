import React from 'react';
import { withBaseFormGroup } from 'utilities/form/components/base-form';
import { IGetLenderResponse } from '../interfaces/CreateLenderRequest';
import AddLenderForm, { LenderPropertiesProps } from './AddLenderForm';

// This is used only in the File Edit, not File Create
const AddLenderFormWithBaseFormGroup = (props: LenderPropertiesProps): JSX.Element => {
  const formWithBase = withBaseFormGroup<IGetLenderResponse, LenderPropertiesProps>(AddLenderForm, props);
  return <>{formWithBase}</>;
};
export default AddLenderFormWithBaseFormGroup;
