import { FileOrderInsightsType, Property } from './get-file-order-insights';

export interface FileSummaryReportType {
  firmName: string;
  fileInfo: FileInfo;
  orderInsights: FileOrderInsightsType;
  cplInfo: Cpl[];
  jacketInfo: Jacket[];
  fastOrders: ServiceOrder[];
  backTitle: BackTitle[];
  rateFees: RateFees;
  recordingFees: RecordingFees;
  underwritingRequestinfo: UnderwritingRequestinfo;
}

export interface FileInfo {
  fileNumber: string;
  fileStatus: string;
  underwriter: string;
  office: Office;
  accountNumber: number;
  properties: Property[];
  parties: Parties;
  createdDate: string;
}

export interface Office {
  name: string;
  address: string;
}

export interface Parties {
  buyer: Buyer;
  lender: Lender;
  seller: Seller;
}

export interface Buyer {
  names: string;
}

export interface Lender {
  names: string;
  addresses: string;
}

export interface Seller {
  names: string;
}
export interface Address {
  isForeignAddress?: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  stateOrProvince?: string;
  postalCode?: string;
}
export interface ServiceOrder {
  productName: string;
  orderNumber: string;
  salePrice: number;
  orderStatus: string;
  modifiedDate: string;
  contactName: string;
  contactPhone: string;
}

export interface Cpl {
  cplId: number;
  cplStatus: string;
  letterTypeName: string;
  coveredPartyDesc: string;
  lenderName: string;
  createdDate: string;
}

export interface Jacket {
  policyNumber: string;
  policyType: string;
  liabilityAmount: string;
  policyEffectiveDate: string;
  policyStatus: string;
}

export interface RateFees {
  feeItems: FeeItem[];
  effectiveDate: string;
  rateEffectiveDate: string;
  grossPremiumTotal: string;
  netPremiumTotal: string;
  tRIDTotal: string;
  isDisplayTRIDAmount: boolean;
  isDisplayNetPremiumAmount: boolean;
}

export interface FeeItem {
  rateFeeDescr: string;
  policyType: string;
  grossPremiumAmount: string;
  netDueAmount: string;
  tRIDAmount: string;
  showAsterisk: boolean;
  businessSegment: string;
}
export interface BackTitle {
  documentNumber: string;
  documentType: string;
  documentDate: string;
  propertyAddress: string;
}

export interface RecordingFees {
  recordingFeeCost: RecordingFee[];
  recordingFeeAddress: RecordingFeeAddress[];
  phone: string;
  fax: string;
  website: string;
  businessHours: string;
  total: number;
  totalBuyer: number;
  totalSeller: number;
}

export interface RecordingFee {
  documentName: string;
  documentKey: string;
  documentAmount: number;
  documentAmountBuyer: number;
  documentAmountSeller: number;
  documentPayableTo: string;
  documentCostType: string;
  documentTaxAssessedBy: string;
  questionList: QuestionList[];
  notes: string[];
}

export interface QuestionList {
  questionText: string;
  questionValue: string;
}

export interface RecordingFeeAddress {
  attention: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  type: string;
  zip: string;
}

export interface UnderwritingRequestinfo {
  fileId: number;
  fileNumber: string;
  accountNumber: number;
  underwritingRequest: UnderwritingRequest;
}

export interface UnderwritingRequest {
  requestInformation: RequestInformation;
  risks: UnderwritingRisk[];
  additionalRisks: any[];
  proposedPolicies: any[];
  documents: Document[];
}

export interface RequestInformation {
  uwbFileId: number;
  requestId: number;
  submittedDate: string;
  requestStatus: string;
  requestName: string;
  estimatedClosingDate: string;
  maximumLiabilityAmount: number;
  transactionType: string;
  transactionTypeDescription: string;
  assignedTo: string;
  emailNotification: string;
  description: string;
  isMortgageModified: boolean;
  isMultisite: boolean;
  hiLiDecisionStatus: string;
  modifiedDate: string;
  modifiedByUserName: string;
}

export interface UnderwritingRisk {
  id: string;
  summary: string;
  name: string;
  sequence: number;
  letterTypeId: number;
  approvedAttorneyId: number;
  agentClosingAttorneyId: number;
  myClosingAttorneyId: number;
}

export interface Document {
  id: number;
  name: string;
  size: string;
  createdDate: string;
  type: string;
  fileType: string;
  isUserUploaded: boolean;
}

export const riskTypeMap = {
  AuthLimit: 'Authorized Risk Limit Exceeded',
  ClaimParty: 'Claim Alert',
  ClaimProperty: 'Claim Alert',
  FinCEN: 'FinCEN GTO Match',
  Foreign: 'Foreign Seller',
  LowAssess: 'Low Assessed Value',
  LowMarket: 'Low Market Value',
  NonOwner: 'Non-Owner Occupied Property',
  OwnerAddr: 'Owner Address Mismatch',
  OwnerName: 'Owner Name Mismatch',
  SDN: 'SDN Match',
  Unknown: 'Potential Unknown Risk',
  Vacant: 'Vacant Land',
  WatchListParty: 'Watchlist - Party',
  WatchListProperty: 'Watchlist - Property',
};

export const missingDataRiskTypeMap = {
  AssessedValue: 'Assessed Value',
  ClaimAlertParty: 'Claim Alert',
  FinCen: 'FinCEN GTO',
  MarketValue: 'Market Value',
  MortgageBalance: 'Mortgage Balance',
  Occupancy: 'Owner Occupancy',
  OwnerAddr: 'Owner Address',
  OwnerName: 'Owner Name',
  PolicyLiability: 'Policy Liability',
  Property: 'Unable to validate Order Insights for a property',
  PropertyInfo: 'Property Information',
  SDN: 'SDN',
  Unknown: 'Potential Unknown Risk',
  WatchListParty: 'Watchlist - Party',
};
