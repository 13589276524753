import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { warningIconPath } from './constants';

function WarningIcon(props: SvgIconProps): React.ReactElement {
  const { htmlColor } = props;
  return (
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path fill={htmlColor ? htmlColor : `#B3150A`} d={warningIconPath}></path>
    </SvgIcon>
  );
}
//#B3150A
export default WarningIcon;
