import { getApiHost } from '../host-by-env';
import { handleError } from 'utilities/utilities';

export async function searchReports(
  token: string,
  firmId: string,
  tabName: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/${tabName?.toLowerCase()}/${firmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function exportReports(files: any, token: string, tabName: string): Promise<any> {
  const url = `${getApiHost()}remittance/${tabName?.toLowerCase()}/export`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(files),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getRateAndFeeData(
  token: string,
  fileId: string,
  type: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/${type}/${fileId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function addRemoveQueue(
  token: string,
  payload: { files: string[] },
  type: 'add' | 'remove',
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/report/queue/${type}`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function calculateReports(
  token: string,
  type: string,
  payload: any,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/${type}/calculate`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function ReportFiles(files: any, token: string, tabName: string): Promise<any> {
  const url = `${getApiHost()}remittance/${tabName?.toLowerCase()}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(files),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getPayInvoiceData(payload: any, token: string): Promise<any> {
  const url = `${getApiHost()}remittance/pay/orders/details`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function orderDetails(orders: any, token: string): Promise<any> {
  const url = `${getApiHost()}remittance/pay/orders/details`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(orders),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getPayOrdersWithRange(token: string, payload: any): Promise<any> {
  const url = `${getApiHost()}remittance/pay`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function setAutoReport(
  token: string,
  payload: { files: string[]; onHold: boolean },
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getApiHost()}remittance/auto-report`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}
