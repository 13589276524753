import React from 'react';
import moment from 'moment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { formatDate } from './invoicesListConfig';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type InvoiceDateRendererProps = {
  value?: string;
  tab?: number;
};

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#fff',
    color: ' #423d3d',
    fontSize: '13px',
    padding: '5px',
    whiteSpace: 'nowrap',
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif;',
    border: '1px solid lightgrey',
  },
});

const InvoiceDateRenderer = ({ value, tab }: InvoiceDateRendererProps) => {
  const invoiceDt = moment(value);
  const currDt = moment(new Date());
  const dayDiff = moment.duration(currDt.diff(invoiceDt)).asDays();
  const classes = useStyles(); // Declare the 'classes' variable

  return (
    <>
      {dayDiff > 90 && tab == 0 ? (
        <div className="error-status d-flex-center">
          <Tooltip title={formatDate(value ?? '')} classes={{ tooltip: classes.tooltip }}>
            <span>{formatDate(value ?? '')}</span>
          </Tooltip>
          <span className="invoice-date-icon">
            <Tooltip title="3 months old or older invoice." classes={{ tooltip: classes.tooltip }}>
              <ErrorOutlineIcon />
            </Tooltip>
          </span>
        </div>
      ) : tab == 0 ? (
        <Tooltip title={formatDate(value ?? '')} classes={{ tooltip: classes.tooltip }}>
          <div>{formatDate(value ?? '')}</div>
        </Tooltip>
      ) : (
        <div>{formatDate(value ?? '')}</div>
      )}
    </>
  );
};

export default InvoiceDateRenderer;
