import React from 'react';

function NoFilesToReview(props: any): React.ReactElement {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="132" height="105" fill="none">
      <path
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M64.71 103.337c28 0 50.701-22.7 50.701-50.8 0-28.1-22.8-50.8-50.7-50.8-28 0-50.7 22.7-50.7 50.8 0 28.1 22.7 50.8 50.7 50.8z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M56.661 28.496l21.483 44.11a2.998 2.998 0 01-1.445 4.023L33.364 97.095a2.999 2.999 0 01-4.023-1.445L2.412 38.56a2.999 2.999 0 011.445-4.023L35.22 19.77l21.441 8.727z"
      ></path>
      <path fill="#D6DCE8" d="M35.22 19.769l5.744 12.225c.834 1.682 2.98 2.423 4.662 1.59l11.035-5.088"></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M94.714 32.892l.622 49.059a2.998 2.998 0 01-3.022 3.023l-47.925.032a2.999 2.999 0 01-3.023-3.022l-.013-63.121a2.999 2.999 0 013.022-3.023l34.667.015 15.672 17.037z"
      ></path>
      <path fill="#D6DCE8" d="M79.042 15.855l-.018 13.508c.037 1.877 1.662 3.462 3.539 3.425l12.151.104"></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M124.872 54.65l-20.041 44.837c-.667 1.513-2.531 2.175-4.022 1.425L57.389 80.89c-1.512-.667-2.174-2.531-1.507-4.044L82.326 19.57c.667-1.513 2.531-2.175 4.022-1.425l31.528 14.565 6.996 21.942z"
      ></path>
      <path fill="#D6DCE8" d="M117.793 32.687l-5.65 12.284c-.816 1.74.051 3.836 1.791 4.652l10.938 5.028"></path>
    </svg>
  );
}

export default NoFilesToReview;
