import React, { useEffect, useRef, useState } from 'react';
import { AgentNetDivider } from '../../../../ui-kit/components/dividers/AgentNetDivider2';
import { Grid } from '@material-ui/core';
import {
  additionalPartyFields,
  Attorney,
  closingAttorneys,
  CPLStatusType,
  CreateCPLType,
  SecondPartyOptions,
  SecondPartyType,
} from 'features/cpl/types';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import SuggestDropdown from 'ui-kit/inputs/SuggestDropdown/SuggestDropdown';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { additionalPartiesSchema } from 'utilities/validation/schemas/cpl-schema';

interface AdditionalPartiesProps {
  index?: number;
  value: CreateCPLType | null;
  setValue: (data: CreateCPLType | null) => void;
  status?: CPLStatusType;
  approvedAttorneys?: Attorney[];
  closingAttorneys: closingAttorneys;
  secondPartyOptions?: SecondPartyType;
  additionalPartiesFields?: additionalPartyFields;
  setCplFormData: (data: string | Attorney | SecondPartyOptions, key: string) => void;
  isStarsStatusActive?: boolean;
  noStarsStatusActive?: boolean;
  attorneysList?: CreateCPLType | null;
  escrowParties?: any;
  titleParties?: any;
  showValidation: boolean;
  validationErrors: FieldValidationError[];
  getFilteredErrors: (errs: FieldValidationError[], fields: string[]) => void;
}

const AdditionalParties: React.FC<AdditionalPartiesProps> = ({
  index,
  value,
  setValue,
  status = '',
  approvedAttorneys,
  closingAttorneys,
  secondPartyOptions,
  additionalPartiesFields,
  setCplFormData,
  isStarsStatusActive,
  noStarsStatusActive,
  attorneysList,
  escrowParties,
  titleParties,
  showValidation,
  validationErrors,
  getFilteredErrors,
}: AdditionalPartiesProps) => {
  const initialRender = useRef(true);
  const [approvedAttorney, setApprovedAttorney] = useState<Attorney>(
    approvedAttorneys?.find((ele) => ele.approvedAttorneyId === additionalPartiesFields?.approvedAttorneys?.default) ??
      {},
  );
  const [closingAttorney, setClosingAttorney] = useState<Attorney>(
    closingAttorneys?.Attorneys?.find(
      (ele) =>
        ele.closingAttorneyId === additionalPartiesFields?.agentClosingAttorneys?.default ||
        ele.closingAttorneyId === additionalPartiesFields?.myClosingAttorneys?.default,
    ) ?? {},
  );
  const [secondParty, setSecondParty] = useState<SecondPartyOptions>(
    secondPartyOptions?.titleSecondParties?.find(
      (ele) => ele.officeId === additionalPartiesFields?.titleSecondParties?.default,
    ) ?? {},
  );
  const additionalPartyFilters = [
    { label: 'Title', value: 'title' },
    { label: 'Escrow', value: 'escrow' },
  ];
  const initialClosingAttorneyId =
    value?.cpl?.myClosingAttorney?.closingAttorneyId ?? value?.cpl?.agentClosingAttorney?.closingAttorneyId;

  const initialSecondParty =
    additionalPartyFilters.find((party) => party.value === value?.cpl?.secondPartyRole?.toLowerCase()) ??
    additionalPartyFilters[0];
  const [secondPartyFilter, setSecondPartyFilter] = useState<RadioProps>(initialSecondParty);
  const [secondPartyType, setSecondPartyType] = useState(
    secondPartyFilter?.value === 'title'
      ? secondPartyOptions?.titleSecondParties
      : secondPartyOptions?.escrowSecondParties,
  );

  useEffect(() => {
    doValidate(
      {
        approvedAttorney: approvedAttorney.name ?? approvedAttorney.dbaName,
        closingAttorney: closingAttorney.firstName,
        secondParty: secondParty.officeName,
      },
      additionalPartiesSchema([
        additionalPartiesFields?.approvedAttorneys?.require,
        additionalPartiesFields?.agentClosingAttorneys?.require,
        additionalPartiesFields?.myClosingAttorneys?.require,
        additionalPartiesFields?.titleSecondParties?.require,
      ]),
    ).then((errs: FieldValidationError[]) => {
      getFilteredErrors(errs, ['approvedAttorney', 'closingAttorney', 'secondParty']);
    });
  }, [approvedAttorney, closingAttorney, secondParty]);

  useEffect(() => {
    if (value?.cpl) {
      const initialApprovedAttorney =
        approvedAttorneys?.find(
          (attorney) => attorney.approvedAttorneyId === value?.cpl?.approvedAttorney?.approvedAttorneyId,
        ) ??
        attorneysList?.approvedAttorneys?.find(
          (attorney) => attorney.approvedAttorneyId === value?.cpl?.approvedAttorney?.approvedAttorneyId,
        );
      if (!initialApprovedAttorney && value?.cpl?.approvedAttorney?.approvedAttorneyId) {
        value.approvedAttorneys &&
          setValue({ ...value, approvedAttorneys: [...value.approvedAttorneys, value?.cpl?.approvedAttorney] });
      }
      setApprovedAttorney(initialApprovedAttorney ?? {});

      const initialClosingAttorney =
        closingAttorneys?.Attorneys?.find((attorney) => attorney.closingAttorneyId === initialClosingAttorneyId) ??
        allclosingAttorneys?.find((attorney) => attorney.closingAttorneyId === initialClosingAttorneyId);
      if (!initialClosingAttorney && initialClosingAttorneyId) {
        if (closingAttorneys?.Attorneys) {
          if (closingAttorneys.attorneyType.key === 'myClosingAttorney') {
            setValue({
              ...value,
              myClosingAttorneys: [...(closingAttorneys?.Attorneys ?? {}), value.cpl.myClosingAttorney ?? {}],
            });
          } else {
            setValue({
              ...value,
              agentClosingAttorneys: [...(closingAttorneys?.Attorneys ?? {}), value.cpl.agentClosingAttorney ?? {}],
            });
          }
        }
      }
      setClosingAttorney(initialClosingAttorney ?? {});

      const initialSecondPartyType =
        secondPartyType?.find((partyType) => partyType.officeId == value?.cpl?.secondParty?.officeId) ??
        fullListSecondParties?.find((ele: SecondPartyOptions) => ele?.officeId === value?.cpl?.secondParty?.officeId);
      if (!initialSecondPartyType && value?.cpl?.secondParty?.officeId) {
        secondPartyType && setSecondPartyType([...secondPartyType, value?.cpl?.secondParty]);
      }

      setSecondParty(initialSecondPartyType ?? {});
    } else {
      setApprovedAttorney(
        approvedAttorneys?.find(
          (ele) => ele.approvedAttorneyId === additionalPartiesFields?.approvedAttorneys?.default,
        ) ?? {},
      );
      setClosingAttorney(
        closingAttorneys?.Attorneys?.find(
          (ele) =>
            ele.closingAttorneyId === additionalPartiesFields?.agentClosingAttorneys?.default ||
            ele.closingAttorneyId === additionalPartiesFields?.myClosingAttorneys?.default,
        ) ?? {},
      );
      setSecondParty(
        secondPartyOptions?.titleSecondParties?.find(
          (ele) => ele.officeId === additionalPartiesFields?.titleSecondParties?.default,
        ) ?? {},
      );
    }
  }, [value, secondPartyType, attorneysList, escrowParties, titleParties]);

  useEffect(() => {
    setCplFormData(approvedAttorney, 'approvedAttorney');
  }, [approvedAttorney]);

  useEffect(() => {
    setCplFormData(closingAttorney, closingAttorneys.attorneyType.key);
  }, [closingAttorney]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setSecondParty({});
    }
    setCplFormData(secondPartyFilter.value, 'secondPartyRole');
  }, [secondPartyFilter]);

  useEffect(() => {
    setCplFormData(secondParty, 'secondParty');
  }, [secondParty]);

  const isAdditionalPartiesDisabled = status === 'Void' || isStarsStatusActive || noStarsStatusActive;
  const allclosingAttorneys = attorneysList?.myClosingAttorneys?.length
    ? attorneysList?.myClosingAttorneys
    : attorneysList?.agentClosingAttorneys;

  const approvedAttorneyOptions = approvedAttorneys?.map((el) => {
    const { dbaName, address1, city, stateOrProvince, postalCode } = el;
    return {
      name: `${dbaName ?? ''} ${address1 ? `, ${address1}, ${city}, ${stateOrProvince}, ${postalCode}` : ''}`,
      value: el?.approvedAttorneyId,
    };
  });

  const fullListApprovedAttorney = attorneysList?.approvedAttorneys?.map((el) => {
    const { dbaName, address1, city, stateOrProvince, postalCode } = el;
    return {
      name: `${dbaName ?? ''} ${address1 ? `, ${address1}, ${city}, ${stateOrProvince}, ${postalCode}` : ''}`,
      value: el?.approvedAttorneyId,
    };
  });

  const closingAttorneyOptions = closingAttorneys?.Attorneys?.map((el) => {
    const { firstName, middleName, lastName, address1, city, stateOrProvince, postalCode } = el;
    return {
      name: `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''} ${
        address1 ? `, ${address1}, ${city}, ${stateOrProvince}, ${postalCode}` : ''
      }`,
      value: el?.closingAttorneyId,
    };
  });

  const fullListClosingAttorneys = allclosingAttorneys?.map((el) => {
    const { firstName, middleName, lastName, address1, city, stateOrProvince, postalCode } = el;
    return {
      name: `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''} ${
        address1 ? `, ${address1}, ${city}, ${stateOrProvince}, ${postalCode}` : ''
      }`,
      value: el?.closingAttorneyId,
    };
  });

  const fullListSecondParties = secondPartyFilter?.value === 'title' ? titleParties : escrowParties;

  const secondPartyList = (list?: SecondPartyOptions[]) => {
    return list?.map((el) => {
      const { officeName, address1, address2, city, stateOrProvince, postalCode } = el;
      return {
        name: [officeName, address1, address2, city, stateOrProvince, postalCode]
          .filter((value) => value)
          .join(', ')
          .replace(',,', ','),
        value: el?.officeId,
      };
    });
  };

  return (
    <>
      <AgentNetDivider
        typoVariant="h3"
        disablePaddingX
        title="Additional Parties"
        subtitle="Adding additional parties to the Closing Protection Letter (CPL) is optional and is used when two different parties are involved in the transaction, like Agent A as the Escrow/Settlement agent and Agent B as the Title agent. Some lenders may require both parties on the CPL, and the 2nd Party feature supports this."
        subtitleMuted
      />
      <Grid container spacing={3} className="additional-parties">
        {additionalPartiesFields?.approvedAttorneys?.display ? (
          <Grid item sm={6}>
            <SuggestDropdown
              dataQa={index ? `CPL${index}ApprovedAttorney` : 'CPLApprovedAttorney'}
              label="Approved Attorney"
              recentOptions={approvedAttorneyOptions ?? []}
              fullOptions={[...(approvedAttorneyOptions ?? []), ...(fullListApprovedAttorney ?? [])] ?? []}
              value={approvedAttorney?.approvedAttorneyId ?? ''}
              onChange={(ele) => {
                setApprovedAttorney(
                  approvedAttorneys?.find((attorney) => attorney.approvedAttorneyId === ele?.value) ??
                    attorneysList?.approvedAttorneys?.find((attorney) => attorney.approvedAttorneyId === ele?.value) ??
                    {},
                );
              }}
              disabled={isAdditionalPartiesDisabled || !additionalPartiesFields?.approvedAttorneys?.editable}
              required={additionalPartiesFields?.approvedAttorneys?.require}
              name="approvedAttorney"
              showValidation={showValidation}
              errs={validationErrors}
            />
          </Grid>
        ) : null}

        {additionalPartiesFields?.agentClosingAttorneys?.display ||
        additionalPartiesFields?.myClosingAttorneys?.display ? (
          <Grid item sm={6}>
            <SuggestDropdown
              dataQa={index ? `CPL${index}ClosingAttorney` : 'CPLClosingAttorney'}
              label={closingAttorneys?.attorneyType.label}
              recentOptions={closingAttorneyOptions ?? []}
              fullOptions={[...(closingAttorneyOptions ?? []), ...(fullListClosingAttorneys ?? [])] ?? []}
              value={closingAttorney?.closingAttorneyId ?? ''}
              onChange={(ele) => {
                setClosingAttorney(
                  closingAttorneys?.Attorneys?.find((attorney) => attorney.closingAttorneyId === ele?.value) ??
                    allclosingAttorneys?.find((attorney) => attorney.closingAttorneyId === ele?.value) ??
                    {},
                );
              }}
              disabled={isAdditionalPartiesDisabled}
              required={
                additionalPartiesFields.agentClosingAttorneys?.require ||
                additionalPartiesFields.myClosingAttorneys?.require
              }
              name="closingAttorney"
              showValidation={showValidation}
              errs={validationErrors}
            />
          </Grid>
        ) : null}
        {additionalPartiesFields?.titleSecondParties?.display ? (
          <>
            <Grid Data-QA={index ? `CPL${index}2ndPartyFilter` : 'CPL2ndPartyFilter'} item sm={12}>
              <RadioGroup
                selected={secondPartyFilter}
                options={additionalPartyFilters}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSecondPartyFilter(
                    additionalPartyFilters?.find((option) => option.value === e.target.value) ?? secondPartyFilter,
                  );
                }}
                disabled={isAdditionalPartiesDisabled || additionalPartiesFields?.titleSecondParties?.require}
              />
            </Grid>
            <Grid item sm={6}>
              <SuggestDropdown
                dataQa={index ? `CPL${index}SecondParty` : 'CPLSecondParty'}
                label="Search Second Party"
                recentOptions={secondPartyList(secondPartyType) ?? []}
                fullOptions={secondPartyList(fullListSecondParties) ?? []}
                value={secondParty?.officeId ?? ''}
                onChange={(party) => {
                  setSecondParty(
                    secondPartyType?.find((ele) => ele?.officeId === party.value) ??
                      fullListSecondParties?.find((ele: SecondPartyOptions) => ele?.officeId === party.value) ??
                      {},
                  );
                }}
                disabled={isAdditionalPartiesDisabled || !additionalPartiesFields?.titleSecondParties?.editable}
                required={additionalPartiesFields?.titleSecondParties?.require}
                name="secondParty"
                showValidation={showValidation}
                errs={validationErrors}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};
export default AdditionalParties;
