import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'required';

export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (maxLength?: number): string => `Max length is ${maxLength}`;

const max_characters = {
  wireNumber: 100,
  checkNumber: 100,
  copyRecipient: 200,
  comments: 500,
};

export const paymentSchema = (val: string | undefined): any => {
  return val === 'Wire'
    ? yup.object().shape({
        wireNumber: yup
          .string()
          .required('Wire number required')
          .max(100, maxLengthFieldMessage(max_characters.wireNumber)),
        email: yup.string().max(200, maxLengthFieldMessage(max_characters.copyRecipient)),
        memo: yup.string().max(500, maxLengthFieldMessage(max_characters.comments)),
      })
    : val === 'Check'
    ? yup.object().shape({
        checkNumber: yup.string().max(100, maxLengthFieldMessage(max_characters.checkNumber)),
        email: yup.string().max(200, maxLengthFieldMessage(max_characters.copyRecipient)),
        memo: yup.string().max(500, maxLengthFieldMessage(max_characters.comments)),
      })
    : yup.object().shape({
        ePayId: yup.string().required('Payment name required'),
        email: yup.string().max(200, maxLengthFieldMessage(max_characters.copyRecipient)),
      });
};
