/**
 * Override component default props here
 */
import { ComponentsProps } from '@material-ui/core/styles/props';

const props: ComponentsProps = {
  MuiButton: { variant: 'contained', disableElevation: true },
  MuiCardHeader: {
    titleTypographyProps: {
      variant: 'body1',
    },
    subheaderTypographyProps: {
      variant: 'body2',
    },
  },
  MuiFormControl: {
    variant: 'standard',
  },
  MuiGrid: {
    spacing: 2,
  },
  MuiListItemText: {},
  MuiOutlinedInput: {
    notched: false,
  },
  MuiTextField: {
    variant: 'standard',
  },
  MuiSelect: {
    MenuProps: {
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      getContentAnchorEl: null,
    },
  },
};

export default props;
