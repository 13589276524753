import { getApiHost } from 'api/host-by-env';
import { handleError } from 'utilities/utilities';

export async function GetUserDetails(userId: string): Promise<any> {
  try {
    const url = `${getApiHost()}firms/manageUsers/userActivationDetails/${userId?.trim()}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const json = await response.json();
    handleError(json);
    return json;
  } catch (error) {
    console.error('Error fetching user activation details:', error);
    return null;
  }
}

export async function SaveUserDetails(userDetails: any): Promise<any> {
  const url = `${getApiHost()}firms/manageUsers/userActivationDetails`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userDetails),
  });
  const json = await response.json();
  return json;
}
