import React from 'react';
import { IAgentNetBaseFormGroupProps, withBaseFormGroup } from 'utilities/form/components/base-form';

import { fileInfoType } from 'api/file/interfaces/get-file';
import FileInfoEditForm from './FileInfoEditForm';

const FileInfoEditFormWithBaseFormGroup = (props: IAgentNetBaseFormGroupProps<fileInfoType>): JSX.Element => {
  const formWithBase = withBaseFormGroup<fileInfoType, IAgentNetBaseFormGroupProps<fileInfoType>>(
    FileInfoEditForm,
    props,
  );
  return <>{formWithBase}</>;
};
export default FileInfoEditFormWithBaseFormGroup;
