import createBreakpoints, { Breakpoints } from '@material-ui/core/styles/createBreakpoints';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1600,
  },
} as Breakpoints;

export default createBreakpoints(breakpoints);
