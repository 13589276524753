import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function AutoReportingIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <path
        fill="#0B79D0"
        d="M22 10.99L19.56 8.2l.34-3.69-3.61-.82L14.4.5 11 1.96 7.6.5 5.71 3.69 2.1 4.5l.34 3.7L0 10.99l2.44 2.79-.34 3.7 3.61.82 1.89 3.2 3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69 2.44-2.8zm-3.95 1.48l-.56.65.08.85.18 1.95-1.9.43-.84.19-.44.74-.99 1.68-1.78-.77-.8-.34-.79.34-1.78.77-.99-1.67-.44-.74-.84-.19-1.9-.43.18-1.96.08-.85-.56-.65L2.67 11l1.29-1.48.56-.65-.09-.86-.18-1.94 1.9-.43.84-.19.44-.74.99-1.68 1.78.77.8.34.79-.34 1.78-.77.99 1.68.44.74.84.19 1.9.43-.18 1.95-.08.85.56.65 1.29 1.47-1.28 1.48z"
      ></path>
      <path fill="#0B79D0" d="M9.09 12.75l-2.32-2.33-1.48 1.49 3.8 3.81 7.34-7.36-1.48-1.49-5.86 5.88z"></path>
    </SvgIcon>
  );
}

export default AutoReportingIcon;
