import { countyType, propertyType } from './interfaces/get-file';
import { getApiHost } from '../host-by-env';
import { handleError } from 'utilities/utilities';

/* Use Async to Handle Property Details */

export type PropertyInfoProps = {
  propertyId: string;
};

/* Use Async GET Request */
export const getPropertyFromList = async (
  payload: propertyType,
  fileId: string,
  propertyId: string,
  token: string,
): Promise<propertyType> => {
  const url = `${getApiHost()}file/${fileId}/property/${propertyId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  return json.result;
};

/* Use Async POST Request */
export const addPropertyToList = async (
  payload: propertyType,
  fileId: string,
  token: string,
): Promise<propertyType> => {
  const url = `${getApiHost()}file/${fileId}/property`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};

/* Use Async PUT Request */
export const updatePropertyInList = async (
  payload: propertyType,
  fileId: string,
  propertyId: string,
  token: string,
): Promise<propertyType> => {
  const url = `${getApiHost()}file/${fileId}/property/${propertyId}`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  return json.result;
};

/* Use Async DELETE Request */
export const deletePropertyFromList = async (
  fileId: string,
  propertyId: string,
  token: string,
): Promise<propertyType> => {
  const url = `${getApiHost()}file/${fileId}/property/${propertyId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};
/* Use Async DELETE Request */
export const getCountiesPropertyFromList = async (state: string, token: string): Promise<countyType> => {
  const url = `${getApiHost()}file/counties/${state}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};
