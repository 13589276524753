export const content = 'IMPORTANT: READ CAREFULLY';
export const openFile = 'Open';

export const createNewServiceOrder = 'Would you like to create one now?';
export const noServiceOrderSupport = 'No Service Orders Found.  File Status does not support Service Order creation';
export const noActiveServiceOrders = 'No Active Service Orders Found';
export const editServiceOrderMessage =
  'Please include any comments to assist the production team with fulfilling your order.';
export const WindowFeatures = 'popup';

export enum ServiceOrdersActions {
  edit = 'Edit',
  view = 'View',
  expandCollapseActive = 'ExpandCollapseActive',
  expandCollapseInActive = 'ExpandCollapseInActive',
  onInactiveToggleChange = 'OnInactiveToggleChange',
}
export enum ServiceOrderStatus {
  orderplaced = 'Order Placed',
  orderdelivered = 'Order Delivered',
  closed = 'Closed',
  cancelled = 'Cancelled',
  openedInError = 'Opened In Error',
  updateDelivered = 'Update Delivered',
  updateRequested = 'Update Requested',
}
