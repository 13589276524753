import { handleError } from 'utilities/utilities';
import { getApiHost } from '../host-by-env';
import { ContactUsSendMessageDTO } from './types';

export async function contactUsSendMessage(payload: ContactUsSendMessageDTO): Promise<boolean> {
  try {
    const url = `${getApiHost()}user/contact-us/send-message`;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
    });
    const json = await response.json();
    handleError(json);
    return json.result;
  } catch (error) {
    console.error('Error in contactUsSendMessage', error);
    return false;
  }
}
